import { Col, InputNumber, Row, Slider, Tooltip } from "antd"

import { QuestionCircleOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
type SliderSettingProps = {
    title: string;
    min: number;
    max: number;
    input: number;
    step: number;
    onChange: Function;
    hidden: boolean;
    toolTipMin?: string;
    toolTipMax?: string;
    toolTip: string
}

const SliderSettings = (props: SliderSettingProps) => {
    const { t } = useTranslation();
    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    };
    return (
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} className={`mt-4 gap-4  ${props.hidden ? 'hidden' : ''}`}>
            <Col span={24} className="flex gap-2">
                <h4>{props.title}</h4>
                <Tooltip trigger={isTouchDevice() ? 'click' : 'hover'} title={t(props.toolTip)}>
                    <QuestionCircleOutlined className="text-[#0047FF]" />
                </Tooltip>
            </Col>
            <Col span={24}>
                <Row className="gap-3 center-element">
                    <Col span={18} className="gap-3 center-element">
                        <div className="font-light" style={{
                            fontSize: '12px',
                            minWidth: 'fit-content'
                        }}>{props.toolTipMin}</div>
                        <Slider
                            style={{
                                width: '100%'
                            }}
                            min={props.min}
                            max={props.max}
                            onChange={(e) => props.onChange(e)}
                            value={props.input}
                            step={props.step}
                        />
                        <div className="font-light" style={{
                            fontSize: '12px'
                        }}>{props.toolTipMax}</div>
                    </Col>
                    <Col span={3}>
                        <InputNumber
                            min={props.min}
                            max={props.max}
                            pattern="[0-9]*"
                            inputMode='decimal'
                            style={{ margin: '0 16px', width: '80px' }}
                            step={props.step}
                            value={props.input}
                            onChange={(e) => props.onChange(e)}

                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
export default SliderSettings