import React from 'react'
import { FashionToolType } from '../../utils/enum'
import TagName from './TagName'
import { useTranslation } from 'react-i18next'

function BoxTagName({ image }: any) {
    const { t } = useTranslation()
    switch (image.type) {
        case FashionToolType.UPSCALE:
            return <TagName name={'Upscale'} />
        case FashionToolType.SWAPFACE:
            return <TagName name={'Swapface'} />
        case FashionToolType.REMOVE_OBJECT:
            return <TagName name={t('remove_object')} />
        case FashionToolType.BORDER_SMOOTH:
            return (<TagName name={t('border_smooth')} />)
        case FashionToolType.REMOVE_REDUNDANT_DETAIL:
            return (<TagName name={t('remove_redundant_detail')} />)
        case FashionToolType.CORRECT_PART:
            return (<TagName name={t('correct_part')} />)
        case FashionToolType.TRY_OUTFIT:
            return (<TagName name={t('try_outfit')} />)

        default:
            return (
                <>
                    <TagName name={image.templateType} />
                    <TagName name={image.templateName} />
                </>
            )
    }
}

export default BoxTagName