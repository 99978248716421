import { createSlice } from '@reduxjs/toolkit';

interface ErrorState {
  typeError: string;
}

const initialState: ErrorState = {
  typeError: '',
};


const eventSelect = createSlice({
  name: 'setError',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.typeError = action.payload
    },
  },
});


export const setError = eventSelect.actions;
export default eventSelect.reducer;