import { takeLatest } from 'redux-saga/effects';
import { deleteToken } from '../../services/local-storage.service';
import { History } from '../../utils/history';
import { authActions } from './authSlice';

// eslint-disable-next-line require-yield
function* logoutSaga() {
    deleteToken();
    History.navigate('/login');
}

export default function* authSaga() {
    yield takeLatest(authActions.logout, logoutSaga);
}
