import swal from "sweetalert";
import SelectCustomItem from "./SelectCustomItem"
import { Skeleton, Tabs, TabsProps } from "antd";
import { useTranslation } from 'react-i18next';
import { FashionParams } from "../../utils/interface";
import SelectModel from "../SelectModel/SelectModel";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError } from "../../redux/app/errorSelect";
import { useAppSelector } from "../../redux/hooks/useAppSelector";
type CustomizeProps = {
  fashionParams: FashionParams;
  setFashionParams: Function;
  listCustomItems: any,
  setModel: Function,
  model: any,
  mockupModel: any,
  selectTemplateRef: any,
  isLoading: boolean;
  initImage: string
}

const Customize = (props: CustomizeProps) => {
  const { t } = useTranslation()
  const [active, setActive] = useState('0')
  const dispatch = useDispatch();
  const errorType = useAppSelector((store) => store.errorInput.typeError);
  const templateName = useAppSelector((store) => store.select.typeModel);
  useEffect(() => {
    setActive('0')
  }, [templateName])
  const onChangeItem = (value: string[], code: string, other?: boolean) => {
    if (props.fashionParams.templateName === '' && code !== 'place') {
      swal(t('dont_select_modal'), '', 'warning').then((res) => {
        setActive('0')
        dispatch(setError.setError('no_select_hair'))
      });
      return;
    }

    // Create a shallow copy of the array
    const selectedItemsCopy = JSON.parse(JSON.stringify(props.fashionParams.selectedItems));

    const existingIndex = selectedItemsCopy.findIndex((item: any) => item.code === code);
    if (existingIndex !== -1) {
      // If the code is a duplicate, replace the data in the copied array
      selectedItemsCopy[existingIndex].data = value;
      selectedItemsCopy[existingIndex].other = other;
    } else {
      // If the code is not a duplicate, add the new object to the copied array
      selectedItemsCopy.push({ code, data: value, other });
    }
    props.setFashionParams((prevParams: FashionParams) => ({
      ...prevParams,
      selectedItems: selectedItemsCopy,
    }));
  }

  const setBackgroundImage = (uploadedBackgroundImage: string) => {
    props.setFashionParams((prevParams: FashionParams) => ({
      ...prevParams,
      backgroundImage: uploadedBackgroundImage,
    }));
  }

  const items2 = props.listCustomItems?.filter((item: any) => item.optionNumber === 1).map((item: any) => {
    let selectedItem = [''];
    try {
      const selectedItemAfterFilter = props.fashionParams?.selectedItems?.filter((item2: any) => item2.code === item.code);
      if (selectedItemAfterFilter?.length > 0) {
        selectedItem = Array.isArray(selectedItemAfterFilter[0].data) ? selectedItemAfterFilter[0].data : [selectedItemAfterFilter[0].data]
      }
      return {
        key: (item.index + 1).toString(),
        label: item.name,
        children: <SelectCustomItem
          title={item.name}
          isMultipleSelection={item.multiChoice}
          hasImage={item.hasImage}
          onChangeSelectCustomItem={onChangeItem}
          listCustomItems={item.data}
          selectedItems={selectedItem}
          code={item.code}
          selectedOtherCustomize={selectedItemAfterFilter?.[0]?.other}
          promptExample={item.prompt_example}
          setBackgroundImage={setBackgroundImage}
          setFashionParams={props.setFashionParams}
          fashionParams={props.fashionParams}
        />,
      }
    } catch (error) {
      console.log('error: ', error);
    }
  })

  const items3 = props.listCustomItems?.filter((item: any) => item.optionNumber === 2).map((item: any) => {
    let selectedItem = [''];
    const selectedItemAfterFilter = props.fashionParams?.selectedItems?.filter((item2: any) => item2.code === item.code);
    if (selectedItemAfterFilter?.length > 0) {
      selectedItem = Array.isArray(selectedItemAfterFilter[0].data) ? selectedItemAfterFilter[0].data : [selectedItemAfterFilter[0].data]
    }
    return {
      key: (item.index + 1).toString(),
      label: item.name,
      children: <SelectCustomItem
        setFashionParams={props.setFashionParams}
        fashionParams={props.fashionParams}
        title={item.name}
        isMultipleSelection={item.multiChoice}
        hasImage={item.hasImage}
        onChangeSelectCustomItem={onChangeItem}
        listCustomItems={item.data}
        selectedItems={selectedItem}
        code={item.code}
        selectedOtherCustomize={selectedItemAfterFilter?.[0]?.other}
        promptExample={item.prompt_example}
        setBackgroundImage={setBackgroundImage}
        initImage={props.initImage}
      />,
    }
  })

  const items: TabsProps['items'] = [
    {
      key: '0',
      label: t('select_modal'),
      children: <SelectModel isLoading={props.isLoading} setModel={props.setFashionParams} model={props.fashionParams.templateCode} mockupModel={props.mockupModel} selectTemplateRef={props.selectTemplateRef} />,
    },
    ...items2 ?? [],
  ];

  return (
    <> {props.isLoading ?
      <>
        <div className="flex flex-col">
          <h2 className="  step-select-model text-sm font-semibold text-gray-800 flex gap-1">2. {t('Model selection and customize')}
            {(errorType === 'no_select_bg' || errorType === 'no_select_light' || errorType === 'no_select_age' || errorType === 'no_select_acc' || errorType === 'no_select_skin' || errorType === 'no_select_hair') && <div className='text-[red] text-xl'>*</div>}</h2>
          <Tabs activeKey={active} onChange={e => setActive(e)} items={items} className="text-sm font-normal leading-5 text-[#667085]" />
        </div>
        <div className="flex flex-col">
          <h2 className="step-select-bg text-sm font-semibold text-gray-800 flex gap-1">3. {t('select_bg_and_light')}</h2>
          <Tabs items={items3} className="text-sm font-semibold leading-5 text-[#667085]" />
        </div>
      </>
      :
      <div className="flex flex-col gap-4 flex-wrap">
        <div className="flex gap-4">
          <Skeleton.Button active={true} />
          <Skeleton.Button active={true} />
          <Skeleton.Button active={true} />
          <Skeleton.Button active={true} />
          <Skeleton.Button active={true} />
          <Skeleton.Button active={true} />
        </div>
        <div className="flex gap-4 flex-wrap">
          <Skeleton.Image active={true} />
          <Skeleton.Image active={true} />
          <Skeleton.Image active={true} />
          <Skeleton.Image active={true} />
          <Skeleton.Image active={true} />
        </div>
      </div>
    }
    </>
  )
}
export default Customize