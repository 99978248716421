import { createSlice } from "@reduxjs/toolkit";
import { FeatureTypeEnum } from "../../utils/enum";

interface IModalSub {
  openModalSub: boolean;
  typeFeature: string;
}

const initialState: IModalSub = {
  openModalSub: false,
  typeFeature: FeatureTypeEnum.MODEL_AI,
};

const eventModalSub = createSlice({
  name: "setIsOpenModalSub",
  initialState,
  reducers: {
    setIsOpenModalSub: (state, action) => {
      state.openModalSub = action.payload;
    },
    setTypeFeatureActive: (state, action) => {
      state.typeFeature = action.payload;
    },
  },
});

export const ModalSub = eventModalSub.actions;
export default eventModalSub.reducer;
