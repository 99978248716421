import { getAnalytics } from "firebase/analytics";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDI1VJ-BSeHEBcLws49Lzg8Qrzzd6_Uh5w",
  authDomain: "modeli-1c646.firebaseapp.com",
  projectId: "modeli-1c646",
  storageBucket: "modeli-1c646.appspot.com",
  messagingSenderId: "817274340994",
  appId: "1:817274340994:web:58bb006643a312a537169b",
  measurementId: "G-SNY94G5WQ3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default analytics