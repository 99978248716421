import { Button, Empty, Spin, Tabs, TabsProps } from "antd";
import { CloseCircleOutlined } from '@ant-design/icons';
import './styles.css'
import search from '../../assets/icons/search.svg';
import { useEffect, useState } from "react";
import TutorService from "../../services/tutor.service";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { ROUTERS } from "../../utils/constant";
import { t } from "i18next";
import { useTour } from "@reactour/tour";

function Tutor() {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const currentLanguage = i18n.language;
    const [data, setData] = useState<any>([]);
    const [key, setKey] = useState<any>('beginner');
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [isShowTourStart, setIsShowTourStart] = useState(true);
    const { setIsOpen } = useTour();
    const items: TabsProps['items'] = [
        {
            key: 'beginner',
            label: t('for_beginner')
        },
        {
            key: 'experienced',
            label: t('for_experienced')
        }
    ];

    const getData = async () => {
        setLoading(true);
        const data = await TutorService.getInstance().getTutorList(currentLanguage, key, searchTerm);
        setData(data.finalResult);
        setLoading(false);
    }

    const handleChange = debounce((event) => {
        setSearchTerm(event.target.value);
    }, 500);

    const onClickStart = () => {
        localStorage.setItem('FirstUser', 'false')
        navigate('/')
        setIsOpen(true)
    }
    const onClickClose = () => {
        setIsShowTourStart(false)
    }

    useEffect(() => {
        getData();
    }, [currentLanguage, key, searchTerm])

    return (
        <div className="tutor">
            <div className="tutor-header md:flex-col md:mb-3">
                <div className="tutor-header-left">
                    <Tabs defaultActiveKey={key} items={items} onChange={(key) => setKey(key)} />
                </div>
                <div className="tutor-header-right">
                    <div className="search-box">
                        <img src={search} alt="" />
                        <input type="text" className="search-input" placeholder="Search" onChange={(event) => handleChange(event)} />
                    </div>
                </div>
            </div>
            {isShowTourStart && <div className="bg-[#F3F6FF] w-full rounded-sm flex justify-between items-center">
                <div className="text-left flex flex-col gap-1 p-10">
                    <div className="font-semibold text-[16px]">
                        {t('start_tour_text_1')}
                    </div>
                    <div className="text-[#667085] font-medium text-[14px]">
                        {t('start_tour_text_2')}
                    </div>
                </div>
                <div className="icon-right p-10">
                    <div className="flex gap-4" >
                        <Button type="primary" size="large" onClick={onClickStart}>{t('start_tour')} </Button>
                        <CloseCircleOutlined className="text-2xl" onClick={onClickClose} />
                    </div>
                </div>
            </div>}
            <div className="tutor-body mt-2">

                {
                    !loading
                        ?
                        data.length > 0
                            ? data.map((ele: any, index: number) => {
                                return (
                                    <>
                                        <div className="topic">
                                            {ele.group}
                                        </div>
                                        <div className="grid grid-cols-4 2xl:grid-cols-3 xl:grid-cols-2 md:grid-cols-1">
                                            {
                                                ele.posts.map((item: any, index: number) => {
                                                    return (
                                                        <div className="item cursor-pointer" onClick={() => { navigate(`${ROUTERS.TUTOR}/${item.id}`) }}>
                                                            <div className="image hover:animate-pulse">
                                                                <div className="w-[316px] h-[178px] rounded-xl" style={{
                                                                    backgroundImage: `url(${item.cover_image})`,
                                                                    objectFit: 'cover',
                                                                    backgroundSize: 'cover',
                                                                    backgroundPositionX: '50%',
                                                                    backgroundPositionY: '50%'
                                                                }}></div>
                                                                {/* <img className="image-item" src={item.cover_image} alt="bg-image" width={316} height={178} /> */}
                                                            </div>
                                                            <div className="title">
                                                                {item.title}
                                                            </div>
                                                            <div className="short">
                                                                {item.summary}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </>
                                )
                            })
                            : <div className="flex justify-center "><Empty /></div>
                        : <div className="flex justify-center"><Spin size="large" /></div>
                }
            </div>
        </div>
    )
}

export default Tutor