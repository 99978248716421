import { Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTERS } from '../../utils/constant';
import TutorService from '../../services/tutor.service';
import { useNavigate, useParams } from 'react-router-dom';
import './styles.css'
import { calculateReadingTime } from '../../utils/function';

const TutorDetail: React.FC = () => {
    const nav = useNavigate();
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const [detailData, setDetailData] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const getDetailData = async () => {
        setLoading(true);
        const data = await TutorService.getInstance().getTutorDetail(id);
        setDetailData(data);
        setLoading(false);
    }

    useEffect(() => {
        getDetailData();
    }, [id])

    return (
        <div className='detail-container'>
            <div className='breadcrumb'>
                <Breadcrumb
                    items={[
                        {
                            title: <span className='cursor-pointer' onClick={() => { nav(ROUTERS.TUTOR) }}>{t('tutor')}</span>,
                        },
                        {
                            title: detailData.title,
                        },
                    ]}
                />
            </div>
            <div className='estimated-time'>{detailData.content && calculateReadingTime(detailData.content, i18n.language)}</div>
            <div className='detail-title'>
                {detailData.title}
            </div>
            <div className='detail-content'>
                <div dangerouslySetInnerHTML={{ __html: detailData.content }} />
            </div>
        </div>

    );
};

export default TutorDetail;