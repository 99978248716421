import React from 'react'
type TagNameProps ={
  name:string
}
function TagName({name}:TagNameProps) {
  return (
    <div className='rounded-2xl bg-[#F5F5F5] px-4 text-center  h-fit text-sm font-medium leading-5'>
        {name}
    </div>
  )
}

export default TagName