import { createSlice } from '@reduxjs/toolkit';

interface isAutoSegmentState {
  isAuto: boolean;
}

const initialState: isAutoSegmentState = {
    isAuto: true,
};


const eventSetIsAuto = createSlice({
  name: 'setIsAuto',
  initialState,
  reducers: {
    setIsAuto: (state, action) => {
      state.isAuto = action.payload
    },
  },
});


export const setIsAuto = eventSetIsAuto.actions;
export default eventSetIsAuto.reducer;