import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentGuideVN from './GuideVN';
import PaymentGuideEN from './GuideEN';
type PaymentGuideProps = {
  setOpenPaymentModal: Function
}
function PaymentGuide({ setOpenPaymentModal }: PaymentGuideProps) {
  const { i18n } = useTranslation()
  return (
    <div>
      {i18n.language === 'vi' ? <PaymentGuideVN setOpenPaymentModal={setOpenPaymentModal} /> : <PaymentGuideEN setOpenPaymentModal={setOpenPaymentModal} />}
    </div>
  );
}

export default PaymentGuide;
