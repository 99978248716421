import { Button, Modal, Skeleton } from 'antd'
import { useEffect, useState } from 'react'
import iconUpload from '../../assets/icons/upload-cloud.png';
import tip from '../../assets/icons/idea.svg';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { convertBlobToBase64, convertHeicToJpg, getImageSize, resizeImageMax2k, urlToBase64 } from '../../utils/function';
import FashionService from '../../services/fashion.service';
import { useDispatch } from 'react-redux';
import { setTypeModel } from '../../redux/app/selectTypeModel';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import { logEvent } from '@firebase/analytics';
import analytics from '../../firebase';
import PopupAddToWhiteList from '../PopupAddToWhiteList';
import { TypeEditor, WhitelistFeatureEnum, WhitelistTypeEnum } from '../../utils/enum';
import { useDropzone } from 'react-dropzone';
import swal from 'sweetalert';
import { setLess512 } from '../../redux/app/uploadImgeSaga';
import { setLoadingApp } from '../../redux/app/loadingApp';
import "./upload-image.css";
import { toolEditorImage } from '../../redux/app/toolEditor';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import ModalTool from '../../pages/Editor/Tools/ModalTool';
import { isGenStore } from '../../redux/app/appIsGenerating';

type ModalSelectInputProps = {
    isOpen: boolean,
    setIsOpenModalSelect: (val: boolean) => void,
    setLoading: Function,
    getCropData: Function,
    onChangeUrlImage: Function,
    setSegmentImagesWithBody: Function,
    intervalId: any,
    clearInterval: Function,
    onToRemove: Function,
    onChangePose: Function,
    getDataPose: Function,
    cropData: any,
    typeModel: string
    selectImage: Function
    openModalTool: boolean;
    setOpenModalTool: Function
}

const permission = {
    feature: WhitelistFeatureEnum.POSE,
    type: WhitelistTypeEnum.DATA
}

function ModalSelectInput({ isOpen, setIsOpenModalSelect, getCropData, onChangePose, typeModel, getDataPose, selectImage, onChangeUrlImage, openModalTool, setOpenModalTool }: ModalSelectInputProps) {
    const dispatch = useDispatch();
    const fashionParamsReducer = useAppSelector((store) => store.select);
    // ----- USE STATE ----- //
    const [isOpenModalWhiteList, setIsOpenModalWhiteList] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [poseId, setPoseId] = useState('');
    const [sample, setSample] = useState<any>('');
    const [listSample, setListSample] = useState<any>([]);
    const [dataPose, setDataPose] = useState<any>([]);
    const { i18n, t } = useTranslation()

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isVNes = i18n.language === 'vi'

    let linkFileGuide = isVNes ? 'https://docs.google.com/presentation/d/1zb-6lt0dQOH4zCp3XSiXD2mvOJ0EhbO2pyY9DiNv4Po/edit#slide=id.g25d18967e54_0_117' : 'https://docs.google.com/presentation/d/1LTWIWK7JDhJLXVzNnXhCnSfndYDjsWLfSMNzaTf9Iic/edit#slide=id.g25d18967e54_0_117'

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/webp': ['.webp'],
            'image/heic': ['.heic']
        },
        onDrop: async (acceptedFiles: any) => {
            await onDropHandler(acceptedFiles);
        }
    });

    const onDropHandler = async (files: any) => {
        if (files.length === 0) {
            swal(t('file_not_image'), '', 'warning')
        } else {
            const file = files[0];
            let isHEIC = file.type === 'image/heic'
            const reader = new FileReader();
            reader.onload = async (event: any) => {
                let imageUrlFinal = event.target.result;
                if (isHEIC) {
                    dispatch(setLoadingApp.setLoading(true))
                    const response = await fetch(event.target.result);
                    const blob = await response.blob();
                    const coverted = await convertHeicToJpg(blob);
                    imageUrlFinal = await convertBlobToBase64(coverted.resultBlob) as string;
                    dispatch(setLoadingApp.setLoading(false))
                }
                const imgUpload = await getImageSize(imageUrlFinal);

                logEvent(analytics, 'upload_image')
                if (imgUpload?.height < 512 || imgUpload?.width < 512) {
                    openModel(event.target.result)
                    // setIsOpenModalWarning(true)
                } else {
                    dispatch(setLoadingApp.setLoading(true))
                    dispatch(setLess512.setLess512(false))
                    const base64Image = await resizeImageMax2k(imageUrlFinal) as any
                    openModel(base64Image)
                    dispatch(setLoadingApp.setLoading(false))
                }
            };
            reader.readAsDataURL(file);
        }

        try {
            if (files.length === 0) {
                return;
            }
            if (files.length > 1) {
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                selectImage(files[0]);
            };
            reader.readAsDataURL(files[0]);
        } catch (error) {
            console.log(error);
        }
    }

    const getDataPoseByType = async () => {
        try {
            const { data } = await FashionService.getInstance().getListPoseByType(typeModel, i18n.language);
            setDataPose(data);
            data && setIsLoading(true);
            onChangeModelInput(data[0]._id, data[0].name, data[0].sample, data[0].guideline, data[0].is_pose)

        } catch (error: any) {
            console.error(error);
        }
    }

    const openModel = (image: string) => {
        dispatch(toolEditorImage.setImageInput(image))
        dispatch(toolEditorImage.setTypeEdit(TypeEditor.PRE))
        setOpenModalTool(true)
    }

    const onCropImage = (i: any, w: number, h: number) => {
        setIsOpenModalSelect(false)
        getCropData(i, w, h)
        selectImage(i);
    }

    const onChangeModelInput = (id: string, name: string, sample: any, guideline: any, isPose: boolean) => {
        setPoseId(id);
        setListSample(sample);
        setSample('')
        onChangePose(isPose);
        dispatch(setTypeModel.setFashionParams({
            ...fashionParamsReducer,
            poseId: id,
            isClickCFGScale: false,
            isClickSteps: false,
            isClickControlWeight: false,
        }));
    }

    const onChangeModelSample = async (url: string, index: number) => {
        dispatch(setLoadingApp.setLoading(true))
        setSample(index)
        const urlBase64 = await urlToBase64(url + '?not-from-cache-please');
        if (urlBase64) {
            dispatch(setLoadingApp.setLoading(false));
            const base64Image = await resizeImageMax2k('data:image/jpeg;base64,' + urlBase64) as any
            // setImage(base64Image)
            openModel(base64Image)
            // setIsOpenModalUpload(true);
            dispatch(setLess512.setLess512(false));
        }
    }

    const onCancel = () => {
        setIsOpenModalSelect(false)
    }

    const handleGetDataInput = (i: any, w: number, h: number) => {
        onCropImage(i, w, h);
        getDataPose(i, w, h)
    }

    const images = [
        {
            original: "https://media2.vision2art.ai/Slide4.JPG",
            thumbnail: "https://media2.vision2art.ai/Slide4.JPG",
        },
        {
            original: "https://media2.vision2art.ai/Slide5.JPG",
            thumbnail: "https://media2.vision2art.ai/Slide5.JPG",
        },
        {
            original: "https://media2.vision2art.ai/Slide6.JPG",
            thumbnail: "https://media2.vision2art.ai/Slide6.JPG",
        },
        {
            original: "https://media2.vision2art.ai/Slide7.JPG",
            thumbnail: "https://media2.vision2art.ai/Slide7.JPG",
        },
        {
            original: "https://media2.vision2art.ai/Slide8.JPG",
            thumbnail: "https://media2.vision2art.ai/Slide8.JPG",
        },
        {
            original: "https://media2.vision2art.ai/Slide9.JPG",
            thumbnail: "https://media2.vision2art.ai/Slide9.JPG",
        },
        {
            original: "https://media2.vision2art.ai/Slide11.JPG",
            thumbnail: "https://media2.vision2art.ai/Slide11.JPG",
        },
        {
            original: "https://media2.vision2art.ai/Slide12.JPG",
            thumbnail: "https://media2.vision2art.ai/Slide12.JPG",
        },
        {
            original: "https://media2.vision2art.ai/Slide13.JPG",
            thumbnail: "https://media2.vision2art.ai/Slide13.JPG",
        },
    ];
    const imagesEN = [
        {
            original: "https://media2.vision2art.ai/Slide4_en.PNG",
            thumbnail: "https://media2.vision2art.ai/Slide4_en.PNG",
        },
        {
            original: "https://media2.vision2art.ai/Slide5_en.PNG",
            thumbnail: "https://media2.vision2art.ai/Slide5_en.PNG",
        },
        {
            original: "https://media2.vision2art.ai/Slide6_en.PNG",
            thumbnail: "https://media2.vision2art.ai/Slide6_en.PNG",
        },
        {
            original: "https://media2.vision2art.ai/Slide7_en.PNG",
            thumbnail: "https://media2.vision2art.ai/Slide7_en.PNG",
        },
        {
            original: "https://media2.vision2art.ai/Slide8_en.PNG",
            thumbnail: "https://media2.vision2art.ai/Slide8_en.PNG",
        },
        {
            original: "https://media2.vision2art.ai/Slide9_en.PNG",
            thumbnail: "https://media2.vision2art.ai/Slide9_en.PNG",
        },
        {
            original: "https://media2.vision2art.ai/Slide11_en.PNG",
            thumbnail: "https://media2.vision2art.ai/Slide11_en.PNG",
        },
        {
            original: "https://media2.vision2art.ai/Slide12_en.PNG",
            thumbnail: "https://media2.vision2art.ai/Slide12_en.PNG",
        },
        {
            original: "https://media2.vision2art.ai/Slide13_en.PNG",
            thumbnail: "https://media2.vision2art.ai/Slide13_en.PNG",
        },
    ];
    const setIsGenerating = (isGen: boolean) => {
        dispatch(isGenStore.setIsGeneratingStore(isGen))
    }
    // ----- USE EFFECT ----- //
    useEffect(() => {
        dispatch(setLoadingApp.setLoading(false))
        dataPose[0] && onChangeModelInput(dataPose[0]._id, dataPose[0].name, dataPose[0].sample, dataPose[0].guideline, dataPose[0].is_pose)
    }, [dataPose])

    useEffect(() => {
        getDataPoseByType();
    }, [i18n.language, typeModel])

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {isOpen &&
                <Modal className='upload-modal' open={isOpen} width={'1600px'} centered={true} footer={null} onCancel={onCancel}>
                    <PopupAddToWhiteList isShowFormWhiteList={isOpenModalWhiteList} setIsShowFormWhiteList={setIsOpenModalWhiteList} setPermission={permission} />
                    {windowWidth > 640
                        ? <div className='flex flex-row'>
                            <div className='left-section'>
                                <div className='select-input-type-section pt-11 pb-11'>
                                    {isLoading
                                        ? <div className='list-img-selection flex justify-center items-center flex-wrap flex-col'>
                                            {dataPose?.map((val: any, index: number) =>
                                                <div
                                                    className={`flex flex-col items-center img-selection ${poseId === val._id && 'img-selected'}`}
                                                    onClick={(e) => {
                                                        if (!val.active) {
                                                            setIsOpenModalWhiteList(true)
                                                        } else
                                                            onChangeModelInput(val._id, val.name, val.sample, val.guideline, val.is_pose)
                                                    }}
                                                >
                                                    <img className='mb-1' width={72} height={108} src={val.image_url} alt="input-image" />
                                                    <span className="text-center">{val.name}</span>
                                                </div>
                                            )}
                                        </div>
                                        : <div className='list-img-selection flex justify-center items-center gap-4 flex-wrap'>
                                            <Skeleton.Image active={true} />
                                            <Skeleton.Image active={true} />
                                            <Skeleton.Image active={true} />
                                            <Skeleton.Image active={true} />
                                        </div>}
                                </div>
                            </div>
                            <div className='right-section'>
                                <div className='title-section flex justify-center'>
                                    <p>{t('Edit_before_processing')}</p>
                                </div>
                                <div className='process-section grid grid-cols-2'>
                                    <div className='upload-section p-5'>
                                        {/* <div className='upload-title-section'>
                                            <p>{t('upload_your_template')}</p>
                                        </div> */}
                                        <div className='upload-image-section hover:cursor-pointer '>
                                            <div  {...getRootProps({ className: 'dropzone' })} className={`upload-box-section ${isDragActive ? '!bg-zinc-400' : '!bg-[#F3F6FF]'}`}>
                                                <input id="input-upload-image-2" type="file" accept="image/*" {...getInputProps()} className="hidden" />
                                                <img src={iconUpload} draggable="false" width={40} alt="icon-upload" />
                                                <span className={`${isDragActive ? 'text-white' : 'text-gray'} text-upload`}>{t('upload_your_template')}</span>
                                                <span className='text-upload-guide'>{t('drop_file')}</span>
                                                <Button type='primary'>{t('upload_image')}</Button>
                                                <span className='text-upload-guide'>{t('Only_support_image_types_png/jpg/jpeg')}</span>
                                            </div>
                                        </div>
                                        <div className='upload-guide-section pb-10'>
                                            {/* slider guide upload */}
                                            <div className='tip flex gap-1 pb-2 sm:flex-row'>
                                                <img src={tip} alt="" />
                                                <p className='text-[#101828] font-semibold text-[16px]'>{t('gui_input')}</p>

                                            </div>
                                            <ImageGallery items={isVNes ? images : imagesEN} slideInterval={3000} autoPlay showThumbnails={false} showIndex showNav showBullets showPlayButton />
                                            <div className='see_more text-right'>
                                                <a href={linkFileGuide} target='_blank'>{t('see_more')}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='example-section'>
                                        <div className='example-title-section !flex-col justify-start !mt-5 h-auto'>
                                            <p className='text-[16px] text-[#101828] font-semibold'>{t('Try_Images_Here')}</p>
                                            <p className='w-auto break-words text-[#667085] text-[14px]' >{t('des_example')}</p>
                                        </div>
                                        <div className="option-select-img mt-2">
                                            {isLoading
                                                ? <div className='list-img-option justify-center items-center flex gap-4 overflow-y-auto max-h-[600px] grid grid-cols-3 xl:grid-cols-2 lg:grid-cols-1 '>
                                                    {listSample.map((val: any, index: number) => (
                                                        <div
                                                            key={index}
                                                            style={{ padding: 0 }}
                                                            className={classNames('item-model col-span-1 flex justify-center', {
                                                                selected_item: sample === index
                                                            })}
                                                        >
                                                            <div
                                                                className="example-image"
                                                                style={{
                                                                    backgroundImage: `url('${val.image_url}')`,
                                                                }}
                                                            >
                                                                <Button
                                                                    type='primary' className="custom-button"
                                                                    onClick={(e) => onChangeModelSample(val.image_url, index)}
                                                                >
                                                                    {t('select')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                                : <div className='list-img-selection flex justify-center items-center gap-4 flex-wrap'>
                                                    <Skeleton.Image active={true} />
                                                    <Skeleton.Image active={true} />
                                                    <Skeleton.Image active={true} />
                                                    <Skeleton.Image active={true} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div className='mobile-section flex flex-col'>
                            <div className='modal-title-section flex justify-center'>
                                <p>{t('Edit_before_processing')}</p>
                            </div>
                            <div className='left-section'>
                                <div className='select-input-type-section pt-11 pb-11'>
                                    {isLoading
                                        ? <div className='list-img-selection'>
                                            {dataPose?.map((val: any, index: number) =>
                                                <div
                                                    className={`flex flex-col items-center img-selection ${poseId === val._id && 'img-selected'}`}
                                                    onClick={(e) => {
                                                        if (!val.active) {
                                                            setIsOpenModalWhiteList(true)
                                                        } else
                                                            onChangeModelInput(val._id, val.name, val.sample, val.guideline, val.is_pose)
                                                    }}
                                                >
                                                    <img className='mb-1' width={72} height={108} src={val.image_url} alt="input-image" />
                                                    <span className="text-center">{val.name}</span>
                                                </div>
                                            )}
                                        </div>
                                        : <div className='list-img-selection flex justify-center items-center gap-4 flex-wrap'>
                                            <Skeleton.Image active={true} />
                                            <Skeleton.Image active={true} />
                                            <Skeleton.Image active={true} />
                                            <Skeleton.Image active={true} />
                                        </div>}
                                </div>
                            </div>
                            <div className='right-section'>
                                <div className='process-section'>
                                    <div className='upload-section'>
                                        <div className='upload-image-section hover:cursor-pointer'>
                                            <div  {...getRootProps({ className: 'dropzone' })} className={`upload-box-section ${isDragActive ? '!bg-zinc-400' : '!bg-[#F3F6FF]'}`}>
                                                <input id="input-upload-image-2" type="file" accept="image/*" {...getInputProps()} className="hidden" />
                                                <img src={iconUpload} width={40} alt="icon-upload" />
                                                <span className={`${isDragActive ? 'text-white' : 'text-gray'} text-upload`}>{t('upload_your_template')}</span>
                                                <span className='text-upload-guide'>{t('drop_file')}</span>
                                                <Button type='primary'>{t('upload_image')}</Button>
                                                <span className='text-upload-guide'>{t('Only_support_image_types_png/jpg/jpeg')}</span>
                                            </div>
                                        </div>
                                        <div className='upload-guide-section'>
                                            <div className='tip flex gap-1 pb-2 sm:flex-row'>
                                                <img src={tip} alt="" />
                                                <p className='text-[#101828] font-semibold text-[16px]'>{t('gui_input')}</p>
                                            </div>
                                            {/* slider guide upload */}
                                            <ImageGallery items={isVNes ? images : imagesEN} autoPlay slideInterval={3000} showThumbnails={false} showIndex showNav showBullets showPlayButton />
                                            <div className='see_more text-right'>
                                                <a href={linkFileGuide} target='_blank'>Tìm hiểu thêm</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='example-section p-5'>
                                        <div className='example-title-section'>
                                            <p>{t('Try_Images_Here')}</p>
                                        </div>
                                        <div className="option-select-img mt-2">
                                            {isLoading
                                                ? <div className='list-img-option justify-center items-center !gap-y-2 flex gap-2 grid grid-cols-2'>
                                                    {listSample.map((val: any, index: number) => (
                                                        <div
                                                            key={index}
                                                            style={{ padding: 0 }}
                                                            className={classNames('item-model col-span-1 flex justify-center', {
                                                                selected_item: sample === index
                                                            })}
                                                        >
                                                            <div
                                                                className="example-image"
                                                                style={{
                                                                    backgroundImage: `url('${val.image_url}')`,
                                                                }}
                                                            >
                                                                <Button
                                                                    type='primary' className="custom-button"
                                                                    onClick={(e) => onChangeModelSample(val.image_url, index)}
                                                                >
                                                                    {t('select')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                : <div className='list-img-selection flex justify-center items-center gap-4 flex-wrap'>
                                                    <Skeleton.Image active={true} />
                                                    <Skeleton.Image active={true} />
                                                    <Skeleton.Image active={true} />
                                                    <Skeleton.Image active={true} />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {openModalTool && <ModalTool isOpenModal={openModalTool} setIsOpenModal={() => setOpenModalTool(false)} setIsGenerating={setIsGenerating} />}
                    {/* {isOpenModalUpload && <ModalEditor
                        openModal={openModel}
                        isOpen={isOpenModalUpload}
                        image={image}
                        getCropData={handleGetDataInput}
                        closeModel={closeModel}
                        setImage={setImage}
                        onChangeUrlImage={onChangeUrlImage}
                    />} */}
                    {/* {isOpenModalUpload && image && <ModalResize setImage={setImage} openModal={openModel} getCropData={} isOpen={isOpenModalUpload} image={image} closeModel={closeModel} />} */}

                </Modal>
            }
        </div>
    )
}

export default ModalSelectInput