import { createSlice } from "@reduxjs/toolkit";

interface scrollAppState {
  isDisplayHeader: boolean;
}

const initialState: scrollAppState = {
  isDisplayHeader: true,
};

const eventLoading = createSlice({
  name: "setIsDisplayHeader",
  initialState,
  reducers: {
    setIsDisplayHeader: (state, action) => {
      state.isDisplayHeader = action.payload;
    },
  },
});

export const setIsDisplayHeader = eventLoading.actions;
export default eventLoading.reducer;
