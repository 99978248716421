// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vto-select-category .ant-select-selector, .vto-select-shop-name {
    border-radius: 8px !important;
}
.vto-select-category .ant-select-selector, .vto-select-shop-name {
    border-color: #C9CED6;
}`, "",{"version":3,"sources":["webpack://./src/components/VTO-UploadModel/VTO-input-setting/styles.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AAEjC;AAHA;IAEI,qBAAqB;AACzB","sourcesContent":[".vto-select-category .ant-select-selector, .vto-select-shop-name {\n    border-radius: 8px !important;\n    border-color: #C9CED6;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
