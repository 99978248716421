import 'react-image-crop/dist/ReactCrop.css';
import './styles.css';

import { Button, InputNumber, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import { useDispatch } from 'react-redux';
import Icon from '@ant-design/icons';
import { editorImage } from '../../redux/app/appImagePreprocessing';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import { convertBlobToBase64, getImageSize } from '../../utils/function';
import NotifyController from '../../utils/toast';
import { canvasPreview } from './CanvasPreview';
import { useDebounceEffect } from './useDebounceEffect';
import { toolEditorImage } from '../../redux/app/toolEditor';
import { TypeEditor } from '../../utils/enum';
import flip from '../../assets/icons/flip.svg'
import save from '../../assets/icons/saveBlack.svg';
import Heading from '../../pages/Editor/DetailsTools/Heading';
type ModalResizeProps = {
  image: string;
  isLoading: boolean;
  onConfirmEditor: Function;
  onClickSave: () => void;
  onClickBackToHome: () => void;
  setLoading: Function;
  typeInput: TypeEditor;
};

const radio23 = 2 / 3;
const radio34 = 3 / 4;
const NUMBER_DIVIDED = 8;
function ModalResize({ image, onClickSave, onConfirmEditor, isLoading, setLoading, typeInput, onClickBackToHome }: ModalResizeProps) {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const imageCropData = useAppSelector((store) => store.EditorImage);

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const resizeCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const [valueWidth, setValueWidth] = useState(0);
  const [valueHeight, setValueHeight] = useState(0);
  const [widthInput, setWidthInput] = useState(0);
  const [heightInput, setHeightInput] = useState(0);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [flipH, setFlipH] = useState(false);
  const [flipV, setFlipV] = useState(false);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [aspect, setAspect] = useState<number | undefined>(radio23);
  const [classWidthImageRender, setClassWidthImageRender] = useState('')
  const [cropped, setCropped] = useState(false);
  const [cropping, setCropping] = useState(false);

  const getSize = async () => {
    const { width, height } = await getImageSize(imageCropData.imageUrl ? imageCropData.imageUrl : image);
    setWidthInput(width);
    setHeightInput(height);
    if (width && height && !imageCropData.imageUrl) {
      const payload = {
        width,
        height,
        imageUrl: imageCropData.imageUrl ? imageCropData.imageUrl : image,
      };
      dispatch(editorImage.setImageEditor(payload));
    }
  };

  const onChangeScale = (ratio: number, width?: number, height?: number) => {
    setCropped(false);
    const aspectRatioData = centerAspectCrop(width ?? widthInput, height ?? heightInput, ratio);
    const w = Math.round((aspectRatioData.width * (width ?? widthInput)) / 100);
    const h = Math.round((aspectRatioData.height * (height ?? heightInput)) / 100);
    const x = Math.round((aspectRatioData.x * (width ?? widthInput)) / 100);
    const y = Math.round((aspectRatioData.y * (height ?? heightInput)) / 100);
    setAspect(ratio);
    setValueWidth(w);
    setValueHeight(h);
    setPositionX(x);
    setPositionY(y);
    setCrop(aspectRatioData);
    if (imgRef.current?.naturalWidth) {
      const percent = (imgRef.current?.width as number) / (imgRef.current?.naturalWidth as number);
      const completedCropData = {
        height: h * percent,
        width: w * percent,
        x: x * percent,
        y: y * percent,
        unit: 'px',
      } as PixelCrop;
      setCompletedCrop(completedCropData);
    }
  };


  function centerAspectCrop(
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 100,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }


  const onOK = async () => {
    if (!previewCanvasRef.current || !resizeCanvasRef.current) throw new Error("previewCanvasRef, !resizeCanvasRef is null");

    if (cropped) {
      NotifyController.success(t("apply_success"));
      return;
    }
    setCropping(true);
    let imgWidth: number = valueWidth;
    let imgHeight: number = valueHeight;
    if (valueWidth > 768) {
      imgWidth = 768;
      imgHeight = Math.round((768 * valueHeight) / valueWidth);
    }
    else {
      imgWidth = valueWidth - (valueWidth % NUMBER_DIVIDED);
      imgHeight = valueHeight - (valueHeight % NUMBER_DIVIDED);
    }
    // imgWidth = valueWidth - (valueWidth % 8);
    // imgHeight = valueHeight - (valueHeight % 8);
    setValueWidth(imgWidth);
    setValueHeight(imgHeight);
    const canvas = resizeCanvasRef.current;
    const ctx = canvas.getContext("2d");
    if (canvas && ctx) {
      canvas.width = imgWidth;
      canvas.height = imgHeight;
      let scaleH = flipH ? -1 : 1, // Set horizontal scale to -1 if flip horizontal
        scaleV = flipV ? -1 : 1, // Set verical scale to -1 if flip vertical
        posX = flipH ? imgWidth * -1 : 0, // Set x position to -100% if flip horizontal 
        posY = flipV ? imgHeight * -1 : 0; // Set y position to -100% if flip vertical

      ctx.save(); // Save the current state
      ctx.scale(scaleH, scaleV); // Set scale to flip the image
      ctx.drawImage(previewCanvasRef.current, posX, posY, imgWidth, imgHeight);
      ctx.restore();
    }
    const blob = await new Promise((resolve) => {
      resizeCanvasRef.current?.toBlob((blob) => {
        if (!blob) {
          throw new Error("Failed to create blob");
        }
        resolve(blob);
      });
    });
    const base64String = await convertBlobToBase64(blob);
    const payload = {
      width: imgWidth,
      height: imgHeight,
      imageUrl: base64String,
    };
    setWidthInput(imgWidth);
    setHeightInput(imgHeight);
    NotifyController.success(t("apply_success"));
    dispatch(editorImage.setImageEditor(payload));
    dispatch(toolEditorImage.setImageInput(base64String))
    dispatch(toolEditorImage.setSizeImage({
      width: imgWidth,
      height: imgHeight,
    }))
    dispatch(
      editorImage.setRenderedImageSize({
        renderedImageSize: {
          width: completedCrop?.width,
          height: completedCrop?.height,
        },
      })
    );

    dispatch(
      editorImage.setHasCropImage({
        hasCropImage: true,
      })
    );
    setCropped(true);
    setCropping(false);
    setFlipH(false)
    setFlipV(false)
  };

  const onFinalClick = async () => {
    setLoading(true)
    if (!cropped) {
      const canvas = resizeCanvasRef.current;
      const ctx = canvas?.getContext("2d");
      if (canvas && ctx) {
        let imgWidth: number = valueWidth;
        let imgHeight: number = valueHeight;
        if (valueWidth > 768) {
          imgWidth = 768;
          imgHeight = Math.round((768 * valueHeight) / valueWidth);
        }
        else {
          imgWidth = valueWidth - (valueWidth % NUMBER_DIVIDED);
          imgHeight = valueHeight - (valueHeight % NUMBER_DIVIDED);
        }
        canvas.width = imgWidth;
        canvas.height = imgHeight;
        ctx.drawImage(previewCanvasRef.current!, 0, 0, imgWidth, imgHeight);
      }
      const blob = await new Promise((resolve) => {
        resizeCanvasRef.current?.toBlob((blob) => {
          if (!blob) {
            throw new Error("Failed to create blob");
          }
          resolve(blob);
        });
      });
      const base64String = await convertBlobToBase64(blob);
      const payload = {
        width: valueWidth,
        height: valueHeight,
        imageUrl: base64String,
      };
      dispatch(editorImage.setImageEditor(payload))
      dispatch(toolEditorImage.setSizeImage({
        width: valueWidth,
        height: valueHeight,
      }))
      dispatch(toolEditorImage.setImageInput(base64String))
      onConfirmEditor(payload);
    } else {
      onConfirmEditor();
    }
  }

  useEffect(() => {
    getSize();
  }, []);

  useEffect(() => {
    if (aspect) {
      const centerAspectCropData = centerAspectCrop(widthInput, heightInput, +aspect)
      const initW = Math.round((centerAspectCropData.width * widthInput) / 100);
      const initH = Math.round((centerAspectCropData.height * heightInput) / 100);
      setValueWidth(initW);
      setValueHeight(initH);
      setPositionX(Math.round((centerAspectCropData.x * widthInput) / 100));
      setPositionY(Math.round((centerAspectCropData.y * heightInput) / 100));
    }
  }, [widthInput, heightInput, aspect]);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1
        );

        dispatch(
          editorImage.setRenderedImageSize({
            renderedImageSize: {
              width: imgRef.current.clientWidth ?? completedCrop?.width,
              height: imgRef.current.clientHeight ?? completedCrop?.height,
            },
          })
        );
      }
    },
    100,
    [completedCrop, image, dispatch]
  );


  useEffect(() => {
    if (imageCropData.width < 300) {
      setClassWidthImageRender('w-full')
    }
    if (imageCropData.width > 1800) {
      setClassWidthImageRender('w-1/2')
    }
    else if (imageCropData.width <= 1800 && imageCropData.width > 800) {
      setClassWidthImageRender('w-1/3')
    }
    else {
      setClassWidthImageRender('w-1/2')
    }
  }, [imageCropData.width])

  return (
    <div id="modal-resize" className='relative'>
      <div className={
        "flex flex-col gap-4  justify-around sm:items-center md:items-center lg:items-center  w-full h-full sm:w-auto md:w-auto sm:flex-col md:flex-col lg:flex-col "
      }
      >
        <Heading title={t('cut_image')} onSave={onClickSave} onClickBackToHome={onClickBackToHome} />
        {/* <div className='flex justify-between ml-10' >
          <div className='text-lg font-semibold text-left'></div>
          <Button className='flex items-center' icon={<Icon component={() => (<img src={save} className={'w-[20px] h-[20px]'} />)} />
          }>{t('save_photo')}</Button>
        </div> */}
        <div className='flex flex-row gap-4 justify-between sm:items-center md:items-center lg:items-center  w-full h-full sm:w-auto md:w-auto sm:flex-col md:flex-col lg:flex-col'>
          <div className="flex flex-col items-start justify-between ml-10  sm:w-full md:w-full lg:w-full min-h-[400px] min-w-[400px]">
            <div className='w-full'>
              <hr className="bg-[#F5F5F5] h-[1px] border-none mt-2 w-full" />
              <div className='flex flex-col'>
                <div className='sub-title !mt-2 text-base font-semibold text-left'>{t('action')}</div>
                <div className="mt-2 flex flex-col justify-center items-center">
                  <div className="flex flex-col gap-4 w-full">
                    <div className="flex justify-start items-start mt-2">{t("ratio_image")}</div>
                    <div
                      className="flex gap-4"
                    >
                      <div className="flex justify-center w-full" onClick={() => onChangeScale(radio23)}>
                        <input
                          className="hidden"
                          type="radio"
                          name="size-crop"
                          id="23"
                        />
                        <label htmlFor="23" className='w-full'>
                          <div className={`btn-size-crop ${aspect === radio23 ? "btn-active" : ""}`}>
                            2:3
                          </div>
                        </label>
                      </div>
                      <div className="flex justify-center w-full" onClick={() => onChangeScale(radio34)}>
                        <input
                          className="hidden"
                          type="radio"
                          name="size-crop"
                          id="34"
                        />
                        <label htmlFor="34" className='w-full'>
                          <div className={`btn-size-crop ${aspect === radio34 ? "btn-active" : ""}`}>
                            3:4
                          </div>
                        </label>
                      </div>
                    </div>

                    <div className="flex justify-start items-start">{t("flip")}</div>
                    <div className='flex gap-4'>
                      <Button className='flex w-full justify-center items-center' onClick={() => { setFlipV(!flipV) }} >
                        <div className='flex gap-1'>
                          <Icon className='rotate-90 flex w-full' component={() => (<img src={flip} width={25} height={25} />)} />
                          <p>{t('flipV')}</p>
                        </div>
                      </Button>
                      <Button className='flex w-full justify-center items-center' onClick={() => { setFlipH(!flipH) }} >
                        <div className='flex gap-1'>
                          <Icon className=' flex w-full' component={() => (<img src={flip} width={25} height={25} />)} />
                          <p>{t('flipH')}</p>
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className='sub-title text-base  font-semibold text-left'>{t('info')}</div>
                <div className="flex flex-col gap-4 text-left">
                  <div className="flex gap-4">
                    <div className="flex flex-col gap-1">
                      <div>{t("width")}</div>
                      <div>
                        {" "}
                        <InputNumber
                          disabled
                          readOnly={true}
                          value={valueWidth}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div>{t("height")}</div>
                      <div>
                        {" "}
                        <InputNumber
                          disabled
                          readOnly={true}
                          value={valueHeight}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="flex flex-col gap-1">
                      <div>{t("position_x")}</div>
                      <div>
                        {" "}
                        <InputNumber
                          disabled
                          readOnly={true}
                          value={positionX}
                          style={{ width: "100%" }}
                        />{" "}
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div>{t("position_y")}</div>
                      <div>
                        {" "}
                        <InputNumber
                          disabled
                          readOnly={true}
                          value={positionY}
                          style={{ width: "100%" }}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full sm:w-[90%]'>
              <div className=" sm:mt-4 md:mt-4 lg:mt-4 xl:mt-4 ">
                <div className="flex justify-center items-center w-full gap-4">
                  <Button
                    type="default"
                    style={{
                      background: "white",
                      borderColor: "#0047FF ",
                      color: "#0047FF ",
                    }}
                    className=" w-full"
                    disabled={cropping}
                    onClick={onOK}
                  >
                    {t("apply_and_continues")}
                  </Button>
                  {typeInput === TypeEditor.PRE && <Button onClick={onFinalClick} type='primary' disabled={isLoading} className='w-full' >{t('done')}</Button>}
                </div>
                <div className={`btn-confirm  flex justify-center items-center w-full ${typeInput === TypeEditor.PRE ? '' : ' !hidden'} `}>
                </div>

                {/* <div className="mt-3 w-[300px] text-left sm:w-full md:w-full lg:w-full text-gray text-guide">
                <p className="font-medium text-sm">{t('text_header_guide_crop')}</p>
                <ul>
                  <li>{t('step_1_crop')}</li>
                  <li>{t('step_2_crop')}</li>
                  <li>{t('step_3_crop')}</li>
                </ul>
              </div> */}
              </div>
            </div>
          </div>
          <div className=" bg-[#CCD9FF] !w-[65vw]  h-3/4 flex justify-center items-center min-h-[75vh] ">
            <div className="flex justify-center items-center">
              {
                classWidthImageRender ?
                  <ReactCrop
                    className={`h-full  ${classWidthImageRender} md:w-[90%] sm:w-[90%] lg:w-[90%] xl:w-[90%]`}
                    crop={crop}
                    onChange={(pxCrop: any, percentCrop: any) => {
                      const ratioW = Math.round(
                        widthInput * (percentCrop?.width / 100)
                      );
                      const ratioH = Math.round(
                        heightInput * (percentCrop?.height / 100)
                      );
                      const positionX = Math.round(pxCrop?.x);
                      const positionY = Math.round(pxCrop?.y);
                      setCrop(pxCrop);
                      setValueHeight(ratioH);
                      setValueWidth(ratioW);
                      setPositionX(positionX);
                      setPositionY(positionY);
                      setCropped(false)
                    }}
                    onComplete={(pxCrop: any, percentCrop: any) => {
                      const ratioW = pxCrop.width; // widthInput * (percentCrop?.width/100);
                      const ratioH = pxCrop.height; //heightInput * (percentCrop?.height/100);
                      const positionX = pxCrop.x; ///   widthInput * (percentCrop?.x/100);
                      const positionY = pxCrop.y; //   heightInput * (percentCrop?.y/100);
                      let completedCropData = {
                        height: ratioH,
                        width: ratioW,
                        x: positionX,
                        y: positionY,
                        unit: "px",
                      } as PixelCrop;
                      setCompletedCrop(completedCropData);
                      setCropped(false)
                    }}
                    aspect={aspect}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imageCropData.imageUrl}
                      onLoad={onImageLoad}
                      style={{
                        transform: `${flipV ? 'scaleY(-1) ' : 'scaleY(1) '} ${flipH ? 'scaleX(-1) ' : 'scaleX(1) '}`,
                      }}
                    />
                    <canvas ref={resizeCanvasRef} className="hidden"></canvas>
                  </ReactCrop>
                  :
                  <Spin spinning={true}></Spin>
              }
            </div >
          </div >
        </div>
      </div >
      {
        !!completedCrop && (
          <>
            <div className="hidden">
              <canvas
                id="preview"
                ref={previewCanvasRef}
                style={{
                  objectFit: "contain",
                  width: completedCrop.width,
                  height: completedCrop.height,
                  transform: 'scaleX(-1)'
                }}
              />
            </div>
          </>
        )
      }
      {/* <hr className='mt-3' /> */}

    </div >
  );
}

export default ModalResize;
