// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    max-width: 600px;
    margin: 0 auto;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.list-item {
    width: calc(33.33% - 20px);
    margin: 10px;
    background-color: #f0f0f0;
    padding: 10px;
    box-sizing: border-box;
}

.show-more {
    display: block;
    text-align: center;
    margin-top: 20px;
    cursor: pointer;
    color: #007bff;
}

.hidden {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/Editor/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,0BAA0B;IAC1B,YAAY;IACZ,yBAAyB;IACzB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".container {\n    max-width: 600px;\n    margin: 0 auto;\n}\n\n.list {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.list-item {\n    width: calc(33.33% - 20px);\n    margin: 10px;\n    background-color: #f0f0f0;\n    padding: 10px;\n    box-sizing: border-box;\n}\n\n.show-more {\n    display: block;\n    text-align: center;\n    margin-top: 20px;\n    cursor: pointer;\n    color: #007bff;\n}\n\n.hidden {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
