import { Button, Checkbox, Modal, Select, Table, Tooltip } from 'antd';
import { Option } from 'antd/es/mentions';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import 'moment-timezone';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import swal from 'sweetalert';
import Loading from '../../Loading';
import AccountService from '../../services/account.service';
import NotifyController from '../../utils/toast';
import CopyButton from './../../assets/vto/materials/copy-button.svg';
import { handleResponseError } from '../../utils/responseError';

const APIKeys = () => {
  const timezone = moment.tz.guess();
  const { t, i18n } = useTranslation()
  const [ready, setReady] = useState(false);
  const [metadata, setMetadata] = useState<{ permissions: string[], expirationOptions: { title: any, value: string }[] } | null>(null);
  const [apiKeys, setApiKeys] = useState<any>(null);
  const [creationModal, setCreationModal] = useState(false);
  const { register, handleSubmit, setError, setValue, reset, clearErrors, control, formState: { errors } } = useForm<{
    permissions: string[],
    expiration: string,
    alias: string
  }>({
    defaultValues: {
      permissions: [],
      expiration: '',
      alias: ''
    }
  });

  const getListUserAPIKeys = async () => {
    try {
      const res = await AccountService.getInstance().getListUserAPIKeys();
      setApiKeys(res.data);
    } catch (error: any) {
      handleResponseError(error);
    }
  }

  const getAPIKeyMetadata = async () => {
    try {
      const res = await AccountService.getInstance().getAPIKeyMetadata();
      setMetadata(res.data);
    } catch (error: any) {
      handleResponseError(error);
    }
  }


  const handleCopy = (s: string) => {
    const codeText = s;
    navigator.clipboard.writeText(codeText);
    NotifyController.success(t('Copy code successfully'));
  };

  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'API Key',
      dataIndex: 'apiKey',
      render: (_record) => {
        return (
          <div className='flex gap-x-4'>
            <span>{_record.slice(0, 12)}...{_record.slice(20)}</span>
            <Tooltip trigger={isTouchDevice() ? 'click' : 'hover'} title={t('copy')}>
              <span className='cursor-pointer ml-auto text-blue-950' onClick={() => handleCopy(_record)}><img style={{
                width: '18px',
                height: '18px',
              }} src={CopyButton} /></span>
            </Tooltip>
          </div>
        )
      }
    },
    {
      title: t('alias'),
      dataIndex: 'alias',
    },
    {
      title: t('permissions'),
      dataIndex: 'permissions',
      render: (_record) => {
        return <span>{_record.map((p: string) => p.replace(/_/gi, ' ')).join(', ')}</span>
      }
    },
    {
      title: t('expiresAt'),
      dataIndex: 'expiresAt',
      render: (_record) => {
        return (
          _record ? <div>{moment.tz(_record * 1000, timezone).format("YYYY-MM-DD HH:mm:ss")}</div>
            : <span>{metadata?.expirationOptions?.filter(o => o.value === 'no_expiration')[0]?.title[i18n.language] ?? ''}</span>
        )
      }
    },
    {
      title: t('createdTs'),
      dataIndex: 'createdTs',
      render: (_record) => {
        return (
          <div>{moment.tz(_record, timezone).format("YYYY-MM-DD HH:mm:ss")}</div>
        )
      }
    },
    {
      title: '#',
      dataIndex: "id",
      render: (_record) => {
        return (
          <Button className="border-red-500 hover:border-red-600 hover:bg-red-600 hover:!text-white"
            onClick={() => {
              console.log(_record);

              swal({
                title: t('are_you_sure'),
                text: t('You_will_not_be_able_to_recover_this'),
                icon: 'warning',
                buttons: [t('cancel'), t('delete')],
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  AccountService.getInstance().deleteAPIKey(_record).then(() => {
                    getListUserAPIKeys();
                    NotifyController.success(t('delete_successfully'));
                  }).catch(error => {
                    handleResponseError(error);
                  })
                }
              })
            }}>{t('delete')}</Button>
        )
      }
    },
  ];

  const onSubmit = async (data: { permissions: string[], expiration: string, alias: string }) => {
    clearErrors(['permissions', 'expiration']);
    const permissions = data.permissions.filter(p => !!p);
    let invalid = false;
    if (permissions.length === 0) {
      setError('permissions', { message: t('form_required_error_msg').replace('{{fieldName}}', t('permissions').toLowerCase()) })
      invalid = true;
    }
    if (!data.expiration) {
      setError('expiration', { message: t('form_required_error_msg').replace('{{fieldName}}', t('expiration').toLowerCase()) })
      invalid = true;
    }

    const maxLength = 50;
    if (data.alias && data.alias.length > maxLength) {
      setError('alias', { message: t('form_max_length_error_msg').replace('{{fieldName}}', t('alias').toLowerCase()).replace('{{maxLength}}', maxLength.toString()) })
      invalid = true;
    }

    if (invalid) return;

    try {
      await AccountService.getInstance().createAPIKey({
        permissions,
        expiration: data.expiration,
        alias: data.alias
      });
      setCreationModal(false);
      reset();
      await NotifyController.success(t('create_api_key_successfully'))
      await getListUserAPIKeys();
    } catch (error: any) {
      handleResponseError(error);
    }
  }

  useEffect(() => {
    (async () => {
      await Promise.all([
        getListUserAPIKeys(),
        getAPIKeyMetadata()
      ])
      setReady(true)
    })();
  }, [])

  if (!ready || !metadata) {
    return <Loading />
  }

  return (
    <div className='text-left flex flex-col gap-2 py-4'>
      <div className='overflow-x-auto mt-4'>
        <div className="flex justify-end mb-4">
          <Button type='primary' onClick={() => setCreationModal(true)}>{t('create')}</Button>
        </div>
        <div className=' text-left font-semibold text-lg'>
          {t('list_api_key_title')}
        </div>
        <Table columns={columns} dataSource={apiKeys} className='mt-2' pagination={false} />
      </div>

      {
        creationModal && (
          <Modal open={creationModal} title={`${t('create')} API Key`} okButtonProps={{ htmlType: 'submit', style: { width: '80px' }, onClick: handleSubmit(onSubmit) }}
            cancelButtonProps={{ style: { width: '80px' } }}
            onCancel={() => {
              setCreationModal(false);
              reset();
            }}>
            <form className='block' onSubmit={handleSubmit(onSubmit)}>
              <div className="block my-4">
                <label htmlFor='alias' className='font-semibold'>{t('alias')}</label>
                <div className='mt-2'>
                  <input id="alias" className='ant-input css-dev-only-do-not-override-yi65l5 custom-input w-full pl-3' {...register('alias')} />
                </div>
                {
                  errors.alias && <div className='text-red-500 text-sm mt-2'>{errors.alias.message}</div>
                }
              </div>
              <div className="block my-4">
                <label className='font-semibold'>{t('permissions')} <span className='text-red-500'>*</span></label>
                <div className="flex gap-x-4 mt-2">
                  {
                    metadata.permissions.map((p, index) => {
                      return (
                        <Checkbox key={`cb_${index}`} value={p} onChange={(event: any) => {
                          setValue(`permissions.${index}`, event.target.checked ? p : '');
                          clearErrors('permissions')
                        }}><span>{p}</span></Checkbox>
                      )
                    })
                  }
                </div>
                {
                  errors.permissions && <div className='text-red-500 text-sm mt-2'>{errors.permissions.message}</div>
                }
              </div>
              <div className="block my-4">
                <label className='font-semibold'>{t('expiration')} <span className='text-red-500'>*</span></label>
                <div className="block">
                  <Controller
                    control={control}
                    name="expiration"
                    render={({ field: { onChange } }) => (
                      <Select
                        dropdownRender={menu => (
                          <div>
                            {menu}
                          </div>
                        )}
                        size='large'
                        className='mt-2'
                        style={{ width: '100%', height: '40px !important', borderRadius: '8px' }}
                        onChange={(value) => onChange(value)}
                        options={
                          metadata.expirationOptions.map((ex) => {
                            return {
                              label: ex.title[i18n.language as string],
                              value: ex.value,
                            }
                          })
                        }
                      />
                    )}
                  />
                  {
                    errors.expiration && <div className='text-red-500 text-sm mt-2'>{errors.expiration.message}</div>
                  }
                </div>
              </div>
            </form>
          </Modal>
        )
      }
    </div>
  )
}
export default APIKeys;