import React from 'react'
import './styles.css'
import { useNavigate } from 'react-router-dom'
function NotFoundPage() {
    const navigate = useNavigate();
    
    return (
        <div className="content">
            <div className="er-nu">4</div>
            <div className="rnd">
                <div className="eyes">
                    <div className="ey"></div>
                    <div className="ey"></div>
                    <div className="muth"></div>
                </div>
            </div>
            <div className="er-nu">4</div>
            <div className="ermsg">The page could not be found</div>
            <div onClick={e=>navigate('/')} className=' ermsg btn-back mt-2'>Back to home</div>
        </div>

    )
}

export default NotFoundPage