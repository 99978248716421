import { Button, Form, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IInputSetting } from '../VTOUploadModel'
type InputIdOutfitProps = {
    setInputSetting: Function,
    inputSetting: IInputSetting
}
function InputIdOutfit({ setInputSetting, inputSetting }: InputIdOutfitProps) {
    const [valueID, setValueID] = useState('')
    const { t } = useTranslation()
    const [form] = Form.useForm();
    const onChangValueID = (value: string) => {
        setValueID(value)
    }

    const pattern = new RegExp("^[a-zA-Z0-9_.-]*$")

    useEffect(() => {
        if (pattern?.test(valueID) && valueID !== '') {
            const _ = {
                ...inputSetting,
                IdOutfit: valueID
            }
            setInputSetting(_)
        } else {
            const _ = {
                ...inputSetting,
                IdOutfit: ''
            }
            setInputSetting(_)
        }
    }, [valueID])

    // useEffect(() => {
    //     inputSetting.IdOutfit === '' && form.resetFields();
    // }, [inputSetting])

    return (
        <div className='flex flex-col items-start gap-2 w-full'>
            <div className='title-input-id text-[#101828] font-semibold text-sm flex flex-row gap-1'>
                <p> {t('input_id_outfit')}</p>
                <p className='text-[red]'>*</p>
            </div>
            <div className='w-full'>
                <Form
                    form={form}
                    name="outfitIdForm"
                    scrollToFirstError
                >
                    <Form.Item
                        className=' !mb-1'
                        name="outfitId"
                        hasFeedback
                        rules={[
                            {
                                pattern: new RegExp("^[a-zA-Z0-9_.-]*$"),
                                message: t('format_wrong'),
                                required: true,
                                // validator(rule, value) {
                                //     if (rule?.pattern?.test(value)) {
                                //         return Promise.resolve(
                                //             setValueID(value)
                                //         );
                                //     } else {
                                //         return Promise.reject('Some message here');
                                //     }
                                // },
                            },
                        ]}
                    >
                        <Input onChange={(e) => onChangValueID(e.target.value)} allowClear required value={valueID} style={{
                            width: '100%'
                        }} maxLength={15} placeholder={t('place_input_id_outfit')} className='border-[#C9CED6] rounded-lg h-10' />
                    </Form.Item>
                </Form>
                {/* <Input allowClear pattern={regex} required   ></Input> */}
                {/* {isValidate ? (
                    <p>Input is valid</p>
                ) : (
                    <p>Input is not valid</p>
                )} */}
            </div>
        </div>
    )
}

export default InputIdOutfit