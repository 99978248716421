import { createSlice } from "@reduxjs/toolkit";

interface IImage {
  width: number;
  height: number;
  imageUrl: string;
  renderedImageSize?: {
    width?: number;
    height?: number;
  };
  hasCropImage: boolean;
  hasRemoveWatermarkImage: boolean;
}

const initialState: IImage = {
  width: 0,
  height: 0,
  imageUrl: "",
  hasCropImage: false,
  hasRemoveWatermarkImage: false,
};

const imageEditor = createSlice({
  name: "setImageEditor",
  initialState,
  reducers: {
    setHasCropImage: (state, action) => {
      state.hasCropImage = action.payload.hasCropImage;
    },
    setHasRemoveWatermarkImage: (state, action) => {
      state.hasRemoveWatermarkImage = action.payload.hasRemoveWatermarkImage;
    },
    setImageEditor: (state, action) => {
      state.width = action.payload.width;
      state.height = action.payload.height;
      state.imageUrl = action.payload.imageUrl;
    },
    resetImageEditor: (state) => {
      state.width = 0;
      state.height = 0;
      state.imageUrl = "";
      state.hasCropImage = false;
      state.renderedImageSize = undefined;
      state.hasRemoveWatermarkImage = false;
    },
    setRenderedImageSize: (state, action) => {
      state.renderedImageSize = action.payload.renderedImageSize;
    },
    setURLImageEditor: (state, action) => {
      state.imageUrl = action.payload.imageUrl;
    },
  },
});

export const editorImage = imageEditor.actions;
export default imageEditor.reducer;
