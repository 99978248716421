// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-guide-payment {
    min-width: 60vw;
    width: 60vw;
    display: flex;
    width: 100%;
    flex-direction: column;
}
.list-btn-link{
    margin: 0 auto;
    padding: 20px;
}
.btn-title{
    border: 1px solid rgb(60, 84, 219);
    border-radius: 8px;

}
.container-guide-payment p {
    font-size: 16px;
}

.container-guide-payment ul {
    font-size: 14px;
}

.registration,
.login,
.payment,
.credits-conversion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.registration img,
.login img,
.payment img,
.credits-conversion img,
.img-4 {
    margin: 0 auto;
    width: 50%;
}

@media screen and (max-width:650px) {

    .registration img,
    .login img,
    .payment img,
    .credits-conversion img,
    .img-4 {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/PaymentGuide/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,aAAa;IACb,WAAW;IACX,sBAAsB;AAC1B;AACA;IACI,cAAc;IACd,aAAa;AACjB;AACA;IACI,kCAAkC;IAClC,kBAAkB;;AAEtB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;;;;IAII,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;;;;;IAKI,cAAc;IACd,UAAU;AACd;;AAEA;;IAEI;;;;;QAKI,WAAW;IACf;AACJ","sourcesContent":[".container-guide-payment {\n    min-width: 60vw;\n    width: 60vw;\n    display: flex;\n    width: 100%;\n    flex-direction: column;\n}\n.list-btn-link{\n    margin: 0 auto;\n    padding: 20px;\n}\n.btn-title{\n    border: 1px solid rgb(60, 84, 219);\n    border-radius: 8px;\n\n}\n.container-guide-payment p {\n    font-size: 16px;\n}\n\n.container-guide-payment ul {\n    font-size: 14px;\n}\n\n.registration,\n.login,\n.payment,\n.credits-conversion {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.registration img,\n.login img,\n.payment img,\n.credits-conversion img,\n.img-4 {\n    margin: 0 auto;\n    width: 50%;\n}\n\n@media screen and (max-width:650px) {\n\n    .registration img,\n    .login img,\n    .payment img,\n    .credits-conversion img,\n    .img-4 {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
