import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import image from './assets/images/image-popup.svg'
import { useTour } from '@reactour/tour'
import { disableScroll } from './utils/function'
import { useTranslation } from 'react-i18next'
function PopupForNewUser() {
    const [isOpenModal, setIsOpenModal] = useState(true)
    const { setIsOpen } = useTour()
    const { t } = useTranslation()

    const skipOpenTour = () => {
        setIsOpenModal(false)
        localStorage.setItem('FirstUser', 'false')
    }
    const onClickExplore = () => {
        setIsOpenModal(false)
        setIsOpen(true);
        disableScroll()
    }
    return (
        <div >
            <Modal open={isOpenModal} footer={null} closeIcon={null} centered
                className='modal-first-user'>
                <div>
                    <div>
                        <img src={image} alt="" width={'100%'} height={'100%'} />
                    </div>
                    <div className='p-4 flex flex-col items-center justify-center gap-2'>
                        <h3 className='font-semibold text-center text-[18px]'>{t('explore_modeli')}</h3>
                        <p className='text-[16px] text-center  text-[#333333]'>{t('explore_modeli2')}</p>
                    </div>
                    <div className='flex gap-4 items-center justify-center pb-4'>
                        <Button type='default' className='border-gray w-[150px]' onClick={skipOpenTour}>{t('skip')}</Button>
                        <Button type='primary' className='w-[150px]' onClick={onClickExplore}>{t('explore_now')} </Button>
                    </div>

                </div>

            </Modal>
        </div>
    )
}

export default PopupForNewUser