import { httpGet } from "./http.service";

export default class TutorService {
    private static instance: TutorService;
    private constructor() { }
    public static getInstance() {
        if (!this.instance) {
            this.instance = new TutorService();
        }
        return this.instance;
    }

    async getTutorList(lang: string, forUser: string, searchTerm: string) {
        const { data } = await httpGet(`/tutor?lang=${lang}&forUser=${forUser}&searchTerm=${searchTerm}`);
        return data.data;
    }

    async getTutorDetail(id?: string) {
        const { data } = await httpGet(`/tutor/detail?id=${id}`);
        return data.data;
    }
}
