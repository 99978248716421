import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Checkbox, Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import { ModalSub } from '../../../redux/app/appModalSub';
import { setStateVTO } from '../../../redux/app/appVTO';
import { useAppSelector } from '../../../redux/hooks/useAppSelector';
import FashionService from '../../../services/fashion.service';
import { ROUTERS } from '../../../utils/constant';
import { FeatureTypeEnum } from '../../../utils/enum';
import NotifyController from '../../../utils/toast';
import './styles.css';
import { handleResponseError } from '../../../utils/responseError';
type ViewImageProps = {
    id: string,
    listIDToRemove: string[],
    setListIDToRemove: Function,
    image: string,
    setDataEdit: Function,
    data: any,
    listData: any,
    isClickEdit: () => void,
    setTrainLoraShopHistory: Function
}
function ViewImage({ id, data, listIDToRemove, setListIDToRemove, image, setDataEdit, listData, isClickEdit, setTrainLoraShopHistory }: ViewImageProps) {
    const navigate = useNavigate();
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const shopId = useAppSelector(store => store.user.referralCode)
    const isSelectMultiple = useAppSelector(store => store.VTOState.isMultipleSelection);
    const hasRoleVTO = useAppSelector(store => store.user.roles.includes('VTO') || store.user.roles.includes('ADMIN'))
    const onChangeAddToArrayListDelete = (e: any) => {
        const isChecked = e.target.checked;
        const imageId = e.target.id
        if (isChecked) {
            // Kiểm tra xem mảng có chứa phần tử imageId không
            if (!listIDToRemove.includes(imageId)) {
                // Nếu không chứa, thêm imageId vào mảng
                setListIDToRemove([...listIDToRemove, imageId]);
            }
        } else {
            // Nếu đã chứa, loại bỏ imageId khỏi mảng
            setListIDToRemove(listIDToRemove.filter(item => item !== imageId));

        }
    }

    const handleButtonRemove = (id: string) => {
        swal({
            title: t('be_careful'),
            text: t('want_to_delete'),
            icon: 'warning',
            dangerMode: true,
            buttons: {
                cancel: {
                    text: t('cancel'),
                    value: null,
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: t('delete'),
                    value: true,
                    visible: true,
                    closeModal: true,
                },
            },
        }).then(async (result) => {
            if (result) {
                try {
                    const dataDelete = await FashionService.getInstance().deleteVTOOutfit([id]);
                    if (dataDelete.success) {
                        NotifyController.success(t('delete_successfully'))
                        dispatch(setStateVTO.setReload(true))
                        setTrainLoraShopHistory({})
                    }
                } catch (error) {
                    handleResponseError(error);
                }
            }
        });
    }

    const onTry = () => {
        if (hasRoleVTO) {
            navigate(`${ROUTERS.TRY_NOW}?shopId=${shopId}&outfitCode=${id}`);
        } else {
            dispatch(ModalSub.setTypeFeatureActive(FeatureTypeEnum.VIRTUAL_TRY_ON))
            dispatch(ModalSub.setIsOpenModalSub(true))
        }
    }

    return (

        <div className='image-model relative w-[250px] h-[300px] sm:w-[168px] sm:h-[200px] md:w-[200px] md:h-[250px] max-w-full shadow-xl' style={{
            backgroundImage: `url('${image}')`,
            backgroundSize: 'cover',
            // objectFit: 'cover',
            backgroundPosition: 'center top',
            borderRadius: '8px'
        }}>
            <div className={`!top-4 custom-tag ${isSelectMultiple ? '!right-0' : '!left-1'}`}>
                <Tag className="shadow-lg" color="blue">{data.count} gens</Tag>
            </div>
            {
                !isSelectMultiple && <>
                    <div className='custom-button-trash flex items-center gap-x-3 !-top-2' >
                        <Tooltip title={t('edit_outfit')}>
                            <div className='btn-edit cursor-pointer w-8 h-8 flex justify-center items-center rounded-full bg-white' onClick={() => {
                                setDataEdit(data);
                                isClickEdit()
                            }}>
                                <EditOutlined className='text-[20px] hover:text-blue-500' />
                            </div>
                        </Tooltip>

                        <Tooltip title={t('delete')}>
                            <div className='btn-trash cursor-pointer w-8 h-8 flex justify-center items-center  rounded-full bg-white' onClick={() => handleButtonRemove(id)}>
                                <DeleteOutlined className='text-[20px] text-[#667085] hover:text-red-500' />
                            </div>
                        </Tooltip>
                    </div>

                    <div className='custom-button-edit flex gap-2 sm:flex-col '>
                        <div className='btn-edit cursor-pointer'>
                            <Button type='primary' onClick={onTry}  >{t('vto_try')}</Button>
                        </div>
                    </div>
                </>
            }

            {
                isSelectMultiple && <div className='custom-button-checkbox'>
                    <div className='btn-checkbox cursor-pointer '>
                        <Checkbox id={id.toString()} className='text-[16px] custom-checkbox' onChange={(e) => onChangeAddToArrayListDelete(e)} />
                    </div>
                </div>
            }
        </div >
    )
}

export default ViewImage