// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.details-payment{
    height: 90vh;
}
.box-checkout {
    border-radius: 8px;
    width: 50%;
    max-width: 50%;
    min-height: 50vh;
    height: auto;
    padding: 40px;
    background-color: rgba(143,202,250,0.46667);
}
.box-checkout .heading-top{
    font-size: 34px;
    font-weight: 700;
}
.text-total{
    font-size: 18px;
}
[dir="ltr"] .text-name-prod{
    text-align: left;
}
[dir="rtl"] .text-name-prod{
    text-align: right;
}
.text-name-prod{
    font-size: 16px;
}
.list-items, .user-info{
    width: 50%;
}

@media screen and (max-width: 768px) {
    .box-checkout {
        padding: 10px;
        width: 90%;
        max-width: 100%;
        min-height: 50vh;
    }
    .list-items, .user-info{
        width: 90%;
    }
    .heading-top{
        font-size: 20px !important;
    }
    .text-total{
        font-size: 14px;
    }
}
@media screen and (max-width: 1080px) {
    .box-checkout {
        padding: 10px;
        width: 90%;
        max-width: 100%;
        min-height: 50vh;
    }
    .list-items, .user-info{
        width: 90%;
    }
    .heading-top{
        font-size: 20px !important;
    }
    .text-total{
        font-size: 14px;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Checkout/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,2CAA2B;AAC/B;AACA;IACI,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,eAAe;AACnB;AACA;IAEI;AACJ;AAHA;IAEI;AACJ;AAHA;IACI,eAAe;AAEnB;AACA;IACI,UAAU;AACd;;AAEA;IACI;QACI,aAAa;QACb,UAAU;QACV,eAAe;QACf,gBAAgB;IACpB;IACA;QACI,UAAU;IACd;IACA;QACI,0BAA0B;IAC9B;IACA;QACI,eAAe;IACnB;AACJ;AACA;IACI;QACI,aAAa;QACb,UAAU;QACV,eAAe;QACf,gBAAgB;IACpB;IACA;QACI,UAAU;IACd;IACA;QACI,0BAA0B;IAC9B;IACA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".details-payment{\n    height: 90vh;\n}\n.box-checkout {\n    border-radius: 8px;\n    width: 50%;\n    max-width: 50%;\n    min-height: 50vh;\n    height: auto;\n    padding: 40px;\n    background-color: #8fcafa77;\n}\n.box-checkout .heading-top{\n    font-size: 34px;\n    font-weight: 700;\n}\n.text-total{\n    font-size: 18px;\n}\n.text-name-prod{\n    font-size: 16px;\n    text-align: start;\n}\n.list-items, .user-info{\n    width: 50%;\n}\n\n@media screen and (max-width: 768px) {\n    .box-checkout {\n        padding: 10px;\n        width: 90%;\n        max-width: 100%;\n        min-height: 50vh;\n    }\n    .list-items, .user-info{\n        width: 90%;\n    }\n    .heading-top{\n        font-size: 20px !important;\n    }\n    .text-total{\n        font-size: 14px;\n    }\n}\n@media screen and (max-width: 1080px) {\n    .box-checkout {\n        padding: 10px;\n        width: 90%;\n        max-width: 100%;\n        min-height: 50vh;\n    }\n    .list-items, .user-info{\n        width: 90%;\n    }\n    .heading-top{\n        font-size: 20px !important;\n    }\n    .text-total{\n        font-size: 14px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
