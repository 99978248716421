import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import './styles.css'

const CROP_AREA_ASPECT = 3 / 4;

type CropImageProps = {
    imageUpload: string,
    cropperRef: React.RefObject<ReactCropperElement>
}
export default function CropImageCpn({ imageUpload, cropperRef }: CropImageProps) {
    return (
        <div>
            <Cropper
                // style={{ height: 00, width: 300 }}
                ref={cropperRef}
                src={imageUpload}
                viewMode={1}
                background={false}
                responsive={false}
                dragMode="move"
                autoCropArea={1}
            />
        </div>
    );
}
