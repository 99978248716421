import Icon, {
    CheckOutlined,
    HighlightOutlined,
    LeftOutlined,
    PictureOutlined
} from '@ant-design/icons';
import { Button, InputNumber, InputNumberProps, Layout, Menu, MenuProps, Spin, Tabs, theme } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import icon_button_vto_active from '../../assets/vto/icons/btn-customize-button-active.svg';
import icon_button_vto from '../../assets/vto/icons/btn-customize.svg';
import icon_watermark_active from '../../assets/vto/icons/btn-watermark-active.svg';
import icon_watermark from '../../assets/vto/icons/btn-watermark.svg';
import integration_code_icon_active from '../../assets/vto/icons/integration-code-icon-active.svg';
import integration_code_icon from '../../assets/vto/icons/integration-code-icon.svg';
import user_gen_limit_active from '../../assets/vto/icons/user-gen-limit-active.svg';
import user_gen_limit from '../../assets/vto/icons/user-gen-limit.svg';
import ModalManagementWaterMark from '../../components/VTO-ManagemenWatermark';
import { userSaga } from '../../redux/app/authSaga';
import AccountService from '../../services/account.service';
import FashionService from '../../services/fashion.service';
import NotifyController from '../../utils/toast';
import ConfigThemes from './ConfigThemes';
import CustomizeVTOButton from './CustomizeVTOButton';
import IntegrationCode from './IntegrationCode';
import './styles.css';
import { handleResponseError } from '../../utils/responseError';
import UploadExampleVTO from './UploadExampleVTO';

const { Header, Sider, Content } = Layout;

function VTOSetting() {
    const [guestDailyGenNumState, setGuestDailyGenNumState] = useState<any>(0);
    const [guestDailyGenNumPerProduct, setGuestDailyGenNumPerProduct] = useState<any>(0);
    const [collapsed, setCollapsed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState('limit_gen');
    const [url, setUrl] = useState("https://media2.vision2art.ai/image-outfit-1715739598638.jpg?not-from-cache-please");
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const onChangTab: MenuProps['onClick'] = (e: any) => {
        setLoading(true)
        setTimeout(() => {
            setCurrent(e.key)
            setLoading(false)
        }, 200)
    }
    const urlExample = 'https://media2.vision2art.ai/image-outfit-1715739598638.jpg';
    const getUserInfo = async () => {
        try {
            const dataUser = await AccountService.getInstance().getUserInfo();
            dispatch(userSaga.setUser(dataUser));
        } catch (error: any) {
            swal(t('something_wrong'), "", "error");
        }
    }

    const handleSaveClick = async () => {
        if (!guestDailyGenNumState) {
            NotifyController.warning(t('please_input_guest_daily_gen_num'))
            return;
        }
        if (!guestDailyGenNumPerProduct) {
            NotifyController.warning(t('please_input_guest_daily_gen_num_per_product'))
            return;
        }
        const dataPayload = {
            generationLimit: guestDailyGenNumState,
            generationLimitPerProduct: guestDailyGenNumPerProduct
        }
        try {
            const data = await FashionService.getInstance().updateVTOSettings(dataPayload)
            if (data) {
                NotifyController.success(t('edit_guest_daily_gen_num_successfully'))
                getUserInfo()
            }
        } catch (error) {
            handleResponseError(error);
        }
    };


    const onChangeValue: InputNumberProps['onChange'] = (value) => {
        setGuestDailyGenNumState(Number(value))
    };

    const onChangeValuePerProduct: InputNumberProps['onChange'] = (value) => {
        setGuestDailyGenNumPerProduct(value)
    };

    const getDailyGen = async () => {
        try {
            const { data } = await FashionService.getInstance().getVTOSetting('generation_limit');
            setGuestDailyGenNumState(data.generationLimit)
            setGuestDailyGenNumPerProduct(data.generationLimitPerProduct)
        } catch (error) {
            handleResponseError(error);
        }
    }

    useEffect(() => {
        (async () => {
            if (current === 'back') {
                navigate(-1)
            }
            setLoading(true)
            getDailyGen()
            setLoading(false)
        })()
    }, [current])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 900 && !collapsed) {
                setCollapsed(true);
            } else if (window.innerWidth >= 900 && collapsed) {
                setCollapsed(false);
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial check to set the state correctly on mount
        handleResize();

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [collapsed]);
    const items = [
        {
            key: 'back',
            icon: <LeftOutlined className='!text-[20px]' />,
            label: <p className='text-[20px]'>{t('back')}</p>,

        },
        {
            key: 'config_themes',

            icon: <HighlightOutlined style={{
                fontSize: '23px',
                minWidth: '29px',
                color: current === 'config_themes' ? '#0047FF' : 'gray',
            }} />,
            label: t('config_themes'),
            style: {
                marginTop: '40px'
            }
        },
        {
            key: 'css_button_vto',
            icon: <Icon component={() => (<img className='flex justify-center items-center' src={current === 'css_button_vto' ? icon_button_vto_active : icon_button_vto} width={25} height={25} />)} />,
            label: t('customize_vto_button'),
        },
        {
            key: 'watermark',
            icon: <Icon component={() => (<img className='flex justify-center items-center' src={current === 'watermark' ? icon_watermark_active : icon_watermark} width={25} height={25} />)} />,
            label: t('add_watermark'),

        },
        {
            key: 'limit_gen',
            icon: <Icon component={() => (<img className='flex justify-center items-center' src={current === 'limit_gen' ? user_gen_limit_active : user_gen_limit} width={25} height={25} />)} />,
            label: t('user_generation_limit'),
        },

        {
            key: 'integrate_code',
            icon: <Icon component={() => (<img className='flex justify-center items-center' src={current === 'integrate_code' ? integration_code_icon_active : integration_code_icon} width={25} height={25} />)} />,
            label: t('integration_code'),

        },
        {
            key: 'upload_example',
            icon: <PictureOutlined style={{
                fontSize: '23px',
                minWidth: '29px',
                color: current === 'upload_example' ? '#0047FF' : 'gray',
            }} />,
            label: t('upload_example'),

        },

    ]
    const itemsMobile = [
        {
            key: 'back',
            label: <p className='text-[20px]'>{t('back')}</p>,

        },
        {
            key: 'config_themes',
            label: t('config_themes'),
            style: {
                marginTop: '40px'
            }
        },
        {
            key: 'css_button_vto',
            label: t('customize_vto_button'),
        },
        {
            key: 'watermark',
            label: t('add_watermark'),

        },
        {
            key: 'limit_gen',
            label: t('user_generation_limit'),
        },

        {
            key: 'integrate_code',
            label: t('integration_code'),

        },
        {
            key: 'upload_example',
            label: t('upload_example'),

        },

    ]
    const onChangTabMobile = (key: string) => {
        setCurrent(key)

    }
    return (
        <div>
            <div className='ml-4 hidden lg:!block' >
                <Tabs items={itemsMobile} activeKey={current} onChange={onChangTabMobile} defaultActiveKey='limit_gen'>

                </Tabs>
            </div>
            <Layout className=' h-[calc(100vh_-_170px)]'  >
                <Sider trigger={null} width={'min-content'} breakpoint="lg"
                    collapsedWidth="0"
                    style={{
                        textAlign: 'left',
                    }} collapsible collapsed={collapsed} className='!bg-white'>
                    <Menu
                        selectedKeys={[current]}
                        onClick={onChangTab}
                        theme="light"
                        mode="vertical"
                        className='custom-menu ml-7 sm:ml-2'
                        defaultSelectedKeys={['limit_gen']}
                        items={items}
                    />
                </Sider>
                <Layout className='relative'>
                    <Content
                        style={{
                            minHeight: 280,
                            height: 'calc(100vh - 170px)',
                            background: colorBgContainer,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        <Spin spinning={loading}>
                            {current === 'watermark' && <ModalManagementWaterMark isOpen={current === 'watermark'} setIsOpen={() => { }} url={url} />}
                            {current === 'limit_gen' &&
                                <div className='flex flex-col items-start gap-2 ml-10 sm:ml-8'>
                                    <div className='text-[#101828] text-[16px] font-semibold mb-3'>{t('action')}</div>
                                    <div className="my-2">
                                        <div className='font-semibold text-left mb-2'>{t('Guest_daily_gen_number')}</div>
                                        <div className='flex gap-2 flex-col'>
                                            <InputNumber
                                                min={1}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                pattern="[0-9]*"
                                                style={{ backgroundColor: 'white' }}
                                                value={guestDailyGenNumState}
                                                onChange={onChangeValue}
                                                addonAfter={<>{t('gen_per_day')}</>}
                                            />
                                        </div>
                                    </div>
                                    <div className="my-2">
                                        <div className='font-semibold text-left mb-2'>{t('Guest_daily_gen_number_per_product')}</div>
                                        <div className='flex gap-2 flex-col'>
                                            <InputNumber
                                                min={1}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                pattern="[0-9]*"
                                                style={{ backgroundColor: 'white' }}
                                                value={guestDailyGenNumPerProduct}
                                                onChange={onChangeValuePerProduct}
                                                addonAfter={<>{t('gen_per_day')}</>}
                                            />
                                            <Button icon={<CheckOutlined />} type="primary" className='my-2' onClick={handleSaveClick}>{t('save')}</Button>
                                        </div>
                                    </div>
                                </div>
                            }
                            {current === 'css_button_vto' && <CustomizeVTOButton />}
                            {current === 'integrate_code' && <IntegrationCode />}
                            {current === 'config_themes' && <ConfigThemes />}
                            {current === 'upload_example' && <UploadExampleVTO />}
                            {/* {current === 'report' && <ReportShop />} */}
                        </Spin>
                    </Content>
                </Layout>
            </Layout>
        </div>
    )
}

export default VTOSetting