// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-resize .ant-modal-content{
    min-height: calc(100vh - 100px);
    /* background-color: gray !important; */
}
.modal-resize .ant-modal-title{
    /* background-color: gray !important; */
}`, "",{"version":3,"sources":["webpack://./src/components/UploadImage/modal-resize.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAG/B,uCAAuC;AAF3C;AAIA;IACI,uCAAuC;AAC3C","sourcesContent":[".modal-resize .ant-modal-content{\n    min-height: calc(100vh - 100px);\n}\n.modal-resize .ant-modal-content{\n    /* background-color: gray !important; */\n}\n.modal-resize .ant-modal-title{\n    /* background-color: gray !important; */\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
