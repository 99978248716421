import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { convertBlobToBase64, convertHeicToJpg, resizeImageMax2k, urlToBase64 } from '../../../utils/function';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import iconUpload from '../../../assets/icons/upload-cloud.png';
import { editorImage as ImageEditor } from '../../../redux/app/appImagePreprocessing';
import { userSaga } from '../../../redux/app/authSaga';
import { setLoadingApp } from '../../../redux/app/loadingApp';
import { toolEditorImage } from '../../../redux/app/toolEditor';
import { useAppSelector } from '../../../redux/hooks/useAppSelector';
import AccountService from '../../../services/account.service';
import FashionService from '../../../services/fashion.service';
import NotifyController from '../../../utils/toast';
import Heading from '../DetailsTools/Heading';

type swapfaceProps = {
    originImageUrl: string,
    imageId: string,
    onClickSave: () => void,
    onClickBackToHome: () => void,
    setIsGenerating: Function,
    isModal: boolean,
    setIsOpenModal: Function
}
function SwapFace({ originImageUrl, imageId, onClickSave, onClickBackToHome, setIsGenerating, isModal, setIsOpenModal }: swapfaceProps) {
    const { t } = useTranslation()
    const userInfo = useAppSelector((store) => store.user);
    const dataCost = useAppSelector((store) => store.costFeature);
    const editorImage = useAppSelector((store) => store.EditorImage);
    const dispatch = useDispatch()

    const [cropData, setCropData] = useState('');
    const [specialId, setSpecialId] = useState('');
    const [disableGenButton, setDisableGenButton] = useState(false);

    const handleImageUpload = async (e: any) => {
        e.preventDefault();
        let files = e.target?.files;
        if (!files || !files.length) {
            console.warn("No file selected")
            return
        }
        const reader = new FileReader();
        reader.onload = async (e: any) => {
            // dispatch(setLoadingApp.setLoading(true));
            let imageUrlFinal = reader.result as string;
            let isHeic = imageUrlFinal.startsWith('data:application/octet-stream;base64')
            if (isHeic) {
                const response = await fetch(reader.result as any);
                const blob = await response.blob();
                const coverted = await convertHeicToJpg(blob);
                imageUrlFinal = await convertBlobToBase64(coverted.resultBlob) as string;
            }
            const base64Image = await resizeImageMax2k(imageUrlFinal) as any
            setCropData(base64Image)
            // dispatch(setLoadingApp.setLoading(false))
        };
        reader.readAsDataURL(files[0]);
        e.target.value = null
    };
    const onClickUploadImage = () => {
        const inputUpload = document.getElementById('input-upload-image-swapface') as HTMLInputElement;
        inputUpload.click();
    };
    const onToRemove = () => {
        setCropData('')
    }
    const updateUserCredits = async () => {
        try {
            const { coins } = await AccountService.getInstance().getUserInfo();
            dispatch(userSaga.setCredits(coins));
        } catch (error) {
            swal(t('something_wrong'), "", "error");
        }
    }
    const onHandleSwapface = async () => {
        try {
            if (cropData) {
                setDisableGenButton(true);
                dispatch(setLoadingApp.setLoading(true))
                if (userInfo.coins < dataCost.swapFace) {
                    NotifyController.warning(t('Not_enough_credit'))
                    setCropData('');
                    setDisableGenButton(false);
                    return;
                }
                const body = {
                    originImageUrl: originImageUrl,
                    initImage: cropData,
                    id: imageId
                }
                const data = await FashionService.getInstance().processSwapface(body);
                setSpecialId(data)
                setDisableGenButton(false);
            } else NotifyController.warning(t('Please_input_your_image'))
        } catch (error: any) {
            setDisableGenButton(false);
            dispatch(setLoadingApp.setLoading(false))
            swal(t(error.message), '', 'error')
        }
    }

    let interval: any;
    useEffect(() => {
        if (specialId) {

            interval = setInterval(async () => {
                const listeningImage = await FashionService.getInstance().listenImageRemove(specialId);
                if (listeningImage.status === 'FINISHED') {
                    updateUserCredits()
                    if (interval) {
                        clearInterval(interval);
                    }
                    const urlBase64 = await urlToBase64(listeningImage.url_arr[0] + '?not-from-cache-please');
                    const imageUrl = 'data:image/png;base64,' + urlBase64;
                    dispatch(toolEditorImage.setImageInput(imageUrl))
                    dispatch(ImageEditor.setImageEditor({ ...editorImage, imageUrl }))
                    dispatch(setLoadingApp.setLoading(false))
                }
                if (listeningImage.status === "FAILED") {
                    updateUserCredits()
                    dispatch(setLoadingApp.setLoading(false))
                }
            }, 2000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        }
    }, [specialId])
    return (
        <div className='flex flex-col gap-2'>
            <div>
                <Heading title={t('swap_face')} onSave={onClickSave} onClickBackToHome={onClickBackToHome} />
            </div>
            <div className='flex flex-row gap-4 justify-between  items-start sm:items-center md:items-center  w-full min-w-[80vw] sm:flex-col md:flex-col lg:flex-col '>
                <div className='upload flex flex-col  w-[400px] ml-10 '>
                    {/* <hr className="bg-[#F5F5F5] h-[1px] border-none mt-2 " /> */}
                    <div className='sub-title text-base  font-semibold text-left my-2'>{t('action')}</div>
                    {!cropData ?
                        <div className='mt-2'>
                            {/* <Tooltip title={t('Only_support_image_types_png/jpg/jpeg')}> */}
                            <div onClick={onClickUploadImage} className='cursor-pointer flex flex-col justify-center  items-center !bg-[#F3F6FF] w-full h-[150px] rounded-lg !border !border-solid !border-[#5379FF] hover:bg-gray-300'>
                                <input id="input-upload-image-swapface" type="file" accept="image/*" onChange={handleImageUpload} className="hidden" />
                                <img src={iconUpload} alt="" />
                                <div className='text-[#101828]'>{t('upload_your_template')}</div>
                                <p className='text-sm text-gray'>{t('Only_support_image_types_png/jpg/jpeg')}</p>
                            </div >
                            {/* </Tooltip> */}

                        </div>
                        :
                        <div>
                            <img src={cropData} id="img-upload" className='rounded-lg' alt="preview-image" width={'150px'} height={'auto'} />
                        </div>
                    }
                    <div className='sub-title text-base font-semibold text-left mt-4'>{t('guide')}</div>
                    <div className="tip flex gap-2 rounded-lg max-w-[400px] p-3 text-left box-guide-tool">
                        <div className="text-[#101828] text-sm">
                            <ul className='text-[#667085] text-[14px] ml-2'>
                                <li> {t("swapface_guide_1")}</li>
                                <li> {t("swapface_guide_2")}</li>
                                <li> {t("swapface_guide_3")}</li>
                            </ul>
                        </div>
                    </div>
                    <div className='mt-8 flex gap-2 w-full'>
                        {cropData && <Button onClick={onToRemove} className='w-full' type='default' danger>{t('delete')}

                        </Button>}
                        <Button onClick={onHandleSwapface} disabled={disableGenButton} className='w-full' type='primary'>
                            <div className='flex justify-center items-center'><p><span>{t('Swap_face')}</span><span> (-{dataCost.swapFace}</span> Credits<span>)</span></p></div>
                        </Button>
                    </div>
                </div >
                <div className='pre-processing-left  relative box-canvas-pre  bg-[#CCD9FF] !w-[65vw]  min-h-3/4 flex justify-center items-center min-h-[75vh]  '>
                    <div className='flex justify-center   '>
                        <img style={{ borderRadius: '14px' }} className='p-10' src={originImageUrl} alt='upscale-img' width={370} height={'auto'} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SwapFace