import { createSlice } from '@reduxjs/toolkit';

interface RealtimeSegmentState {
  segmentData?: string;
}

const initialState: RealtimeSegmentState = {
};


const realtimeSegmentSlice = createSlice({
  name: "realtimeSegment",
  initialState,
  reducers: {
    setRealtimeSegment: (state, action) => {
      state.segmentData = action.payload.segmentData;
    },
    resetRealtimeSegment: (state) => {
      state.segmentData = undefined;
    },
  },
});

export const realtimeSegmentActions = realtimeSegmentSlice.actions;
export default realtimeSegmentSlice.reducer;