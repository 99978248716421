import { Button, Modal } from 'antd';
import { ThunderboltOutlined, WarningOutlined } from '@ant-design/icons';

import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/hooks/useAppSelector';

type ModalUpscaleProps = {
    isOpenModalWarning: boolean;
    setIsOpenModalWarning: Function;
    onClickContinuesUpload: Function;
    upscaleImage: Function,
    setLoading: Function
}

function ModalUpscale({ isOpenModalWarning, setIsOpenModalWarning, onClickContinuesUpload, upscaleImage, setLoading }: ModalUpscaleProps) {
    const dataCost = useAppSelector(store => store.costFeature)
    const { t } = useTranslation()
    return (
        <Modal
            open={isOpenModalWarning}
            closeIcon={null}
            onCancel={() => { setIsOpenModalWarning(false); setLoading(false) }}
            centered
            footer={
                <div className='text-center flex gap-2 justify-center'>
                    <Button onClick={() => onClickContinuesUpload()}>{t('continues_less_512')}</Button>
                    <Button onClick={() => upscaleImage()} className='btn-primary'>
                        {t('upscale')}
                        <span className='ml-1'>{`(`}{-dataCost.upscale} Credits {`)`}</span>
                    </Button>
                </div>
            }
        >
            <div className='flex flex-col justify-center items-center gap-2'>
                <WarningOutlined className='text-5xl text-yellow-400 text-center' />
                <div className='text-xl font-bold'> {t('warning')}</div>
                <div className='text-md text-center'>
                    {t('warning_less_512')}
                </div>
            </div>
        </Modal>
    )
}

export default ModalUpscale