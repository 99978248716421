// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cropper-point {
    height: 13px;
    width: 13px;
}

@media (min-width: 1200px) {
    .point-se {
        height: 13px !important;
        width: 13px !important;
    }
}

@media (min-width: 992px) {
    .point-se {
        height: 13px !important;
        width: 13px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/CropComponent/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;AACf;;AAEA;IACI;QACI,uBAAuB;QACvB,sBAAsB;IAC1B;AACJ;;AAEA;IACI;QACI,uBAAuB;QACvB,sBAAsB;IAC1B;AACJ","sourcesContent":[".cropper-point {\n    height: 13px;\n    width: 13px;\n}\n\n@media (min-width: 1200px) {\n    .point-se {\n        height: 13px !important;\n        width: 13px !important;\n    }\n}\n\n@media (min-width: 992px) {\n    .point-se {\n        height: 13px !important;\n        width: 13px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
