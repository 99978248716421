import 'react-image-crop/dist/ReactCrop.css';
import { Button, InputNumber, Spin } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop';
import { useDispatch } from 'react-redux';

import { editorImage } from '../../redux/app/appImagePreprocessing';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import { convertBlobToBase64, getImageSize } from '../../utils/function';
import NotifyController from '../../utils/toast';
import { useDebounceEffect } from '../UploadImage/useDebounceEffect';
import { canvasPreview } from '../UploadImage/CanvasPreview';
import Modal from 'antd/es/modal/Modal';

type ModalResizeProps = {
    image: string;
    initImage?: string;
    isOpen: boolean;
    setIsOpen: Function;
    setBgImage: Function;
    setImageCropped: Function;
};

const radio23 = 2 / 3;
const radio34 = 3 / 4;
const NUMBER_DIVIDED = 8;
function ModalCropImage({ image, initImage, isOpen, setIsOpen, setBgImage, setImageCropped }: ModalResizeProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const resizeCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    const [valueWidth, setValueWidth] = useState(0);
    const [valueHeight, setValueHeight] = useState(0);
    const [widthInput, setWidthInput] = useState(0);
    const [heightInput, setHeightInput] = useState(0);
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [aspect, setAspect] = useState<number | undefined>(radio23);
    const [classWidthImageRender, setClassWidthImageRender] = useState('')
    const [cropped, setCropped] = useState(false);
    const [cropping, setCropping] = useState(false);

    const getSize = async () => {
        const { width, height } = await getImageSize(image);
        setWidthInput(width);
        setHeightInput(height);
    };

    const onChangeScale = (ratio: number, width?: number, height?: number) => {
        setCropped(false);
        const aspectRatioData = centerAspectCrop(width ?? widthInput, height ?? heightInput, ratio)
        const w = Math.round(
            (aspectRatioData.width * (width ?? widthInput)) /
            100
        );
        const h = Math.round(
            (aspectRatioData.height * (height ?? heightInput)) /
            100
        );
        const x = Math.round(
            (aspectRatioData.x * (width ?? widthInput)) / 100
        );
        const y = Math.round(
            (aspectRatioData.y * (height ?? heightInput)) / 100
        );
        setAspect(ratio);
        setValueWidth(w);
        setValueHeight(h);
        setPositionX(x);
        setPositionY(y);
        setCrop(aspectRatioData);
        if (imgRef.current?.naturalWidth) {
            const percent =
                (imgRef.current?.width as number) /
                (imgRef.current?.naturalWidth as number);
            const completedCropData = {
                height: h * percent,
                width: w * percent,
                x: x * percent,
                y: y * percent,
                unit: "px",
            } as PixelCrop;
            setCompletedCrop(completedCropData);
        }
    };

    function centerAspectCrop(
        mediaWidth: number,
        mediaHeight: number,
        aspect: number
    ) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: "%",
                    width: 100,
                },
                aspect,
                mediaWidth,
                mediaHeight
            ),
            mediaWidth,
            mediaHeight
        );
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    }

  
    const onOK = async () => {
        if (!previewCanvasRef.current || !resizeCanvasRef.current) throw new Error("previewCanvasRef, !resizeCanvasRef is null");
        let imgWidth: number = valueWidth;
        let imgHeight: number = valueHeight;
        // imgWidth = valueWidth - (valueWidth % 8);
        // imgHeight = valueHeight - (valueHeight % 8);
        setValueWidth(imgWidth);
        setValueHeight(imgHeight);
        const canvas = resizeCanvasRef.current;
        const ctx = canvas.getContext("2d");
        if (canvas && ctx) {
            canvas.width = imgWidth;
            canvas.height = imgHeight;
            ctx.drawImage(previewCanvasRef.current, 0, 0, imgWidth, imgHeight);
        }
        const blob = await new Promise((resolve) => {
            resizeCanvasRef.current?.toBlob((blob) => {
                if (!blob) {
                    throw new Error("Failed to create blob");
                }
                resolve(blob);
            });
        });
        const base64String = await convertBlobToBase64(blob);
        setBgImage(base64String)
        setImageCropped(base64String)
        setIsOpen(false)
    };

    useEffect(() => {
        getSize();
    }, []);

    useEffect(() => {
        if (aspect) {
            const centerAspectCropData = centerAspectCrop(widthInput, heightInput, +aspect)
            const initW = Math.round((centerAspectCropData.width * widthInput) / 100);
            const initH = Math.round((centerAspectCropData.height * heightInput) / 100);
            setValueWidth(initW);
            setValueHeight(initH);
            setPositionX(Math.round((centerAspectCropData.x * widthInput) / 100));
            setPositionY(Math.round((centerAspectCropData.y * heightInput) / 100));
        }
    }, [widthInput, heightInput, aspect]);

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    1
                );

                dispatch(
                    editorImage.setRenderedImageSize({
                        renderedImageSize: {
                            width: imgRef.current.clientWidth ?? completedCrop?.width,
                            height: imgRef.current.clientHeight ?? completedCrop?.height,
                        },
                    })
                );
            }
        },
        100,
        [completedCrop, image, dispatch]
    );

    useEffect(() => {
        if (widthInput < 300) {
            setClassWidthImageRender('w-full')
        }
        if (widthInput > 1800) {
            setClassWidthImageRender('w-1/2')
        }
        else if (widthInput <= 1800 && widthInput > 800) {
            setClassWidthImageRender('w-1/3')
        }
        else {
            setClassWidthImageRender('w-1/2')
        }
    }, [image])

    return (
        <Modal open={isOpen} width='fit-content' centered={false} className={'top-2'} title={t('crop_image')}
            onCancel={() => {
                setIsOpen(false)
            }}
            footer={
                <div className='flex justify-center items-center gap-4' >
                    <Button onClick={() => {
                        setIsOpen(false)
                    }}>{t('cancel')}</Button>
                    <Button type='primary' onClick={() => {
                        onOK()
                    }}>{t('confirm')}</Button>
                </div>
            }

        >
            <div id="modal-resize">
                <div className={
                    "flex flex-row gap-4 justify-between sm:items-center md:items-center lg:items-center  w-full sm:flex-col md:flex-col lg:flex-col "
                } >
                    <div className="flex flex-col items-center  sm:w-full md:w-full lg:w-full min-h-[300px] min-w-[350px] ">
                        <div className="flex flex-col gap-4 mt-6 ">
                            <div className="flex gap-4">
                                <div className="flex flex-col">
                                    <div>{t("width")}</div>
                                    <div>
                                        {" "}
                                        <InputNumber
                                            disabled
                                            readOnly={true}
                                            value={valueWidth}
                                            style={{ width: "150px" }}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div>{t("height")}</div>
                                    <div>
                                        {" "}
                                        <InputNumber
                                            disabled
                                            readOnly={true}
                                            value={valueHeight}
                                            style={{ width: "150px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="flex flex-col">
                                    <div>{t("position_x")}</div>
                                    <div>
                                        {" "}
                                        <InputNumber
                                            disabled
                                            readOnly={true}
                                            value={positionX}
                                            style={{ width: "150px" }}
                                        />{" "}
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div>{t("position_y")}</div>
                                    <div>
                                        {" "}
                                        <InputNumber
                                            disabled
                                            readOnly={true}
                                            value={positionY}
                                            style={{ width: "150px" }}
                                        />{" "}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-6 flex flex-col justify-center items-center">
                            <div className="flex flex-col">
                                <div className="flex justify-start items-start">{t("ratio_image")}</div>
                                <div
                                    onChange={(e: any) => onChangeScale(e.target.id == "23" ? radio23 : radio34)}
                                    className="flex  gap-4 "
                                >
                                    <div className="flex justify-center">
                                        <input
                                            className="hidden"
                                            type="radio"
                                            name="size-crop"
                                            id="23"
                                        />
                                        <label htmlFor="23">
                                            <div
                                                className={` btn-size-crop ${aspect === radio23 ? " btn-active " : ""
                                                    }`}
                                            >
                                                2:3
                                            </div>
                                        </label>
                                    </div>
                                    <div className="flex justify-center">
                                        <input
                                            className="hidden"
                                            type="radio"
                                            name="size-crop"
                                            id="34"
                                        />
                                        <label htmlFor="34">
                                            <div
                                                className={` btn-size-crop ${aspect === radio34 ? "btn-active" : ""
                                                    }`}
                                            >
                                                3:4
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12 sm:mt-4 md:mt-4 lg:mt-4 xl:mt-4 w-full">
                                <hr className="bg-[#F5F5F5] h-[1px] border-none" />
                                {initImage && <div className="flex flex-col justify-center items-center  gap-2 mt-6 md:mt-2 sm:mt-2 lg:mt-2 xl:mt-2">
                                    <div className='select-bg w-full h-[220px] bg-img border-red-100 border-2 border-solid rounded-lg' style={{
                                        backgroundImage: 'url("' + initImage + '")',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'contain',
                                        backgroundRepeat: 'no-repeat'
                                    }}></div>
                                    <div>{t('your_input')}</div>
                                </div>}
                                <hr className="bg-[#F5F5F5] h-[1px] border-none mt-3" />
                            </div>
                        </div>
                    </div>
                    <div className=" bg-[#CCD9FF] !w-[65vw] w-3/4 h-3/4 flex justify-center items-center min-h-[75vh] ">
                        <div className="flex justify-center items-center">
                            {
                                classWidthImageRender ?
                                    <ReactCrop
                                        className={`h-full  ${classWidthImageRender} md:w-[90%] sm:w-[90%] lg:w-[90%] xl:w-[90%]`}
                                        crop={crop}
                                        onChange={(pxCrop: any, percentCrop: any) => {
                                            const ratioW = Math.round(
                                                widthInput * (percentCrop?.width / 100)
                                            );
                                            const ratioH = Math.round(
                                                heightInput * (percentCrop?.height / 100)
                                            );
                                            const positionX = Math.round(pxCrop?.x);
                                            const positionY = Math.round(pxCrop?.y);
                                            setCrop(pxCrop);
                                            setValueHeight(ratioH);
                                            setValueWidth(ratioW);
                                            setPositionX(positionX);
                                            setPositionY(positionY);
                                            setCropped(false)
                                        }}
                                        onComplete={(pxCrop: any, percentCrop: any) => {
                                            const ratioW = pxCrop.width; // widthInput * (percentCrop?.width/100);
                                            const ratioH = pxCrop.height; //heightInput * (percentCrop?.height/100);
                                            const positionX = pxCrop.x; ///   widthInput * (percentCrop?.x/100);
                                            const positionY = pxCrop.y; //   heightInput * (percentCrop?.y/100);
                                            let completedCropData = {
                                                height: ratioH,
                                                width: ratioW,
                                                x: positionX,
                                                y: positionY,
                                                unit: "px",
                                            } as PixelCrop;
                                            setCompletedCrop(completedCropData);
                                            setCropped(false)
                                        }}
                                        aspect={aspect}
                                    >
                                        <img
                                            ref={imgRef}
                                            alt="Crop me"
                                            src={image}
                                            onLoad={onImageLoad}
                                        />
                                        <canvas ref={resizeCanvasRef} className="hidden"></canvas>
                                    </ReactCrop>
                                    :
                                    <Spin spinning={true}></Spin>
                            }
                        </div >
                    </div >
                </div >
                {!!completedCrop && (
                    <>
                        <div className="hidden">
                            <canvas
                                id="preview"
                                ref={previewCanvasRef}
                                style={{
                                    objectFit: "contain",
                                    width: completedCrop.width,
                                    height: completedCrop.height,
                                }}
                            />
                        </div>
                    </>
                )}
            </div >
        </Modal >
    );
}

export default ModalCropImage;
