import { logEvent } from '@firebase/analytics';
import { Button } from 'antd'
import React from 'react'
import { setLoadingApp } from '../../../redux/app/loadingApp';
import { convertBlobToBase64, convertHeicToJpg, getImageSize, resizeImageMax2k } from '../../../utils/function';
import analytics from '../../../firebase';
import swal from 'sweetalert';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLess512 } from '../../../redux/app/uploadImgeSaga';
import iconUpload from '../../../assets/icons/upload-cloud.png';
import { toolEditorImage } from '../../../redux/app/toolEditor';
import { useNavigate } from 'react-router-dom';
import { ROUTERS } from '../../../utils/constant';
import { editorImage } from '../../../redux/app/appImagePreprocessing';
import { TypeEditor } from '../../../utils/enum';
function Upload() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg'],
            'image/jpg': ['.jpg'],
            'image/webp': ['.webp'],
            'image/heic': ['.heic']
        },
        onDrop: async (acceptedFiles: any) => {
            await onDropHandler(acceptedFiles);
        }
    });

    const onDropHandler = async (files: any) => {
        if (files.length === 0) {
            swal(t('file_not_image'), '', 'warning')
        } else {
            const file = files[0];
            let isHEIC = file.type === 'image/heic'
            const reader = new FileReader();
            reader.onload = async (event: any) => {
                let imageUrlFinal = event.target.result;
                if (isHEIC) {
                    dispatch(setLoadingApp.setLoading(true))
                    const response = await fetch(event.target.result);
                    const blob = await response.blob();
                    const coverted = await convertHeicToJpg(blob);
                    imageUrlFinal = await convertBlobToBase64(coverted.resultBlob) as string;
                    dispatch(setLoadingApp.setLoading(false))
                }
                const imgUpload = await getImageSize(imageUrlFinal);

                logEvent(analytics, 'upload_image')
                if (imgUpload?.height < 512 || imgUpload?.width < 512) {

                    dispatch(toolEditorImage.setImageInput(event.target.result))
                    const { width, height } = await getImageSize(event.target.result)
                    dispatch(toolEditorImage.setSizeImage({
                        width: width,
                        height: height
                    }))
                    dispatch(toolEditorImage.setTypeEdit(TypeEditor.UPLOAD))
                    navigate(ROUTERS.TOOL_EDITOR)
                    // setImage(event.target.result);
                    // // openModel(event.target.result)
                    // setIsOpenModalWarning(true)
                } else {

                    dispatch(setLoadingApp.setLoading(true))
                    dispatch(setLess512.setLess512(false))
                    const base64Image = await resizeImageMax2k(imageUrlFinal) as any;
                    const { width, height } = await getImageSize(base64Image)
                    dispatch(toolEditorImage.setImageInput(base64Image))
                    dispatch(toolEditorImage.setSizeImage({
                        width: width,
                        height: height
                    }))
                    dispatch(editorImage.setImageEditor({
                        imageUrl: base64Image,
                        width: width,
                        height: height
                    }))
                    dispatch(toolEditorImage.setTypeEdit(TypeEditor.UPLOAD))
                    navigate(ROUTERS.TOOL_EDITOR)
                    dispatch(setLoadingApp.setLoading(false))
                }
            };
            reader.readAsDataURL(file);
        }

        try {
            if (files.length === 0) {
                return;
            }
            if (files.length > 1) {
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                // selectImage(files[0]);
            };
            reader.readAsDataURL(files[0]);
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div className='m-0 m-auto flex justify-center items-center'>
            <div className='flex flex-col justify-center items-center w-1/2 h-[80vh]'>
                <div className={`upload-image-section flex justify-center items-center hover:cursor-pointer p-3 rounded-lg  !w-[65vw]  h-3/4  min-h-[75vh] sm:p-4  ${isDragActive ? '!bg-zinc-400' : 'bg-[#CCD9FF]'}`}>
                    <div  {...getRootProps({ className: 'dropzone' })} className={`flex border-dashed border border-white shadow-sm bg-white  flex-col gap-3 justify-center items-center p-10 rounded-md   upload-box-section `}>
                        <input id="input-upload-image-2" type="file" accept="image/*" {...getInputProps()} className="hidden" />
                        <img src={iconUpload} draggable="false" width={40} alt="icon-upload" />
                        <span className={`${isDragActive ? 'text-black' : 'text-gray'} text-upload`}>{t('upload_your_template')}</span>
                        <span className='text-upload-guide'>{t('drop_file')}</span>
                        <Button type='primary'>{t('upload_image')}</Button>
                        <span className='text-upload-guide'>{t('Only_support_image_types_png/jpg/jpeg')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Upload