import { Button, Col, Input, InputNumber, InputNumberProps, Row, Slider, Spin, Tabs, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { setStateVTO } from '../../redux/app/appVTO';
import FashionService from '../../services/fashion.service';
import { convertBlobToBase64, convertHeicToJpg, resizeImageToMaxSize768x1024 } from '../../utils/function';
import NotifyController from '../../utils/toast';
import SliderUploadModel from '../VTO-UploadModel/SliderUploadModel';
import InputCategory from '../VTO-UploadModel/VTO-input-setting/InputCategory';
import ImageTrainLora from '../VTO-UploadModel/ImageTrainLora';
import InputLinkProducts from '../VTO-UploadModel/VTO-input-setting/InputLinkProducts';
import InputLimitGen from '../VTO-UploadModel/VTO-input-setting/InputLimitGen';
import AI from '../../assets/vto/icons/ai.svg';
import { handleResponseError } from '../../utils/responseError';

type VTOUploadModelProps = {
    setInputSetting: Function,
    inputSetting: IInputSetting,
    setSelectedImage: Function,
    selectedImage: string,
    isOnClickReset: boolean,
    setUploadedImages: Function,
    uploadedImages: any,
    dataEdit: any,
    setEditInputSetting: Function,
    isOpenCate: boolean,
    setIsOpenCate: Function,
    saveCropperState: Function,
    cropperStates: any,
    setCropperStates: Function,
    cropperRef: any,
    slideIndex: number,
    setSlideIndex: Function,
    onClose: Function,
    setTrainLoraShopHistory: Function,

}
export interface IInputSetting {
    IdOutfit: string,
    category: string,
    prompt: string,
    weight: number,
}
function VTOEditOutfit({
    setEditInputSetting,
    setIsOpenCate,
    isOpenCate,
    setSelectedImage,
    isOnClickReset,
    setUploadedImages,
    dataEdit,
    saveCropperState,
    cropperStates,
    setCropperStates,
    cropperRef,
    slideIndex,
    setSlideIndex, onClose,
    setTrainLoraShopHistory,
}: VTOUploadModelProps) {
    const [valueId, setValueId] = useState('')
    const [categorySelected, setCategorySelected] = useState('')
    const [productLink, setProductLink] = useState('')
    const [numberLimit, setNumberLimit] = useState(null)
    const { t } = useTranslation()
    const [isLoadingText, setIsLoadingText] = useState(false)
    const [tabActive, setTabActive] = useState('0')
    const dispatch = useDispatch()
    const firstLoadRef = useRef(true)
    type TargetKey = React.MouseEvent | React.KeyboardEvent | string;
    const onRemoveImage = (url: string, id: string) => {
        if (id) {
            swal({
                title: t('are_you_sure'),
                text: t('You_will_not_be_able_to_recover_this'),
                icon: 'warning',
                buttons: [t('cancel'), t('delete')],
                dangerMode: true,
            }).then(async (willDelete) => {
                if (willDelete) {
                    try {
                        await FashionService.getInstance().deleteVTOImageItem(id);
                        const cloneEditInputSetting = { ...dataEdit }
                        cloneEditInputSetting.imageAndSetting = cloneEditInputSetting.imageAndSetting.filter((item: any) => item._id !== id)
                        setEditInputSetting(cloneEditInputSetting)
                        dispatch(setStateVTO.setReload(true))
                        setTrainLoraShopHistory({})
                        setTabActive((cloneEditInputSetting.imageAndSetting.length - 1).toString())
                    } catch (error) {
                        handleResponseError(error);
                    }
                }
            });
        } else {
            const cloneEditInputSetting = { ...dataEdit }
            cloneEditInputSetting.imageAndSetting = cloneEditInputSetting.imageAndSetting.filter((item: any) => item.modelImage !== url)
            setEditInputSetting(cloneEditInputSetting)
            // make sure u can add image this again after remove
            const inputFile = document.getElementById('input-upload-image-item') as HTMLInputElement;
            if (inputFile) {
                inputFile.value = '';
            }
            setTabActive((cloneEditInputSetting.imageAndSetting.length - 1).toString())
        }
        if (dataEdit.imageAndSetting.length > 0) {
            setSelectedImage(dataEdit.imageAndSetting[0].modelImage)
            setSlideIndex(0)
        }
    }

    const handleImageUpload = async (e: any) => {
        try {
            e.preventDefault();
            let files = e.target?.files;
            if (!files || !files.length) {
                console.warn("No file selected")
                return
            }
            const reader = new FileReader();
            reader.onload = async (event: any) => {
                let imageUrlFinal = event.target.result;
                let isHEIC = imageUrlFinal.startsWith('data:application/octet-stream;base64')
                if (isHEIC) {
                    const response = await fetch(event.target.result);
                    const blob = await response.blob();
                    const converted = await convertHeicToJpg(blob);
                    imageUrlFinal = await convertBlobToBase64(converted.resultBlob) as string;
                }
                if (imageUrlFinal) {
                    const imageMaxSize1024 = await resizeImageToMaxSize768x1024(imageUrlFinal)
                    const cloneEditInputSetting = { ...dataEdit }
                    cloneEditInputSetting.imageAndSetting.push({
                        modelImage: imageMaxSize1024,
                        prompt: '',
                        weight: 1,
                    })
                    setEditInputSetting(cloneEditInputSetting)
                    const lastTab = dataEdit.imageAndSetting.length - 1
                    setTabActive(lastTab.toString())
                }
            }
            reader.readAsDataURL(files[0]);
            e.target.value = null
        } catch (error) {
            NotifyController.error('Something went wrong!')
        }

    }

    const onClickUploadImage = () => {
        const inputUpload = document.getElementById('input-upload-image-item') as HTMLInputElement;
        inputUpload.click();
    };

    const onCopyId = (id: string) => {
        navigator.clipboard.writeText(id);
        NotifyController.success('Copy code successfully')
    }

    const onChangeOutfitId = (e: any) => {
        setValueId(e.target.value)
        const cloneEditInputSetting = { ...dataEdit }
        cloneEditInputSetting.outfitCode = e.target.value
        setEditInputSetting(cloneEditInputSetting)
    }

    const onChange: InputNumberProps['onChange'] = (newValue) => {
        const cloneEditInputSetting = { ...dataEdit }
        cloneEditInputSetting.imageAndSetting[tabActive].weight = newValue
        setEditInputSetting(cloneEditInputSetting)
    };

    const onChangePrompt = (e: any) => {
        const value = e.target.value;
        const cloneEditInputSetting = { ...dataEdit }
        cloneEditInputSetting.imageAndSetting[tabActive].prompt = value
        setEditInputSetting(cloneEditInputSetting)
    }
    const genCaptionByImage = async (index: number) => {
        setIsLoadingText(true)
        const img = dataEdit?.imageAndSetting[index]?.modelImage;
        try {
            const { data } = await FashionService.getInstance().getCaptionByImage(img)
            const value = data?.caption;
            if (value) {
                const cloneUploadedImages = { ...dataEdit }
                cloneUploadedImages.imageAndSetting[tabActive].prompt = value
                setUploadedImages(cloneUploadedImages)
            }
        } catch (error) {
            handleResponseError(error);
        } finally {
            setIsLoadingText(false)
        }
    }
    useEffect(() => {
        if (isOnClickReset) {
            setUploadedImages([])
        }

    }, [isOnClickReset])

    useEffect(() => {
        if (dataEdit.imageAndSetting.length > 0)
            setSelectedImage(dataEdit.imageAndSetting[0]?.modelImage)
        else {
            onClose()
        }
    }, [dataEdit])

    useEffect(() => {
        setValueId(dataEdit.outfitCode)
        setCategorySelected(dataEdit.category)
        setProductLink(dataEdit.productLink)
        setNumberLimit(dataEdit.generationLimitPerDay)
    }, [dataEdit])

    const onChangeTab = (newActiveKey: string) => {
        setTabActive(newActiveKey);
        setSlideIndex(+(newActiveKey))
    }

    useEffect(() => {
        if (firstLoadRef.current) {
            firstLoadRef.current = false;
        } else {
            const activeE = document.getElementById(`tab-active-${tabActive}`);
            if (activeE) {
                activeE.scrollIntoView({
                    block: 'center', // Ensures the element aligns with the top of the scrollable container
                    behavior: 'smooth' // Smooth scrolling effect
                });
            }
        }
    }, [tabActive])
    const items = dataEdit.imageAndSetting.map((item: any, index: number) => ({
        key: index.toString(),
        label: <div className='font-semibold text-[18px]'>
            <Tooltip color='white' title={
                <div className='rounded-lg'>
                    <img src={dataEdit.imageAndSetting[index].modelImage} width={80} alt="" />
                </div>
            }>
                {t('image_number')} {index + 1}
            </Tooltip>
        </div>,
        id: `tab-active-${index}`,
        children: <div key={index} className='bg-stone-200 p-2  rounded-b-lg'>
            {dataEdit.imageAndSetting.length > 0 && <SliderUploadModel
                setTrainLoraShopHistory={setTrainLoraShopHistory}
                saveCropperState={saveCropperState}
                cropperStates={cropperStates}
                setCropperStates={setCropperStates}
                cropperRef={cropperRef}
                indexActive={+(tabActive)}
                setIndexActive={setSlideIndex}
                listImages={dataEdit.imageAndSetting}
                setListImages={setUploadedImages}
                isEdit={true}
                dataEdit={dataEdit}
                setEditInputSetting={setEditInputSetting}
            />}
            {dataEdit.imageAndSetting.length > 0 && <ImageTrainLora setCloseShow={onClose} isEdit={true} dataEdit={dataEdit} isEnable={dataEdit.imageAndSetting[+(index)]?.applyTrainedLora ?? true} outfitId={dataEdit.imageAndSetting[index]._id} inputSetting={setEditInputSetting} indexActive={+(index)} listOriginalImage={dataEdit.imageAndSetting} setListOriginalImages={setUploadedImages} setTrainLoraShopHistory={setTrainLoraShopHistory} />}
            {dataEdit.imageAndSetting.length > 0 &&
                <>
                    <div>
                        <div className='flex pb-2 flex-row justify-between items-end w-full'>
                            <div className=' text-[#101828] font-semibold leading-20'>{t('label_prompt')} ({t('image_number')}{+(tabActive) + 1}):</div>
                            <div onClick={() => { genCaptionByImage(index) }} className='flex flex-row text-center gap-1 justify-center items-center border-primary border-solid border  hover:cursor-pointer rounded-lg p-1 text-primary'>
                                <div>
                                    {t('create_by_ai')}
                                </div>
                                <img src={AI} width={'20px'} alt="" />
                            </div>
                        </div>
                        <Spin spinning={isLoadingText}>
                            <TextArea onChange={onChangePrompt} value={item?.prompt} showCount maxLength={300} allowClear />
                        </Spin>
                    </div>
                    <div>
                        <Row className='flex justify-between'>
                            <Col span={16} className='text-[#667085] font-medium text-sm'>
                                {t('creating')}
                            </Col>
                            <Col span={8} className='text-[#667085] font-medium text-sm'>
                                {t('by_input')}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={20}>
                                <Slider
                                    className='ml-3'
                                    min={0.8}
                                    max={1.2}
                                    step={0.01}
                                    onChange={onChange}
                                    value={item.weight}
                                />
                            </Col>
                            <Col span={4}>
                                <InputNumber
                                    value={item.weight}
                                    min={0.8}
                                    max={1.2}
                                    step={0.01}
                                    style={{ margin: '0 16px' }}
                                    onChange={onChange}
                                />
                            </Col>
                        </Row>
                    </div>
                </>

            }
        </div>
    }));

    const onEdit = (targetKey: TargetKey, action: 'add' | 'remove') => {
        if (action === 'add') {
            onClickUploadImage();
        } else {
            onRemoveImage(dataEdit.imageAndSetting[+(targetKey)].modelImage, dataEdit.imageAndSetting[+(targetKey)]._id)
        }
    };
    return (
        <div className='overflow-hidden flex flex-col gap-4 mt-2 relative justify-center' >
            {/* <input id="input-upload-image-replace" type="file" accept="image/png,image/jpeg,image/jpg,image/webp" onChange={onReplaceImage} className="hidden" /> */}
            <div className='flex gap-2'>
                <div className='flex gap-2 flex-col w-full'>
                    <div className='title-input-id text-[#101828] font-semibold text-sm flex flex-row gap-1'>
                        <p> {t('input_id_outfit')}</p>
                        <p className='text-[red]'>*</p>
                    </div>
                    <Input className='w-full h-10 rounded-lg' onChange={onChangeOutfitId} value={valueId} prefix={<CopyOutlined onClick={() => onCopyId(dataEdit.outfitCode)} className='text-xl hover:cursor-pointer opacity-50 hover:opacity-100' />} />
                </div>
                <div className='w-full '>
                    <InputCategory inputSetting={dataEdit} setInputSetting={setEditInputSetting} isOpenCate={isOpenCate} setIsOpenCate={setIsOpenCate} category={categorySelected} />
                </div>
            </div>
            <div className='flex sm:flex-col w-full gap-2'>
                <div className='w-[70%] sm:w-full'>
                    <InputLinkProducts inputSetting={dataEdit} value={productLink} setInputSetting={setEditInputSetting} />
                </div>
                <div className='w-[30%] sm:w-full'>
                    <InputLimitGen inputSetting={dataEdit} value={numberLimit} setInputSetting={setEditInputSetting} isEdit={true} />
                </div>
            </div>

            {dataEdit.imageAndSetting.length > 0 && <div className='flex gap-2  justify-start mt-2 '>
            </div>}
            <input id="input-upload-image-item" type="file" accept="image/png,image/jpeg,image/jpg,image/webp, image/heif, image/heic" onChange={handleImageUpload} className="hidden" />
            <div>
                <Tabs
                    className='tab-ant-custom'
                    key={tabActive}
                    type="editable-card"
                    onChange={onChangeTab}
                    activeKey={tabActive}
                    items={items}
                    onEdit={onEdit}
                    more={{
                        visible: false,
                        overlayClassName: 'hidden'
                    }}
                    moreIcon={<></>}
                    hideAdd={dataEdit.imageAndSetting.length >= 5}
                    removeIcon={<DeleteOutlined className='hover:text-red-500 text-xl' />}
                />
            </div>
        </div >
    )
}

export default VTOEditOutfit