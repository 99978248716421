import { useEffect } from "react";
import { Spin } from "antd";
import "./App.css";
import AppWrapper from "./AppWrapper";
import Loading from "./Loading";
import { useAppSelector } from "./redux/hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { TourProvider } from '@reactour/tour'
import { enableBodyScroll } from "body-scroll-lock";
import { stepsEN, stepsVN } from "./StepTour/stepsVN";
import FashionService from "./services/fashion.service";
import { useDispatch } from "react-redux";
import { cost } from '../src/redux/app/appCost';
import { handleResponseError } from "./utils/responseError";

function App() {
  const loading = useAppSelector((store) => store.LoadingApp.isLoading);
  const { t, i18n } = useTranslation()
  const appBody = document.getElementById('box-left')
  const enableBody2 = (target: any) => appBody && enableBodyScroll(appBody);
  const lang = i18n.language
  const dispatch = useDispatch()
  const getConfig = async () => {
    try {
      const { data } = await FashionService.getInstance().getConfiguration();
      const chatbotEnableObject = data.find((item: any) => item.config_key === "modeli.chatbot.fb.enabled");
      const img2imgObject = data.find((item: any) => item.config_key === "app.modeli.credit.fee.img2img");
      const txt2imgObject = data.find((item: any) => item.config_key === "app.modeli.credit.fee.txt2img");
      const upscaleObject = data.find((item: any) => item.config_key === "app.modeli.credit.fee.upscale");
      const swapFaceObject = data.find((item: any) => item.config_key === "app.modeli.credit.fee.swapface");
      const inpaintObject = data.find((item: any) => item.config_key === "app.modeli.credit.fee.inpaint");
      const blackwords = data.find((item: any) => item.config_key === "modeli.nsfw_prompt");

      const dataPayload = {
        primaryGen: JSON.parse(img2imgObject.config_value),
        swapFace: JSON.parse(swapFaceObject.config_value),
        upscale: JSON.parse(upscaleObject.config_value),
        removeObject: JSON.parse(inpaintObject.config_value),
        borderSmooth: JSON.parse(inpaintObject.config_value),
        removeWatermark: JSON.parse(inpaintObject.config_value),
      }
      dispatch(cost.setMainCost(dataPayload))
      dispatch(cost.setListBlackWords(blackwords.config_value))
      const isShowIconMess = JSON.parse(chatbotEnableObject.config_value)
      const iconFbClass = document.getElementById('go-to-message');
      iconFbClass && (iconFbClass.style.display = isShowIconMess ? 'block' : 'none');
    } catch (error) {
      handleResponseError(error);
    }
  }
  useEffect(() => {
    getConfig()
  }, [])
  return (
    <TourProvider steps={lang === 'vi' ? stepsVN : stepsEN} showCloseButton={false} onClickHighlighted={() => { }} onClickMask={() => { }} className='box-tour' padding={10}
      beforeClose={enableBody2}>
      <div className="App">
        <Spin
          spinning={loading}
          size="large"
          indicator={<Loading />}
          style={{
            zIndex: 9999999,
          }}
        >
          <AppWrapper />
          {/* <AppFooter /> */}
        </Spin>
      </div>
    </TourProvider>
  );
}

export default App;
