import React, { useEffect, useState } from 'react';
import signUpGuide from '../../assets/images/guide/signup.png';
import loginGuide from '../../assets/images/guide/login.png';
import paymentGuide from '../../assets/images/guide/payment.png';
import vnpayGuide from '../../assets/images/guide/vnpay-method.png';
import checkoutGuide from '../../assets/images/guide/checkout.png';
import gen from '../../assets/images/guide/quy-doi-1.png';
import upscale from '../../assets/images/guide/quy-doi-2.png';
import rmwm from '../../assets/images/guide/quy-doi-3.png';
import upscale2 from '../../assets/images/guide/quy-doi-4.png';
import regen from '../../assets/images/guide/quy-doi-5.png';
import swap from '../../assets/images/guide/quy-doi-6.png';
import smoothBorder from '../../assets/images/guide/quy-doi-7.png';
import './styles.css'
import { BackTop, Button } from 'antd';
import Guide from '../Guide/Guide';
import LanguageSwitch from '../../components/LanguageSwitch/LanguageSwitch';
import { useNavigate } from 'react-router-dom';
type PaymentGuideProps = {
  setOpenPaymentModal: Function
}
function PaymentGuideVN({ setOpenPaymentModal }: PaymentGuideProps) {
  const [isPlayVideo, setIsPlayVideo] = useState(false)
  const navigate = useNavigate()

  const Registration = () => {
    return (
      <section className="registration pl-20 pr-20" id='sigup'>
        <h2 className='mb-2'>1. Đăng kí</h2>
        <div className='flex justify-between w-[90%] sm:flex-col md:flex-col lg:flex-col'>
          <div className='flex gap-2 flex-col'>
            <p className='mb-1 w-full'>
              <div className='text-left'>
                <strong>- Bước 1:</strong> Vào trang <a href="https://beta.modeli.ai/login">https://beta.modeli.ai/login</a>.
              </div>
              <div className='flex w-full gap-2 justify-around flex-wrap mt-2'>
                <div className='text-left ml-3'>
                  Nhập đầy đủ các trường yêu cầu:
                  <br />
                  - Email
                  <br />
                  - Mật khẩu
                  <br />
                  - Số điện thoại
                  <br />
                  - Tên thương hiệu
                  <br />
                  - Website URL
                  <br />
                  - Quy mô shop
                  <br />
                  - Mặt hàng
                  <br />
                  - Thời gian kinh doanh
                  <br />

                </div>
              </div>
            </p>
            <p className='mb-1 text-left'><strong>- Bước 2:</strong> Click Button [Đăng kí]</p>
          </div>
          <div>
            <img style={{
              width: '100%'
            }} src={signUpGuide} alt="Registration Screenshot" />
          </div>
        </div>
      </section>
    );
  }

  const Login = () => {
    return (
      <section className="login mt-6 pl-20 pr-20" id='login'>
        <div className='flex text-left justify-between w-[90%]'>
          <div>
            <h2 className='mb-2'>2. Đăng nhập</h2>
            <h3 className='mb-1'>a/ Đăng nhập với email và Mật khẩu.</h3>
            <p className='mb-1'><strong>- Bước 1:</strong> Vào trang <a href="https://beta.modeli.ai/login">https://beta.modeli.ai/login</a>. Thực hiện đăng nhập với Email và mật khẩu vừa đăng kí.</p>
            <p className='mb-1'><strong>- Bước 2:</strong> Click button [Đăng nhập] để vào app Modeli.</p>
            <h3 className='mb-1'>b/ Đăng nhập với google.</h3>
          </div>
          <div>
            <img src={loginGuide} style={{
              width: '100%'
            }} alt="Login Screenshot" />
          </div>
        </div>
      </section>
    );
  }

  const Payment = () => {
    return (
      <section className="payment mt-6 pl-20 pr-20 " id='payment'>

        <h2 className='mb-2'>3. Thanh toán</h2>
        <p className='mb-1'>Có thể thanh toán bằng cổng thanh toán Stripe hoặc VNpay.</p>
        <p className='mb-1'><strong>- Bước 1:</strong> Vào app <a href="https://beta.modeli.ai">https://beta.modeli.ai</a>.</p>
        <p className='mb-1'><strong>- Bước 2:</strong> Click vào button Credits ở góc bên phải. Hoặc Click vào button [Profile] ở góc phải. Xuất hiện popup hiển thị các gói thanh toán.</p>
        <img src={paymentGuide} alt="Payment Options" />
        <p className='mb-1'><strong>- Bước 3:</strong> Chọn gói: Basic hoặc Top up.</p>
        <p className='mb-1'><strong>- Bước 4:</strong> Chọn cổng thanh toán:</p>
        <ul className='text-left ml-8 '>
          <li> Chọn thanh toán qua cổng VNpay: Click vào button [VNpay] trong popup thanh toán</li>
          <li className='mb-1'> Chọn thanh toán qua cổng Stripe: Click vào button [Stripe] trong popup thanh toán</li>
        </ul>
        <p className='mb-1'><strong>- Bước 5:</strong> Kiểm tra đơn hàng:</p>
        <img src={checkoutGuide} alt="Checkout" />
        <p className='mb-1'><strong>- Bước 6:</strong> Thanh toán: Click vào button [Thanh toán]</p>
        <p className='mb-1'><strong>- Bươc 7:</strong> Chuyển hướng sang trang thanh toán: user thực hiện thanh toán, nhập đầy đủ thông tin để mua Credits</p>
        <img src={vnpayGuide} alt="Payment Process" />
      </section>
    );
  }

  const CreditsConversion = () => {
    return (
      <section className="credits-conversion mt-6 pl-20 pr-20 " id='conversion'>
        <h2 className='mb-2'>4. Cách chuyển đổi Credits</h2>
        <h3 className='mb-2'>4.1 Quy đổi khi thực hiện [Generate] hình ảnh:</h3>
        <ul className='text-left mb-1'>
          <li><p>Ứng với 1 hình ảnh được generate sẽ tiêu tốn <strong>3 Credits</strong>.</p></li>
          <li><p>Một lần Generate: có thể gen được từ 1 đến 4 hình ảnh. Do đó, Số Credits tối thiếu cho 1 lần gen là <strong>3 Credits</strong> và tối đa là <strong>12 Credits</strong>.</p></li>
        </ul>

        <img src={gen} alt="Generate" className='img-4 mb-1' />

        <h3 className='mb-2 mt-1'>4.2 Quy đổi Credits khi tiền xử lý hình ảnh ( để có đầu vào tốt hơn):</h3>
        <ul className='text-left mb-1'>
          <li className='flex flex-col w-full line-clamp-1'>
            <p className=' mb-2'>
              a/ Upscale: Đối với ảnh có kích thước nhỏ (&lt; 768 x 1024), chất lượng thấp, cần  <strong>3 Credits</strong> để upscale hình ảnh với kích thước và chất lượng tốt hơn.
            </p>
            <img src={upscale} alt="Preprocessing" className='img-4 mb-1' />
          </li>

          <li className='flex flex-col w-full'>
            <p className=' mb-2 mt-1'>
              b/ Xóa Watermark: đối với các ảnh có Watermark cần bỏ đi sẽ tiêu tốn <strong>3 Credits</strong> cho 1 lần xóa.
            </p>
            <img src={rmwm} alt="Preprocessing" className='img-4 mb-1' />
          </li>
        </ul>
        <h3 className='mb-2 mt-1'>4.3 Quy đổi Credits khi hậu xử lý hình ảnh:</h3>
        <div className="image-processing-item text-left w-full">
          <h3 className='mb-2'>a/ Tăng kích thước:</h3>
          <p className='mb-1'>Trường hợp người dùng muốn tăng kích thước hình ảnh output.</p>
          <p className='mb-1'><strong>Mỗi lần tăng kích thước sẽ tiêu tốn 3 Credits</strong></p>
          <div className='flex'>
            <img src={upscale2} alt="Resize" className='img-4 mb-1' />
          </div>
        </div>
        <div className="image-processing-item text-left mt-1">
          <h3 className='mb-2'> b/ Gen lại theo vùng:</h3>
          <p className='mb-1'>Với các kết quả hình ảnh có chi tiết thừa, có đối tượng trong Background không mong muốn, hoặc tay chân bị dị dạng chưa đẹp ta thực hiện chức năng gen lại theo vùng để có kết quả tốt hơn.</p>
          <p className='mb-1'><strong>Mỗi lần Gen lại sẽ tiêu tốn 3 Credits</strong></p>
          <div className='flex'>
            <img src={regen} alt="Reprocess" className='img-4 mb-1' />
          </div>
        </div>
        <div className="image-processing-item text-left">
          <h3 className='mb-2'>c/ Swapface:</h3>
          <p className='mb-1'>Với kết quả vừa gen ra, người dùng có thể đổi mặt người mẫu trong hình thành mặt của 1 mẫu khác.</p>
          <p className='mb-1'><strong>Mỗi lần Swapface sẽ tiêu tốn 5 Credits</strong></p>
          <div className='flex'>
            <img src={swap} alt="Swapface" className='img-4' />
          </div>
        </div>
        <div className="image-processing-item text-left mt-1">
          <h3 className='mb-2'>d/ Làm mịn viền:</h3>
          <p className='mb-1'>Trường hợp kết quả gen viền quần áo chưa được mịn, còn răng cưa, ta dùng chức năng làm mịn viền để được kết quả tốt hơn.</p>
          <p className='mb-1'><strong>Mỗi lần làm mịn viền sẽ tiêu tốn 3 Credits</strong></p>
          <div className='flex'>
            <img src={smoothBorder} alt="Smooth Edges" className='img-4' />
          </div>
        </div>
      </section>
    );
  }
  const onClickGoToSection = (sectionId: string) => {
    let element = document.getElementById(sectionId);
    element && element.scrollIntoView({ behavior: "smooth" });
    element = null
  }

  useEffect(() => {
    setOpenPaymentModal(false)
  }, [])

  return (
    <div className='container-guide-payment '>
      <div className='flex justify-between '>
        <div className='list-btn-link'>
          <Button type="primary" block onClick={() => { navigate('/') }} className='cursor-pointer hover:underline'>
            Quay về trang chủ
          </Button>
        </div>
        <div className='list-btn-link flex flex-row  gap-2 justify-center items-center mt-4 w-1/2  sm:flex-col md:flex-col'>
          <Button type="text" block onClick={() => { onClickGoToSection('sigup') }} className=' cursor-pointer hover:underline'>
            Đăng Kí
          </Button>
          |
          <Button type="text" block onClick={() => { onClickGoToSection('login') }} className=' cursor-pointer hover:underline'>
            Đăng Nhập
          </Button>
          |
          <Button type="text" block onClick={() => { onClickGoToSection('payment') }} className='cursor-pointer hover:underline'>
            Thanh Toán
          </Button>
          |
          <Button type="text" block onClick={() => { onClickGoToSection('conversion') }} className=' cursor-pointer hover:underline'>
            Quy Đổi
          </Button>
          |
          <Button type="text" block onClick={() => { onClickGoToSection('video-guide'); setIsPlayVideo(true) }} className='cursor-pointer hover:underline'>
            Video Hướng Dẫn
          </Button>
          |
          <LanguageSwitch styles={{ marginRight: 8 }} />
        </div>
      </div>
      <Registration />
      <Login />
      <Payment />
      <CreditsConversion />
      <BackTop />
      <section className="video-guidemt-6 pl-20 pr-20 " id='video-guide'>
        <h2 className='mb-2 text-left'>5. Video hướng dẫn</h2>
        <Guide isPlayVideo={isPlayVideo} />
      </section>
    </div>
  );
}

export default PaymentGuideVN;
