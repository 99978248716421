import { createSlice } from "@reduxjs/toolkit";
// PRIMARY_GEN = 3,
// SWAP_FACE = 2,
// UPSCALE = 2,
// REMOVE_OBJECT = 1,
// BORDER_SMOOTH = 1,
// REMOVE_WATERMARK = 2,
// TRAIN = 6
interface CostState {
  primaryGen: number;
  swapFace: number;
  upscale: number;
  removeObject: number;
  borderSmooth: number;
  removeWatermark: number;
  trainLora: number;
  blackWords: string
}

const initialState: CostState = {
  primaryGen: 3,
  swapFace: 2,
  upscale: 2,
  removeObject: 1,
  borderSmooth: 1,
  removeWatermark: 2,
  trainLora: 6,
  blackWords: ''
};

const costFeature = createSlice({
  name: "setCost",
  initialState,
  reducers: {
    setMainCost: (state, action) => {
      state.primaryGen = action.payload.primaryGen;
      state.swapFace = action.payload.swapFace;
      state.upscale = action.payload.upscale;
      state.removeObject = action.payload.removeObject;
      state.borderSmooth = action.payload.borderSmooth;
      state.removeWatermark = action.payload.removeWatermark;
    },
    setLoraCost: (state, action) => {
      state.trainLora = action.payload.trainLora;
    },
    setListBlackWords: (state, action) => {
      state.blackWords = action.payload;
    },
  },
});

export const cost = costFeature.actions;
export default costFeature.reducer;
