import type { MenuProps } from 'antd';
import { Button, Menu, Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { setTypeModel } from '../../redux/app/selectTypeModel';
import { ICustomItems } from '../../utils/interface';
import { useTranslation } from 'react-i18next';
import { useState } from 'react'
import PopupAddToWhiteList from '../PopupAddToWhiteList';
import { WhitelistFeatureEnum, WhitelistStatusEnum, WhitelistTypeEnum } from '../../utils/enum';
type MenuMainProps = {
  current: string;
  setCurrent: Function;
  customItems: ICustomItems[]
}

const permission = {
  feature: WhitelistFeatureEnum.TEMPLATE,
  type: WhitelistTypeEnum.DATA
}
function MenuMain(props: MenuMainProps) {
  const [isShowFormWhiteList, setIsShowFormWhiteList] = useState(false)
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const items = props.customItems?.map(item => {
    return {
      label: item.name,
      key: item.type,
    }
  })

  const onClick: MenuProps['onClick'] = (e) => {
    dispatch(setTypeModel.setTypeModel(e.key));
    dispatch(setTypeModel.setTypeName(items.find(item => item.key === e.key)?.label));
  };
  const isTouchDevice = () => {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  };

  const showFormWhitelist = () => {
    setIsShowFormWhiteList(true)
  }
  return <Menu onClick={onClick} selectedKeys={[props.current]} mode="horizontal" className="text-xl font-semibold text-gray my-3 flex justify-start !bg-transparent ">
    <PopupAddToWhiteList isShowFormWhiteList={isShowFormWhiteList} setIsShowFormWhiteList={setIsShowFormWhiteList} setPermission={permission} />
    {props.customItems.map(menu => (
      <Menu.Item key={menu.type} disabled={!menu?.active}>
        {!menu?.active ?
          <Tooltip trigger={isTouchDevice() ? 'click' : 'hover'} placement="bottom">
            <div style={{ width: '100%', cursor: 'pointer' }} onClick={e => showFormWhitelist()}>
              {menu.name}
            </div>
          </Tooltip>
          :
          <div style={{ width: '100%' }}>
            {menu.name}
          </div>}
      </Menu.Item>
    ))}
  </Menu>
}

export default MenuMain