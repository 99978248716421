export enum FashionToolType {
    SWAPFACE = 'SWAPFACE',
    UPSCALE = 'UPSCALE',
    REMOVE_OBJECT = "REMOVE_OBJECT",
    BORDER_SMOOTH = "SMOOTH_BORDER",
    REMOVE_REDUNDANT_DETAIL = "REMOVE_REDUNDANT_DETAIL",
    CORRECT_PART = "CORRECT_PART",
    TRY_OUTFIT = "TRY_OUTFIT",
}

export enum FashionClothesEnum {
    DRESS = 'dress',
    SHIRT = 'shirt',
}

export enum FeatureCostEnum {
    PRIMARY_GEN = 3,
    SWAP_FACE = 2,
    UPSCALE = 2,
    REMOVE_OBJECT = 1,
    BORDER_SMOOTH = 1,
    REMOVE_WATERMARK = 2,
    TRAIN = 6
}
export enum WhitelistTypeEnum {
    FEATURE = "FEATURE",
    DATA = "DATA",
}

export enum WhitelistFeatureEnum {
    TEMPLATE = "TEMPLATE",
    UPSCALE = "UPSCALE",
    POSE = "POSE",
    SWAPFACE = "SWAPFACE",
    GENERATE = "GENERATE",
    REMOVE_OBJECT = "REMOVE_OBJECT",
    REMOVE_REDUNDANT_DETAIL = "REMOVE_REDUNDANT_DETAIL",
    CORRECT_PART = "CORRECT_PART",
    BORDER_SMOOTH = "SMOOTH_BORDER",
}

export enum WhitelistStatusEnum {
    WAITING = "WAITING",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED"
}
export enum TypeEditor {
    PRE = 'prev',
    POST = 'post',
    UPLOAD = 'upload'
}
export enum DiscountPricePlan {
    QUARTER = '20',
    YEAR = '30',
}
export enum VTOCategoryOutfit {
    FEMALE = 'FEMALE',
    MALE = 'MALE',
    CHIL = 'CHILDREN'
}
export enum FeatureTypeEnum {
    MODEL_AI = 'model_ai',
    VIRTUAL_TRY_ON = 'virtual_try_on',
}