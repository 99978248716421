// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-search .ant-input {
    background-color: #667085 !important
}

.input-search .ant-input {
    color: white
}

.searchBox {
    background-color: #667085;
    position: relative;
    display: flex;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    width: 50px;
    height: 32px;
    transition: 300ms;
    border-radius: 4px;
}

.searchBox.active {
    transition: 200ms;
    width: 300px;
}

.searchBox.active .close {
    transition: 500ms;
    scale: 1;
}

.searchBox .search {
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
}

.searchBox .close {
    position: relative;
    min-width: 50px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    scale: 0;
}

.searchBox .searchInput {
    position: relative;
    height: 100%;
    border-radius: 12px;
    width: 100%;
}

.searchBox .searchInput .input {
    border: 0;
    outline: 0;
    width: 100%;
    height: 32px;
    font-size: 16px;
    background-color: #667085;
    color: white
}`, "",{"version":3,"sources":["webpack://./src/components/VTO-SearchInput/styles.css"],"names":[],"mappings":"AAAA;IACI;AAEJ;;AAHA;IAEI;AACJ;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,gBAAgB;IAChB,uCAAuC;IACvC,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,0BAAkB;IAAlB,uBAAkB;IAAlB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IAInB,QAAQ;AAHZ;;AAMA;IACI,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,SAAS;IACT,UAAU;IACV,WAAW;IACX,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB;AACJ","sourcesContent":[".input-search .ant-input {\n    background-color: #667085 !important;\n    color: white\n}\n\n.searchBox {\n    background-color: #667085;\n    position: relative;\n    display: flex;\n    overflow: hidden;\n    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);\n    width: 50px;\n    height: 32px;\n    transition: 300ms;\n    border-radius: 4px;\n}\n\n.searchBox.active {\n    transition: 200ms;\n    width: 300px;\n}\n\n.searchBox.active .close {\n    transition: 500ms;\n    scale: 1;\n}\n\n.searchBox .search {\n    position: relative;\n    width: fit-content;\n    height: 32px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    min-width: 50px;\n}\n\n.searchBox .close {\n    position: relative;\n    min-width: 50px;\n    height: 32px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.searchBox .close {\n    scale: 0;\n}\n\n.searchBox .searchInput {\n    position: relative;\n    height: 100%;\n    border-radius: 12px;\n    width: 100%;\n}\n\n.searchBox .searchInput .input {\n    border: 0;\n    outline: 0;\n    width: 100%;\n    height: 32px;\n    font-size: 16px;\n    background-color: #667085;\n    color: white\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
