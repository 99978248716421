// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.term-of-use {
    text-align: left;
    padding: 50px;
}

.term-of-use h1,
.term-of-use h2 {
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/TermOfUser/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;;IAEI,aAAa;AACjB","sourcesContent":[".term-of-use {\n    text-align: left;\n    padding: 50px;\n}\n\n.term-of-use h1,\n.term-of-use h2 {\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
