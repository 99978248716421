import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import enFlag from '../../assets/images/us.svg';
import vnFlag from '../../assets/images/vn.svg';
import './styles.css';
import { Switch } from 'antd';
import { DownOutlined } from '@ant-design/icons';
type Props = {
    styles?: any;
};

const LanguageSwitch = (props: Props) => {
    const [isShowDropdown, setIsShowDropdown] = React.useState(false);
    const { i18n } = useTranslation();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (event.target.closest('.language-container') === null) {
                setIsShowDropdown(false);
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div style={props.styles} onClick={() => setIsShowDropdown(!isShowDropdown)} className="d-flex align-items-center language-container ">
            <div className='flex justify-center items-center gap-1 py-2  '>
                {/* <img id="icon-language" className='object-cover rounded-full w-5 h-5' src={i18n.language === 'en' ? enFlag : vnFlag} alt="language-icon" width={'100%'} height={'100%'} /> */}
                <div className="current-language w-[30px] md:text-[12px] md:w-[20px]">{i18n.language === 'en' ? 'EN' : 'VI'}</div>
                <div className={isShowDropdown ? 'rotate-180 duration-75 arrow !border-t-black' : 'duration-75 arrow !border-t-black'}></div>
                {/* <DownOutlined className={isShowDropdown ? 'rotate-180 duration-75' : 'duration-75'} /> */}
            </div>
            {isShowDropdown && (
                <div className="dropdown-custom mt-2 !z-[10]  md:!-top-20 md:!left-1/2 md:-translate-x-1/2">
                    <div onClick={() => changeLanguage('en')} className="dropdown-item">
                        English
                    </div>
                    <div onClick={() => changeLanguage('vi')} className="dropdown-item">
                        Tiếng Việt
                    </div>
                </div>
            )}
        </div>
    );
};

export default LanguageSwitch;
