import React from 'react'
import { useTranslation } from 'react-i18next'
import bling from '../../assets/icons/bling2.svg';
import cancel from '../../assets/icons/cancel2.svg';
type ButtonVTOProps = {
    textBling: string
}
const ButtonVTO = ({ textBling }: ButtonVTOProps) => {
    const { t } = useTranslation()
    return (
        <div className='flex gap-8 justify-center items-center m-auto my-0 w-full w-fit my-2'>
            <div className='text-[#101828] text-[16px] font-semibold w-fit-content max-w-fit  rounded-lg my-2 flex justify-center items-center gap-2 sm:text-[14px]'>
                <img src={bling} alt="" />
                {textBling}
            </div>
            <div>
                <div className='text-[#101828] text-[16px] font-semibold w-fit-content max-w-fit  rounded-lg my-2 flex justify-center items-center gap-2 sm:text-[14px]'>
                    <img src={cancel} alt="" />
                    {t('cancel_any_time')}
                </div>
            </div>
        </div>
    )
}

export default ButtonVTO