import { createSlice } from "@reduxjs/toolkit";

interface VTOState {
  isMultipleSelection: boolean;
  isReload: boolean;
  isReloadCate: boolean;
}

const initialState: VTOState = {
  isMultipleSelection: false,
  isReload: false,
  isReloadCate: false,
};

const eventLoading = createSlice({
  name: "setStateVTO",
  initialState,
  reducers: {
    setIsMultipleSelection: (state, action) => {
      state.isMultipleSelection = action.payload;
    },
    setReload: (state, action) => {
      state.isReload = action.payload;
    },
    setReloadCategory: (state, action) => {
      state.isReloadCate = action.payload;
    },
  },
});

export const setStateVTO = eventLoading.actions;
export default eventLoading.reducer;
