import { Button, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PaymentService from '../../services/payment.service';
import AccountService from '../../services/account.service';
import { useDispatch } from 'react-redux';
import { userSaga } from '../../redux/app/authSaga';
import analytics from '../../firebase';
import { logEvent } from '@firebase/analytics';
import { ModeActive } from '../../redux/app/appModeActiveProfile';
import swal from 'sweetalert';

type Props = {
    setOpenPaymentModal: Function
};

const StripePaymentResult = (props: Props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [result, setResult] = useState<any>({});
    const [timeCounter, setTimeCounter] = useState(5);
    const urlParams = new URLSearchParams(window.location.search);
    const checkoutSessionId = urlParams.get('checkout_session_id');
    const dispatch = useDispatch();

    const checkPaymentStatus = async () => {
        if (checkoutSessionId) {
            const data = await PaymentService.getInstance().checkPaymentStatus(checkoutSessionId);
            if (data.isValid) {
                setResult({
                    status: 'success',
                    title: t('Payment_Successfully'),
                })
                logEvent(analytics, 'stripe_payment_success')
                try {
                    const dataUser = await AccountService.getInstance().getUserInfo();
                    dispatch(userSaga.setUser(dataUser));
                } catch (error) {
                    swal(t('something_wrong'), "", "error");
                }
                
            }
        } else {
            setResult({
                status: 'error',
                title: t('Payment_Failed'),
            })
            logEvent(analytics, 'stripe_payment_failed')
        }
    }

    useEffect(() => {
        props.setOpenPaymentModal(false)
    }, [])

    useEffect(() => {
        checkPaymentStatus();
    }, [checkoutSessionId]);
    setTimeout(function () {
        setTimeCounter(timeCounter - 1)
    }, 1000);
    if (timeCounter === 0) {
        dispatch(ModeActive.setModeActive('billing'))
        navigate('/profile')
    }
    return (
        <div className="payment-result">
            <Result
                status={result.status}
                title={result.title}
                extra={[
                    <div className="change-style-button flex justify-center gap-1" key="return">
                        {t('Return_to_profile')}<a onClick={e => {
                            dispatch(ModeActive.setModeActive('billing'))
                            navigate('/profile')

                        }}> Profile </a> {t('in')} {timeCounter} {t('seconds')}
                    </div>
                ]}
            />
        </div>
    );
};
export default StripePaymentResult;
