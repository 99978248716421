import setError from "./app/errorSelect";
import { AnyAction, combineReducers } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import selectTypeModel from "./app/selectTypeModel";
import setUser from "./app/authSaga";
import setIsAuto from "./app/appAutoSegment";
import setLess512 from "./app/uploadImgeSaga";
import setLoadingApp from "./app/loadingApp";
import setIsAutoPose from "./app/appAutoPose";
import editorImage from "./app/appImagePreprocessing";
import realtimeSegment from "./app/realtimeSegment";
import toolEditorImage from "./app/toolEditor";
import appIsGenerating from "./app/appIsGenerating";
import isDisplayHeader from "./app/appScroll";
import modalSub from "./app/appModalSub";
import modeActive from "./app/appModeActiveProfile";
import VTOState from "./app/appVTO";
import costFeature from "./app/appCost";
const combinedReducer = combineReducers({
  auth: authSlice,
  select: selectTypeModel,
  user: setUser,
  errorInput: setError,
  segmentAuto: setIsAuto,
  sizeImage: setLess512,
  LoadingApp: setLoadingApp,
  PoseAuto: setIsAutoPose,
  EditorImage: editorImage,
  realtimeSegment: realtimeSegment,
  toolEditor: toolEditorImage,
  isGeneratingImage: appIsGenerating,
  isDisplayHeader: isDisplayHeader,
  VTOState: VTOState,
  modalSub: modalSub,
  modeActive: modeActive,
  costFeature: costFeature,
});

export const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === "app/clearAllReducers") {
    return combinedReducer(undefined, { type: undefined });
  }
  return combinedReducer(state, action);
};
