import { createSlice } from '@reduxjs/toolkit';

interface isAutoPoseState {
  isAutoPose: boolean;
}

const initialState: isAutoPoseState = {
    isAutoPose: true,
};


const eventSetIsAutoPose = createSlice({
  name: 'setIsAutoPose',
  initialState,
  reducers: {
    setIsAutoPose: (state, action) => {
      state.isAutoPose = action.payload
    },
  },
});


export const setIsAutoPose = eventSetIsAutoPose.actions;
export default eventSetIsAutoPose.reducer;