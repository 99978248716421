import { useState, useEffect } from 'react';
import classNames from 'classnames';
import '../../pages/Home/styles.css';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal, Skeleton } from 'antd';
import NotifyController from '../../utils/toast';

import { PlusCircleOutlined } from '@ant-design/icons';
import { checkForTrue, getObjectWithTrueValue, MAX_LENGTH_INPUT_PROMPT } from '../../utils/function';
import ModalSelectBgOther from './ModalSelectBgOther';
import { useAppSelector } from '../../redux/hooks/useAppSelector';

type SelectCustomItemProps = {
  title: string;
  onChangeSelectCustomItem: Function;
  listCustomItems: Array<{
    code: string;
    name: string;
    prompt: string;
    imageUrl: string;
    list_backgrounds: []
  }>;
  selectedItems: string[];
  isMultipleSelection: boolean;
  hasImage?: boolean;
  selectedOtherCustomize: boolean;
  code: string;
  promptExample?: string
  initImage?: string
  setBackgroundImage: Function;
  fashionParams: any;
  setFashionParams: Function
};

const SelectCustomItem = (props: SelectCustomItemProps) => {
  const bgImageCopied = useAppSelector(store => store.select.backgroundImage)
  const isCopy = useAppSelector(store => store.select.isClickCopySetting)
  const codeCopied = useAppSelector(store => store.select.selectedItems)
  const [isOpenModalCustomize, setIsOpenCustomize] = useState(false)
  const [isSelectCustomize, setIsSelectCustomize] = useState(false)
  const [isUseControlNet, setIsUseControlNet] = useState(false)
  const [isOpenModalCustomizeBg, setIsOpenModalCustomizeBg] = useState(false)
  const [valueInputCustomize, setValueInputCustomize] = useState('')
  const [wordCount, setWordCount] = useState(0)
  const [imgSelected, setImgSelected] = useState('')
  const [typeBgSelected, setTypeBgSelected] = useState('')
  const [isSelectedImage, setIsSelectedImage] = useState(false)
  const [dataBgSelect, setDataBgSelect] = useState({
    defaultPrompt: '',
    listData: [],
    name: '',
    code: ''
  })
  const [valueOther, setValueOther] = useState('')
  const { t } = useTranslation()

  const handleSelectionChange = (item: string, list_backgrounds?: []) => {
    if (props.code === 'place') {
      const hasDefaultTrue = list_backgrounds && checkForTrue(list_backgrounds, 'is_default');
      const imageBgTrue = list_backgrounds && getObjectWithTrueValue(list_backgrounds)
      if (hasDefaultTrue && imageBgTrue) {
        props.onChangeSelectCustomItem(item, props.code, false);
        props.setBackgroundImage(imageBgTrue)
        setTypeBgSelected(item)
      } else {
        setTypeBgSelected(item)
        props.onChangeSelectCustomItem(item, props.code, false);
        props.setBackgroundImage('')
      }
    }
    if (props.isMultipleSelection) {
      const newSelectedItems = props.selectedItems.includes(item)
        ? props.selectedItems.filter(selected => selected !== item)
        : [...props.selectedItems, item];
      props.onChangeSelectCustomItem(newSelectedItems, props.code, props.selectedItems.indexOf("") === 1 && true);
    } else {
      setIsSelectCustomize(false);
      setValueInputCustomize('');
      const newSelectedItem = props.selectedItems[0] === item ? '' : item;
      props.onChangeSelectCustomItem(newSelectedItem, props.code);
    }
  };

  const handleOtherOption = (key: string, value: string) => {
    if (props.isMultipleSelection) {
      const indexOfEmptyString = props.selectedItems.indexOf("");
      // Make sure the customize selection is the only one value before the ""
      if (indexOfEmptyString === 0) {
        const newSelectedItems = props.selectedItems.includes(key)
          ? props.selectedItems.filter(selected => selected !== key)
          : [value, ...props.selectedItems];
        props.onChangeSelectCustomItem(newSelectedItems, props.code, true);
      } else if (indexOfEmptyString === 1) {
        const newSelectedItems = [...props.selectedItems];
        newSelectedItems[0] = value;
        props.onChangeSelectCustomItem(newSelectedItems, props.code, true);
      } else if (indexOfEmptyString === -1) {
        const newSelectedItems = [value, "", ...props.selectedItems];
        props.onChangeSelectCustomItem(newSelectedItems, props.code, true);
      }
    } else {
      const newSelectedItem = props.selectedItems[0] === value ? '' : value;
      props.onChangeSelectCustomItem(newSelectedItem, props.code, true);
    }
  };

  const onClickBtnCustomize = () => {
    setIsOpenCustomize(true)
  }

  const onOkSelectOtherBg = (img: string, code: string, other: boolean = false, prompt: string) => {
    setIsOpenModalCustomizeBg(false)
    props.onChangeSelectCustomItem(other ? prompt : code, props.code, other);
    other && setValueOther(prompt)
    props.setBackgroundImage(img)
    if (img) {
      setImgSelected(img)
      setIsSelectedImage(true)
    }
  }

  const onOke = (isButtonOk: boolean) => {
    if (!valueInputCustomize && isButtonOk) {
      NotifyController.warning(t('Please_input_your_customization'))
      return;
    }
    if (isButtonOk) {
      setValueOther(valueInputCustomize)
      handleOtherOption('other', valueInputCustomize)
      setIsOpenCustomize(false)
      setIsSelectCustomize(true)
    } else {
      let newSelectedItems = [...props.selectedItems];
      newSelectedItems = newSelectedItems.filter(item => item !== valueInputCustomize);
      props.onChangeSelectCustomItem(newSelectedItems, props.code, false);
      setIsOpenCustomize(false);
      setIsSelectCustomize(false);
      setValueInputCustomize('')
    }
  }

  const handelClickCustomizeBtn = (prompt: string, code: string, name: string, listBG: []) => {
    setIsOpenModalCustomizeBg(true)
    setDataBgSelect({
      defaultPrompt: prompt,
      listData: listBG,
      name,
      code
    })

  }
  useEffect(() => {
    if (props.selectedOtherCustomize) {
      setIsSelectCustomize(props.selectedOtherCustomize)
      setValueInputCustomize(props.selectedItems[0])
      setValueOther(props.selectedItems[0])
    } else {
      setValueInputCustomize(props.selectedItems[0])
      setIsSelectCustomize(false)
    }
  }, [props.selectedOtherCustomize])

  useEffect(() => {
    //when change type bg 
    setIsUseControlNet(false)
  }, [typeBgSelected])

  useEffect(() => {
    dataBgSelect.defaultPrompt === codeCopied[0] && setIsSelectedImage(true)
  }, [isOpenModalCustomizeBg, dataBgSelect.defaultPrompt])

  useEffect(() => {
    if (!bgImageCopied) // check if copy has prompt and empty bg then set is Using controlnet
      setIsUseControlNet(true)
    else {
      setIsUseControlNet(false)
    }
  }, [bgImageCopied, isCopy])

  useEffect(() => {
    setIsSelectedImage(false)
  }, [dataBgSelect.defaultPrompt])

  return (
    <div className='flex flex-col font-normal  pb-2 text-sm text-[#101828] font-medium' id={props.title}>
      <Modal
        open={isOpenModalCustomize}
        title={t('customize') + ' ' + props.title}
        onCancel={() => onOke(false)}
        onOk={() => onOke(true)}
        cancelText={t('cancel')}
      >
        <div>{t('input_des')}</div>
        <Input
          value={valueInputCustomize}
          defaultValue={valueOther}
          className='mb-2'
          maxLength={MAX_LENGTH_INPUT_PROMPT}
          onChange={e => {
            setValueInputCustomize(e.target.value);
            setWordCount(e.target.value.length)
          }}
          placeholder={t('input_customize')}></Input>
        <div className='flex justify-between'>
          <div> {t('example')}:  {props?.promptExample}</div>
          <div> <span className='text-[10px] text-[red]'>{wordCount}/{MAX_LENGTH_INPUT_PROMPT}</span></div>
        </div>
      </Modal>
      {(props.listCustomItems) ?
        <div className='list-body grid grid-cols-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4'>
          {props.listCustomItems?.map((item, index) => (
            <div
              key={index}
              className={classNames('box-image relative cursor-pointer item-option-custom item-selection', {
                selected_item: props.selectedItems.includes(item.code),
              })}
            >
              <input
                type={'checkbox'}
                name='select-body'
                id={item.name}
                className='hidden'
                checked={props.selectedItems.includes(item.code)}
                onChange={() => handleSelectionChange(item.code, item.list_backgrounds)}
              />
              <label htmlFor={item.name} >
                {!props.hasImage ?
                  <div className='cursor-pointer flex justify-center text-center items-center align-center h-[50px] ' style={{
                    margin: '0 auto'
                  }}>
                    {item.name}
                  </div>
                  : <div className='select-bg w-full h-[150px] bg-img' style={{
                    backgroundImage: `url(${item.imageUrl})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                  }}>
                    {props.title === 'Background' &&
                      <Button
                        type='primary' className="custom-button-bg"
                        onClick={() => handelClickCustomizeBtn(item.prompt, item.code, item.name, item.list_backgrounds)}
                      // onClick={(e) => onChangeModelSample(val.image_url, index)}
                      >
                        {t('customize')}
                      </Button>}
                    <div className='name-body bg-bottom absolute bottom-0 text-center text-white'  >
                      <span className='text-white font-semibold'>{item.name}</span>
                    </div>
                  </div>
                }
              </label>
            </div>
          ))}
          {(props.code !== 'place' || isSelectCustomize) && <div
            className={classNames('box-image customize_button relative cursor-pointer item-option-custom item-selection flex justify-center text-center items-center align-center'
              , {
                selected_item_customize: isSelectCustomize
              })}
            onClick={onClickBtnCustomize}
          >
            <input
              type={'checkbox'}
              name='select-body'
              className='hidden'
            />
            <label >
              <div className='cursor-pointer flex flex-col justify-center items-center' style={{
                margin: '0 auto'
              }}>
                {<div className={`flex gap-1 justify-center items-center ${props.hasImage ? 'flex-col h-[150px]' : 'flex-row h-[50px]'}`} >
                  <div>
                    <PlusCircleOutlined style={{
                      fontSize: '30px'
                    }} />
                  </div>
                  <div className='flex flex-col'>
                    <div className={props.hasImage ? 'font-semibold text-xl' : 'font-semibold text-sm'}>{t('customize')}</div>
                    {valueOther && <div className='text-[10px] w-[80px]  overflow-hidden text-ellipsis whitespace-nowrap'>{valueOther}</div>}
                  </div>
                </div>
                }
                <div>
                </div>
              </div>
            </label>
          </div>}
        </div>
        :
        <div className='list-body grid grid-cols-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4'>
          {props.hasImage ?
            <>
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
              <Skeleton.Image active={true} />
            </>
            :
            <>
              <Skeleton.Input active={true} />
              <Skeleton.Input active={true} />
              <Skeleton.Input active={true} />
              <Skeleton.Input active={true} />
            </>
          }
        </div>}
      {isOpenModalCustomizeBg && <ModalSelectBgOther setFashionParams={props.setFashionParams} fashionParams={props.fashionParams} codeCopied={codeCopied[0]?.data || ''} isUseControlNet={isUseControlNet} initImage={props.initImage} bgImageCopied={bgImageCopied} imgSelected={imgSelected} isSelectedImage={isSelectedImage} setIsSelectedImage={setIsSelectedImage} setImgSelected={setImgSelected} code={dataBgSelect.code} onOkSelectOtherBg={onOkSelectOtherBg} listData={dataBgSelect.listData} defaultPrompt={dataBgSelect.defaultPrompt} setIsOpen={setIsOpenModalCustomizeBg} isOpen={isOpenModalCustomizeBg} />}

    </div>
  );
};

export default SelectCustomItem;