import { InfoCircleOutlined } from '@ant-design/icons';
import { InputNumber, Modal, Tag, Tooltip } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import checkIcon from '../../assets/icons/check-icon.png';
import { ModalSub } from '../../redux/app/appModalSub';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import PaymentService from '../../services/payment.service';
import { FeatureTypeEnum } from '../../utils/enum';
import ButtonVTO from './ButtonVTO';
import ListButtonPayment from './ListButtonPayment';
import './styles.css';
import Toggle from './Toggle';
import ToggleType from './ToggleType';
enum EPaymentType {
    STRIPE = 'STRIPE',
    VNPAY = 'VNPAY',
}

type ModalRequiredSubProps = {
    visible: boolean,
    setModalSub: (e: boolean) => void
}
const dataRow1 = [
    "Credits",
    "Generation Speed",
    "Number of Concurrent Job",
    "Support"
]
function NewModalRequiredSub() {
    const visible = useAppSelector(store => store.modalSub.openModalSub)
    const subscription = useAppSelector(store => store.user.subscription)
    const featureType = useAppSelector(store => store.modalSub.typeFeature)
    const [products, setProducts] = useState<any>([]);
    const [isStripeEnabled, setIsStripeEnabled] = useState(true);
    const [isVNPayEnabled, setIsVNPayEnabled] = useState(true);
    const [dataFirstRow, setDataFirstRow] = useState({
        modelAIAttributes: dataRow1,
        vtoAttributes: dataRow1
    })
    const [radioValue, setRadioValue] = useState('monthly');
    const [creditsInput, setCreditsInput] = useState(100);
    const [indexType, setIndexType] = useState<number>(0);
    const [listDiscountPercent, setListDiscountPercent] = useState<number[]>([0]);
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const isVND = i18n.language === 'vi'; //only VND

    const getListProduct = async () => {
        const currentLocale = i18n.language;
        const data = await PaymentService.getInstance().getListPackage(featureType, currentLocale);
        setProducts(data.products);
        setDataFirstRow({
            modelAIAttributes: data.modelAIAttributes,
            vtoAttributes: data.vtoAttributes
        })
        const percents: any = [];
        //get percent discount of product quarterly and yearly
        data.products.forEach((product: any) => {
            if (product.price && product.price.length > 0) {
                const vndPrice = product.price[0];
                if (vndPrice.list_items && vndPrice.list_items.length > 0) {
                    vndPrice.list_items.forEach((item: any) => {
                        percents.push(item.discountPercent);

                    });
                }
            }
        });

        const uniquePercents: any = [...new Set(percents)];
        setListDiscountPercent(uniquePercents);
        setIsStripeEnabled(data.isStripeEnabled);
        setIsVNPayEnabled(data.isVnpayEnabled);
    }

    const handleClickPaymentButton = useCallback((type: string, id: string, radioValue: string, isStripeEnabled: boolean, isVNPayEnabled: boolean, products: any, credits: number, isTopUp: boolean) => {
        if (!isStripeEnabled && type === EPaymentType.STRIPE) {
            swal(`Stripe is disabled! Please contact support for more information`, '', 'warning');
            return
        }
        if (!isVNPayEnabled && type === EPaymentType.VNPAY) {
            swal(`VNpay is disabled! Please contact support for more information`, '', 'warning');
            return
        }
        const dataPayment = {
            type,
            id,
            radioValue,
            isStripeEnabled,
            isVNPayEnabled,
            products,
            credits,
            isTopUp
        }
        navigate('/checkout', { state: { dataPayment } })
    }, [isStripeEnabled, isVNPayEnabled, i18n.language])

    const handleClickContactUS = () => {
        window.open("https://www.facebook.com/modeliaivn", "_blank");
    }

    const handleClickTryNow = () => {
        dispatch(ModalSub.setTypeFeatureActive(FeatureTypeEnum.MODEL_AI))
        dispatch(ModalSub.setIsOpenModalSub(false))
    }

    const FormatNumber = (number: number) => {
        return (Math.round(number / 100) * 100);
    }

    useEffect(() => {
        visible && getListProduct()
    }, [visible, featureType]);

    useEffect(() => {
        switch (radioValue) {
            case 'monthly':
                setIndexType(0)
                break;
            case 'quarterly':
                setIndexType(1)
                break;
            case 'yearly':
                setIndexType(2)
                break;

            default:
                break;
        }
    }, [radioValue])

    return (
        <Modal open={visible} width={'fit-content'} centered className='p-4 min-h-full' onCancel={handleClickTryNow}
            footer={
                <div className='m-auto my-0 w-fit text-[#0047FF] text-base hover:underline hover:cursor-pointer font-semibold italic'
                    onClick={() => {
                        window.open("https://docs.google.com/document/d/1YHrGRz6lhAHQ08KBrk5uEi_-iTx8mrvMRXB46HrBeRo/edit?usp=sharing", "_blank");
                    }}
                >
                    {t('payment_guide2')}
                </div>
            }

            zIndex={9999}>
            <div className='flex justify-center items-center min-w-full sm:mt-4'>
                <ToggleType selectedOption={featureType} />
            </div>
            <div className='min-w-[450px] sm:min-w-full flex justify-between items-center mt-4  flex-wrap max-w-full md:justify-start md:items-center sm:justify-start sm:items-center'>
                <div className=' text-center'>
                    {/* <p className='font-semibold text-[16px] text-[#0047FF] text-left'>{t('Upgrade')}</p> */}
                    <p className='text-[36px] text-[#101828] font-semibold text-left'>{featureType === FeatureTypeEnum.MODEL_AI ? 'AI Model' : 'Virtual Try On'}</p>
                    {featureType === FeatureTypeEnum.VIRTUAL_TRY_ON ?
                        <p className='text-[16px] text-[#667085] font-normal text-left max-w-[450px] min-w-[355px] sm:min-w-full'>{t('des_vto')}</p>
                        : <p className='text-[16px] text-[#667085] font-normal text-left min-w-[355px] sm:min-w-full pb-6'>{t('Pricing plans that scale')}</p>}
                </div>
                {products && products?.[0]?.isSub && <div className=' sm:mt-2 md:mt-2 lg:mt-2 min-w-auto sm:min-w-full md:min-w-full lg:min-w-full'>
                    <Toggle selectedOption={radioValue} setSelectedOption={setRadioValue} listDiscountPercent={listDiscountPercent} />
                </div>}
            </div>
            {featureType === FeatureTypeEnum.VIRTUAL_TRY_ON ? <ButtonVTO textBling={t('one_image_per_gen_vto')} /> : <ButtonVTO textBling={t('one_image_per_gen')} />}

            <div className="flex gap-2 overflow-x-auto sm:flex-col pb-1" style={{
                // boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
                // borderRadius: '20px',
                overflowX: products.length > 1 ? "auto" : 'hidden',
                justifyContent: products.length > 1 ? "start" : 'center'
            }}>
                {products.length > 1 && <table className="tg">
                    <thead>
                        <tr>
                            <th className="tg-0lax rounded-tl-2xl"></th>
                            {products.length > 0 && products?.map((item: any, index: number) => {
                                return (item.isSub && <th key={`prod_${index}`} className="tg-0lax header-table min-w-[235px] max-w-fit">
                                    <div className='flex flex-col gap-1 justify-center items-center'>
                                        <div className='name-product text-[16px] font-semibold text-primary uppercase mt-2'>{item.title ?? item.name}</div>
                                        {item.isSub && ((item.price[isVND ? 0 : 1]?.list_items[indexType].discountAmount) && (item.price[isVND ? 0 : 1]?.list_items[indexType].number_of_months)) ? <div className='price-product flex flex-row justify-center items-baseline'>
                                            <div className='font-bold text-[24px] text-[#101828] md:text-[20px]'>

                                                {isVND && (((item.price[isVND ? 0 : 1]?.list_items[indexType].discountAmount) / (item.price[isVND ? 0 : 1]?.list_items[indexType].number_of_months)))?.toLocaleString()}{isVND && (` VND`)}
                                                {!isVND && `$` + (((item.price[isVND ? 0 : 1]?.list_items[indexType].discountAmount) / (item.price[isVND ? 0 : 1]?.list_items[indexType].number_of_months)))?.toLocaleString()}
                                            </div>
                                            <div className='text-[16px] font-medium text-[#667085]'> /{t('monthly')}</div>
                                        </div>
                                            : <div className='font-bold text-[24px] text-[#101828] md:text-[20px]'>{t('contact')}</div>
                                        }
                                        <div className='brand-des text-[14px] font-medium text-[#667085] py-2'> {item.brandText}</div>
                                    </div>
                                </th>)
                            })}
                        </tr></thead>
                    <tbody>
                        {(featureType === FeatureTypeEnum.MODEL_AI ? dataFirstRow.modelAIAttributes : dataFirstRow.vtoAttributes).map((_data, i) => {
                            return (
                                <tr key={`attr_${i}`}>
                                    {<td className='ml-2 min-w-[130px] !px-4'>{_data}</td>}
                                    {products.map((product: any) => {
                                        return (
                                            product.isSub && <td key={product.id} className='text-[16px]' style={{ padding: '8px', textAlign: 'center', borderBottom: '1px solid #ddd' }} >
                                                <div className='flex flex-col justify-center items-center'>
                                                    <div className='flex gap-1 max-w-[220px]'>
                                                        {product.description[i].description}
                                                        {product.description[i].is_shop_tooltip && <Tooltip title={product.description[i].tooltip} overlayStyle={{
                                                            zIndex: 99999999999999
                                                        }} ><InfoCircleOutlined className='ml-1' /> </Tooltip>}
                                                    </div>
                                                    <div className='small-text text-[#667085] text-sm'>
                                                        {product.description[i].is_shop_tooltip && product.description[i].small_text}
                                                    </div>
                                                </div>
                                            </td>
                                        )
                                    })
                                    }
                                </tr>
                            )
                        })}




                        <tr>
                            {<td className='ml-2 min-w-[130px] !px-4'>{t('payment_via')}</td>}
                            {products.map((product: any, index: number) => {
                                return (
                                    product.isSub &&
                                    <td className='m-auto my-0' key={`prodSub_${index}`}><ListButtonPayment
                                        index={index}
                                        radioValue={radioValue}
                                        onclickPayment={handleClickPaymentButton}
                                        products={products}
                                        isShowContactUs={!product.isContact && !product.isFree}
                                        isStripeEnabled={isStripeEnabled}
                                        isVNPayEnabled={isVNPayEnabled}
                                        credits={creditsInput}
                                        isTopUp={false}
                                    /></td>
                                )
                            })
                            }
                        </tr>

                    </tbody>
                </table>}
                {products.map((product: any, index: number) => {
                    return (
                        !product.isSub && <div key={`prod_${index}`} className={`border-[#B5C5FD] rounded-[20px] border-solid border-[1px] flex flex-col w-full min-w-[270px]  sm:hidden ${products.length < 2 ? 'max-w-[350px]' : 'max-w-[300px]'} `} >
                            <div className='title-topup text-primary font-semibold text-[16px] mt-2 uppercase text-center'>
                                {!product.isSub && product.name}
                            </div>
                            <div className='text-price-top-up flex flex-wrap items-baseline justify-center mt-2'>
                                {isVND && <div className='price-credits text-[28px] font-semibold  text-black ml-4 flex flex-nowrap gap-1'>
                                    <div>
                                        {(!product.isSub && product.price[0]?.discountAmountPerCredit * creditsInput).toLocaleString()}
                                    </div>
                                    <div>
                                        {'VND'}
                                    </div>
                                </div>}
                                {!isVND && <div className='price-credits text-[32px] font-semibold  text-black ml-4 sm:text-[26px]'>
                                    ${(!product.isSub && product.price[1]?.discountAmountPerCredit * creditsInput).toLocaleString()}
                                </div>}
                                <div className='text-credits text-gray text-[10px] font-medium leading-2'>
                                    / {creditsInput} Credits
                                </div>
                            </div>
                            {isVND && <div className='text-description text-[14px] font-medium text-gray px-4 py-2 mx-auto'>
                                {!product.isSub && product.price[isVND ? 0 : 1]?.discountAmountPerCredit} VND/1 Credit
                            </div>}
                            {!isVND && <div className='text-description text-[14px] font-medium text-gray px-4 py-2 mx-auto'>
                                $ {!product.isSub && product.price[isVND ? 0 : 1]?.discountAmountPerCredit}/1 Credit
                            </div>}
                            {/* <div className='text-description text-[14px] font-medium text-gray px-4 py-2'>{t('Additional Credit')}</div> */}
                            <hr className='w-full' />
                            <div className='list-benefit p-4'>
                                {!product.isSub && product.description.map((des: any, idx: number) => {
                                    return (
                                        <div key={`prod_des_${idx}`} className='item-benefit flex flex-row gap-1 items-center'>
                                            <img src={checkIcon} alt="tick" width={'30px'} height={'30px'} />
                                            <div className='text-benefit max-w-[225px]'>{des.description}</div>
                                        </div>
                                    )
                                })

                                }

                            </div>
                            <hr style={{
                                borderTop: '1px dashed #C9CED6',
                                width: '100%'
                            }} />
                            <div className='text-left ml-4 mt-4'>{t('add_credits')}:</div>
                            <label className='ml-5 text-[10px] pb-2'>{t('min')}: 100 credits</label>
                            <InputNumber placeholder={t('enter_credits')} className='w-[90%] mt-2' value={creditsInput}
                                onChange={(e: any) => {
                                    setCreditsInput(e)
                                }}
                                min={100}
                                max={1000000}
                                maxLength={7}
                                style={{
                                    margin: '0 auto'
                                }} />
                            <div className='list-tag flex gap-2 justify-center items-center mt-1'>
                                <Tag color="processing" className='cursor-pointer' onClick={() => { setCreditsInput(100) }}>100</Tag>
                                <Tag color="processing" className='cursor-pointer' onClick={() => { setCreditsInput(500) }}>500</Tag>
                                <Tag color="processing" className='cursor-pointer' onClick={() => { setCreditsInput(1000) }}>1000</Tag>
                            </div>
                            <div className="w-full mt-1 pb-1" style={{
                                pointerEvents: creditsInput >= 100 && Object.keys(subscription).length !== 0 ? 'auto' : 'none',
                                filter: creditsInput >= 100 && Object.keys(subscription).length !== 0 ? 'grayscale(0)' : 'grayscale(1)'
                            }}>
                                <ListButtonPayment
                                    index={products.length - 1}
                                    radioValue={radioValue}
                                    onclickPayment={creditsInput >= 100 && Object.keys(subscription).length !== 0 ? handleClickPaymentButton : () => { }}
                                    products={products}
                                    isShowContactUs={true}
                                    isStripeEnabled={isStripeEnabled}
                                    isVNPayEnabled={isVNPayEnabled}
                                    credits={creditsInput}
                                    isTopUp={true}
                                />
                            </div>
                        </div>
                    )
                })
                }
            </div>
            {/* !product.isSub && <div className={`border-[#B5C5FD] rounded-[20px] border-solid border-[1px] flex flex-col w-full sm:hidden ${products.length >= 2 ? 'max-w-[300px]':'w-full'} `}></div> */}
            {products.map((product: any, index: number) => {
                return (
                    !product.isSub && <div key={`prod_${index}`} className='border-[#B5C5FD] rounded-[20px] border-solid border-[1px] min-w-full hidden flex-col max-w-[300px]  sm:!flex sm:mt-2 ' >
                        <div className='title-topup text-primary font-semibold text-[16px] ml-4 mt-2 uppercase'>
                            {!product.isSub && product.name}
                        </div>
                        <div className='text-price-top-up flex flex-wrap items-baseline mt-2 justify-center'>
                            {isVND && <div className='price-credits text-[32px] font-semibold  text-black ml-4 flex flex-nowrap gap-1 sm:text-[26px]'>
                                <div>
                                    {(!product.isSub && product.price[0]?.discountAmountPerCredit * creditsInput).toLocaleString()}
                                </div>
                                <div>
                                    {'VND'}
                                </div>
                            </div>}
                            {!isVND && <div className='price-credits text-[32px] font-semibold  text-black ml-4'>
                                ${(!product.isSub && product.price[1]?.discountAmountPerCredit * creditsInput).toLocaleString()}
                            </div>}
                            <div className='text-credits text-gray text-[10px] font-medium leading-2'>
                                / {creditsInput} Credits
                            </div>
                        </div>
                        {isVND && <div className='text-description text-[14px] font-medium text-gray px-4 py-2 mx-auto'>
                            {!product.isSub && product.price[isVND ? 0 : 1]?.discountAmountPerCredit} VND/1 Credit
                        </div>}
                        {!isVND && <div className='text-description text-[14px] font-medium text-gray px-4 py-2 mx-auto'>
                            $ {!product.isSub && product.price[isVND ? 0 : 1]?.discountAmountPerCredit}/1 Credit
                        </div>}
                        {/* <div className='text-description text-[14px] font-medium text-gray px-4 py-2'>{t('Additional Credit')}</div> */}
                        <hr className='w-full' />
                        <div className='list-benefit p-4'>
                            {!product.isSub && product.description.map((des: any, idx: number) => {
                                return (
                                    <div key={`prod_des_${idx}`} className='item-benefit flex flex-row gap-1 items-center'>
                                        <img src={checkIcon} alt="tick" width={'30px'} height={'30px'} />
                                        <div className='text-benefit'>{des.description}</div>
                                    </div>
                                )
                            })

                            }

                        </div>
                        <hr style={{
                            borderTop: '1px dashed #C9CED6',
                            width: '100%'
                        }} />
                        <div className='text-left ml-4 mt-4'>{t('add_credits')}:</div>
                        <label className='ml-5 text-[10px] pb-2'>{t('min')}: 100 credits</label>
                        <InputNumber placeholder={t('enter_credits')} className='w-[90%] mt-2' value={creditsInput}
                            onChange={(e: any) => {
                                setCreditsInput(e)
                            }}
                            min={100}
                            max={1000000}
                            maxLength={7}
                            style={{
                                margin: '0 auto',
                                maxWidth: '100%'
                            }} />
                        <div className='list-tag flex gap-2 justify-center items-center mt-1'>
                            <Tag color="processing" className='cursor-pointer' onClick={() => { setCreditsInput(100) }}>100</Tag>
                            <Tag color="processing" className='cursor-pointer' onClick={() => { setCreditsInput(500) }}>500</Tag>
                            <Tag color="processing" className='cursor-pointer' onClick={() => { setCreditsInput(1000) }}>1000</Tag>
                        </div>
                        <div className="p-2 flex-end " style={{
                            pointerEvents: creditsInput >= 100 && Object.keys(subscription).length !== 0 ? 'auto' : 'none',
                            filter: creditsInput >= 100 && Object.keys(subscription).length !== 0 ? 'grayscale(0)' : 'grayscale(1)'
                        }}>
                            <ListButtonPayment
                                index={products.length - 1}
                                radioValue={radioValue}
                                onclickPayment={creditsInput >= 100 && Object.keys(subscription).length !== 0 ? handleClickPaymentButton : () => { }}
                                products={products}
                                isShowContactUs={true}
                                isStripeEnabled={isStripeEnabled}
                                isVNPayEnabled={isVNPayEnabled}
                                credits={creditsInput}
                                isTopUp={true}
                            />
                        </div>
                    </div>
                )
            })
            }
        </Modal >
    )
}

export default NewModalRequiredSub