import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from '../iStore';

const initialState: AuthState = {
    user: undefined,
    loadingLogin: false,
    loadingUserInfo: false
};

const authSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        logout: () => {}
    }
});

export const authActions = authSlice.actions;
export default authSlice.reducer;
