// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* "slide active-anim" : "slide" */
.slide {
    display: none;
}

.slide.active-anim {
    display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/VTO-UploadModel/styles.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["/* \"slide active-anim\" : \"slide\" */\n.slide {\n    display: none;\n}\n\n.slide.active-anim {\n    display: block;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
