import React from 'react'
import logoVNPay from '../../assets/icons/vnpay.svg';
import logoStripe from '../../assets/icons/stripe.svg';
import { EPaymentType } from './ModalRequiredSub';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
type ListButtonPaymentProps = {
    onclickPayment: Function,
    products: any,
    isShowContactUs: any
    index: number
    radioValue: any
    isStripeEnabled: boolean
    isVNPayEnabled: boolean
    credits: number,
    isTopUp?: Boolean
}
function ListButtonPayment({ onclickPayment, products, isShowContactUs, index, radioValue, isStripeEnabled, isVNPayEnabled, credits, isTopUp }: ListButtonPaymentProps) {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language
    //EPaymentType.STRIPE, item.id, radioValue, isStripeEnabled, isVNPayEnabled, products
    const handleClickContactUS = () => {
        window.open("https://www.facebook.com/modeliaivn", "_blank");
    }
    return (
        <div className=''>

            {isShowContactUs ?
                <div className='btn-payment-section flex flex-col max-w-[220px] m-auto my-0 '>
                    <div className=' gap-3 flex sm:flex-col md:flex-col '>
                        <div
                            onClick={() => onclickPayment(EPaymentType.STRIPE, products[index].id, radioValue, isStripeEnabled, isVNPayEnabled, products, credits, isTopUp)}
                            className="btn-stripe !bg-[#635BFF] py-2"
                        >
                            <img src={logoStripe} alt="stripe" width={"80px"} className='object-contain' />
                        </div>
                        {currentLanguage === 'vi' ? <div
                            onClick={() => onclickPayment(EPaymentType.VNPAY, products[index].id, radioValue, isStripeEnabled, isVNPayEnabled, products, credits, isTopUp)}
                            className="btn-vnpay !bg-[#ED1B23] flex justify-center items-center py-2 "
                        >
                            <img src={logoVNPay} alt="vnpay" width={"80px"} className='object-contain' />
                        </div>
                            : <Tooltip overlayStyle={{
                                zIndex: 99999999999999,
                                textAlign: 'center'
                            }} title={t('only_support_vnd')}>
                                <div
                                    className="btn-vnpay !bg-[#ED1B23] py-2"
                                    style={{
                                        filter: 'grayscale(1)'
                                    }}
                                >
                                    <img src={logoVNPay} alt="vnpay" width={"80px"} className='object-contain' />
                                </div>
                            </Tooltip>
                        }

                    </div>
                </div>
                :
                <div className=' max-w-[200px] m-auto my-0 p-2 flex flex-col justify-center'><div className='bg-[#0047FF]   rounded-lg w-auto p-[11.5px]   text-[16px] font-semibold text-white cursor-pointer text-center' onClick={handleClickContactUS} >{t('contact_us')}</div></div>

            }

        </div>
    )
}

export default ListButtonPayment