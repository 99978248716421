import { useTranslation } from 'react-i18next';
import './styles.scss'

type ToggleProps = {
    selectedOption: string;
    setSelectedOption: Function,
    listDiscountPercent: number[]
}
function Toggle({ selectedOption, setSelectedOption, listDiscountPercent }: ToggleProps) {
    const handleOptionChange = (option: string) => {
        setSelectedOption(option);
    };
    const { t } = useTranslation()
    return (
        <div className='flex justify-center items-center max-w-full'>
            <div className="switch-container-v2">
                <input
                    type="radio"
                    id="monthly"
                    name="switch"
                    value="monthly"
                    checked={selectedOption === 'monthly'}
                    onChange={() => handleOptionChange('monthly')}
                />
                <label htmlFor="monthly" className='flex flex-col items-center justify-center'>
                    <div>
                        <p className='font-normal text-[16px] text-[#667085]'>
                            {t('monthly')}
                        </p>
                    </div>
                </label>

                <input
                    type="radio"
                    id="quarterly"
                    name="switch"
                    value="quarterly"
                    checked={selectedOption === 'quarterly'}
                    onChange={() => handleOptionChange('quarterly')}
                />
                <label htmlFor="quarterly" className='flex flex-col items-center'>
                    <div>
                        <p className='font-normal text-[16px] text-[#667085]'>
                            {t('quarterly')}
                        </p>
                        <div className={`discount px-2 w-auto h-auto bg-[#12B76A] rounded text-white font-semibold flex sm:flex-col sm:px-1 sm:text-[11px]`}>
                            {t('discount')} {listDiscountPercent[1]}%
                        </div>
                    </div>
                </label>

                <input
                    type="radio"
                    id="yearly"
                    name="switch"
                    value="yearly"
                    checked={selectedOption === 'yearly'}
                    onChange={() => handleOptionChange('yearly')}
                />
                <label htmlFor="yearly" className='flex flex-col items-center'>
                    <div>
                        <p className='font-normal text-[16px] text-[#667085]'>
                            {t('yearly')}
                        </p>
                        <div className={`discount px-2 w-auto h-auto bg-[#12B76A] rounded text-white font-semibold flex sm:flex-col sm:px-1 sm:text-[11px]`}>
                            {t('discount')} {listDiscountPercent[2]}%
                        </div>
                    </div>
                </label>

                <div className="slider"></div>
            </div>
        </div>
    )
}

export default Toggle