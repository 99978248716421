import { useTranslation } from 'react-i18next';
import './styles.css'
import { useEffect } from 'react';
import analytics from '../../firebase';
import { logEvent } from '@firebase/analytics';

export const TermOfUse = () => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    useEffect(()=>{
        logEvent(analytics, 'page_term_of_user')
    },[])
    if (currentLanguage === 'vi') {
        return (
            <section className='term-of-use'>
                <h1 className="privacy-h1">Điều khoản và Điều kiện</h1>

                <h2 className="privacy-h2">1. Điều khoản và Điều kiện</h2>

                <p className="privacy-p">
                    Modeli có thể thay đổi Điều khoản và Điều kiện, một phần hoặc toàn bộ, bất kỳ lúc nào và những thay đổi đó sẽ có hiệu lực từ thời điểm được đăng lên trang web. Khi bạn sử dụng trang web của chúng tôi, bạn đồng ý tuân thủ những
                    điều khoản này.
                </p>

                <h2 className="privacy-h2">2. Thương hiệu</h2>

                <p className="privacy-p">Người sở hữu quyền sở hữu các tài liệu được xuất bản trên trang web này sẽ giữ quyền sở hữu đối với những tài liệu đó.</p>

                <h2 className="privacy-h2">3. Điều khoản và Điều kiện của Người mua</h2>

                <p className="privacy-p">
                    Tất cả các tài liệu được tải lên sẽ hiển thị trên web, trừ các tệp tin đã được mua, sẽ chỉ hiển thị cho người mua mã của bạn. Khi bạn nhấp vào "gửi" và sau khi sản phẩm của bạn được phê duyệt trên trang web này, bạn chấp nhận các
                    điều khoản và điều kiện và bạn sẽ có một thỏa thuận pháp lý giữa bạn và Modeli liên quan đến trang web này. Nếu bạn gửi các tệp tin và nội dung thay mặt cho công ty của bạn, bạn tuyên bố rằng bạn có quyền pháp lý để làm như
                    vậy và để buộc công ty của bạn vào một thỏa thuận với các điều khoản và điều kiện được viết ở đây. Bạn cũng tuyên bố rằng bạn đã đủ 18 tuổi.
                </p>

                <h2 className="privacy-h2">4. Người mua</h2>

                <p className="privacy-p">Modeli có quyền độc lập hoàn toàn để:</p>
                <ul className="privacy-ul-il">
                    <li>Không cấp cho bạn một tài khoản Người mua</li>
                    <li>Tạm ngừng hoạt động tài khoản của bạn bất kỳ lúc nào mà không cần thông báo trước</li>
                </ul>
                <p className="privacy-p">
                    Modeli không có nghĩa vụ cung cấp lý do cho quyết định từ chối yêu cầu mở tài khoản hoặc tạm ngừng hoạt động tài khoản của bạn. Bạn là người duy nhất có quyền và chịu trách nhiệm duy nhất đối với mọi truy cập và sử dụng trang
                    web với tên người dùng và mật khẩu của bạn. Trong trường hợp có bất kỳ việc sử dụng trái phép nào của thông tin đăng nhập của bạn, bạn phải thông báo ngay lập tức cho Modeli.
                </p>

                <h2 className="privacy-h2">5. Vi phạm bản quyền</h2>

                <p className="privacy-p">Trong trường hợp bạn cho rằng có vi phạm bản quyền với sản phẩm của bạn, vui lòng gửi thông tin này đến copy@Modeli.com. Vui lòng lưu ý bao gồm các thông tin sau trong email của bạn:</p>

                <ul className="privacy-ul-il">
                    <li>Tên, địa chỉ, email của bạn;</li>
                    <li>Trong trường hợp bạn là một doanh nghiệp, thì tên doanh nghiệp, địa chỉ doanh nghiệp;</li>
                    <li>Một liên kết và một giải thích về tài liệu vi phạm bản quyền được định vị;</li>
                    <li>Một báo cáo chi tiết để giải thích về vi phạm.</li>
                    <li>Chúng tôi sẽ điều tra báo cáo của bạn và yêu cầu thêm thông tin nếu cần để tiến hành điều tra.</li>
                </ul>

                <h2 className="privacy-h2">6. Vai trò của Modeli</h2>

                <p className="privacy-p">
                    Người phát triển là người sở hữu duy nhất các mã mà anh ấy / cô ấy chọn để liệt kê trên trang web này và Modeli không có quyền sở hữu hoặc sử dụng các mã được tải lên. Modeli không kiểm soát và sẽ không chịu trách nhiệm về
                    chất lượng, tính hợp pháp hoặc an toàn của các mã được bán và tải xuống từ trang web này. Modeli không đảm bảo rằng bất kỳ tài liệu nào trên trang web không vi phạm quyền sở hữu trí tuệ của người khác. Nếu bạn nghi ngờ vi phạm
                    bản quyền, bạn có thể gửi nó theo phần 5 trong thỏa thuận này.
                </p>

                <h2 className="privacy-h2">7. Sử dụng và Bảo trì trang web</h2>

                <p className="privacy-p">
                    Modeli có quyền tạm thời hoặc hoàn toàn ngừng truy cập vào trang web cho bạn hoặc tắt hoàn toàn trang web nói chung. Đôi khi và theo quyết định độc quyền của Modeli, trang web có thể bị tắt để bảo trì. Tất cả những điều
                    được nêu trong phần này có thể được thực hiện mà không cần bất kỳ thông báo nào. Modeli sẽ không chịu trách nhiệm về bất kỳ thiệt hại nào do một hoặc tất cả những điều đã được đề cập ở trên trong phần này gây ra.
                </p>

                <h2 className="privacy-h2">8. Phí và Dịch vụ</h2>

                <p className="privacy-p">
                    Đăng ký trang web cả như một người mua và nhà phát triển là miễn phí. Người mua sẽ trả tiền cho các sản phẩm và dịch vụ mà họ chọn mua. Trong trường hợp người mua đặt ở châu Âu và mua hàng cá nhân (không mua cho một công ty), thuế
                    giá trị gia tăng (VAT) sẽ được thêm vào theo quy định của luật VAT của Liên minh châu Âu. Nếu bạn mua hàng cho một công ty, bạn sẽ cần cung cấp đầy đủ thông tin về công ty để loại trừ VAT. Là người mua, bạn hoàn toàn chịu trách
                    nhiệm thanh toán tất cả các khoản phí và chi phí bao gồm thuế thu nhập, hàng hóa và dịch vụ và VAT liên quan đến việc sử dụng trang web này, bất kể bạn được thuế ở đâu. Người mua chịu trách nhiệm cho bất kỳ chi phí chuyển đổi tiền
                    tệ từ loại tiền tệ của họ sang USD. Người mua có thể mua hàng bằng thẻ tín dụng và PayPal.
                </p>

                <h2 className="privacy-h2">9. Độ tin cậy của thông tin người mua</h2>

                <p className="privacy-p">Bằng cách đăng ký trở thành nhà phát triển trên trang web này, bạn tuyên bố rằng tất cả các chi tiết bạn đã nêu liên quan đến bạn và doanh nghiệp của bạn là chính xác 100% và được cập nhật.</p>

                <h2 className="privacy-h2">10. Bảo mật và quyền riêng tư</h2>

                <p className="privacy-p">
                    Modeli không đảm bảo tính bảo mật cho dữ liệu của bạn và bất kỳ tài liệu nào được tải lên trang web này. Chúng tôi duy trì các cơ chế bảo mật của riêng mình trên trang web để kiểm soát và bảo vệ tất cả dữ liệu trên trang web.
                </p>

                <h2 className="privacy-h2">11. Tuyên bố miễn trừ trách nhiệm</h2>

                <p className="privacy-p">
                    Modeli không loại trừ bất kỳ Quyền không thể loại trừ nào nhưng loại trừ tất cả các điều kiện và bảo đảm khác được ngụ ý theo thông lệ, luật pháp hoặc quy chế. Trừ khi được quy định bởi các Quyền không thể loại trừ:
                </p>
                <ul className="privacy-ul-il">
                    <li>Tất cả nội dung, tài liệu và sản phẩm trên trang web này được cung cấp NGUYÊN TRẠNG VÀ KHÔNG CÓ BẢO ĐẢM DƯỚI BẤT KỲ HÌNH THỨC NÀO</li>
                </ul>
                <p className="privacy-p">Modeli không đảm bảo rằng trang web không có lỗi và máy chủ lưu trữ trang web không có vi-rút hoặc các thành phần có hại hoặc độc hại khác</p>

                <h2 className="privacy-h2">12. Hành vi bị cấm</h2>

                <p className="privacy-p">Bạn đảm bảo rằng khi sử dụng trang web và các dịch vụ của nó, bạn sẽ không:</p>
                <ul className="privacy-ul-il">
                    <li>sử dụng địa chỉ email hoặc dữ liệu sai;</li>
                    <li>chèn quảng cáo của riêng bạn hoặc của bên thứ ba và sử dụng dịch vụ cho bất kỳ mục đích thương mại hoặc quảng cáo nào khác.</li>
                    <li>
                        tham gia vào việc gửi thư rác, làm tràn ngập, thu thập địa chỉ e-mail hoặc thông tin cá nhân khác, thu thập dữ liệu, "quét màn hình", "quét cơ sở dữ liệu" hoặc bất kỳ hoạt động nào khác với mục đích lấy danh sách người dùng
                        hoặc thông tin khác, hoặc gửi thư dây chuyền hoặc sơ đồ kim tự tháp thông qua Trang web;
                    </li>
                    <li>
                        hoặc cố gắng truy cập trái phép vào các hệ thống máy tính khác thông qua Trang web. Bạn đồng ý rằng bạn sẽ không sử dụng Trang web theo bất kỳ cách nào có thể làm hỏng, vô hiệu hóa, quá tải hoặc làm suy yếu Trang web hoặc can
                        thiệp vào việc sử dụng và hưởng thụ Trang web của bất kỳ bên nào khác.
                    </li>
                </ul>
                <p className="privacy-p">Modeli có toàn quyền quyết định xem có vi phạm các điều khoản này hay không và tạm ngưng tài khoản của bạn mà không cần bất kỳ thông báo hay giải thích nào.</p>

                <h2 className="privacy-h2">13. Diễn đàn</h2>

                <p className="privacy-p">
                    Bạn chịu trách nhiệm cho bất kỳ tài liệu nào bạn đăng trên trang web. chúng tôi bảo lưu quyền nhưng không bắt buộc phải chỉnh sửa, xem xét và sửa đổi các tài liệu mà bạn đã đăng trên trang web. Modeli theo quyết định riêng của
                    mình có quyền chặn và xóa nội dung sẽ bị phát hiện là vi phạm các điều khoản của chúng tôi hoặc nội dung sẽ sử dụng thuật ngữ bạo lực hoặc xúc phạm.
                </p>

                <h2 className="privacy-h2">14. Bồi thường</h2>

                <p className="privacy-p">Bạn sẽ chịu trách nhiệm pháp lý và hoàn toàn chịu trách nhiệm đối với mọi khiếu nại và chi phí trực tiếp và gián tiếp (bao gồm mọi khoản phí pháp lý) phát sinh từ các vấn đề sau:</p>

                <ul className="privacy-ul-il">
                    <li>bất kỳ sản phẩm nào bạn đã tải lên trang web</li>
                    <li>việc bạn sử dụng trang web</li>
                    <li>bất kỳ nội dung, tài liệu đồ họa hoặc tệp nào bạn đã tải lên trang web</li>
                    <li>bất kỳ vi phạm các điều khoản và điều kiện trong thỏa thuận này</li>
                </ul>

                <h2 className="privacy-h2">15. Định nghĩa</h2>

                <p className="privacy-p">Trong các Điều khoản và Điều kiện này:</p>
                <ul className="privacy-ul-il">
                    <li>Nhà phát triển là người đã đăng ký trở thành nhà phát triển trên Modeli và đã điền vào biểu mẫu các thông tin chi tiết của mình cũng như đồng ý với các điều khoản và điều kiện.</li>
                    <li>Người mua có nghĩa là một người hoặc một công ty đã mua một sản phẩm hoặc dịch vụ trên trang web này.</li>
                    <li>Chúng tôi hoặc chúng tôi có nghĩa là Modeli.</li>
                    <li>Trang web của chúng tôi và trang web này có nghĩa là Modeli.</li>
                    <li>Bạn hoặc nhà phát triển hoặc người mua phương tiện của bạn.</li>
                </ul>
            </section>
        );
    }

    return (
        <section style={{ padding: 50 }}>
            <h1 className="privacy-h1">Terms and Conditions</h1>

            <h2 className="privacy-h2">1. Terms and Conditions</h2>

            <p className="privacy-p">
                SellQualityGames may change the Terms and Conditions, partly or in whole at any time and those changes will be valid on the time of posting them to the site. When you use our website you choose to agree to these terms.
            </p>

            <h2 className="privacy-h2">2. Trade Marks</h2>

            <p className="privacy-p">Whoever owns the rights to the materials published on this site will keep his rights on those materials.</p>

            <h2 className="privacy-h2">3. Buyers Terms and Conditions</h2>

            <p className="privacy-p">
                All uploaded materials will be visible on the web, except for paid files which will become visible to buyers who purchased your code. when you click ‘submit’ and upon approval of your product on this site you accept the terms and
                conditions and you are in a legal agreement between you and SellQualityGames in relation to this site. If you are submitting files and content on the behalf of your company your declare that you have the legal authority to do so and
                to bind your company into an agreement of the terms and condition written here. you are also stating that you are at least 18 years old.
            </p>

            <h2 className="privacy-h2">4. Buyers</h2>

            <p className="privacy-p">SellQualityGames reserves full independent rights to:</p>
            <ul className="privacy-ul-il">
                <li>Not grant you with a Buyer account</li>
                <li>To suspend your account at any time without further notice</li>
            </ul>
            <p className="privacy-p">
                SellQualityGames will not be obliged to provide reasons for the decision to reject your request for an account or for the suspension of your account. you are the only authorized and hold the sole responsibility to every access and use
                of the site with your username and password. in case there is any unauthorized use of your login details you must notify SellQualityGames immediately.
            </p>

            <h2 className="privacy-h2">5. Copyright Infringements</h2>

            <p className="privacy-p">In case you think there had been a copyright violation of your products please email this information to copy@sellqualitygames.com. Please note to add the following to your email:</p>

            <ul className="privacy-ul-il">
                <li>Your name, address, email;</li>
                <li>In case you are a business then – business name, business address;</li>
                <li>A link and an explanation to the located copyrighted materials;</li>
                <li>A detailed report that explains about the infringement.</li>
                <li>We will inquire into your report and ask for more information in case needed to proceed the inquiry.</li>
            </ul>

            <h2 className="privacy-h2">6. SellQualityGames’s Role</h2>

            <p className="privacy-p">
                The developer is the only owner of the codes he/she chooses to list on this site and SellQualityGames has no rights of ownership or to use the uploaded codes. SellQualityGames doesn’t control and will not be held responsible for the
                quality, legality or safety of the codes that are sold and downloaded from this site. SellQualityGames gives no guarantee that any of the materials on the site do not infringe the intellectual property of others. if you suspect a
                copyright infringement you can submit it in accordance to section 5 in this agreement.
            </p>

            <h2 className="privacy-h2">7. Site Usage and Maintenance</h2>

            <p className="privacy-p">
                SellQualityGames reserves the right to partially or fully stop the access to the site for you or for a complete shutdown of the site in general. From time to time and to SellQualityGames sole discretion the site might be down for
                maintenance. All of the stated in this section can be done without any given notice. SellQualityGames will not be held liable to a loss caused by one or all of the mentioned above in this section.
            </p>

            <h2 className="privacy-h2">8. Fees and Services</h2>

            <p className="privacy-p">
                Registering to the site both as a buyer and as a developer is free of charge. Buyers will pay for the products and services they choose to purchase. in case a buyer is located in Europe and purchases privately (buyer doesn’t purchase
                for a company) VAT will be added in accordance to EU VAT laws. If you are buying for a company you will need to state it’s full details in order for the VAT to be excluded. As a buyer you are fully responsible to pay all fees and
                charges including withholding tax, good and services and VAT that is associated with the usage of this site wherever you are levied. Buyers are responsible for any currency conversion costs from their currency to USD. Buyers can
                purchase using credit card and PayPal.
            </p>

            <h2 className="privacy-h2">9. Buyer Credibility of Information</h2>

            <p className="privacy-p">By applying to become a developer on this site you declare that all of the details you have stated relating to you and your business are 100% correct and up to date.</p>

            <h2 className="privacy-h2">10. Security and Privacy</h2>

            <p className="privacy-p">
                SellQualityGames doesn’t guarantee the security of your data and any materials that’s uploaded onto this site. We do maintain our own security mechanisms on the site to control and protect all of the data on the site.
            </p>

            <h2 className="privacy-h2">11. Disclaimer</h2>

            <p className="privacy-p">SellQualityGames does not exclude any Non-excludable Rights but does exclude all other conditions and warranties implied by custom, law or statute. Except as provided for by the Non-excludable Rights:</p>
            <ul className="privacy-ul-il">
                <li>all content , materials and products on this site are provided AS IS AND WITHOUT WARRANTIES OF ANY KIND</li>
            </ul>
            <p className="privacy-p">SellQualityGames does not guarantee that the site is free of errors and that the server that hosts the site is free of viruses or other harmful or malicious components</p>

            <h2 className="privacy-h2">12. Prohibited Conduct</h2>

            <p className="privacy-p">You warrant that when using the site and it’s services you will not:</p>
            <ul className="privacy-ul-il">
                <li>use a false email address or data;</li>
                <li>insert your own or a third party’s advertising and use the service for any further commercial or promotional purposes.</li>
                <li>
                    engage in spamming, flooding, harvesting of e-mail addresses or other personal information, spidering, “screen scraping,” “database scraping,” or any other activity with the purpose of obtaining lists of users or other
                    information, or send chain letters or pyramid schemes via the Site;
                </li>
                <li>
                    or attempt to gain unauthorized access to other computer systems through the Site. You agree that you will not use the Site in any manner that could damage, disable, overburden, or impair the Site or interfere with any other
                    party’s use and enjoyment of the Site.
                </li>
            </ul>
            <p className="privacy-p">SellQualityGames reserves full right to determine at it’s sole discretion if there was a breach in these terms and suspend your account without any notice or explanation.</p>

            <h2 className="privacy-h2">13. Forums</h2>

            <p className="privacy-p">
                You are responsible for any of the materials that you post on the site. we reserve rights but are not obliged to edit, review and modify the materials that you have posted on site. SellQualityGames at it’s sole discretion reserves the
                right to block and delete content that will be found to violate our terms or content that will use violent or offensive terminology.
            </p>

            <h2 className="privacy-h2">14. Indemnification</h2>

            <p className="privacy-p">You will be held liable and responsible solely for any direct and indirect claims and expenses (including any legal fees) that arise from the following matters:</p>

            <ul className="privacy-ul-il">
                <li>any product you have uploaded to the site</li>
                <li>your usage of the site</li>
                <li>any content, graphic materials or files you have uploaded to the site</li>
                <li>any breach of the terms and conditions in this agreement</li>
            </ul>

            <h2 className="privacy-h2">15. Definitions</h2>

            <p className="privacy-p">In these Terms and Conditions:</p>
            <ul className="privacy-ul-il">
                <li>Developer is a person who applied to become a developer on SellQualityGames and had filled a form with his details and agreed to the terms and conditions.</li>
                <li>Buyers means a person or a company that bought a product or service on this site.</li>
                <li>We or us means SellQualityGames.</li>
                <li>Our site and this site means SellQualityGames.</li>
                <li>You or your means developer or buyer.</li>
            </ul>
        </section>
    );
};
