import { Button, Modal } from 'antd'
import { ScissorOutlined, HighlightOutlined, FullscreenOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons';
import 'react-image-crop/dist/ReactCrop.css'
import './styles.css'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import { editorImage } from '../../redux/app/appImagePreprocessing';
import Advanced from './advanced';
import ModalResizeCanvas from './ModalResizeCanvas';
import watermark from '../../assets/icons/watermark-black-2.svg';
import watermarkActive from '../../assets/icons/watermark-black-2-active.svg';
import RotateScreen from './RotateScreen';
import RemoveWatermark from './RemoveWatermark';
import ModalUpscale from './ModalUpscale';
import { logEvent } from '@firebase/analytics';
import { setLoadingApp } from '../../redux/app/loadingApp';
import FashionService from '../../services/fashion.service';
import { userSaga } from '../../redux/app/authSaga';
import NotifyController from '../../utils/toast';
import analytics from '../../firebase';
import ModalResize from './ModalResize';
import { resizeImageByWidthAndHeight } from '../../utils/function';
type ModalEditorProps = {
    image: string,
    isOpen?: boolean,
    closeModel?: () => void,
    getCropData?: any,
    openModal: Function,
    setImage: Function,
    onChangeUrlImage?: Function,

}
type ImageCropDataFromResizeTabProps = {
    width: number,
    height: number,
    imageUrl: string
}
function ModalEditor({ image, isOpen, closeModel, openModal, getCropData, setImage, onChangeUrlImage }: ModalEditorProps) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const dataCost = useAppSelector(store => store.costFeature)
    const imageCropData = useAppSelector((store) => store.EditorImage)
    const currentCoins = useAppSelector((store) => store.user.coins);
    const hasCrop = useAppSelector(store => store.EditorImage.hasCropImage)
    const resizeCanvasRef = useRef<HTMLCanvasElement>(null)
    const [openModalAdvanced, setOpenModalAdvanced] = useState(false)
    const [isRotate, setRotate] = useState(false)
    const [imageUrl, setImageUrl] = useState<string>(image)

    const [isOpenModalWarning, setIsOpenModalWarning] = useState(false);
    const [firstLoadImage, setFirstLoadImage] = useState(false);
    const [imageBeforeUpscale, setImageBeforeUpscale] = useState('');
    const [tabActive, setTabActive] = useState<string>('cut')
    const [loading, setLoading] = useState(false);

    const widthScreen = window.innerWidth;
    const onConfirmEditor = async (imageCropDataFromResizeTab?: ImageCropDataFromResizeTabProps) => {
        if (!hasCrop && tabActive !== 'cut') {
            NotifyController.warning(t('please_crop_correct_ratio_image'));
            setLoading(false)
            setTabActive('cut')
            return
        }
        let imageDataCrop = {
            width: imageCropData.width,
            height: imageCropData.height,
            imageUrl: imageCropData.imageUrl
        }

        if (imageCropDataFromResizeTab && imageCropDataFromResizeTab.width && imageCropDataFromResizeTab.height && imageCropDataFromResizeTab.imageUrl) {
            let imgWidth: number = imageCropDataFromResizeTab.width;
            let imgHeight: number = imageCropDataFromResizeTab.height;
            if (imageCropDataFromResizeTab.width > 768) {
                imgWidth = 768;
                imgHeight = Math.round((768 * imageCropDataFromResizeTab.height) / imageCropDataFromResizeTab.width);
            }
            else {
                imgWidth = imageCropDataFromResizeTab.width - (imageCropDataFromResizeTab.width % 8);
                imgHeight = imageCropDataFromResizeTab.height - (imageCropDataFromResizeTab.height % 8);
            }
            const newBase64Image = await resizeImageByWidthAndHeight(imageCropDataFromResizeTab.imageUrl, imgWidth, imgHeight)
            imageDataCrop = {
                width: imgWidth,
                height: imgHeight,
                imageUrl: newBase64Image as string
            }
        }
        dispatch(editorImage.setImageEditor(imageDataCrop))
        const ratioImage = Math.round((imageDataCrop.height / imageDataCrop.width) * 10) / 10;
        //disable limit size upload
        // if (imageDataCrop.width > 768 || imageCropData.height > 1152) {
        //     setTabActive('cut')
        //     NotifyController.warning(t('please_crop_image'))
        // } else {
        if (ratioImage === Math.round((3 / 2) * 10) / 10 || ratioImage === Math.round((4 / 3) * 10) / 10) {
            setFirstLoadImage(true)
            checkLess512(imageDataCrop.imageUrl, imageDataCrop.width, imageDataCrop.height)
        } else {
            NotifyController.warning(t('please_crop_correct_ratio_image'))
            setTabActive('cut')
            // setFirstLoadImage(false)
        }
        // }
    }

    const checkLess512 = (url: string, inputWidth: number, inputHeight: number) => {
        if (inputWidth < 700 || inputHeight < 1000) {
            setImageBeforeUpscale(url)
            setIsOpenModalWarning(true)
        } else {
            closeModal();
            getCropData(url, inputWidth, inputHeight);
            onChangeUrlImage && onChangeUrlImage(url, inputWidth, inputHeight)
        }
    }

    const upscaleImage = async () => {
        dispatch(setLoadingApp.setLoading(true));
        try {
            const imageAfterUpscale = await FashionService.getInstance().processUpscaleV2(imageBeforeUpscale);
            getCropData(`data:image/jpeg;base64,${imageAfterUpscale.imagePaths}`, Math.round(imageAfterUpscale.width), Math.round(imageAfterUpscale.height));
            setIsOpenModalWarning(false);
            dispatch(userSaga.setCredits(currentCoins - dataCost.upscale));
            closeModal();
        } catch (error) {
            NotifyController.error(t('An error occurred, please proceed with a low-quality image.'));
            setIsOpenModalWarning(false);
            continuesUploadFunction();
            closeModal();
        }
        dispatch(setLoadingApp.setLoading(false));
    }

    const continuesUploadFunction = () => {
        logEvent(analytics, 'upload_image')
        getCropData(imageBeforeUpscale, imageCropData.width, imageCropData.height);
    }

    const onChangeToCut = () => {
        if (firstLoadImage)
            setTabActive('cut')
        else
            NotifyController.warning(t('please_crop_image'))
    }

    const onChangeToRemove = () => {
        if (firstLoadImage) {

            setTabActive('remove')
            setOpenModalAdvanced(true)

        }
        else
            NotifyController.warning(t('please_crop_image'))
    }

    const onChangeToResize = () => {
        if (firstLoadImage) {
            setTabActive('resize')
        } else
            NotifyController.warning(t('please_crop_image'))
    }

    const onChangeToRemoveWatermark = () => {
        if (firstLoadImage) {
            setTabActive('remove_watermark')
        }
        else
            NotifyController.warning(t('please_crop_image'))
    }

    const closeModal = () => {
        dispatch(editorImage.resetImageEditor());
        setTimeout(() => {
            closeModel && closeModel();
        }, 100)
    }

    useEffect(() => {
        return () => {
            resizeCanvasRef.current?.remove()
        }
    }, [imageCropData.imageUrl])

    useEffect(() => {
        setFirstLoadImage(true)
    }, [image, openModal])

    useEffect(() => {
        if (isOpen) {
            setImageUrl(image)
        }
    }, [isOpen, image])

    useLayoutEffect(() => {
        if (widthScreen < 512) {
            setRotate(true)
        } else {
            setRotate(false)
        }
    }, [widthScreen])

    useEffect(() => {
        return () => {
            dispatch(editorImage.resetImageEditor());
        }
    }, [])

    return (
        <div id="modal-resize">
            <Modal
                centered={true}
                title={<div className='text-center text-[22px]'>
                    {t('Edit_before_processing')}
                </div>}
                width={'95vw'}
                open={isOpen}
                bodyStyle={{
                    minHeight: '75vh'
                }}
                footer={null}
                // footer={[
                //     <>
                //         {
                //             isRotate ? <></> : <>
                //                 <hr />
                //                 <div className='btn-confirm mt-4 flex justify-center items-center'>
                //                     <Button type='primary' disabled={loading} className='w-[250px] sm:w-full md:w-full lg:w-full xl:fw-full ' onClick={onConfirmEditor}>{t('done')}</Button>
                //                 </div>
                //             </>
                //         }
                //     </>
                // ]}
                closeIcon={[<CloseOutlined onClick={closeModal} />]}
            >  {isRotate ? <RotateScreen /> :
                <>
                    <div className='flex'>
                        <div className={`tab-pre ${tabActive === 'cut' ? 'tab_selected hover:cursor-pointer' : ''}`} onClick={onChangeToCut} style={{
                            pointerEvents: loading ? 'none' : 'auto',
                            color: loading ? 'gray' : 'black'
                        }}>
                            <div className='flex justify-center items-center flex-col gap-2' >
                                <ScissorOutlined className={tabActive === 'cut' ? 'text-[#4E89FF] text-2xl font-semibold' : 'text-2xl font-semibold'} />
                                <p className='font-semibold'>{t('cut_image')}</p>
                            </div>
                        </div>
                        <div className={`tab-pre ${tabActive === 'remove' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToRemove} style={{
                            pointerEvents: loading ? 'none' : 'auto',
                            color: loading ? 'gray' : 'black'
                        }}>
                            <div className='flex justify-center items-center flex-col gap-2'>
                                <HighlightOutlined className={tabActive === 'remove' ? 'text-[#4E89FF] text-2xl font-semibold' : 'text-2xl font-semibold'} />
                                <p className='font-semibold'>{t('remove_image')}</p>
                            </div>
                        </div>
                        <div className={`tab-pre ${tabActive === 'resize' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToResize} style={{
                            pointerEvents: loading ? 'none' : 'auto',
                            color: loading ? 'gray' : 'black'
                        }}>
                            <div className='flex justify-center items-center flex-col gap-2'>
                                <FullscreenOutlined className={tabActive === 'resize' ? 'text-[#4E89FF] text-2xl font-semibold' : 'text-2xl font-semibold'} />
                                <p className='font-semibold'>{t('resize_image')}</p>
                            </div>
                        </div>
                        <div className={`tab-pre ${tabActive === 'remove_watermark' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToRemoveWatermark} style={{
                            pointerEvents: loading ? 'none' : 'auto',
                            color: loading ? 'gray' : 'black'
                        }}>
                            <div className='flex justify-center items-center flex-col gap-2'>
                                <Icon className={tabActive === 'remove_watermark' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'remove_watermark' ? watermarkActive : watermark} className={'w-[25px] h-[25px]'} />)} />
                                <p className='font-semibold'>{t('remove_watermark_image')}</p>
                            </div>
                        </div>
                    </div>

                    {/* {tabActive === 'cut' && <ModalResize image={imageUrl} onConfirmEditor={onConfirmEditor} isLoading={loading} setLoading={setLoading} />} */}
                    {/* {tabActive === 'remove' && openModalAdvanced && <Advanced openModalAdvanced={openModalAdvanced} tabActive={tabActive} setTabActive={setTabActive} onConfirmEditor={onConfirmEditor} isLoading={loading} />}
                    {tabActive === 'resize' && <ModalResizeCanvas tabActive={tabActive} setTabActive={setTabActive} onConfirmEditor={onConfirmEditor} isLoading={loading} />}
                    {tabActive === 'remove_watermark' && <RemoveWatermark
                        setLoading={setLoading} isLoading={loading} onConfirmEditor={onConfirmEditor} tabActive={tabActive} />}
                    {isOpenModalWarning && <ModalUpscale isOpenModalWarning={isOpenModalWarning} setIsOpenModalWarning={setIsOpenModalWarning} upscaleImage={upscaleImage} onClickContinuesUpload={continuesUploadFunction} setLoading={setLoading} />} */}

                </>
                }
            </Modal>
        </div >
    )
}

export default ModalEditor