import React, { createRef, useRef, useState } from 'react'
import CropImageComponent from '../../CropComponent'
import { ReactCropperElement } from 'react-cropper';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { getCroppedImg } from '../../../utils/function';
type CropImageProps = {
    imageUrl: string,
    openModal: boolean,
    setOpenModal: Function,
    indexActive: number,
    setListImages: Function,
    setArrayImages: Function,
    arrayImages: any,

}
type CropperState = {
    cropBoxData: any,
    canvasData: any,

};

function CropImage({ imageUrl, openModal, setOpenModal, indexActive, setListImages, arrayImages, setArrayImages }: CropImageProps) {
    const { t } = useTranslation()
    const cropperRef = createRef<ReactCropperElement>();
    const [cropperStates, setCropperStates] = useState<CropperState[]>([]);
    const saveCropperState = async () => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            const newCropperStates = [...cropperStates];
            newCropperStates[indexActive] = {
                cropBoxData: cropper.getCropBoxData(),
                canvasData: cropper.getCanvasData(),
            };
            setCropperStates(newCropperStates);
            const cloneUploadedImages = [...arrayImages]
            const cropData = cropperRef.current?.cropper?.getData();
            const imageData = cropperRef.current?.cropper?.getImageData()
            if (cloneUploadedImages.length > 0) {

                const mul = (cropData && cropData.height) / (imageData && imageData.naturalHeight);
                const croppedImage = await getCroppedImg(
                    imageUrl,
                    cropData,
                    mul
                );
                cloneUploadedImages[indexActive].modelImage = croppedImage
                setListImages(cloneUploadedImages)
                setArrayImages(cloneUploadedImages);
                setOpenModal(false)
            }
        }
    };
    return (
        <Modal open={openModal} onCancel={() => setOpenModal(false)}
            className='flex justify-center items-center'
            okText={t('save')}
            cancelText={t('cancel')}
            onOk={saveCropperState}
            centered
            title={t('crop_image')}
        >
            <div className='mt-2'>
                <CropImageComponent
                    imageUpload={imageUrl}
                    cropperRef={cropperRef}
                />
            </div>
        </Modal>
    )
}

export default CropImage