import React, { createRef, useRef, useState } from 'react'

import { ReactCropperElement } from 'react-cropper';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { getCroppedImg } from '../../utils/function';
import CropImage from './CropImage';
import CropImageCpn from './CropImageCpn';
import { EStatusTrain } from './ImageTrainLora';

type CropImageProps = {
    imageUrl: string,
    openModal: boolean,
    setOpenModal: Function,
    indexActive: number,
    setListImagesLora: Function,
    setListImages: Function,
    listImages: any,
    status: string,
    setStatus: Function

}
type CropperState = {
    cropBoxData: any,
    canvasData: any,

};

function CropFromOriginal({ imageUrl, openModal, setOpenModal, indexActive, setListImages, listImages, setListImagesLora, status, setStatus }: CropImageProps) {
    const { t } = useTranslation()
    const cropperRef = createRef<ReactCropperElement>();
    const [cropperStates, setCropperStates] = useState<CropperState[]>([]);
    const saveCropperState = async () => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            const newCropperStates = [...cropperStates];
            newCropperStates[indexActive] = {
                cropBoxData: cropper.getCropBoxData(),
                canvasData: cropper.getCanvasData(),
            };
            setCropperStates(newCropperStates);
            const cloneUploadedImages = [...listImages]
            const cropData = cropperRef.current?.cropper?.getData();
            const imageData = cropperRef.current?.cropper?.getImageData()
            if (cloneUploadedImages.length > 0) {

                const mul = (cropData && cropData.height) / (imageData && imageData.naturalHeight);
                const croppedImage = await getCroppedImg(
                    imageUrl,
                    cropData,
                    mul
                );
                setListImagesLora((prevList: string[]) => [...prevList, croppedImage]);
                if (status === EStatusTrain.FINISHED) {
                    setStatus(EStatusTrain.RETRAIN)
                }
                setOpenModal(false)
            }
        }
    };
    return (
        <Modal open={openModal} onCancel={() => setOpenModal(false)}
            className='flex justify-center items-center'
            okText={t('save')}
            cancelText={t('cancel')}
            onOk={saveCropperState}
            centered
        >
            <div className='mt-4'>
                <CropImageCpn
                    imageUpload={imageUrl}
                    cropperRef={cropperRef}
                />
            </div>
        </Modal>
    )
}

export default CropFromOriginal