import { createSlice } from '@reduxjs/toolkit';

interface SizeImage {
  less512: boolean;
}

const initialState: SizeImage = {
    less512: false,
};


const eventLess512 = createSlice({
  name: 'setLess512',
  initialState,
  reducers: {
    setLess512: (state, action) => {
      state.less512 = action.payload
    },
  },
});


export const setLess512 = eventLess512.actions;
export default eventLess512.reducer;