import { createSlice } from "@reduxjs/toolkit";

interface generatingProps {
  isGen: boolean;
  isGoHome: boolean;
}

const initialState: generatingProps = {
  isGen: false,
  isGoHome: false,
};

const eventSelect = createSlice({
  name: "isGenStore",
  initialState,
  reducers: {
    setIsGeneratingStore: (state, action) => {
      state.isGen = action.payload;
    },
    setBackToHome: (state, action) => {
      state.isGoHome = action.payload;
    },
  },
});

export const isGenStore = eventSelect.actions;
export default eventSelect.reducer;
