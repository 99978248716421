import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import { getDrawCursor, getImageSize, resizeCanvasFitScreen } from '../../utils/function';
import { fabric } from 'fabric'
import { useDebouncedCallback } from '../../hooks/useDebouncedCallback';
import { editorImage } from '../../redux/app/appImagePreprocessing';
import { useDispatch } from 'react-redux';
import { Button, Radio, Slider, Switch, Tooltip } from 'antd';
import { PlusOutlined, MinusOutlined, HighlightOutlined, DragOutlined } from '@ant-design/icons';
import undo from '../../assets/icons/undo.svg'
import redo from '../../assets/icons/redo.svg'
import reset from '../../assets/icons/reset.svg'
import { useTranslation } from 'react-i18next';
import { resizeImage } from './function';
import NotifyController from '../../utils/toast';
import { toolEditorImage } from '../../redux/app/toolEditor';
import { TypeEditor } from '../../utils/enum';
import Heading from '../../pages/Editor/DetailsTools/Heading';
type AdvancedProps = {
    openModalAdvanced: boolean;
    tabActive: string;
    setTabActive: Function;
    isLoading: boolean;
    onConfirmEditor: any;
    typeInput: TypeEditor;
    onClickSave: () => void;
    onClickBackToHome: () => void;
}
function Advanced({ openModalAdvanced, tabActive, setTabActive, onConfirmEditor, isLoading, typeInput, onClickSave, onClickBackToHome }: AdvancedProps) {
    let imageCropData = useAppSelector((store) => store.EditorImage)
    const imageTool = useAppSelector((store) => store.toolEditor);
    const { t } = useTranslation()
    const [modeMove, setModeMove] = useState('brush');
    const [widthPoint, setWidthPoint] = useState<any>(10)
    const fabricRef = useRef<fabric.Canvas | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [multiplier, setMultiplier] = useState<any>(1)
    const [canvasSizeData, setCanvasSizeData] = useState<
        | {
            scaleX: number;
            scaleY: number;
            widthCanvas: number;
            heightCanvas: number;
        }
        | undefined
    >();
    const dispatch = useDispatch()
    const [isDragging, setDragging] = useState(false);
    const [isRightMouseDown, setRightMouseDown] = useState(false);
    const [valueZoom, setValueZoom] = useState<any>(100)

    let isRedoing = false;
    let h: any = [];
    let panning = false;

    const setBg = (url: string, w: number, h: number) => {
        return new Promise<void>((resolve) => {
            const imgScale = resizeImage(w, h);
            setMultiplier(imgScale && imgScale.multiplier);
            fabric.Image.fromURL(
                url,
                function (img: any) {
                    fabricRef.current &&
                        fabricRef.current.setBackgroundImage(
                            img,
                            fabricRef.current.renderAll.bind(fabricRef.current),
                            {
                                scaleX: imgScale.sX,
                                scaleY: imgScale.sY,
                            }
                        );
                    fabricRef && fabricRef.current?.setHeight(imgScale.h);
                    fabricRef && fabricRef.current?.setWidth(imgScale.w);
                    // resolve();
                },
                { crossOrigin: "*" }
            );
        })
    };

    const initFabric = useCallback(() => {
        if (canvasRef.current) {
            fabricRef.current = new fabric.Canvas(canvasRef.current, {
                isDrawingMode: true,
            });
            fabricRef.current.freeDrawingBrush.width = 10;
            fabricRef.current.freeDrawingBrush.color = '0047FF';
            fabricRef.current.freeDrawingBrush.color = 'white';
            var eraser = new fabric.PencilBrush(fabricRef.current);
            eraser.color = '#fff';
            fabricRef.current.freeDrawingBrush = eraser

            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(
                widthPoint
            )}) 5 5, crosshair`;
            fabricRef.current.freeDrawingBrush.width = 10;
            fabricRef.current.on("object:added", function () {
                if (!isRedoing) {
                    h = [];
                }
                isRedoing = false;
            });
        }
    }, [canvasRef, fabricRef, canvasSizeData]);

    const handleResize = useCallback(async () => {

        if (!imageCropData.imageUrl) {
            return;
        }
        const elementCanvas = document.getElementsByClassName("pre-processing-left");
        if (!elementCanvas) return;
        const { w, h } = resizeImage(imageCropData.width, imageCropData.height);
        if (w && h) {
            const imgScale2 = resizeCanvasFitScreen(
                elementCanvas[0]?.clientWidth,
                elementCanvas[0]?.clientHeight,
                w,
                h
            );
            if (imgScale2) {
                fabricRef.current?.setHeight(elementCanvas[0]?.clientHeight);
                fabricRef.current?.setWidth(elementCanvas[0]?.clientWidth);
                setCanvasSizeData({
                    widthCanvas: elementCanvas[0]?.clientWidth,
                    heightCanvas: elementCanvas[0]?.clientHeight,
                    scaleX: imgScale2.sX,
                    scaleY: imgScale2.sY,
                });
                setMultiplier(imgScale2.multiplier && imgScale2.multiplier);
            }
        }
    }, [imageCropData.imageUrl])

    const handleUndo = () => {
        if (fabricRef.current && fabricRef.current._objects.length > 0) {
            h.push(fabricRef.current && fabricRef.current._objects.pop());
            fabricRef.current && fabricRef.current.renderAll();
        }
    }

    const handleRedo = () => {
        if (h.length > 0) {
            isRedoing = true;
            fabricRef.current && fabricRef.current.add(h.pop());
        }
    }

    const handleReset = () => {
        isRedoing = true
        h = [];
        if (fabricRef.current) {
            fabricRef.current._objects = []
            fabricRef.current && fabricRef.current.renderAll();
            fabricRef.current.setViewportTransform([1, 0, 0, 1, 0, 0]);
        }
    }

    const handleMinus = (e: any) => {
        const valueInput = widthPoint - 1
        setWidthPoint(valueInput)
    }

    const handlePlus = (e: any) => {
        const valueInput = widthPoint + 1
        setWidthPoint(valueInput)
    }

    const inputOpacityPoint = (e: any) => {
        setWidthPoint(e)
    }

    const onChangeZoomPlus = (e: number) => {
        canvas_ZoomPlus(e / 100)
        setValueZoom(e)
    }

    const canvas_ZoomPlus = (scaleZoom: number) => {
        if (fabricRef.current) {
            const zoomCenter = new fabric.Point(200.0, 200.0);
            fabricRef.current.zoomToPoint(zoomCenter, scaleZoom);
        }
    };

    const handleContextMenu = (event: any) => {
        if (event.button === 2) {
            disablePanning()
            setRightMouseDown(true);
            event.preventDefault();
        }
    };

    const disablePanning = () => {
        if (fabricRef.current) {
            fabricRef.current.isDrawingMode = true;
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(widthPoint)}) ${widthPoint / 2} ${widthPoint / 2}, crosshair`
            fabricRef.current.off('mouse:move');
        }
    }

    const handleMouseDown = (event: any) => {
        if (event.button != 2) return
        if (isRightMouseDown && fabricRef.current) {
            fabricRef.current.freeDrawingCursor = `grab`
            setDragging(true);
        }
    };

    const handleMouseMove = (event: any) => {
        if (isDragging && isRightMouseDown && fabricRef.current) {
            fabricRef.current.freeDrawingCursor = `grab`
            const delta = new fabric.Point(event.movementX, event.movementY);
            fabricRef.current.relativePan(delta);
        }
    };

    const handleMouseUp = () => {
        if (isDragging && fabricRef.current) {
            setDragging(false);
            modeMove === 'move' && setModeMove('brush')
            fabricRef.current.isDrawingMode = true;
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(widthPoint)}) ${widthPoint / 2} ${widthPoint / 2}, crosshair`
        }
        setRightMouseDown(false);
    };

    const onChangeSwitch = (value: string) => {
        const checked = value === 'move';
        setModeMove(value);
        if (fabricRef.current && checked) {
            fabricRef.current.freeDrawingCursor = 'grab'

            fabricRef.current.selection = false;
            fabricRef.current.forEachObject(function (object) {
                object.selectable = false;
                object.set({ hasBorders: false, hasControls: false });
            });
            fabricRef.current.isDrawingMode = false;
            fabricRef.current.hoverCursor = 'move';
            fabricRef.current.renderAll();
            fabricRef.current.forEachObject(function (object) {
                object.selectable = false;
            });
            fabricRef.current.on('mouse:up', function (e) {
                panning = false;
            });

            fabricRef.current.on('mouse:down', function (e) {
                panning = true;
            });

            let previousTouch: any;
            checked && fabricRef.current.on('mouse:move', function (e: any) {

                if (checked && panning && e && e.e && fabricRef.current) {
                    if (e.e.type === 'mousemove') {
                        const delta = new fabric.Point(e.e.movementX, e.e.movementY);
                        fabricRef.current.relativePan(delta);
                    }
                    if (e.e.type === 'touchmove' && panning) {
                        const touch = e.e?.touches[0];
                        if (previousTouch) {
                            const mX = touch.pageX - previousTouch?.pageX;
                            const mY = touch.pageY - previousTouch?.pageY;
                            const delta = new fabric.Point(mX, mY);
                            fabricRef.current.relativePan(delta);
                        }
                        previousTouch = touch;
                    }
                }
            });
        }
        if (!checked && fabricRef.current) {
            disablePanning()
        }
    };

    const onApplyRemove = () => {
        fabricRef.current && fabricRef.current.setViewportTransform([1, 0, 0, 1, 0, 0]);
        let imgScale = resizeImage(imageCropData.width, imageCropData.height);
        const img = fabricRef.current && fabricRef.current.toDataURL(
            {
                format: 'png',
                multiplier: imgScale.multiplier
            }
        ) as any;
        const payload = {
            width: imageCropData.width,
            height: imageCropData.height,
            imageUrl: img
        }
        dispatch(toolEditorImage.setSizeImage({
            width: imageCropData.width,
            height: imageCropData.height,
        }))
        dispatch(toolEditorImage.setImageInput(img))
        dispatch(editorImage.setImageEditor(payload))
        // setTabActive('resize')
    }

    const onConfirmEditorInComponent = () => {
        onApplyRemove();
        fabricRef.current && fabricRef.current.setViewportTransform([1, 0, 0, 1, 0, 0]);
        let imgScale = resizeImage(imageCropData.width, imageCropData.height);
        const img = fabricRef.current && fabricRef.current.toDataURL(
            {
                format: 'png',
                multiplier: imgScale.multiplier
            }
        ) as any;
        const payload = {
            width: imageCropData.width,
            height: imageCropData.height,
            imageUrl: img
        }
        onConfirmEditor(payload)

    }

    useEffect(() => {
        if (typeInput === TypeEditor.POST || typeInput === TypeEditor.UPLOAD) {
            const imageCropData2 = {
                ...imageCropData,
                imageUrl: imageTool.inputImage,
                height: imageTool.heightImage,
                width: imageTool.widthImage,
            }
            imageCropData = imageCropData2
        }
        return () => {
            if (fabricRef && fabricRef.current && fabricRef.current._objects.length > 0) {
                onApplyRemove()
            }
        }
    }, [typeInput, imageTool])

    useEffect(() => {
        if (openModalAdvanced) {
            (async () => {
                if (fabricRef.current) {
                    handleReset();
                    fabricRef.current.isDrawingMode = true;
                    fabricRef.current.freeDrawingBrush.color = '0047FF';
                    fabricRef.current.freeDrawingBrush.color = 'white';
                    var eraser = new fabric.PencilBrush(fabricRef.current);
                    eraser.color = '#fff';
                    fabricRef.current.freeDrawingBrush = eraser
                    fabricRef.current.freeDrawingBrush.width = 10
                    // modeM === 'brush' && fabricRef.current.on('mouse:up', onApplyRemove)
                }
                if (!fabricRef.current) {
                    initFabric();
                }

                await setBg(imageCropData.imageUrl, imageCropData.width, imageCropData.height);

            })();
        }
    }, [canvasRef, openModalAdvanced, imageCropData.imageUrl, dispatch, imageTool]);

    useEffect(() => {
        if (fabricRef.current) {
            fabricRef.current.freeDrawingBrush.width = widthPoint;
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(widthPoint)}) ${widthPoint / 2} ${widthPoint / 2}, crosshair`
        }
    }, [widthPoint])
    return (
        <div >
            <div className={openModalAdvanced && tabActive === 'remove' ? 'flex flex-col gap-2' : 'hidden'} >
                <Heading title={t('remove_image')} onSave={onClickSave} onClickBackToHome={onClickBackToHome} />
                <div className='flex flex-row gap-4 justify-between  sm:items-center md:items-center lg:items-center min-w-[80vw]  w-full sm:flex-col md:flex-col lg:flex-col '>
                    <div className='flex gap-2  ml-10 justify-between box-button-pre flex-col min-w-[350px]  min-h-[300px] sm:w-full md:w-full lg:full xl:flex-col'>
                        <div>
                            {/* <div className='text-lg font-semibold text-left'>{t('remove_image')}</div> */}
                            <div className='sub-title text-base  font-semibold text-left'>{t('action')}</div>
                            <div >
                                <div className='container-button flex justify-between mt-3 w-full'>
                                    <div className=' flex gap-2'>
                                        <div className='undo hover:cursor-pointer' onClick={handleUndo}>
                                            <Tooltip title={t('undo')} placement={'bottom'}>
                                                <img src={undo} alt="" />
                                            </Tooltip>
                                        </div>
                                        <div className='redo hover:cursor-pointer' onClick={handleRedo}>
                                            <Tooltip title={t('redo')} placement={'bottom'}>
                                                <img src={redo} alt="" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className='reset flex gap-2 justify-center items-center'>
                                        <div className='hover:cursor-pointer' onClick={handleReset}>
                                            <Tooltip title={t('Reset')} placement={'bottom'}>
                                                <img src={reset} alt="" />
                                            </Tooltip>
                                        </div>
                                        <div>{t('Reset')}</div>
                                    </div>
                                </div>
                                <div className='flex w-full flex-col mt-3 gap-4'>
                                    <div className='flex gap-2 '>
                                        <div className='flex flex-col gap-2'>
                                            <div className='text-left'>{t('size_brush')}:</div>
                                            <div className='flex gap-2'>

                                                <Button
                                                    shape="circle"
                                                    type="default"
                                                    icon={<MinusOutlined />}
                                                    onClick={e => handleMinus(e)}
                                                />
                                                <Slider min={1} max={100} value={widthPoint} onChange={e => inputOpacityPoint(e)} className='w-full min-w-[200px] max-w-[350px]' />
                                                <Button
                                                    shape="circle"
                                                    type="default"
                                                    icon={<PlusOutlined />}
                                                    onClick={e => handlePlus(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex gap-2 '>
                                        <div className='flex flex-col gap-2'>
                                            <div className='text-left'>{t('zoom_image')}:</div>
                                            <div className='flex gap-2 justify-center items-center'>
                                                <p >100%</p>
                                                <Slider min={100} max={300} defaultValue={100} value={valueZoom} onChange={onChangeZoomPlus} className='w-full min-w-[200px] max-w-[350px]' />
                                                <p>300%</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <hr className="bg-[#F5F5F5] h-[1px] border-none mt-2" />
                            <div className='sub-title text-base  font-semibold text-left mt-4'>{t('guide')}</div>
                            <div className="mt-3 w-full text-left text-gray text-guide box-guide-tool">
                                {t('text_guide_remove_area')}
                            </div>
                        </div>
                        <div>
                            <div className={`btn-confirm mt-4 flex justify-center items-center w-full ${(typeInput === TypeEditor.PRE) ? ' ' : ' !hidden'}`}>
                                <Button onClick={onConfirmEditorInComponent} type='primary' disabled={isLoading} className='w-full ' >{t('done')}</Button>
                            </div>
                        </div>
                    </div>
                    <div className='pre-processing-left  relative box-canvas-pre  bg-[#CCD9FF] !w-[65vw] w-full min-h-3/4 flex justify-center items-center min-h-[75vh]  ' onContextMenu={(e) => handleContextMenu(e)} onMouseDown={(e) => handleMouseDown(e)} onMouseMove={(e) => handleMouseMove(e)} onMouseUp={handleMouseUp}>
                        <canvas id="canvas" ref={canvasRef} className='w-auto h-auto '  ></canvas>
                        <div className={'absolute bottom-0 right-0 opacity-50'}>
                            <Radio.Group style={{ width: '100%' }} buttonStyle='solid' value={modeMove} onChange={(e) => onChangeSwitch(e.target.value)}>
                                <Radio.Button value="brush" ><HighlightOutlined /> </Radio.Button>
                                <Radio.Button value="move" ><DragOutlined /></Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Advanced