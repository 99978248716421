import React from 'react'
import save from '../../../assets/icons/saveBlack.svg';
import { useTranslation } from 'react-i18next';
import back from '../../../assets/icons/back.svg';
type HeadingProps = {
    title: string;
    onSave: () => void;
    onClickBackToHome: () => void;

}
function Heading({ title, onSave, onClickBackToHome }: HeadingProps) {
    const { t } = useTranslation();
    return (
        <div className='flex justify-between ml-10 w-[98%]' >
            <div className='text-lg font-semibold text-left'>{title}</div>
            <div className='flex gap-2 justify-center items-center'>
                <div onClick={onClickBackToHome} className='
            flex items-center gap-2 border border-solid border-[#667085]
             text-[#667085] rounded-2xl p-2 font-semibold hover:cursor-pointer'>
                    <img src={back} className={'w-[20px] h-[20px]'} />
                    {t('back_to_home')}
                </div>
                <div onClick={onSave} className='
            flex items-center gap-2 border border-solid border-[#667085]
             text-[#667085] rounded-2xl p-2 font-semibold hover:cursor-pointer' >
                    <img src={save} className={'w-[20px] h-[20px]'} />
                    {t('save_photo')}</div>
            </div>
        </div>
    )
}

export default Heading