// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-container {
  display: flex;
  justify-content: center;
  width: 400px;
  border-radius: 8px;
}

.switch-container input[type="radio"] {
  display: none;
}

.switch-container label {
  padding: 5px;
  text-align: center;
  background-color: #F5F5F5;
  cursor: pointer;
  width: 33%;
  max-height: 100px;
  transition: background-color 0.5s, color 0.5s, border-radius 0.5s;
}

.switch-container label:nth-child(2) {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.switch-container label:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.switch-container input[type="radio"]:checked+label {
  color: #ffffff !important;
}

.switch-container input[type="radio"]:checked+label {
  background-color: #0047FF;
  border-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.switch-container input[type="radio"]:checked+label div p {
  color: #ffffff !important;
}

.switch-container .toggle-label {
  padding: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ModalRequiredSub/ToggleType/styles.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,UAAU;EACV,iBAAiB;EACjB,iEAAiE;AACnE;;AAEA;EACE,2BAA2B;EAC3B,8BAA8B;AAChC;;AAEA;EACE,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EAEE,yBAAyB;AAI3B;;AANA;EACE,yBAAyB;EAEzB,kBAAkB;EAClB,4BAA4B;EAC5B,+BAA+B;AACjC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".switch-container {\n  display: flex;\n  justify-content: center;\n  width: 400px;\n  border-radius: 8px;\n}\n\n.switch-container input[type=\"radio\"] {\n  display: none;\n}\n\n.switch-container label {\n  padding: 5px;\n  text-align: center;\n  background-color: #F5F5F5;\n  cursor: pointer;\n  width: 33%;\n  max-height: 100px;\n  transition: background-color 0.5s, color 0.5s, border-radius 0.5s;\n}\n\n.switch-container label:nth-child(2) {\n  border-top-left-radius: 8px;\n  border-bottom-left-radius: 8px;\n}\n\n.switch-container label:last-child {\n  border-top-right-radius: 8px;\n  border-bottom-right-radius: 8px;\n}\n\n.switch-container input[type=\"radio\"]:checked+label {\n  background-color: #0047FF;\n  color: #ffffff !important;\n  border-radius: 8px;\n  border-top-right-radius: 8px;\n  border-bottom-right-radius: 8px;\n}\n\n.switch-container input[type=\"radio\"]:checked+label div p {\n  color: #ffffff !important;\n}\n\n.switch-container .toggle-label {\n  padding: 10px 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
