import React, { useEffect, useState } from 'react'
import { IInputSetting } from '../VTOUploadModel'
import { Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
type InputLinkOutfitProps = {
    setInputSetting: Function,
    inputSetting: IInputSetting,
    value?: string
}
function InputLinkProducts({ setInputSetting, inputSetting, value }: InputLinkOutfitProps) {
    const [valueLink, setValueLink] = useState(value)
    const { t } = useTranslation()
    const onChangValueLink = (value: string) => {
        setValueLink(value)
    }
    const [form] = Form.useForm();

    useEffect(() => {
        if (value) {
            form.setFieldsValue({
                productLink: value
            })
            setValueLink(value)
        }
    }, [value])
    useEffect(() => {

        if (valueLink !== '') {
            const _ = {
                ...inputSetting,
                productLink: valueLink
            }
            setInputSetting(_)
        } else {
            const _ = {
                ...inputSetting,
                productLink: ''
            }
            setInputSetting(_)
        }

    }, [valueLink])
    return (
        <div>
            <label className='text-[#101828] font-semibold text-sm flex flex-row gap-1' htmlFor="link_prod">{t('link_product')}</label>
            <Form
                form={form}
                name="urlForm"
                scrollToFirstError
            >
                <Form.Item
                    className='!mb-1'
                    name="productLink"
                    hasFeedback
                    normalize={(value, prevVal, prevVals) => value.trim()}
                    rules={[
                        {
                            validator: (_, value) => {
                                if (!value || value.trim() === '') {
                                    return Promise.resolve();
                                }
                                const pattern = new RegExp("(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})");
                                if (pattern.test(value)) {//check format
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(t('format_wrong')));
                            },
                        },
                    ]}
                >

                    <Input className='mt-2 h-10 rounded-lg' id="link_prod" placeholder={t(' https://modeli.ai/products/1')} required allowClear value={valueLink} onChange={(e) => onChangValueLink(e.target.value)} />
                </Form.Item>
            </Form>
        </div>
    )
}

export default InputLinkProducts