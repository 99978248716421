import React, { useEffect, useState } from 'react';
import signUpGuide from '../../assets/images/guide/signup.png';
import loginGuide from '../../assets/images/guide/login.png';
import paymentGuide from '../../assets/images/guide/payment.png';
import vnpayGuide from '../../assets/images/guide/vnpay-method.png';
import checkoutGuide from '../../assets/images/guide/checkout.png';
import gen from '../../assets/images/guide/quy-doi-1.png';
import upscale from '../../assets/images/guide/quy-doi-2.png';
import rmwm from '../../assets/images/guide/quy-doi-3.png';
import upscale2 from '../../assets/images/guide/quy-doi-4.png';
import regen from '../../assets/images/guide/quy-doi-5.png';
import swap from '../../assets/images/guide/quy-doi-6.png';
import smoothBorder from '../../assets/images/guide/quy-doi-7.png';
import './styles.css'
import { BackTop, Button } from 'antd';
import Guide from '../Guide/Guide';
import LanguageSwitch from '../../components/LanguageSwitch/LanguageSwitch';
import { useNavigate } from 'react-router-dom';
type PaymentGuideProps = {
  setOpenPaymentModal: Function
}
function PaymentGuideVN({ setOpenPaymentModal }: PaymentGuideProps) {
  const [isPlayVideo, setIsPlayVideo] = useState(false)
  const navigate = useNavigate()

  const Registration = () => {
    return (
      <section className="registration pl-20 pr-20" id='sigup'>
        <h2 className='mb-2'>1. Registration</h2>
        <div className='flex justify-between w-[90%] sm:flex-col md:flex-col lg:flex-col'>
          <div className='flex gap-2 flex-col'>
            <p className='mb-1 w-full'>
              <div className='text-left'>
                <strong>- Step 1:</strong> Visit the website  <a href="https://beta.modeli.ai/login">https://beta.modeli.ai/login</a>.
              </div>
              <div className='flex w-full gap-2 justify-around flex-wrap mt-2'>
                <div className='text-left ml-3'>
                  Fill in all the necessary information:
                  <br />
                  - Email
                  <br />
                  - Password
                  <br />
                  - Phone number
                  <br />
                  - Brand name
                  <br />
                  - Website URL
                  <br />
                  - Shop size
                  <br />
                  - Product category
                  <br />
                  - Business duration
                  <br />

                </div>
              </div>
            </p>
            <p className='mb-1 text-left'><strong>- Step 2:</strong> Click on the [Register] button</p>
          </div>
          <div>
            <img style={{
              width: '100%'
            }} src={signUpGuide} alt="Registration Screenshot" />
          </div>
        </div>
      </section>
    );
  }

  const Login = () => {
    return (
      <section className="login mt-6 pl-20 pr-20" id='login'>
        <div className='flex text-left justify-between w-[90%]'>
          <div>
            <h2 className='mb-2'>2. Log in</h2>
            <h3 className='mb-1'>a/ Log in with email and password</h3>
            <p className='mb-1'><strong>- Step 1:</strong> Go to the website  <a href="https://beta.modeli.ai/login">https://beta.modeli.ai/login</a>. Log in with above information.</p>
            <p className='mb-1'><strong>- Bước 2:</strong> Click button [Đăng nhập] để vào app Modeli.</p>
            <h3 className='mb-1'>b/ Log in with Google</h3>
          </div>
          <div>
            <img src={loginGuide} style={{
              width: '100%'
            }} alt="Login Screenshot" />
          </div>
        </div>
      </section>
    );
  }

  const Payment = () => {
    return (
      <section className="payment mt-6 pl-20 pr-20 " id='payment'>

        <h2 className='mb-2'>3. Payment</h2>
        <p className='mb-1'>You can use the Stripe or VNpay payment gateway.</p>
        <p className='mb-1'><strong>- Step 1:</strong> Open the link <a href="https://beta.modeli.ai">https://beta.modeli.ai</a>.</p>
        <p className='mb-1'><strong>- Step 2:</strong> Click on the “Credit button” in the top right corner. Or Click on the [Profile] button in the top right corner. A popup will appear displaying payment packages as shown in the image:</p>
        <img src={paymentGuide} alt="Payment Options" />
        <p className='mb-1'><strong>- Step 3:</strong>  Choose a package.
          <br />
          There are 2 packages to choose from: Basic (Choose monthly/quarterly/yearly payment) or Top up (Additional payment)
        </p>
        <p className='mb-1'><strong>- Step 4:</strong>Select the payment gateway</p>
        <ul className='text-left ml-8 '>
          <li> Select payment via VNpay gateway: Click on the [VNpay] button in the payment popup</li>
          <li className='mb-1'> Select payment via Stripe gateway: • Click on the [Stripe] button in the payment popup.</li>
        </ul>
        <p className='mb-1'><strong>- Bước 5:</strong> Check-out your order:</p>
        <img src={checkoutGuide} alt="Checkout" />
        <p className='mb-1'><strong>- Bước 6:</strong> Payment: Click a button [Payment]</p>
        <p className='mb-1'><strong>- Bươc 7:</strong> Redirect to the payment page: You will make the payment, enter all the required information to purchase credits</p>
        <img src={vnpayGuide} alt="Payment Process" />
      </section>
    );
  }

  const CreditsConversion = () => {
    return (
      <section className="credits-conversion mt-6 pl-20 pr-20 " id='conversion'>
        <h2 className='mb-2'>4. Converting Credits</h2>
        <h3 className='mb-2'>4.1 Conversion when doing [Generate] images:</h3>
        <ul className='text-left mb-1'>
          <li><p>Consume 3 Credits</p></li>
          <li><p>One generation session can produce from 1 to 4 images. Therefore, the minimum number of Credits required for one generation session is 3 credits, and the maximum is 12 Credits.</p></li>
        </ul>

        <img src={gen} alt="Generate" className='img-4 mb-1' />

        <h3 className='mb-2 mt-1'>4.2 Credit Conversion when Image Processing (for better input):</h3>
        <ul className='text-left mb-1'>
          <li className='flex flex-col w-full line-clamp-1'>
            <p className=' mb-2'> 
              a/ Upscale: For images with small sizes (smaller than 768 x 1024), low quality
              <br />
              Consume 3 credits.
            </p>
            <img src={upscale} alt="Preprocessing" className='img-4 mb-1' />
          </li>

          <li className='flex flex-col w-full'>
            <p className=' mb-2 mt-1'>
              b/ Remove Watermark: For images with watermarks (text, images, or transparent logos overlaid on the image) that need to be removed.
              <br /> Consume 3 credits
            </p>
            <img src={rmwm} alt="Preprocessing" className='img-4 mb-1' />
          </li>
        </ul>
        <h3 className='mb-2 mt-1'>4.3 Credit Conversion for Editting: to improve the quality of generated images:</h3>
        <div className="image-processing-item text-left w-full">
          <h3 className='mb-2'>a/ Increase Size:</h3>
          <p className='mb-1'>In case users want to increase the size of the output image.</p>
          <p className='mb-1'><strong>• Connsume 3 Credits.</strong></p>
          <div className='flex'>
            <img src={upscale2} alt="Resize" className='img-4 mb-1' />
          </div>
        </div>
        <div className="image-processing-item text-left mt-1">
          <h3 className='mb-2'> b/ Regenerate by Region:</h3>
          <p className='mb-1'>For images with excessive details, unexpected objects in the background, or distorted limbs that need improvement, we utilize the function to regenerate by region for better results</p>
          <p className='mb-1'><strong>Consume 3 Credits.</strong></p>
          <div className='flex'>
            <img src={regen} alt="Reprocess" className='img-4 mb-1' />
          </div>
        </div>
        <div className="image-processing-item text-left">
          <h3 className='mb-2'>c/ Swapface:</h3>
          <p className='mb-1'>With the generated result, you can swap the face of the model in the image with the face of another model.</p>
          <p className='mb-1'><strong>• Consume 5 Credits</strong></p>
          <div className='flex'>
            <img src={swap} alt="Swapface" className='img-4' />
          </div>
        </div>
        <div className="image-processing-item text-left mt-1">
          <h3 className='mb-2'>d/ Smooth Edges:</h3>
          <p className='mb-1'>In case the generated result has rough edges on clothes or jagged teeth, you use the smooth edges function to achieve a better outcome</p>
          <p className='mb-1'><strong>Consume 3 credits.</strong></p>
          <div className='flex'>
            <img src={smoothBorder} alt="Smooth Edges" className='img-4' />
          </div>
        </div>
      </section>
    );
  }
  const onClickGoToSection = (sectionId: string) => {
    let element = document.getElementById(sectionId);
    element && element.scrollIntoView({ behavior: "smooth" });
    element = null
  }

  useEffect(() => {
    setOpenPaymentModal(false)
  }, [])

  return (
    <div className='container-guide-payment '>
      <div className='flex justify-between '>
        <div className='list-btn-link'>
          <Button type="primary" block onClick={() => { navigate('/') }} className='cursor-pointer hover:underline'>
            Back to Home
          </Button>
        </div>
        <div className='list-btn-link flex flex-row  gap-2 justify-center items-center mt-4 w-1/2  sm:flex-col md:flex-col'>
          <Button type="text" block onClick={() => { onClickGoToSection('sigup') }} className=' cursor-pointer hover:underline'>
           Sign up
          </Button>
          |
          <Button type="text" block onClick={() => { onClickGoToSection('login') }} className=' cursor-pointer hover:underline'>
           Sign in
          </Button>
          |
          <Button type="text" block onClick={() => { onClickGoToSection('payment') }} className='cursor-pointer hover:underline'>
            Payment
          </Button>
          |
          <Button type="text" block onClick={() => { onClickGoToSection('conversion') }} className=' cursor-pointer hover:underline'>
          Converting
          </Button>
          |
          <Button type="text" block onClick={() => { onClickGoToSection('video-guide'); setIsPlayVideo(true) }} className='cursor-pointer hover:underline'>
          Tutorial Video
          </Button>
          |
          <LanguageSwitch styles={{ marginRight: 8 }} />
        </div>
      </div>
      <Registration />
      <Login />
      <Payment />
      <CreditsConversion />
      <BackTop />
      <section className="video-guidemt-6 pl-20 pr-20 " id='video-guide'>
        <h2 className='mb-2 text-left'>5. Tutorial video</h2>
        <Guide isPlayVideo={isPlayVideo} />
      </section>
    </div>
  );
}

export default PaymentGuideVN;
