import { TypeEditor } from "./../../utils/enum";
import { createSlice } from "@reduxjs/toolkit";

interface IToolEditor {
  inputImage: string;
  outputImage: string;
  typeEdit: TypeEditor;
  widthImage: number;
  heightImage: number;
  samId?: string;
  imageId?: string;
  typeImageOutput?: string;
  isLoadingSegment?: boolean;
}

const initialState: IToolEditor = {
  inputImage: "",
  outputImage: "",
  typeEdit: TypeEditor.UPLOAD,
  widthImage: 0,
  heightImage: 0,
  samId: "",
  imageId: "",
  typeImageOutput: "",
  isLoadingSegment: false,
};

const toolEditor = createSlice({
  name: "setToolEditor",
  initialState,
  reducers: {
    setImageInput: (state, action) => {
      state.inputImage = action.payload;
    },
    setImageOutput: (state, action) => {
      state.outputImage = action.payload;
    },
    setSizeImage: (state, action) => {
      state.widthImage = action.payload.width;
      state.heightImage = action.payload.height;
    },
    setToolEditorState: (state, action) => {
      state.inputImage = action.payload.inputImage;
      state.typeEdit = action.payload.typeEdit;
      state.outputImage = action.payload.outputImage;
    },
    setTypeEdit: (state, action) => {
      state.typeEdit = action.payload;
    },
    setSamID: (state, action) => {
      state.samId = action.payload;
    },
    setTypeOutputOfPost: (state, action) => {
      state.typeImageOutput = action.payload;
    },
    setImageId: (state, action) => {
      state.imageId = action.payload;
    },
    setIsLoadingSegment: (state, action) => {
      state.isLoadingSegment = action.payload;
    },
    resetImageEditor: (state) => {
      state.inputImage = "";
      state.typeEdit = TypeEditor.UPLOAD;
      state.outputImage = "";
      state.widthImage = 0;
      state.heightImage = 0;
      state.typeImageOutput = "";
      state.imageId = '';
      state.samId = ''
    },
  },
});

export const toolEditorImage = toolEditor.actions;
export default toolEditor.reducer;
