import { logEvent } from "@firebase/analytics";
import axios from "axios";
import analytics from "../firebase";
import { customizeKey } from "../utils/function";
import { httpDelete, httpGet, httpPost } from "./http.service";

export default class AccountService {
  private static instance: AccountService;
  private constructor() {}
  public static getInstance() {
    if (!this.instance) {
      this.instance = new AccountService();
    }
    return this.instance;
  }
  async login(email: string, password: string, language: string) {
    const { data, status } = await httpPost("/account/login", {
      email,
      password,
      language,
    });
    return { data, status };
  }

  async signUp(
    email: string,
    password: string,
    invitationCode: string,
    language: string,
    shopSize: string,
    shopCategory: string,
    shopInfo: string,
    phoneNumber: string,
    brandName: string,
    yearInOperation: string,
    roleInOrganization: string,
    source: string,
    infoLocation?: string
  ) {
    const { data, status } = await httpPost("/account/register", {
      email,
      password,
      invitationCode,
      language,
      shopSize,
      shopCategory,
      shopInfo,
      phoneNumber,
      brandName,
      yearInOperation,
      roleInOrganization,
      source,
      infoLocation
    });
    return { data, status };
  }
  async signUpSurvey(dataInput: any) {
    const data = await axios.post(
      "https://sheet.best/api/sheets/bee1b786-3428-40e7-a065-e49fa2e6acaa",
      dataInput
    );
    return data;
  }
  async FBSurvey(dataInput: any) {
    const data = await axios.post(
      "https://sheet.best/api/sheets/bee1b786-3428-40e7-a065-e49fa2e6acaa",
      dataInput
    );
    return data;
  }
  async bonusCreditsAfterSubmit() {
    const data = await httpPost("/account/feedback", {});
    return data;
  }

  async verifyEmail(email: string, verifyCode: string) {
    const { data } = await httpPost("/account/verify-mail", {
      email,
      verifyCode,
    });
    return data;
  }

  async sendCode(email: string) {
    const { data } = await httpPost("/account/send-code", { email });
    return data.data;
  }

  async getUserInfo() {
    try {
      const { data } = await httpGet("/account/user-info");
      return data.data;
    } catch (error: any) {
      logEvent(analytics, `server_error_${customizeKey(error.message)}`);
      throw error;
    }
  }
  // async getCredits() {
  //   try {
  //     const { data } = await httpGet("/account/credit");
  //     return data.data;
  //   } catch (error: any) {
  //     logEvent(analytics, `server_error_${customizeKey(error.message)}`);
  //     throw error;
  //   }
  // }

  async fillIno(
    shopSize: string,
    shopCategory: string,
    phoneNumber: string,
    shopInfo: string,
    brandName: string,
    yearInOperation: string,
    roleInOrganization: string,
    lang: string,
    source: string,
    infoLocation?: string
  ) {
    try {
      const { data, status } = await httpPost("/account/fill-info", {
        shopSize,
        shopCategory,
        phoneNumber,
        shopInfo,
        brandName,
        yearInOperation,
        roleInOrganization,
        lang,
        source,
        infoLocation
      });
      return { data, status };
    } catch (error: any) {
      logEvent(analytics, `server_error_${customizeKey(error.message)}`);
      throw error;
    }
  }
  //https://geolocation-db.com/documentation
  // async checkIpUserSignUp() {
  //   try {
  //     const response = await fetch("https://api.ipify.org?format=json");
  //     const { ip } = await response.json();
  //     const { data } = await axios.get(`http://ip-api.com/json/${ip}`);
  //     return data;
  //   } catch (error) {
  //     console.log("error:", error);
  //   }
  // }
  // async editUsername(username: string) {
  //     try {
  //         const { data } = await httpPut("/account/user", { username });
  //         return data.data;
  //     } catch (error: any) {
  //         NotifyController.error(error.message)
  //         throw error;
  //     }
  // }

  async forgotPassword(email: string) {
    const { data } = await httpPost("/account/forgot-password", { email });
    return data.data;
  }

  async verifyForgotPassword(email: string, verifyCode: string) {
    const { data } = await httpPost("/account/verify-forgot-password", {
      email,
      verifyCode,
    });
    return data;
  }

  async resetPassword(email: string, password: string) {
    const { data } = await httpPost("/account/forgot-password-reset", {
      email,
      password,
    });
    return data;
  }

  async changePassword(formData: {
    oldPassword: string;
    newPassword: string;
    verifyNewPassword: string;
    language: string;
  }) {
    const { data } = await httpPost("/account/change-password", formData);
    return data;
  }

  async getListUserAPIKeys() {
    const { data } = await httpGet("/api/v2/api-keys");
    return data;
  }

  async getAPIKeyMetadata() {
    const { data } = await httpGet("/api/v2/api-keys/metadata");
    return data;
  }

  async createAPIKey({
    permissions,
    expiration,
    alias,
  }: {
    permissions: string[];
    expiration: string;
    alias?: string;
  }) {
    const { data } = await httpPost("/api/v2/api-keys", {
      permissions,
      expiration,
      alias,
    });
    return data;
  }

  async deleteAPIKey(id: string) {
    const { data } = await httpDelete("/api/v2/api-keys", { id });
    return data;
  }
}
