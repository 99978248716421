

import { useTranslation } from "react-i18next";
import BoxStep from "../BoxStep";
import { disableEvent } from "../utils/function";

export const stepsVN = [{
    selector: '.step-upload',
    content: <BoxStep
        title={'Xử lý tách trang phục'}
        srcVideo="https://media2.vision2art.ai/Speed+up/Modeli+1_Final+Speedup.mp4"
        description={'Bóc tách vùng trang phục bạn mong muốn. Bao gồm tùy chỉnh: tự động, thủ công và nâng cao'}
        textButtonMore={'Tìm hiểu thêm'}
    />,
},
{
    selector: '.step-select-model',
    content: <BoxStep
        title={'Tùy chỉnh cho mẫu'}
        srcVideo="https://media2.vision2art.ai/Speed+up/Modeli_2+Speed.mp4"
        description={'Tạo ra đa dạng AI Model về sắc tộc, màu tóc, biểu cảm và phong cách trang điểm'}
        textButtonMore={'Tìm hiểu thêm'}
    />,

},
{
    selector: '.step-select-bg',
    content: <BoxStep
        title={'Tùy chỉnh background và ánh sáng'}
        srcVideo="https://media2.vision2art.ai/Speed+up/Modeli_3+Speed.mp4"
        description={'Chọn phông nền và ánh sáng phù hợp với phối cảnh'}
        textButtonMore={'Tìm hiểu thêm'}
    />
},
{
    selector: '.step-tool',
    content: <BoxStep
        title={'Công cụ chỉnh sửa ảnh'}
        srcVideo="https://media2.vision2art.ai/Speed+up/Modeli_4+Speed.mp4"
        description={'Bộ công cụ xử lý để tối ưu hình ảnh đầu vào'}
        textButtonMore={'Tìm hiểu thêm'}
    />,
    action: () => {
        disableEvent('step-tool')
    }
},
{
    selector: '.step-my-work',
    content: <BoxStep
        title={'Xem lại các kết quả thực hiện của bạn'}
        srcVideo="https://media2.vision2art.ai/Speed+up/Modeli_5+Speed.mp4"
        description={'Xem lại các kết quả thực hiện của bạn'}
        textButtonDone={'Hoàn thành'}
        textButtonMore={'Tìm hiểu thêm'}
    />,
    action: () => {
        disableEvent('step-my-work')
    }
}
    ,]
export const stepsEN = [{
    selector: '.step-upload',
    content: <BoxStep
        title={'Processing garment separation'}
        srcVideo="https://media2.vision2art.ai/Speed+up/Modeli+1_Final+Speedup.mp4"
        description={'Customize the expected clothing areas. Includes options: automatic, manual, and advanced'}
        textButtonMore={'More'}
    />,
},
{
    selector: '.step-select-model',
    content: <BoxStep
        title={'Customization for model'}
        srcVideo="https://media2.vision2art.ai/Speed+up/Modeli_2+Speed.mp4"
        description={'Generate diverse AI models for race, hair color, expressions, and makeup styles'}
        textButtonMore={'More'}
    />,

},
{
    selector: '.step-select-bg',
    content: <BoxStep
        title={'Customize background and lighting'}
        srcVideo="https://media2.vision2art.ai/Speed+up/Modeli_3+Speed.mp4"
        description={'Select appropriate background and lighting to match the scene'}
        textButtonMore={'More'}
    />
},
{
    selector: '.step-tool',
    content: <BoxStep
        title={'Image editing tools'}
        srcVideo="https://media2.vision2art.ai/Speed+up/Modeli_4+Speed.mp4"
        description={'Image editing tools'}
        textButtonMore={'More'}
    />,
    action: () => {
        disableEvent('step-tool')
    }
},
{
    selector: '.step-my-work',
    content: <BoxStep
        title={'Photo library'}
        srcVideo="https://media2.vision2art.ai/Speed+up/Modeli_5+Speed.mp4"
        description={'Review your execution results'}
        textButtonDone={'Got it!'}
        textButtonMore={'More'}
    />,
    action: () => {
        disableEvent('step-my-work')
    }
}
    ,]