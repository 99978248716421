import { CopyOutlined, SaveOutlined } from "@ant-design/icons";
import { Breadcrumb, Carousel, Tooltip, notification } from "antd";
import moment from "moment";
import 'moment/locale/vi';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import prev from '../../../assets/icons/previous.svg';
import refresh from '../../../assets/icons/refresh.svg';
import trash from '../../../assets/icons/trashRed.svg';
import CollapseCustomize from "../../../components/ListRenderedImages/CollapseCustomize";
import { setIsAutoPose } from "../../../redux/app/appAutoPose";
import { setIsAuto } from "../../../redux/app/appAutoSegment";
import { setTypeModel } from "../../../redux/app/selectTypeModel";
import { toolEditorImage } from "../../../redux/app/toolEditor";
import { useAppSelector } from "../../../redux/hooks/useAppSelector";
import FashionService from "../../../services/fashion.service";
import { ROUTERS } from "../../../utils/constant";
import { FashionToolType } from "../../../utils/enum";
import NotifyController from "../../../utils/toast";
import TagNameMyWork from "../List/TagNameMyWork";
import './styles.css';
import { downloadImage } from "../../../utils/function";

const timezone = moment.tz.guess();
const MyWorkDetail = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [image, setImage] = useState<any>({});
    const navigate = useNavigate();

    const fashionParamsReducer = useAppSelector((store) => store.select);
    const userInfo = useAppSelector((store) => store.user);
    const { id } = useParams();
    const isAdmin = useAppSelector((store) => store.user.roles).includes("ADMIN");
    const lang = i18n.language
    moment.locale(lang);

    lang === 'vi' && moment.updateLocale('vi', {
        relativeTime: {
            future: "trong %s",
            past: "%s trước",
            s: 'giây',
            ss: '%d giây',
            m: "1 phút",
            mm: "%d phút",
            h: "1 giờ",
            hh: "%d giờ",
            d: "1 ngày",
            dd: "%d ngày",
            M: "1 tháng",
            MM: "%d tháng",
            y: "1 năm",
            yy: "%d năm"
        }
    });
    const onDelete = (id: string) => {
        swal({
            title: t('be_careful'),
            text: t('want_to_delete'),
            icon: 'warning',
            dangerMode: true,
            buttons: {
                cancel: {
                    text: t('cancel'),
                    value: null,
                    visible: true,
                    closeModal: true,
                },
                confirm: {
                    text: t('delete'),
                    value: true,
                    visible: true,
                    closeModal: true,
                },
            },
        }).then(async (result) => {
            if (result) {
                await FashionService.getInstance().deleteImage(id);
                swal(t('delete_successfully'), '', 'success').then(() => {
                    navigate(ROUTERS.MY_WORK);
                });
            }
        });
    };

    const onCopySetting = async (image: any) => {
        // dispatch(setLoadingApp.setLoading(true))
        dispatch(toolEditorImage.resetImageEditor())
        dispatch(toolEditorImage.setIsLoadingSegment(false))
        let res;
        if (image.samSpecialId) res = await FashionService.getInstance().getSegmentDetail(image.samSpecialId);
        if (!image.poseImage || !image.poseData || image.autoPose === undefined) {
            const body = { image: image.originImageUrl, width: image.width, height: image.height, poseId: image.poseId }
            const dataPose = await FashionService.getInstance().getDataPose(body);
            if (dataPose.images[0].startsWith('data:image')) {
                // If it already contains base64 data, assign it
                image.poseImage = dataPose.images[0];
            } else {
                // If it doesn't contain base64 data, append it
                image.poseImage = 'data:image/png;base64,' + dataPose.images[0];
            }
            image.poseData = dataPose.poses[0].people[0].pose_keypoints_2d;
            image.autoPose = true;
        }
        dispatch(setTypeModel.setTypeModel(image.clothesCode));
        dispatch(setTypeModel.setTypeName(image.templateType));
        dispatch(setIsAutoPose.setIsAutoPose(image.autoPose))
        dispatch(setTypeModel.setOriginImageUrl(image.originImageUrl));
        dispatch(setTypeModel.setFashionParams({
            templateName: image.templateName,
            templateCode: image.templateCode,
            prompt: image.prompt,
            negativePrompt: image.negativePrompt,
            samplingStep: image.steps,
            denoisingStrength: image.denoisingStrength,
            controlWeight: image.controlWeight,
            selectedItems: image.data,
            isClickCopySetting: !fashionParamsReducer.isClickCopySetting,
            segment: res && res.data.url_v2,
            annotations: res && res.data.annotations,
            originImageUrl: image.originImageUrl,
            samSpecialId: image.samSpecialId,
            numberOfImages: image.url_v2.length || 4,
            poseId: image.poseId,
            poseImage: image.poseImage,
            poseData: image.poseData,
            width: image.width,
            height: image.height,
            backgroundImage: image.backgroundImage,
            BGWeight: image.BGWeight
        }));
        dispatch(setIsAuto.setIsAuto(res && (res.data.autoMask === undefined ? true : res.data.autoMask)));
        // dispatch(setLoadingApp.setLoading(false))
        navigate(ROUTERS.HOME)
    }

    const CustomArrowPrev = ({ onClick }: any) => (
        <button onClick={onClick} className="custom-arrow prev">
            <img src={prev} alt="prev" />
        </button>
    );

    const CustomArrowNext = ({ onClick }: any) => (
        <button onClick={onClick} className="custom-arrow next">
            <img src={prev} alt="Next" className="rotate-180" />
        </button>

    );
    const openNotification = () => {
        NotifyController.success('Copy successfully')
    };
    const handelCopyLink = (link: string) => {
        navigator.clipboard.writeText(link);
        openNotification()
    }

    const handelDownload = (downloadLink: string) => {
        downloadImage(downloadLink)
    }
    const items = [
        {
            key: '1',
            label: t('advanced'),
            children:
                <ul className='mt-[-25px] ml-5 text-sm'>
                    <li style={{ color: '#667085' }}>
                        <span className='text-black'>Prompt:</span> {image.prompt}
                    </li>
                    <li style={{ color: '#667085' }}>
                        <span className='text-black'>Negative prompt:</span> {image.negativePrompt}
                    </li>
                </ul>,
            style: {
                color: 'var(--neutral- black, #101828)',
                fontStyle: 'normal',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                marginLeft: '-15px'
            }
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await FashionService.getInstance().getImage(id!, i18n.language);
                setImage(data);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [i18n.language]);
    return (
        <div >
            <Breadcrumb
                className="detail-breadcrumb"
                items={[
                    {
                        title: <a onClick={() => { navigate(ROUTERS.MY_WORK) }}>{t('your_work')}</a>,
                    },
                    {
                        title: <p>{t('Image_details')}</p>,
                    },
                ]}
            />
            <div className="flex justify-center items-center mt-2">
                <div className="detail-body-section sm:p-10 flex flex-col min-w-[350px] max-w-[350px] sm:max-w-[350px] lg:max-w-[600px]">
                    <div className="detail-image-section  ">
                        <Carousel
                            arrows
                            dotPosition="bottom"
                            nextArrow={<CustomArrowNext />}
                            prevArrow={<CustomArrowPrev />}
                        // showThumbs={false}
                        // showArrows={true}
                        // renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        //     hasPrev && <CustomArrowPrev onClick={onClickHandler} />
                        // }
                        // renderArrowNext={(onClickHandler, hasNext, label) =>
                        //     hasNext && <CustomArrowNext onClick={onClickHandler} />
                        // }
                        >
                            {image?.url_v2?.map((image: any, index: number) =>
                                <div className="img-item detail flex justify-center items-center" key={index}>
                                    <img className="carousel-img item-img-render box-item-img  h-auto w-full" width={'100%'} height={'400px'} src={image} />
                                    <div className="container-btn btn-details">
                                        <CopyOutlined className="icon" onClick={() => handelCopyLink(image)} />
                                        <SaveOutlined className="icon" onClick={() => handelDownload(image)} />
                                    </div>
                                </div>
                            )}
                        </Carousel>
                    </div>
                    <div className="detail-content-section">
                        <div className="detail-content-1st-section px-2 items-center">
                            <Tooltip title={moment.tz(image.created_ts, timezone).locale(i18n.language).format('YYYY-MM-DD HH:mm:ss')}>
                                <div className="font-normal hover:cursor-help">{moment(image.created_ts).fromNow()}</div>
                            </Tooltip>
                            {/* <div className="date-time">{moment(image.created_ts).fromNow()}{moment.tz(image.created_ts, timezone).locale(i18n.language).format('YYYY-MM-DD HH:mm:ss')}</div> */}
                            <div className="flex ml-4 gap-5">
                                {image.type !== FashionToolType.UPSCALE && image.type !== FashionToolType.SWAPFACE && image.type !== FashionToolType.REMOVE_OBJECT && image.type !== FashionToolType.BORDER_SMOOTH && image.type !== FashionToolType.REMOVE_REDUNDANT_DETAIL && image.type !== FashionToolType.CORRECT_PART && (
                                    <Tooltip placement="bottom" title={t('tooltip_copy')}>
                                        <img src={refresh} className="cursor-pointer text-lg" onClick={() => onCopySetting(image)} />
                                    </Tooltip>
                                )}
                                <Tooltip placement="bottom" title={t('delete')} >
                                    <img src={trash} className="cursor-pointer text-lg" onClick={() => onDelete(image.id)} />
                                </Tooltip>
                            </div>
                        </div>
                        <div className="detail-content-2nd-section flex justify-center items-center">
                            <div className='tag-section-detail'>
                                <TagNameMyWork val={image} />
                            </div>
                        </div>
                        <div className="detail-content-3rd-section max-w-[350px]">
                            {isAdmin && <CollapseCustomize user={userInfo} promptInput={image.promptInput} negativeInput={image.negativePrompt} id={image?.id ? image?.id : ''} response={image.response} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyWorkDetail;
