import { Modal } from 'antd'
import React from 'react'
import ToolEditor from '../ToolEditor'
type ModalToolProps = {
    isOpenModal: boolean,
    setIsOpenModal: Function,
    setIsGenerating: Function,
}
function ModalTool({ isOpenModal, setIsOpenModal, setIsGenerating }: ModalToolProps) {
    const isToolModal = true
    return (
        <Modal className='flex justify-center items-center model-tool' open={isOpenModal} closeIcon={null} width="100vw" maskStyle={{
            opacity: 1,
            backgroundColor: 'white'
        }} footer={null} centered={false}
            bodyStyle={{
                boxShadow: 'none',
                border: 'none'
            }}
            style={{
                top: 50,
                padding: 0,
            }}  >
            <ToolEditor isToolModal={isToolModal} setIsOpenModal={setIsOpenModal} setIsGenerating={setIsGenerating} />
        </Modal>
    )
}

export default ModalTool