import classNames from 'classnames'
import '../../pages/Home/styles.css';
import { FashionParams } from '../../utils/interface';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setTypeModel } from '../../redux/app/selectTypeModel';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import { setError } from '../../redux/app/errorSelect';
import { Skeleton } from 'antd';
import { setLoadingApp } from '../../redux/app/loadingApp';

const SelectModel = (props: any) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const fashionParamsReducer = useAppSelector((store) => store.select);

  const onChangeModel = (template: any) => {
    dispatch(setLoadingApp.setLoading(true))
    props.setModel((prevParams: FashionParams) => ({
      ...prevParams,
      templateName: template.name,
      templateCode: template.code,
      cfgScale: template.cfg_scale,
      samplingStep: template.steps,
      denoisingStrength: template.denoising_strength,
      controlWeight: template.control_weight,
    }));
    dispatch(setError.setError(''));
    dispatch(setTypeModel.setFashionParams({
      ...fashionParamsReducer,
      isClickCFGScale: false,
      isClickSteps: false,
      isClickControlWeight: false,
    }));
    setTimeout(() => {
      dispatch(setLoadingApp.setLoading(false))
    }, 300)
  }
  return (
    <div id="select_modal" style={{ maxHeight: 'fit-content' }}>
      {/* <div className='header-text pb-2 text-sm font-semibold mb-4 text-gray-800 ' style={{ overflow: 'hidden' }}>{t('model_template')}</div> */}
      {props.isLoading ?
        <div ref={props.selectTemplateRef} className='list-model flex flex-row flex-wrap gap-4'>
          {props.mockupModel.map((template: any, index: number) => (
            <div key={index} style={{
              padding: 0
            }} className={classNames('item-model  cursor-pointer item-option item-selection', { selected_item: props.model === template.code })} onChange={() => onChangeModel(template)}>
              <input className={'hidden'} type="checkbox" name="select-model" id={template.name} />
              <label htmlFor={template.name} className="block   rounded-lg  cursor-pointer">
                <div className="flex justify-center  img-model" style={{
                  backgroundImage: `url(${template?.image})`,
                  backgroundSize: 'cover',
                  backgroundPositionY: 0
                }}>
                  {/* <img className="img-model" src={template.image} alt="" /> */}
                </div>
                <div className="text-center absolute bottom-0 bg-bottom text-white">
                  <span className="text-[12px]">{template.name}</span>
                </div>
              </label>
            </div>
          ))}
        </div> : <div className='list-model flex flex-row flex-wrap gap-4'>
          <Skeleton.Image active={true} />
          <Skeleton.Image active={true} />
          <Skeleton.Image active={true} />
          <Skeleton.Image active={true} />
        </div>}
    </div>
  )
}
export default SelectModel;