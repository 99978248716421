import { Button, Col, Input, Modal, Row, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import { checkHasValueInArrayListBg, getObjectWithTrueValue, MAX_LENGTH_INPUT_PROMPT, urlToBase64 } from '../../utils/function';
import { ScissorOutlined } from '@ant-design/icons';
import ModalCropImage from './ModalCropImage';
import SliderSettings from '../AdvancedSetting/SliderSettings';
import { FashionParams } from '../../utils/interface';
import { useDispatch } from 'react-redux';
import { setTypeModel } from '../../redux/app/selectTypeModel';
type ModalSelectBgOtherType = {
    isOpen: boolean;
    setIsOpen: Function;
    onOkSelectOtherBg: Function;
    defaultPrompt: string;
    listData: any;
    code: string;
    bgImageCopied: string;
    initImage?: string;
    imgSelected: string;
    codeCopied: string;
    setImgSelected: Function;
    setIsSelectedImage: Function;
    setFashionParams: Function;
    fashionParams: any;
    isSelectedImage: boolean;
    isUseControlNet: boolean;
}
function ModalSelectBgOther({ isOpen, setFashionParams, fashionParams, isUseControlNet, setIsOpen, code, codeCopied, bgImageCopied, defaultPrompt, listData, onOkSelectOtherBg, imgSelected, initImage, setImgSelected, setIsSelectedImage, isSelectedImage }: ModalSelectBgOtherType) {
    const dispatch = useDispatch();
    const fashionParamsReducer = useAppSelector((store) => store.select);
    const isAdmin = useAppSelector((store) => store.user.roles).includes("ADMIN");
    const [wordCount, setWordCount] = useState(defaultPrompt.length)
    const [valuePrompt, setValuePrompt] = useState(defaultPrompt)
    const [bgImage, setBgImage] = useState('')
    const [imageCrop, setImageCrop] = useState('')
    const [imageCropped, setImageCropped] = useState('')
    const [isOpenModalCrop, setIsOpenModalCrop] = useState(false)
    const { t } = useTranslation()

    const handleSelectionChange = (img: string) => {
        if (img === imgSelected) {
            setImgSelected('')
            setBgImage('')
        } else {
            setImgSelected(img)
            setIsSelectedImage(true)
            setBgImage(img)
        }
    }

    const onOk = () => {
        setIsOpen(false)
        if (bgImage) {
            onOkSelectOtherBg(bgImage, code)
        } else {
            onOkSelectOtherBg("", code, valuePrompt !== defaultPrompt, valuePrompt)
        }
    }

    const onChangeInput = (e: any) => {
        setWordCount(e.target.value.length)
        setValuePrompt(e.target.value)
    }

    const clickBtnCrop = async (img: string) => {
        const base64 = await urlToBase64(img);
        setIsOpenModalCrop(true);
        setImageCrop('data:image/png;base64,' + base64)
    }

    const onChangeIPadapterWeight = (value: any) => {
        if (!value) return
        if (isNaN(value)) {
            return;
        }
        setFashionParams((prevParams: FashionParams) => ({
            ...prevParams,
            BGWeight: value,
        }));
        dispatch(setTypeModel.setFashionParams({
            ...fashionParamsReducer,
            isClickBGWeight: true,
        }));
    }


    useEffect(() => {
        if (!listData) return
        const isApplyBgCopied = bgImageCopied && code === codeCopied && !isSelectedImage;
        if (isApplyBgCopied && !isUseControlNet) {
            //set  active khi copy
            setImgSelected(bgImageCopied);
            setBgImage(bgImageCopied);
            const isCropImageCopy = checkHasValueInArrayListBg(listData, bgImageCopied);
            if (!isCropImageCopy) {
                setImageCropped(bgImageCopied)
            }
        } else {
            if (isSelectedImage) {
                //set  active khi có đã chọn trước đó
                setImgSelected(imgSelected);
                setBgImage(imgSelected);
            } else {
                //set  active khi có is_default === true, is using prompt
                if (isUseControlNet) {
                    setImgSelected('');
                    setBgImage('');
                } else {
                    //check when copy use controlnet (bg image is empty) then done selected any items
                    const urlDefaultSelected = listData && listData.length > 0 && getObjectWithTrueValue(listData);
                    setImgSelected(urlDefaultSelected);
                    setBgImage(urlDefaultSelected);
                }

            }


        }
    }, [listData, bgImageCopied, isSelectedImage, code, isUseControlNet]);

    // useEffect(() => {
    //     setIsSelectedImage(false)

    // }, [listData])

    useEffect(() => {
        imageCropped && setImgSelected(imageCropped)
    }, [imageCropped])
    return (
        <div>
            <Modal
                centered
                open={isOpen}
                title={t('customize') + ' ' + 'background'}
                cancelText={t('cancel')}
                onCancel={() => { setIsOpen(false); }}
                onOk={onOk}
                width={listData?.length > 0 ? 'fit-content' : '60vw'}
            >
                <div>{t('input_des')}</div>
                <Input
                    // value={valueInputCustomize}
                    defaultValue={valuePrompt}
                    value={valuePrompt}
                    onChange={onChangeInput}
                    maxLength={MAX_LENGTH_INPUT_PROMPT}
                    className='mb-2'
                    placeholder={t('input_customize')}></Input>

                <div className='flex justify-between'>
                    <div></div>
                    <span className='text-[10px] text-[red]'>{wordCount}/{MAX_LENGTH_INPUT_PROMPT}</span>
                </div>

                {listData && listData.length > 0 &&
                    <>
                        <SliderSettings hidden={false} onChange={onChangeIPadapterWeight} input={fashionParams.BGWeight} toolTipMin={t('icw_min')} toolTipMax={t('icw_max')} toolTip={'icw'} title={t('input_IPadapter_weight')} min={isAdmin ? 0 : 0.8} max={isAdmin ? 2 : 1.2} step={0.01} />
                        <div className='text-sm'>{t('select_other_bg_2')}</div>
                        <p className='text-gray text-sm my-1'>{t('tooltip_cut_image')}</p>
                        <div className='list-bg-example bg-slate-200 mt-4 max-h-[55vh] p-2 gap-4 grid grid-cols-4 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 overflow-y-auto '>
                            <div
                                className={classNames('box-image relative cursor-pointer item-option-custom item-selection w-[180px]', {
                                    hidden: !imageCropped,
                                    selected_item: imgSelected === imageCropped
                                })}
                            >
                                <Tooltip title={t('cropped')}>
                                    <div className='absolute top-2 right-3'>✂️</div>
                                </Tooltip>
                                <input
                                    type={'checkbox'}
                                    name='select-body'
                                    id={'crop-image'}
                                    className='hidden'
                                    onChange={(e) => handleSelectionChange(imageCropped)}
                                />
                                <label htmlFor={'crop-image'} >
                                    <div className='select-bg w-full h-[220px] bg-img' style={{
                                        backgroundImage: 'url("' + imageCropped + '")',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat'
                                    }}>

                                    </div>
                                </label>
                            </div>
                            {listData.map((n: any, i: string) => (
                                <div
                                    key={i}
                                    className={classNames('box-image relative cursor-pointer item-option-custom item-selection w-[180px] ', {
                                        selected_item: imgSelected === n.bg_url,
                                        hidden: !n.is_active
                                    })}
                                >
                                    <input
                                        type={'checkbox'}
                                        name='select-body'
                                        id={i}
                                        className='hidden'
                                        checked={n.is_default}
                                        onChange={(e) => handleSelectionChange(n.bg_url)}
                                    />
                                    <Button className='button-crop' onClick={e => clickBtnCrop(n.bg_url)}><ScissorOutlined /></Button>
                                    <label htmlFor={i} >
                                        <div className='select-bg w-full h-[220px] bg-img' style={{
                                            backgroundImage: 'url("' + n.bg_url + '")',
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat'
                                        }}>
                                        </div>
                                    </label>
                                </div>
                            )
                            )}

                        </div>
                    </>
                }
                {isOpenModalCrop && <ModalCropImage initImage={initImage} isOpen={isOpenModalCrop} setBgImage={setBgImage} setImageCropped={setImageCropped} setIsOpen={setIsOpenModalCrop} image={imageCrop} />}
            </Modal >
        </div >
    )
}

export default ModalSelectBgOther