import { useNavigate } from 'react-router-dom';


export const History: any = {
  navigate: null,
  push: (page: any, ...rest: any) => History.navigate(page, ...rest),
};

const NavigateSetter = () => {
  History.navigate = useNavigate();
  return null;
};

export default NavigateSetter