import { useTranslation } from 'react-i18next';
import { FeatureTypeEnum } from '../../../utils/enum';
import './styles.scss'
import { useDispatch } from 'react-redux';
import { ModalSub } from '../../../redux/app/appModalSub';

type ToggleProps = {
    selectedOption: string;
}
function ToggleType({ selectedOption }: ToggleProps) {
    const dispatch = useDispatch()
    const handleOptionChange = (option: string) => {
        dispatch(ModalSub.setTypeFeatureActive(option));
    };
    const { t } = useTranslation()
    return (
        <div className='flex justify-center items-center'>
            <div className="switch-container">
                <input
                    type="radio"
                    id={FeatureTypeEnum.MODEL_AI}
                    name="switch2"
                    value={FeatureTypeEnum.MODEL_AI}
                    checked={selectedOption === FeatureTypeEnum.MODEL_AI}
                    onChange={() => handleOptionChange(FeatureTypeEnum.MODEL_AI)}
                />
                <label htmlFor={FeatureTypeEnum.MODEL_AI} className='flex flex-col items-center justify-center toggle-label'>
                    <div>
                        <p className='font-normal text-[16px] text-[#667085]'>
                            AI Model
                        </p>
                    </div>
                </label>
                <input
                    type="radio"
                    id={FeatureTypeEnum.VIRTUAL_TRY_ON}
                    name="switch2"
                    value={FeatureTypeEnum.VIRTUAL_TRY_ON}
                    checked={selectedOption === FeatureTypeEnum.VIRTUAL_TRY_ON}
                    onChange={() => handleOptionChange(FeatureTypeEnum.VIRTUAL_TRY_ON)}
                />
                <label htmlFor={FeatureTypeEnum.VIRTUAL_TRY_ON} className='flex flex-col items-center justify-center toggle-label'>
                    <div>
                        <p className='font-normal text-[16px] text-[#667085]'>
                            Virtual Try On
                        </p>
                    </div>
                </label>
            </div>
        </div>
    )
}

export default ToggleType