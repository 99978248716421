import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import iconUpload from '../../assets/icons/upload-cloud.png';
import { Button, Spin } from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import NotifyController from '../../utils/toast';
import { base64ToBlob, convertBlobToBase64, convertHeicToJpg, resizeImageToMaxSize768x1024 } from '../../utils/function';
import FashionService from '../../services/fashion.service';
import { uploadFileToS3 } from '../../utils/uploadS3';
import { handleResponseError } from '../../utils/responseError';
import ModalGuideUploadExample from './ModalGuideUploadExample';
import tip from '../../assets/icons/idea.svg';
function UploadExampleVTO() {
    const { t } = useTranslation()
    const [listImages, setListImage] = useState<string[]>([''])
    const [isChangeList, setIsChangeList] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isOpenModalGuide, setIsOpenModalGuide] = useState(false)
    const [limit, setLimit] = useState({
        min: 2,
        max: 6
    })
    const currentTime = Date.now();
    const onClickUploadImage = () => {
        const inputUpload = document.getElementById('input-logo') as HTMLInputElement;
        inputUpload.click();
    };
    const onChangeInputFiles = async (e: any) => {
        try {
            e.preventDefault();
            let files = e.target?.files;
            if (!files || !files.length) {
                console.warn("No file selected")
                return
            }
            const reader = new FileReader();
            reader.onload = async (event: any) => {
                let imageUrlFinal = event.target.result;
                let isHEIC = imageUrlFinal.startsWith('data:application/octet-stream;base64')
                if (isHEIC) {
                    const response = await fetch(event.target.result);
                    const blob = await response.blob();
                    const coverted = await convertHeicToJpg(blob);
                    const url = await uploadFileToS3(coverted, currentTime + `.jpg`, 'jpg')
                    imageUrlFinal = await resizeImageToMaxSize768x1024(url)
                }
                const image1024 = await resizeImageToMaxSize768x1024(imageUrlFinal)
                // const response = await fetch(image1024);
                // const blob = await response.blob();
                const blob = await base64ToBlob(image1024, "image/jpg");
                const url = await uploadFileToS3(blob, currentTime + `.jpg`, 'jpg')
                setListImage((prevList: string[]) => [...prevList, url]);
                setIsChangeList(true)
            }
            reader.readAsDataURL(files[0]);
            e.target.value = null
        } catch (error) {
            NotifyController.error('Something went wrong!')
        }


    }
    const toRemoveImage = (url: string) => {
        const newA = listImages.filter((item: any) => item !== url);
        setListImage(newA)
        setIsChangeList(true)

    }
    const UploadLogoComponent = (
        <div onClick={onClickUploadImage} className='flex flex-col min-h-[250px] min-w-[185px] max-w-[300px] gap-2 h-full w-full items-center justify-center bg-[#F3F6FF] border border-solid border-[#0047FF] p-8 rounded-lg hover:cursor-pointer'>
            <img src={iconUpload} alt="" />
            <Button disabled={false} type='primary'>
                {t('upload')}
            </Button>
        </div>
    )
    const UploadLogoComponentMobile = (
        <div onClick={onClickUploadImage} className='flex flex-col rounded-full min-h-[50px] min-w-[50px] max-w-[50px] gap-2 h-full w-full items-center justify-center bg-[#F3F6FF] border border-solid border-[#0047FF] hover:cursor-pointer'>
            <img src={iconUpload} alt="" />
        </div>
    )

    const getExampleImage = async () => {
        const { data } = await FashionService.getInstance().getConfig();
        setListImage(data.exampleImages)
    }
    const saveImageExample = async (listImages: string[]) => {
        try {
            setLoading(true)
            const data = await FashionService.getInstance().saveExampleImages(listImages);
            if (data.success) {
                setTimeout(() => {
                    setLoading(false)
                }, 200)
            }
        } catch (error: any) {
            setLoading(false)
            handleResponseError(error.message)
        }
    }
    const showModalGuide = () => {
        setIsOpenModalGuide(true)
    }
    useEffect(() => {
        isChangeList && saveImageExample(listImages)
    }, [listImages, isChangeList])
    useEffect(() => {
        getExampleImage()
    }, [])
    return (
        <div className='flex flex-col justify-start gap-8 mt-1'>
            <div onClick={() => { showModalGuide() }} className='sm:hidden ml-10 bg-[#FEFCE8] flex flex-row gap-2 min-w-fit max-w-[120px] p-3 font-normal justify-center items-center rounded-md h-[40px] hover:cursor-pointer'>
                <div>
                    <img src={tip} alt="" />
                </div>
                <div>{t('guide')}</div>
            </div>
            <div className=' hidden sm:!block sm:fixed sm:right-3 sm:bottom-[100px] z-[999]'>
                <div onClick={() => showModalGuide()}>
                    <QuestionCircleOutlined className='text-yellow-200 text-[40px]' />
                </div>
            </div>
            {isOpenModalGuide && <ModalGuideUploadExample iOpen={isOpenModalGuide} setIsOpen={setIsOpenModalGuide} />}
            <Spin spinning={loading}>
                <div className={`flex gap-3 mt-8 ml-10 flex-wrap sm:relative`}>
                    <input className='hidden' type="file" accept="image/*" onChange={onChangeInputFiles} name="input-log" id="input-logo" />

                    {
                        listImages.length < limit.max &&
                        <>
                            <div className='border border-solid border-primary rounded-lg w-fit sm:hidden'>
                                {UploadLogoComponent}
                            </div>
                            <div className=' hidden sm:!block sm:fixed sm:right-2 sm:bottom-10'>
                                {UploadLogoComponentMobile}
                            </div>
                        </>
                    }
                    {
                        [...listImages].reverse()?.map((img: string, index: number) =>
                        (
                            <div key={index} className='flex flex-col gap-2 relative' >
                                <img src={img} alt="" className=' rounded-lg max-w-[185px] max-h-[250px]' />
                                {listImages.length > limit.min && <Button danger onClick={() => toRemoveImage(img)} className='absolute bottom-4 -translate-x-1/2 left-1/2'>{t('delete')}</Button>}
                            </div>
                        )
                        )
                    }
                </div>
            </Spin>
        </div>
    )
}

export default UploadExampleVTO