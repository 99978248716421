import { createSlice } from '@reduxjs/toolkit';
import { UserState } from '../iStore';

const initialState: UserState = {
  id: '',
  username: '',
  email: '',
  referralCode: '',
  coins: 0,
  genNumber: 0,
  maxGenNumber: 0,
  subscription: {},
  roles: [],
  maxConcurrent: 1,
  isFeedback: false,
  totalGen: 0,
  isOpeningGenerate: false,
  isOpeningSwapface: false,
  isOpeningRemoveObject: false,
  isOpeningUpscale: false,
  isOpeningSmoothBorder: false,
  isOpeningCorrectPart: false,
  isOpeningRemoveRedundant: false,
  isOpening3rdControlnet: false,
  guestDailyGenNum: 0,
  shopUrl: '',
  isFromAccSocial: false,
  subscriptionCancelable: {
    stripe: null,
    vnpay: null
  }
};


const eventUser = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.id = action.payload.id;
      state.username = action.payload.username;
      state.email = action.payload.email;
      state.referralCode = action.payload.referralCode;
      state.coins = action.payload.coins;
      state.subscription = action.payload.subscription;
      state.roles = action.payload.roles;
      state.genNumber = action.payload.genNumber;
      state.maxGenNumber = action.payload.maxGenNumber;
      state.maxConcurrent = action.payload.maxConcurrent;
      state.isFeedback = action.payload.isFeedback;
      state.totalGen = action.payload.totalGen;
      state.isOpeningGenerate = action.payload.isOpeningGenerate
      state.isOpeningRemoveObject = action.payload.isOpeningRemoveObject
      state.isOpeningSmoothBorder = action.payload.isOpeningSmoothBorder
      state.isOpeningSwapface = action.payload.isOpeningSwapface
      state.isOpeningUpscale = action.payload.isOpeningUpscale
      state.isOpeningCorrectPart = action.payload.isOpeningCorrectPart
      state.isOpeningRemoveRedundant = action.payload.isOpeningRemoveRedundant
      state.isOpening3rdControlnet = action.payload.isOpening3rdControlnet
      state.guestDailyGenNum = action.payload.guestDailyGenNum
      state.shopUrl = action.payload.shopUrl
      state.isFromAccSocial = action.payload.isFromAccSocial
      state.subscriptionCancelable = action.payload.subscriptionCancelable
    },
    setCredits: (state, action) => {
      state.coins = action.payload;
    },
    setGenNumber: (state, action) => {
      state.genNumber = action.payload.genNumber;
    },
    setShopUrl: (state, action) => {
      state.shopUrl = action.payload.shopUrl;
    }
  },
});


export const userSaga = eventUser.actions;
export default eventUser.reducer;