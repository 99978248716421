import {
    CheckOutlined
} from '@ant-design/icons';
import { Button, Input } from 'antd';
import { t } from 'i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { userSaga } from '../../redux/app/authSaga';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import FashionService from '../../services/fashion.service';
import NotifyController from '../../utils/toast';
import CopyButton from './../../assets/vto/materials/copy-button.svg';
import './styles.css';

const IntegrationCode = () => {
    const dispatch = useDispatch();
    const userInfo = useAppSelector((store) => store.user);
    const [shopUrl, setShopUrl] = useState(userInfo.shopUrl || '');
    const [isEditing, setIsEditing] = useState(!userInfo.shopUrl);

    const handleCopy = (s: string) => {
        const codeText = s;
        navigator.clipboard.writeText(codeText);
        NotifyController.success(t('Copy code successfully'));
    };

    const handleSaveShopUrl = async () => {
        if (isEditing) {
            if (!shopUrl) {
                NotifyController.error(t('Shop_url_is_required'));
                return;
            }
            if (shopUrl.length > 255) {
                NotifyController.error(t('Shop_url_is_too_long'));
                return;
            }
            try {
                await FashionService.getInstance().updateShopUrl(shopUrl);
            } catch (error) {
                NotifyController.error(t('Invalid_shop_url'));
                return;
            }
            dispatch(userSaga.setShopUrl({ shopUrl }));
            NotifyController.success(t('Shop URL saved successfully'));
            setIsEditing(false);
        } else {
            setIsEditing(true);
        }
    };

    return (
        <>
            <div className="integration-container">
                <div className="integration-section">
                    <div className='text-[#101828] text-[16px] font-semibold mb-3'>{t('action')}</div>
                    <p className='font-semibold mt-8 mb-1'>{t('Integration_code')}</p>
                </div>
                <div className='flex flex-col gap-4 border border-sky-400 border-solid rounded-lg p-6'>
                    <div className="my-2">
                        <div className='font-medium text-[16px] underline mb-2'>
                            {t('step_1')}: {t('step1_header')}
                        </div>
                        <div className='flex gap-2 items-center'>
                            <Input
                                value={shopUrl}
                                onChange={(e) => setShopUrl(e.target.value)}
                                disabled={!isEditing}
                                className='w-full'
                            />
                            <Button icon={<CheckOutlined />} type="primary" onClick={handleSaveShopUrl}>
                                {isEditing ? t('save') : t('Edit')}
                            </Button>
                        </div>
                    </div>
                    <div className="my-2">
                        <div className='font-medium text-[16px] underline mb-2'>
                            {t('step_2')}: {t('step2_header')}
                        </div>
                        <div className='flex gap-2 mt-4 items-center'>
                            <SyntaxHighlighter language="javascript" style={docco} showInlineLineNumbers>
                                {`${process.env.REACT_APP_BE}/vto/script.js?shopId=${userInfo.referralCode}`}
                            </SyntaxHighlighter>
                            <div className='integration-code-block-right hover:cursor-pointer'>
                                <div className='copy-container' onClick={() => handleCopy(`${process.env.REACT_APP_BE}/vto/script.js?shopId=${userInfo.referralCode}`)}>
                                    <img src={CopyButton} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="my-2">
                        <div className='font-medium text-[16px] underline mb-2'>
                            {t('step_3')}: {t('step3_header')}
                        </div>
                        <div className='flex gap-2 items-center'>
                            <SyntaxHighlighter language="html" style={docco} showInlineLineNumbers>
                                {`<button id="vto-button" data-productid="{CURRENT_ID}">Thử ngay</button>`}
                            </SyntaxHighlighter>
                            <div className='integration-code-block-right hover:cursor-pointer'>
                                <div className='copy-container' onClick={() => handleCopy(`<button id="vto-button" data-productid="{CURRENT_ID}">Thử ngay</button>`)}>
                                    <img src={CopyButton} />
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-1 mt-2'>
                            <p>{t('step2_des')}</p>
                        </div>
                    </div>
                    <div className="my-2">
                        <div className='font-medium text-[16px] underline mb-2'>
                            {t('step_4')}: {t('step4_header')}
                        </div>
                        <div className='flex gap-2 items-center'>
                            <SyntaxHighlighter language="html" style={docco} showInlineLineNumbers>
                                {`<div id="embeddedPlugin"></div>`}
                            </SyntaxHighlighter>
                            <div className='integration-code-block-right hover:cursor-pointer'>
                                <div className='copy-container' onClick={() => handleCopy(`<div id="embeddedPlugin"></div>`)}>
                                    <img src={CopyButton} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-left mt-4 font-semibold'>{t('platform')}:</div>
                <ul className='mt-4 flex gap-4 min-w-[50%] justify-around items-center'>
                    <li className='flex gap-2'>
                        <a href="https://docs.google.com/document/d/1iLRts6LOdcuQ5Y6ErldGmG5IMeoeTO1n" target={'_blank'}>
                            <img src="https://media2.vision2art.ai/WordPress-logotype-alternative.png" alt="" width={120} />
                        </a>
                    </li>
                    <li className='flex gap-2'>
                        <a href="https://docs.google.com/document/d/1NJJKATUUi0rDg_a9AVCYfh13ZGYfN2RawIDfZyNBfEo" target={'_blank'}>
                            <img src={"https://media2.vision2art.ai/logo_haravan.png"} alt="" width={80} />
                        </a>
                    </li>
                    <li className='flex gap-2'>
                        <a href="https://docs.google.com/document/d/1wsKLZYzrEgldiXXbQJwZ-gAGQAKraEa1" target={'_blank'}>
                            <img src="https://media2.vision2art.ai/shopify-mono-black.png" alt="" width={150} />
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default IntegrationCode;
