import { Modal } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import checkIcon from '../../assets/icons/check-icon.png'
import PaymentService from '../../services/payment.service'
import './styles.css';
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logoVNPay from '../../assets/icons/vnpay.svg';
import logoStripe from '../../assets/icons/stripe.svg';
import { FeatureTypeEnum } from '../../utils/enum';
import ButtonVTO from './ButtonVTO';
import Toggle from './Toggle';
import ToggleType from './ToggleType';

export enum EPaymentType {
    STRIPE = 'STRIPE',
    VNPAY = 'VNPAY',
}

type ModalRequiredSubProps = {
    visible: boolean,
    setModalSub: (e: boolean) => void
}

function ModalRequiredSub({ visible, setModalSub }: ModalRequiredSubProps) {
    const [products, setProducts] = useState<any>([]);
    const [isStripeEnabled, setIsStripeEnabled] = useState(true);
    const [isVNPayEnabled, setIsVNPayEnabled] = useState(true);
    const [radioValue, setRadioValue] = useState('monthly');
    const [featureType, setFeatureType] = useState(FeatureTypeEnum.VIRTUAL_TRY_ON);
    const [indexType, setIndexType] = useState<number>(0);
    const [listDiscountPercent, setListDiscountPercent] = useState<number[]>([0]);
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    const isVND = true; //only VND

    const getListProduct = async () => {
        const currentLocale = i18n.language;
        const data = await PaymentService.getInstance().getListPackage(featureType, currentLocale);
        setProducts(data.products);
        const percents: any = [];
        //get percent discount of product quarterly and yearly
        data.products.forEach((product: any) => {
            if (product.price && product.price.length > 0) {
                const vndPrice = product.price[0];
                if (vndPrice.list_items && vndPrice.list_items.length > 0) {
                    vndPrice.list_items.forEach((item: any) => {
                        percents.push(item.discountPercent);

                    });
                }
            }
        });

        const uniquePercents: any = [...new Set(percents)];
        setListDiscountPercent(uniquePercents);
        setIsStripeEnabled(data.isStripeEnabled);
        setIsVNPayEnabled(data.isVnpayEnabled);
    }

    const handleClickPaymentButton = useCallback((type: string, id: string, radioValue: string, isStripeEnabled: boolean, isVNPayEnabled: boolean, products: any) => {
        if (!isStripeEnabled && type === EPaymentType.STRIPE) {
            swal(`Stripe is disabled! Please contact support for more information`, '', 'warning');
            return
        }
        if (!isVNPayEnabled && type === EPaymentType.VNPAY) {
            swal(`VNpay is disabled! Please contact support for more information`, '', 'warning');
            return
        }
        const dataPayment = {
            type,
            id,
            radioValue,
            isStripeEnabled,
            isVNPayEnabled,
            products
        }
        navigate('/checkout', { state: { dataPayment } })
    }, [isStripeEnabled, isVNPayEnabled, i18n.language])

    const handleClickContactUS = () => {
        window.open("https://www.facebook.com/modeliaivn", "_blank");
    }

    const handleClickTryNow = () => {
        setModalSub(false)
    }

    const FormatNumber = (number: number) => {
        return (Math.round(number / 100) * 100);
    }

    useEffect(() => {
        visible && getListProduct()
    }, [visible, featureType]);

    useEffect(() => {
        switch (radioValue) {
            case 'monthly':
                setIndexType(0)
                break;
            case 'quarterly':
                setIndexType(1)
                break;
            case 'yearly':
                setIndexType(2)
                break;

            default:
                break;
        }
    }, [radioValue])

    return (
        <Modal open={visible} width={'fit-content'} centered className='p-4 min-h-full' onCancel={e => setModalSub(false)}
            footer={
                <div className='flex justify-center items-center text-[#0047FF] text-base hover:underline hover:cursor-pointer font-semibold italic'
                    onClick={() => {
                        window.open("https://docs.google.com/document/d/1YHrGRz6lhAHQ08KBrk5uEi_-iTx8mrvMRXB46HrBeRo/edit?usp=sharing", "_blank");
                    }}
                >
                    {t('payment_guide2')}
                </div>
            }

            zIndex={9999}>
            <div className='flex justify-center items-center'>
                <ToggleType selectedOption={featureType} />
            </div>
            <div className='flex justify-between items-center mt-4 sm:flex-col max-w-full'>
                <div className='max-w-1/2s text-center'>
                    <p className='font-semibold text-[16px] text-[#0047FF] text-left'>{t('Upgrade')}</p>
                    <p className='text-[36px] text-[#101828] font-semibold text-left'>{featureType === FeatureTypeEnum.MODEL_AI ? 'AI Model' : 'Virtual Try On'}</p>
                    <p className='text-[16px] text-[#667085] font-normal text-left'>{t('Pricing plans that scale')}</p>
                </div>
                {products && products?.[0]?.isSub && <Toggle selectedOption={radioValue} setSelectedOption={setRadioValue} listDiscountPercent={listDiscountPercent} />}
            </div>
            {/* {featureType === FeatureTypeEnum.VIRTUAL_TRY_ON ? <ButtonVTO /> : <div className='h-[57px]'></div>} */}
            <div className='p-4 flex justify-center flex-wrap gap-4 md:flex-col sm:flex-col lg:flex-col'>
                {products?.map((item: any, index: number) => {
                    return (
                        <div key={index} className='product-box min-h-max'>
                            <div className='border border-solid border-t rounded-t-2xl  border-[#d9d9d9] p-4 min-h-[100px]'>
                                <div className='product-name pb-2 uppercase text-left sm:!text-2xl'> {item.name} </div>
                                <div className='product-price flex justify-start gap-1 sm:!text-2xl' style={{
                                    lineHeight: 'normal'
                                }}>
                                    {item.isSub
                                        ? (!item.isContact && !item.isFree)
                                            ? <div className='flex sm:font-sm'>
                                                {(!item.isContact && !item.isFree) && (((item.price[isVND ? 0 : 1]?.list_items[indexType].discountAmount) / 1000)) && <>
                                                    {(((item.price[isVND ? 0 : 1]?.list_items[indexType].discountAmount) / (item.price[isVND ? 0 : 1]?.list_items[indexType].number_of_months)) / 1000)?.toLocaleString()},<sup className='text-xl sm:text-[12px]'>{i18n.language === 'vi' ? '000đ/tháng' : '000d/month'}</sup>
                                                </>} </div>
                                            : <div className='flex sm:font-sm'>
                                                {(!item.isContact && !item.isFree) && (((item.price[isVND ? 0 : 1]?.list_items[indexType].amount) / 1000)) && <>
                                                    {((((item.price[isVND ? 0 : 1]?.list_items[indexType].amount) / (item.price[isVND ? 0 : 1]?.list_items[indexType].number_of_months)) / 1000))?.toLocaleString()},<sup className='text-xl sm:text-[12px]'>{i18n.language === 'vi' ? '000đ/tháng' : '000d/month'}</sup>
                                                </>}  </div>

                                        : <div className='flex sm:font-sm'>
                                            {((item.price[0]?.amount) / 1000)?.toLocaleString()},<span >{i18n.language === 'vi' ? '000đ' : '000d'}</span>
                                        </div>
                                    }
                                    {item?.isContact && <div>{t('contact')} </div>}
                                    {item?.isFree && <div>{t('free')} </div>}

                                </div>
                                <div className='flex justify-start gap-2 mt-2'>
                                    <div className='des-plan text-[#667085] text-sm'>
                                        {item.brandText}
                                    </div>
                                </div>

                            </div>
                            <div className='border border-solid  border-[#d9d9d9] flex justify-between flex-col h-full rounded-b-2xl'>
                                <div className='list-benefit flex flex-col gap-4 mt-3 p-4  '>
                                    {item.description?.map((item2: string, index: number) => {
                                        return (

                                            <div key={index} className='flex gap-2' style={{ alignItems: 'center' }}>
                                                <img src={checkIcon} alt="tick" width={'30px'} height={'30px'} />
                                                {index === 0 && item.price[0]?.list_items
                                                    ? <p className='flex flex-col text-[#101828] font-semibold  max-w-[250px] min-w-[250px] sm:min-w-[150px] sm:max-w-[150px]'>
                                                        {featureType === FeatureTypeEnum.MODEL_AI && <>
                                                            <div>
                                                                {`+${(parseInt(item2) * item.price[0]?.list_items?.[indexType]?.number_of_months).toLocaleString()} credits `}/{t(radioValue)}
                                                            </div>
                                                            <div className='text-[12px] text-gray italic'>{`(~${FormatNumber((parseInt(item2) * item.price[0]?.list_items?.[indexType]?.number_of_months) / 10) / 2}
                                                  -
                                                   ${FormatNumber((parseInt(item2) * item.price[0]?.list_items?.[indexType]?.number_of_months) / 10)} ${t('final_pictures')})`}</div>
                                                        </>
                                                        }{
                                                            featureType === FeatureTypeEnum.VIRTUAL_TRY_ON && <>
                                                                <div>
                                                                    {`+${(parseInt(item2) * item.price[0]?.list_items?.[indexType]?.number_of_months).toLocaleString()} credits `}/{t(radioValue)}
                                                                </div>

                                                                <div className='text-[12px] text-gray italic'>{`(~${FormatNumber((parseInt(item2) * item.price[0]?.list_items?.[indexType]?.number_of_months)).toLocaleString()} ${t('vto_per_gen')})`}</div>
                                                            </>
                                                        }

                                                    </p>
                                                    : <p className='break-words text-[#101828] font-semibold  max-w-[250px] min-w-[250px] sm:min-w-[150px] sm:max-w-[150px]'>{item2}</p>
                                                }

                                            </div>

                                        )
                                    })}
                                </div>
                                <div className='bg-[#F5F5F5] rounded-b-2xl'>
                                    {(!item.isContact && !item.isFree) ?
                                        <div className='btn-payment-section mt-3 flex flex-col p-4  '>
                                            <div>
                                                <div className='text-[#667085] '>{t('payment_via')}:</div>
                                            </div>
                                            <div className=' gap-3 flex sm:flex-col md:flex-col '>
                                                <div
                                                    onClick={() => handleClickPaymentButton(EPaymentType.STRIPE, item.id, radioValue, isStripeEnabled, isVNPayEnabled, products)}
                                                    className="btn-stripe !bg-[#635BFF] p-2"
                                                >
                                                    <img src={logoStripe} alt="stripe" width={100} className='object-contain' />
                                                </div>
                                                {i18n.language === 'vi' &&
                                                    <div
                                                        onClick={() => handleClickPaymentButton(EPaymentType.VNPAY, item.id, radioValue, isStripeEnabled, isVNPayEnabled, products)}
                                                        className="btn-vnpay !bg-[#ED1B23] p-2"
                                                    >
                                                        <img src={logoVNPay} alt="vnpay" width={100} className='object-contain' />
                                                    </div>
                                                }

                                            </div>
                                        </div>
                                        : item.isFree ? <div className='p-4 bg-[#F5F5F5] rounded-b-2xl min-h-[80px] flex flex-col justify-end'><div onClick={handleClickTryNow} className='bg-[#0047FF]  rounded-lg  w-auto p-[11.5px] text-white cursor-pointer text-center  text-[16px] font-semibold'>{t('try_now')}</div></div>
                                            :
                                            item.isContact ? <div className='p-4 bg-[#F5F5F5] rounded-b-2xl min-h-[80px] flex flex-col justify-end'><div onClick={handleClickContactUS} className='bg-[#0047FF]   rounded-lg w-auto p-[11.5px]   text-[16px] font-semibold text-white cursor-pointer text-center' >{t('contact_us')}</div></div>
                                                : ''
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </Modal>
    )
}

export default ModalRequiredSub