import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { logEvent } from "@firebase/analytics";
import { Spin, Tooltip } from "antd";
import LZString from "lz-string";
import { InferenceSession, Tensor } from "onnxruntime-web";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import iconUpload from "../../assets/icons/upload-cloud.png";
import analytics from "../../firebase";
import { initValue } from "../../pages/Home";
import { handleImageScale } from "../../realtimeSegment/components/helpers/ImageHelper";
import { modelScaleProps } from "../../realtimeSegment/components/helpers/Interface";
import getFile from "../../realtimeSegment/components/helpers/getFile";
import {
  createMaskedImageRealTimeData,
  getAllMasks,
  keepArrayForMultiMask,
  maskToColorImage,
  rleToCanvasBlackWhite,
  rleToImage,
  traceCompressedRLeStringToSVG,
  traceOnnxMaskToSVG,
} from "../../realtimeSegment/components/helpers/mask_utils";
import {
  modelData,
  setParmsandQueryModel,
} from "../../realtimeSegment/components/helpers/modelAPI";
import RealtimeSegmentContext from "../../realtimeSegment/components/hooks/createContext";
import { setIsAuto } from "../../redux/app/appAutoSegment";
import { setError } from "../../redux/app/errorSelect";
import { useAppSelector } from "../../redux/hooks/useAppSelector";
import FashionService from "../../services/fashion.service";
import { FashionClothesEnum } from "../../utils/enum";
import { convertBase64Image, customizeKey, getImageSize, urlToBase64 } from "../../utils/function";
import { FashionParams } from "../../utils/interface";
import ModalSelectInput from "./ModalSelectInput";
import { changeToBlueImage, removeBlackImage } from "./function";
import { setIsAutoPose } from "../../redux/app/appAutoPose";
import { realtimeSegmentActions } from "../../redux/app/realtimeSegment";
import { editorImage } from "../../redux/app/appImagePreprocessing";
import mergeImages from 'merge-images';
import ModalEditSegmentAndPose from "./ModalEditSegmentAndPose/ModalEditSegmentAndPose";
import { store } from "../../redux/rootStore";
import { toolEditorImage } from "../../redux/app/toolEditor";
interface UploadImageProps {
  onChangeUrlImage: Function;
  onChangeMaskImage: Function;
  onChangePose: (e: any) => void;
  pose: boolean;
  urlImg: string;
  imgUploadRef: any;
  setFashionParams: Function;
  onSetSegment: Function;
  fashionParams: FashionParams;
  setGeneratingSamData: Function;
  onSetPose: Function;
  setDataImagePose: Function;
  imagePrePose: string;
  sizePose: any;
  dataOpenPose: any;
  setImagePrePose: Function;
  setDataOpenPose: Function;
  setSizePose: Function;
  openModalTool: boolean;
  setOpenModalTool: Function
}

const UploadImage = ({
  onChangeUrlImage,
  onChangePose,
  pose,
  onChangeMaskImage,
  imgUploadRef,
  setFashionParams,
  fashionParams,
  onSetSegment,
  setGeneratingSamData,
  urlImg,
  onSetPose,
  imagePrePose,
  sizePose,
  dataOpenPose,
  setDataImagePose,
  setImagePrePose,
  setDataOpenPose,
  setSizePose,
  openModalTool,
  setOpenModalTool
}: UploadImageProps) => {
  const toolEditor = useAppSelector(store => store.toolEditor)
  const isLoadingSegment = toolEditor.isLoadingSegment
  const [loading, setLoading] = useState(isLoadingSegment);
  const [dataAnnotations, setDataAnnotations] = useState<any[]>([]);
  const [arrayMaskSelected, setArrayMaskSelected] = useState([]);
  const [isOpenModalSelect, setIsOpenModalSelect] = useState(false);
  const [isOpenModalOpenPose, setIsOpenModalOpenPose] = useState(false);
  const [isOpenModalEditCloth, setIsOpenModalEditCloth] = useState(false);
  const [imagePrevPose, setImagePrevPose] = useState('');
  const [firstOpen, setFirstOpen] = useState(false);
  const [mergedImage, setMergedImage] = useState('');
  const [imageSegment, setImageSegment] = useState({
    image: "",
    width: 0,
    height: 0,
  });
  const [cropData, setCropData] = useState("");
  const [segmentImagesWithBody, setSegmentImagesWithBody] =
    useState<string>("");
  const [segmentImages, setSegmentImages] = useState<string>("");
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const poseDataFromAPI = useRef<any>();
  const typeModel = useAppSelector((store) => store.select.typeModel);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isGenerating = useAppSelector(store => store.isGeneratingImage.isGen)
  const isGoHome = useAppSelector(store => store.isGeneratingImage.isGoHome)
  const clothesType = useAppSelector((store) => store.select.typeModel);
  const outputTool = useAppSelector((store) => store.toolEditor);
  const errorType = useAppSelector((store) => store.errorInput.typeError);
  const fashionParamsReducer = useAppSelector((store) => store.select);
  const [valueWeight, setValueWeight] = useState(fashionParamsReducer.controlWeight);
  // ================== //
  const {
    click: [, setClick],
    clicks: [clicks, setClicks],
    image: [image, setImage],
    prevImage: [, setPrevImage],
    svg: [svg, setSVG],
    svgs: [svgs, setSVGs],
    allsvg: [, setAllsvg],
    isErased: [, setIsErased],
    isModelLoaded: [, setIsModelLoaded],
    segmentTypes: [, setSegmentTypes],
    maskImg: [maskImg, setMaskImg],
    isErasing: [, setIsErasing],
    stickerTabBool: [stickerTabBool],
    isMultiMaskMode: [isMultiMaskMode, setIsMultiMaskMode],
    isHovering: [, setIsHovering],
    showLoadingModal: [, setShowLoadingModal],
    eraserText: [, setEraserText],
    predMask: [predMask, setPredMask],
    predMasks: [predMasks, setPredMasks],
    predMasksHistory: [predMasksHistory],
    isLoading: [, setIsLoading],
    maskCanvas: [maskCanvas,]
  } = useContext(RealtimeSegmentContext)!;
  const [model, setModel] = useState<InferenceSession | null>(null);
  const [multiMaskModel, setMultiMaskModel] = useState<InferenceSession | null>(
    null
  );
  const [tensor, setTensor] = useState<Tensor | null>(null);
  const [hasClicked, setHasClicked] = useState<boolean>(false);
  const [mask, setMask] = useState<
    | string[]
    | Uint8Array
    | Float32Array
    | Int8Array
    | Uint16Array
    | Int16Array
    | Int32Array
    | BigInt64Array
    | Float64Array
    | Uint32Array
    | BigUint64Array
    | string
    | null
  >(null);
  const [modelScale, setModelScale] = useState<modelScaleProps | null>(null);
  const [selectImage, setSelectImage] = useState<any>(null);
  const [showRealTime, setIsShowRealTime] = useState<boolean>(false)
  const handleSegModelResults = ({ tensor }: { tensor: Tensor }) => {
    setTensor(tensor);
    setIsErasing(false);
    setShowLoadingModal(false);
    setEraserText({ isErase: false, isEmbedding: false });
  };

  const handleResetState = (showLoadingModal?: boolean) => {
    setMaskImg(null);
    setHasClicked(false);
    setClick(null);
    setClicks(null);
    setSVG(null);
    setSVGs(null);
    setAllsvg(null);
    setTensor(null);
    setImage(null);
    setPrevImage(null);
    setPredMask(null);
    setIsErased(false);
    setShowLoadingModal(!!showLoadingModal);
    setIsModelLoaded({ boxModel: false, allModel: false });
    setSegmentTypes("Click");
    setIsMultiMaskMode(false);
    setIsHovering(null);
    setPredMasks(null);
  };

  const handleAllModelResults = ({
    allJSON,
    image_height,
  }: {
    allJSON: {
      encodedMask: string;
      bbox: number[];
      score: number;
      point_coord: number[];
      uncertain_iou: number;
      area: number;
    }[];
    image_height: number;
  }) => {
    const allMaskSVG = allJSON.map(
      (el: {
        encodedMask: string;
        bbox: number[];
        score: number;
        point_coord: number[];
        uncertain_iou: number;
        area: number;
      }) => {
        const maskenc = LZString.decompressFromEncodedURIComponent(
          el.encodedMask
        );
        const svg = traceCompressedRLeStringToSVG(maskenc, image_height);
        return { svg: svg, point_coord: el.point_coord };
      }
    );
    setAllsvg(allMaskSVG);
    setIsModelLoaded((prev) => {
      return { ...prev, allModel: true };
    });
  };

  const handleSelectedImage = async (
    data: File | URL | string,
    options?: { shouldNotFetchAllModel?: boolean; shouldDownload?: boolean }
  ) => {
    try {
      const shouldNotFetchAllModel = options?.shouldNotFetchAllModel;
      const shouldDownload = options?.shouldDownload;
      handleResetState(true);
      let imgName: string = "";
      if (data instanceof URL) {
        imgName = data.pathname;
      } else if (typeof data === "string") {
        imgName = new URL(data).pathname;
      }
      imgName = imgName.substring(imgName.lastIndexOf("/") + 1);
      const imgData: File = data instanceof File ? data : await getFile(data);
      const img = new Image();
      img.src = URL.createObjectURL(imgData);
      img.onload = () => {
        const { height, width, scale, uploadScale } = handleImageScale(img);
        setModelScale({
          onnxScale: scale / uploadScale,
          maskWidth: width * uploadScale,
          maskHeight: height * uploadScale,
          scale: scale,
          uploadScale: uploadScale,
          width: width,
          height: height,
        });
        img.width = Math.round(width * scale);
        img.height = Math.round(height * scale);
        setImage(img);
        setPrevImage(img);
        setIsErased(false);

      };
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectedImageRealTime = async (
    data: File | URL | string,
    options?: { shouldNotFetchAllModel?: boolean; shouldDownload?: boolean }
  ) => {
    try {
      const shouldNotFetchAllModel = options?.shouldNotFetchAllModel;
      const shouldDownload = options?.shouldDownload;
      handleResetState(true);
      let imgName: string = "";
      if (data instanceof URL) {
        imgName = data.pathname;
      } else if (typeof data === "string") {
        imgName = new URL(data).pathname;
      }
      imgName = imgName.substring(imgName.lastIndexOf("/") + 1);
      const imgData: File = data instanceof File ? data : await getFile(data);
      const img = new Image();
      img.src = URL.createObjectURL(imgData);
      img.onload = () => {
        const { height, width, scale, uploadScale } = handleImageScale(img);
        setModelScale({
          onnxScale: scale / uploadScale,
          maskWidth: width * uploadScale,
          maskHeight: height * uploadScale,
          scale: scale,
          uploadScale: uploadScale,
          width: width,
          height: height,
        });
        img.width = Math.round(width * scale);
        img.height = Math.round(height * scale);
        setImage(img);
        setPrevImage(img);
        setIsErased(false);
        setParmsandQueryModel({
          width,
          height,
          uploadScale,
          imgData: img,
          handleSegModelResults,
          handleAllModelResults,
          imgName,
          shouldDownload,
          shouldNotFetchAllModel,
          setShowLoadingModal
        });
      };
    } catch (error) {
      console.log(error);
    }
  };

  const runMultiMaskModel = async () => {
    try {
      if (
        multiMaskModel === null ||
        clicks === null ||
        tensor === null ||
        modelScale === null ||
        !hasClicked // only run for clicks
      )
        return;
      if (stickerTabBool) return;
      const feeds = modelData({
        clicks,
        tensor,
        modelScale,
        last_pred_mask: null, // Only 1 click allowed, so no last predicted mask exists
      });
      if (feeds === undefined) return;
      const results = await multiMaskModel.run(feeds);

      const output = results["output"];
      const areas = results["areas"].data;
      const uncertain_ious = results["uncertain_ious"].data;
      const ious = results["ious"].data;

      const allMasks = getAllMasks(
        output.data,
        output.dims[2], // height
        output.dims[1] // width
      ); // There are 3

      // allMasksSorted will be a list of 1-3 masks, sorted by area.
      // The best mask is selected for rendering on the collapsed canvas.
      // You can loop through allMasksSorted
      // and render each one onto a separate layer in the multi
      // mask animation
      let sorted_indices = sortAndReturnIndices(
        // @ts-ignore
        Array(Number(areas[1]), Number(areas[2]), Number(areas[3]))
      ); // Keep only masks indices 1, 2, 3
      sorted_indices.reverse();

      let allMasksSorted = sortByIndices(
        [allMasks[1], allMasks[2], allMasks[3]],
        sorted_indices
      ); // Keep only 3
      let allUncertainIoUSorted = sortByIndices(
        [uncertain_ious[1], uncertain_ious[2], uncertain_ious[3]],
        sorted_indices
      );
      let allOverlapIoUsSorted = sortByIndices(
        [ious[0], ious[1], ious[2]], // Only 3 of these, not 4
        sorted_indices
      );

      // Filter bad and duplicate masks
      const keepArray = keepArrayForMultiMask(
        allUncertainIoUSorted,
        allOverlapIoUsSorted
      );
      allMasksSorted = allMasksSorted.filter(
        (obj: any, i: number) => keepArray[i]
      );
      allUncertainIoUSorted = allUncertainIoUSorted.filter(
        (obj: any, i: number) => keepArray[i]
      );

      // Trace remaining masks
      const svgStrs = allMasksSorted.map((mask) =>
        traceOnnxMaskToSVG(mask, output.dims[2], output.dims[1])
      );

      setSVGs(svgStrs.reverse());

      allUncertainIoUSorted = allUncertainIoUSorted.reverse();
      const bestIdx = allUncertainIoUSorted.indexOf(
        Math.max(...allUncertainIoUSorted)
      );
      setSVG(svgStrs[bestIdx]);

      // !!!!Multiple clicks are not allowed!!!
      setClick(null);
      setIsLoading(false);
      setIsModelLoaded((prev: any) => {
        return { ...prev, boxModel: true };
      });
    } catch (e) {
      // console.log(e);
    }
  };

  const sortAndReturnIndices = (arr: Array<number>) => {
    const indices = Array.from(arr.keys());
    indices.sort((a, b) => arr[b] - arr[a]);
    return indices;
  };

  const sortByIndices = (items: any, indices: Array<number>) => {
    const result = [];
    for (var i = 0; i < indices.length; i++) {
      result.push(items[indices[i]]);
    }
    return result;
  };

  const runModel = async () => {
    try {
      if (
        model === null ||
        clicks === null ||
        tensor === null ||
        modelScale === null
      ) {
        return;
      }
      if (stickerTabBool) return;
      const feeds = modelData({
        clicks,
        tensor,
        modelScale,
        last_pred_mask: predMask,
      });
      if (feeds === undefined) return;
      const results = await model.run(feeds);
      const output = results[model.outputNames[0]];
      if (hasClicked) {
        const pred_mask = results[model.outputNames[1]];
        setPredMask(pred_mask);
        if (!predMasksHistory) {
          setPredMasks([...(predMasks || []), pred_mask]);
        }
        const svgStr = traceOnnxMaskToSVG(
          output.data,
          output.dims[1],
          output.dims[0]
        );
        setSVG(svgStr);
        setMask(output.data);
      } else {
        setMaskImg(rleToImage(output.data, output.dims[0], output.dims[1]));
      }
      setClick(null);
      setIsLoading(false);
      setIsModelLoaded((prev: any) => {
        return { ...prev, boxModel: true };
      });
    } catch (e) { }
  };

  const onClickShowModalOpenPose = () => {
    setIsOpenModalOpenPose(true)
  }

  const handleEditByRealtimeSegment = async () => {
    try {
      const { width, height } = await getImageSize(cropData)
      if (mask) {
        const maskImage = rleToCanvasBlackWhite(mask, width, height).toDataURL() // black-white image.
        const maskedImage = await maskToColorImage(cropData, mask, width, height); // rgba image
        onSetSegment(maskImage)
        const blueImage = await changeToBlueImage('data:image/png;base64,' + maskedImage)
        blueImage && setSegmentImagesWithBody(blueImage)
      }
      dispatch(setIsAuto.setIsAuto(false))
      setIsOpenModalEditCloth(false)
    } catch (error) {
      console.log('error', error);
    }
  };

  // ================== //

  const onToRemove = () => {
    onChangeUrlImage("");
    setCropData("");
    setSegmentImages("");
    setImagePrevPose('')
    setDataImagePose('', [])
    setSegmentImagesWithBody("");
    setArrayMaskSelected([]);
    if (intervalId) {
      clearInterval(intervalId);
      setLoading(false);
    }
    dispatch(editorImage.resetImageEditor())
    dispatch(toolEditorImage.resetImageEditor())
    dispatch(setIsAutoPose.setIsAutoPose(true))
    setIsOpenModalSelect(false);
    setFashionParams({
      ...fashionParams,
      maskImage: "",
      poseImage: ""
    });
    dispatch(realtimeSegmentActions.resetRealtimeSegment());
  };

  const handleImageUpload = async (e: any) => {
    e.preventDefault();
    let files = e.target?.files;
    if (files) {
      logEvent(analytics, "upload_image");
    }
    if (!files || !files.length) {
      console.warn("No file selected");
      return;
    }
    e.target.value = null;
  };

  const getDataPose = async (image: string, width: number, height: number) => {
    poseDataFromAPI.current = {};
    const body = { image, width, height, poseId: fashionParamsReducer.poseId }
    const dataPose = await FashionService.getInstance().getDataPose(body);
    poseDataFromAPI.current = {
      imagePrePose: 'data:image/png;base64,' + dataPose.images[0],
      dataOpenPose: dataPose.poses[0].people[0].pose_keypoints_2d,
      width: width,
      height: height
    }
  }

  const listenJob = async (jobId: string) => {
    let timeout = 180000;
    const interval = setInterval(() => {
      try {
        if (jobId) {
          FashionService.getInstance()
            .listenSegmentResult(jobId)
            .then((res: any) => {
              if (res.status === "FINISHED") {
                const data = res.result;
                if (data.length < 2) {
                  swal(
                    t("error_image_dont_segment"),
                    t("please_try_again"),
                    "error"
                  ).then((res) => {
                    onToRemove();
                  });
                }
                dispatch(setIsAuto.setIsAuto(true));
                const element = document.getElementById(
                  "upload-image-section"
                );
                element &&
                  element.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest",
                  });
                dispatch(setError.setError("no_upload"));
                const annotations = res.annotations;
                listenDetectPose(annotations, data);
                clearInterval(interval);
              } else if (res.status === "FAILED") {
                setLoading(false);
                swal(
                  t("error_image_dont_segment"),
                  t("please_try_again"),
                  "error"
                ).then((res) => {
                  onToRemove();
                });
                clearInterval(interval);
              }
            });
        }
      } catch (error: any) {
        console.error(error.message);
        swal(error.message, "", "error");
        logEvent(analytics, `server_error_${customizeKey(error.message)}`);
        clearInterval(interval);
        setLoading(false);
      }
      timeout -= 2000;
      if (timeout <= 0) {
        clearInterval(interval);
        setLoading(false);
      }
    }, 2000);
    setIntervalId(interval);
  };

  const listenDetectPose = async (annotations: any, data: any) => {
    if (poseDataFromAPI.current.dataOpenPose) {
      setImagePrePose(poseDataFromAPI.current.imagePrePose)
      setDataOpenPose(poseDataFromAPI.current.dataOpenPose)
      setSizePose({
        width: poseDataFromAPI.current.width,
        height: poseDataFromAPI.current.height,
      })
      setFashionParams((prevParams: FashionParams) => ({
        ...prevParams,
        poseData: poseDataFromAPI.current.dataOpenPose,
        poseImage: poseDataFromAPI.current.imagePrePose,
      }));
      setDataAnnotations(annotations);
      //TODO: change to blue color
      const blueImage = await changeToBlueImage('data:image/png;base64,' + data[1])

      blueImage && setSegmentImagesWithBody(blueImage);
      onChangeMaskImage(data[0], data[1], null);
      setLoading(false);
    } else {
      let timeout = 180000;
      const interval = setInterval(async () => {
        if (poseDataFromAPI.current.dataOpenPose) {
          setImagePrePose(poseDataFromAPI.current.imagePrePose)
          setDataOpenPose(poseDataFromAPI.current.dataOpenPose)
          setSizePose({
            width: poseDataFromAPI.current.width,
            height: poseDataFromAPI.current.height,
          })
          setFashionParams((prevParams: FashionParams) => ({
            ...prevParams,
            poseData: poseDataFromAPI.current.dataOpenPose,
            poseImage: poseDataFromAPI.current.imagePrePose,
          }));
          setDataAnnotations(annotations);
          //TODO: change to blue color
          const blueImage = await changeToBlueImage('data:image/png;base64,' + data[1])
          blueImage && setSegmentImagesWithBody(blueImage);
          onChangeMaskImage(data[0], data[1], null);
          clearInterval(interval);
          setLoading(false);
        }

        timeout -= 1000;
        if (timeout <= 0) {
          clearInterval(interval);
          setLoading(false);
        }
      }, 1000);
      setIntervalId(interval);
    }

  };

  useEffect(() => {
    (async () => {
      if (outputTool.outputImage && outputTool.widthImage > 0 && outputTool.heightImage > 0) {
        await getCropData(outputTool.outputImage, outputTool.widthImage, outputTool.heightImage)
        await getDataPose(outputTool.outputImage, outputTool.widthImage, outputTool.heightImage)
        setSelectImage(outputTool.outputImage)
      }
    })();
  }, [outputTool])


  const getCropData = async (image: string, width: number, height: number) => {
    setImageSegment({ image, width, height });
    let samSpecialId;
    let type;
    setLoading(true);
    try {
      if (clothesType === FashionClothesEnum.DRESS) {
        const data = await FashionService.getInstance().processSegment({
          type: "woman",
          image,
          version: 2,
        });
        listenJob(data);
        samSpecialId = data;
        type = "body";
      } else {
        const data = await FashionService.getInstance().processSegment({
          type: "man",
          image,
          version: 2,
        });
        listenJob(data);
        samSpecialId = data;
        type = "body";
      }
    } catch (error: any) {
      swal(error.message, "", "error");
      logEvent(analytics, `server_error_${customizeKey(error.message)}`);
      setLoading(false);
    }
    onChangeUrlImage(image, width, height);
    setCropData(image);
    if (samSpecialId && type) {
      setGeneratingSamData({ samSpecialId, type });
    }
  };

  const initModel = async () => {
    try {
      const MODEL_DIR =
        process.env.MODEL_DIR ??
        "/model/interactive_module_quantized_592547_2023_03_19_sam6_long_uncertain.onnx";
      if (MODEL_DIR === undefined) return;
      const URL: string = MODEL_DIR;
      const model = await InferenceSession.create(URL);
      setModel(model);
    } catch (e) {
      console.log(e);
    }
    try {
      const MULTI_MASK_MODEL_DIR =
        process.env.MULTI_MASK_MODEL_DIR ??
        "/model/interactive_module_quantized_592547_2023_03_20_sam6_long_all_masks_extra_data_with_ious.onnx";
      if (MULTI_MASK_MODEL_DIR === undefined) return;
      const URL2: string = MULTI_MASK_MODEL_DIR;
      const multiMaskModel = await InferenceSession.create(URL2);
      setMultiMaskModel(multiMaskModel);
    } catch (e) {
      console.log(e);
    }
  };

  const onClickShowModalEdit = async () => {
    await handleSelectedImage(selectImage);
    setIsOpenModalEditCloth(true);
  };

  const onClickSelectSample = () => {
    setIsOpenModalSelect(true);
    logEvent(analytics, "popup_select_sample");
  };

  if (cropData) {
    dispatch(setError.setError(""));
  }

  const onClickShowModalOpenPoseAndSegment = async () => {
    await handleSelectedImage(selectImage);
    setIsOpenModalEditCloth(true)
  }


  // ========= USE EFFECT SECTION ========= //
  useEffect(() => {
    (async () => {
      const imagePrev = await removeBlackImage(imagePrePose)
      imagePrev && setImagePrevPose(imagePrev)
    })()
  }, [imagePrePose, fashionParamsReducer.isClickCopySetting])

  useEffect(() => {
    const runOnnx = async () => {
      if (isMultiMaskMode) {
        if (hasClicked) {
          runMultiMaskModel();
        }
      } else {
        runModel();
      }
    };
    runOnnx();
  }, [clicks, hasClicked, isMultiMaskMode]);
  useEffect(() => {
    if (segmentImagesWithBody && imagePrevPose && cropData) {
      mergeImages([
        {
          src: cropData,
          opacity: 1
        },
        {
          src: segmentImagesWithBody,
          opacity: 0.5,

        },
        {
          src: imagePrevPose,
          opacity: 1
        }
      ], {

      })
        .then((b64) => {
          setMergedImage(b64)
        });
    } else {
      if (!imagePrevPose) {
        setMergedImage(segmentImagesWithBody)
      }
    }


  }, [segmentImagesWithBody, imagePrevPose, cropData])
  useEffect(() => {
    if (isOpenModalEditCloth && showRealTime && selectImage) {
      setFirstOpen(true)
    }
    if (!isOpenModalEditCloth) { setFirstOpen(false); setIsShowRealTime(false) }
  }, [isOpenModalEditCloth, showRealTime, selectImage])

  useEffect(() => {
    (async () => {
      if (firstOpen) {
        await initModel();
        await handleSelectedImageRealTime(selectImage)
      }
    })();
  }, [firstOpen, selectImage])

  useEffect(() => {
    setCropData("");
    setLoading(false);
    setSegmentImages("");
    setSegmentImagesWithBody("");
    if (intervalId) {
      clearInterval(intervalId);
    }
  }, [pose]);

  useEffect(() => {
    onToRemove();
    setFashionParams(initValue);
  }, [typeModel]);

  useEffect(() => {
    (async () => {
      onToRemove();
      setValueWeight(fashionParamsReducer.controlWeight)
      if (
        fashionParamsReducer.annotations &&
        fashionParamsReducer.annotations.length > 0 &&
        fashionParamsReducer.segment &&
        fashionParamsReducer.originImageUrl
      ) {
        let newBase64: string = fashionParamsReducer.segment[0];
        if (!fashionParamsReducer.segment[0].startsWith('data:image')) {
          // If it already contains base64 data, assign it
          newBase64 = 'data:image/png;base64,' + fashionParamsReducer.segment[0];
        }
        const base64Image = await convertBase64Image(newBase64)
        setSegmentImagesWithBody(base64Image);
        setDataAnnotations(
          JSON.parse(JSON.stringify(fashionParamsReducer.annotations))
        );
        const urlBase64 = await urlToBase64(fashionParamsReducer.originImageUrl + "?not-from-cache-please");
        const { width, height } = await getImageSize(fashionParamsReducer.originImageUrl + "?not-from-cache-please")
        setCropData(`data:image/png;base64,${urlBase64}`);
        setSelectImage(`data:image/png;base64,${urlBase64}`)
        setImageSegment({
          image: `data:image/png;base64,${urlBase64}`,
          width,
          height
        });
        onChangeUrlImage(
          `data:image/png;base64,${urlBase64}`,
          width,
          height
        );
        setGeneratingSamData({});
      }
    })()
  }, [fashionParamsReducer.isClickCopySetting]);

  useEffect(() => {
    if (isGenerating || isGoHome)
      setIsOpenModalSelect(false)
  }, [isGenerating, isGoHome])

  useEffect(() => {
    isLoadingSegment && setLoading(isLoadingSegment)
    return () => {
      dispatch(realtimeSegmentActions.resetRealtimeSegment());
    };
  }, [isLoadingSegment]);

  return (
    <div>
      <div
        className="text-sm font-semibold text-gray-800 flex gap-1"
        id="upload-image-section"
      >
        1. {t("upload_fashion")}
        {errorType === "no_upload" && (
          <div className="text-[red] text-xl">*</div>
        )}
      </div>
      <div className={`upload flex flex-col justify-center items-center mt-2 ${cropData ? "overflow-x-auto" : ""
        }`}>
        {!cropData ? (
          <>
            <div
              ref={imgUploadRef}
              onClick={(e) => onClickSelectSample()}
              className=" step-upload icon-upload cursor-pointer flex flex-col justify-center items-center w-full h-[150px] rounded-lg box-upload hover:bg-gray-300"
            >
              <input
                id="input-upload-image"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden"
              />
              <img src={iconUpload} alt="icon-upload" />
              <span className="text-gray">{t("Process_Segment")}</span>
            </div>
            {isOpenModalSelect && (
              <ModalSelectInput
                openModalTool={openModalTool}
                setOpenModalTool={setOpenModalTool}
                typeModel={typeModel}
                getDataPose={getDataPose}
                isOpen={isOpenModalSelect}
                setIsOpenModalSelect={setIsOpenModalSelect}
                getCropData={getCropData}
                onChangeUrlImage={onChangeUrlImage}
                // setSegmentImages={setSegmentImages}
                setSegmentImagesWithBody={setSegmentImagesWithBody}
                intervalId={intervalId}
                clearInterval={clearInterval}
                setLoading={setLoading}
                onToRemove={onToRemove}
                onChangePose={onChangePose}
                cropData={cropData}
                selectImage={(img: any) => setSelectImage(img)}
              />
            )}
          </>
        ) : (
          <div className="flex gap-4 sm:flex-col md:flex-col lg:flex-col w-full pb-2">
            <div className="initial-img">
              <div className=" ">
                <div className="icon-upload img-preview w-[320px] h-[426px]  border border-solid border-black relative  flex flex-col justify-center items-center  rounded-lg  hover:bg-gray-300">
                  <div
                    style={{
                      opacity: loading ? "0.5" : "1",
                      backgroundImage: `url(${cropData})`,
                      backgroundPosition: "center",
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                    id="img-upload"
                    className="rounded-lg w-[320px] h-[426px]"
                  />
                  {loading && (
                    <div>
                      <Spin className="loading absolute top-1/2 left-1/2" />
                      <p className="absolute top-[55%] left-[0px] text-center px-2">
                        {t("waiting_moment")}
                      </p>
                    </div>
                  )}
                  <Tooltip
                    title={t("click_to_remove")}
                    color={"red"}
                    key={"red"}
                    className="cursor-pointer"
                  >
                    <DeleteOutlined
                      onClick={onToRemove}
                      className=" btn-delete-image absolute  text-gray rounded "
                    />
                  </Tooltip>
                </div>
                <div className="text-center mt-2 text-gray text-sm">
                  {t("Original")}
                </div>
              </div>
            </div>
            {segmentImagesWithBody && <div className="segment_openpose">
              <div className='flex flex-row w-full' >
                <div className="flex gap-2 sm:flex-col md:flex-col lg:flex-col">
                  <div>
                    <div style={{
                      backgroundImage: `url(${mergedImage})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      alignItems: 'flex-end',
                      zIndex: 0
                    }} className=' relative icon-upload justify-center img-preview bg-gray-300 flex border border-solid border-black rounded-lg items-center overflow-hidden
                            w-[320px] h-[426px]
                            '>
                      <Tooltip
                        title={t("guide_edit_pose")}
                        className="cursor-pointer !z-[99]"
                      >
                        <InfoCircleOutlined
                          className=" btn-delete-image absolute  text-gray rounded "
                        />
                      </Tooltip>
                      <div className='flex gap-2 justify-center'>
                        <div onClick={e => onClickShowModalOpenPoseAndSegment()} className='btn manual bg-[#0047FF] text-center rounded-lg min-w-[100px] z-[99] p-2 mb-3 cursor-pointer text-white hover:opacity-80 '>
                          {t('open_pose')}
                        </div>
                      </div>
                    </div>
                    <div className='text-center mt-2 w-[320px] text-gray text-sm'>{t('guide_edit_pose_title')}</div>
                  </div>
                </div>
              </div>
            </div>}
            {/* {isOpenModalOpenPose && <ModalOpenPose
              valueWeight={valueWeight}
              setValueWeight={setValueWeight}
              setDataOpenPose={setDataOpenPose}
              isOpen={isOpenModalOpenPose}
              setIsOpenModalOpenPose={setIsOpenModalOpenPose}
              widthImage={sizePose.width} heighImage={sizePose.height}
              image={cropData}
              pose={dataOpenPose}
              setImageResultPose={setDataImagePose}
              fashionParams={fashionParams}
              setFashionParams={setFashionParams}
            />} */}
            {/* {isOpenModalEdit && (
              <ModalEditRealtimeSegment
                showRealTime={showRealTime}
                setIsShowRealTime={setIsShowRealTime}
                isOpenModal={isOpenModalEditCloth}
                urlSegmentManual={imageSegment}
                urlSegment={image}
                setIsOpenModalEdit={setIsOpenModalEdit}
                hasClicked={hasClicked}
                setHasClicked={setHasClicked}
                modelScale={modelScale}
                widthSegmentRealtime={imageSegment.width}
                heightSegmentRealtime={imageSegment.height}
                handleResetState={handleResetState}
                dataAnnotations={dataAnnotations}
                handleEditByRealtimeSegment={handleEditByRealtimeSegment}
                onSetSegment={onSetSegment}
                // setArrayMaskSelected={setArrayMaskSelected}
                setSegmentImagesWithBody={setSegmentImagesWithBody}
              ></ModalEditRealtimeSegment>
            )} */}
          </div>
        )}
        {isOpenModalEditCloth && <ModalEditSegmentAndPose
          imageSegment={imageSegment}
          showRealTime={showRealTime}
          setIsShowRealTime={setIsShowRealTime}
          isOpenModal={isOpenModalEditCloth}
          urlSegment={image}
          setIsOpenModalEdit={setIsOpenModalEditCloth}
          hasClicked={hasClicked}
          setHasClicked={setHasClicked}
          modelScale={modelScale}
          handleResetState={handleResetState}
          dataAnnotations={dataAnnotations}
          handleEditByRealtimeSegment={handleEditByRealtimeSegment}
          onSetSegment={onSetSegment}
          // setArrayMaskSelected={setArrayMaskSelected}
          setSegmentImagesWithBody={setSegmentImagesWithBody}
          valueWeight={valueWeight}
          setValueWeight={setValueWeight}
          setDataOpenPose={setDataOpenPose}
          widthImage={sizePose.width}
          heighImage={sizePose.height}
          image={cropData}
          pose={dataOpenPose}
          setImageResultPose={setDataImagePose}
          fashionParams={fashionParams}
          setFashionParams={setFashionParams}
        />}
      </div>
    </div>
  );
};
export default UploadImage;
