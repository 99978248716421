import { createSlice } from "@reduxjs/toolkit";

interface ModeActive {
    modeActive: string;
}

const initialState: ModeActive = {
    modeActive: "account",
};

const tabActive = createSlice({
    name: "setModeActive",
    initialState,
    reducers: {
        setModeActive: (state, action) => {
            state.modeActive = action.payload;
        },
    },
});

export const ModeActive = tabActive.actions;
export default tabActive.reducer;
