import { logEvent } from '@firebase/analytics';
import { Button, InputNumber } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import AdvancedSetting from '../../components/AdvancedSetting/AdvancedSetting';
import Customize from '../../components/Customize/Customize';
import ListRenderedImages from '../../components/ListRenderedImages/ListRenderedImages';
import MenuMain from '../../components/MenuMain';
import UploadImage from '../../components/UploadImage/UploadImage';
import analytics from '../../firebase';
import PopupForNewUser from '../../PopupForNewUser';
import { isGenStore } from '../../redux/app/appIsGenerating';
import { setIsDisplayHeader } from '../../redux/app/appScroll';
import { userSaga } from '../../redux/app/authSaga';
import { setError } from '../../redux/app/errorSelect';
import { setTypeModel } from '../../redux/app/selectTypeModel';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import AccountService from '../../services/account.service';
import FashionService from '../../services/fashion.service';
import { FashionClothesEnum } from '../../utils/enum';
import { customizeKey, generateRandomNumberString, urlToBase64 } from '../../utils/function';
import { CustomItems, FashionParams, IImage, IImageGen } from '../../utils/interface';
import NotifyController from '../../utils/toast';
import './styles.css';

export const initValue = {
  clothesType: FashionClothesEnum.DRESS,
  clothesName: 'Váy nữ',
  isPose: false,
  initImage: '',
  templateName: '',
  prompt: '',
  negativePrompt: '',
  cfgScale: 7,
  samplingStep: 20,
  numberOfImages: 4,
  width: 0,
  height: 0,
  maskImage: '',
  denoisingStrength: 0.75,
  controlWeight: 1.00,
  BGWeight: 1.00,
  watermark: '',
  userPrompt: '',
  userNegativePrompt: '',
  templateCode: '',
}
const NUMBER_GET_FB = 5;
const Home = (props: any) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation()

  // TODO [SPM]
  const isGenerating = useAppSelector((store) => store.isGeneratingImage.isGen)
  const clothesType = useAppSelector((store) => store.select.typeModel);
  const dataCost: any = useAppSelector((store) => store.costFeature);
  const clothesName = useAppSelector((store) => store.select.typeName);
  const fashionParamsReducer = useAppSelector((store) => store.select);
  const userInfo = useAppSelector((store) => store.user);
  const autoMask = useAppSelector((store) => store.segmentAuto);
  const isAutoPose = useAppSelector((store) => store.PoseAuto.isAutoPose)
  const outputTool = useAppSelector((store) => store.toolEditor)
  // const [isGenerating, setIsGenerating] = useState(false);
  const [generatingSamData, setGeneratingSamData] = useState<{ samSpecialId: string, type: 'dress' | 'body' } | undefined>();
  const [templates, setTemplates] = useState<any>([]);
  const [images, setImages] = useState<IImage[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [menu, setMenu] = useState<any>([]);
  const [selectedCustomItems, setSelectedCustomItems] = useState<CustomItems>();
  const [fashionParams, setFashionParams] = useState<any>(fashionParamsReducer);
  const [listGenerating, setListGenerating] = useState<IImageGen[]>([]);
  const [cost, setCost] = useState<number>(dataCost.primaryGen as number);
  const [inputNumberImages, setInputNumberImages] = useState(4);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isShowHeader, setIsShowHeader] = useState(true);
  const [isNewUser, setIsNewUser] = useState(true);
  const [countGen, setCountGen] = useState(NUMBER_GET_FB)
  const [imagePrePose, setImagePrePose] = useState('');
  const [sizePose, setSizePose] = useState({
    width: 0,
    height: 0
  });
  const [dataOpenPose, setDataOpenPose] = useState<number[]>([]);
  const [openModalTool, setOpenModalTool] = useState(false);

  const imgUploadRef = useRef<any>(null);
  const selectTemplateRef = useRef<any>(null);
  const resultRef = useRef<any>(null);

  const onChangePose = (key: boolean) => {
    setFashionParams({ ...fashionParams, isPose: key });
  }
  const setIsGenerating = (isGen: boolean) => {
    dispatch(isGenStore.setIsGeneratingStore(isGen))
  }
  const onChangeUrlImage = (url: string, width: number, height: number) => {
    setFashionParams((prevParams: FashionParams) => ({
      ...prevParams,
      initImage: url,
      width: width,
      height: height,
    }));
    // dispatch(setTypeModel.setFashionParams(fashionParams))
  }

  const onChangeMaskImage = (maskImage: string) => {
    setFashionParams((prevParams: FashionParams) => ({
      ...prevParams,
      maskImage,
    }));
  }

  const onSetSegment = (maskImage: string) => {
    setFashionParams((prevParams: FashionParams) => ({
      ...prevParams,
      maskImage,
    }));
  }

  const onSetPose = (poseImage: string, poseData: number[]) => {
    setFashionParams((prevParams: FashionParams) => ({
      ...prevParams,
      poseImage,
      poseData
    }));
  }

  useEffect(() => {
    if (outputTool.outputImage) {
      onChangeUrlImage(outputTool.outputImage, outputTool.widthImage, outputTool.heightImage)
    }
  }, [outputTool])

  const Generate = () => {
    const onChangeNumberOfImages = (value: any) => {
      setCost(value * dataCost.primaryGen)
      setInputNumberImages(value)
      setFashionParams((prevParams: FashionParams) => ({
        ...prevParams,
        numberOfImages: value,
      }));
      dispatch(setTypeModel.setNumberOfImages(value))
    }

    return (
      <div className='box-gen p-4 bg-[#F5F5F5]'>
        <h4 className=' text-base font-semibold text-black mb-2'>{t('number_of_images')}</h4>
        <div className='flex pb-2 gap-4'>
          <InputNumber
            min={1}
            max={4}
            pattern="[0-9]*"
            inputMode='numeric'
            style={{ width: '100%' }}
            className='rounded-lg'
            step={1}
            value={inputNumberImages}
            onChange={(e) => onChangeNumberOfImages(e)}
          />
          <Button style={{ width: '100%' }} type='primary' className='btn-gen rounded-lg' disabled={isDisable} onClick={onGenerateImage} >
            <div className='flex justify-center'>
              {t('generate')}
              <span className='negative-credits mr-1 ml-1'>
                (-{cost} Credits)</span>
            </div>
          </Button>
        </div>
        <div onClick={() => props.setOpenPaymentModal(true)} className='cursor-pointer flex justify-center tracking-credits flex-col items-center'>
          <p>{!userInfo.subscription.name && <span>{t('Free_gen')}: {userInfo.genNumber}/{userInfo.maxGenNumber} {t('gens')}</span>}</p>
        </div>
      </div>
    )
  }

  const getImages = async (page: number, limit: number) => {
    const data = await FashionService.getInstance().getImages(page, limit, i18n.language);
    setImages(data.images);
    setPagination(data.pagination);
  }

  const getCustomItems = async (templateCode?: string) => {
    // dispatch(setLoadingApp.setLoading(true))
    const data = await FashionService.getInstance().getCustomItems(i18n.language, templateCode ?? fashionParams.templateCode, clothesType);
    data && setIsLoading(true)
    setSelectedCustomItems(data.data);
    setMenu(data.menu);
    if (!clothesType && !clothesName) {
      dispatch(setTypeModel.setTypeModel(data.type));
      dispatch(setTypeModel.setTypeName(data.name));
    }
    const selectedItems: any[] = [];
    for (const item of data.data) {
      if (item.code === 'place') continue;
      for (const item2 of item.data) {
        if (item2.is_default) {
          if (item.multiChoice) {
            if (Array.isArray(selectedItems) && selectedItems.some(ele => ele.code === item.code)) {
              selectedItems.filter(ele => ele.code === item.code)[0].data.push(item2.code);
            } else {
              selectedItems.push({
                code: item.code,
                data: [item2.code],
                other: false
              })
            }
          } else {
            selectedItems.push({
              code: item.code,
              data: item2.code,
              other: false
            })
            break;
          }
        }
      }
    }
    setFashionParams((prevParams: FashionParams) => ({
      ...prevParams,
      selectedItems,
    }));
    // dispatch(setLoadingApp.setLoading(false))
  }

  const getTemplates = async (clothesType: string) => {
    const data = await FashionService.getInstance().getTemplates(clothesType, i18n.language);
    setTemplates(data);
  };
  const onGenerateImage = async () => {
    logEvent(analytics, 'click_gen');
    try {
      if (userInfo.coins < dataCost.primaryGen) {
        NotifyController.warning(t('Not_enough_credit'))
        props.setOpenPaymentModal(true);
        return;
      }
      if (!fashionParams.initImage) {
        swal(t('Please_select_an_image'), '', 'warning').then((res) => {
          const element = document.getElementById('upload-image-section');
          element && element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
          dispatch(setError.setError('no_upload'))
        });
        return;
      };

      if (!fashionParams.templateName || !fashionParams.templateCode) {
        swal(t('dont_select_modal'), '', 'warning').then((res) => {
          selectTemplateRef?.current?.scrollIntoView({ behavior: 'smooth' });
        });
        return;
      }

      if (fashionParams.numberOfImages < 1 && fashionParams.numberOfImages > 4) {
        swal(t('Number_of_images_invalid'), '', 'warning');
        return;
      }

      let dataBody: any = {};
      if (!fashionParams.maskImage) {
        // TODO: [SPM] handle it
        if (!generatingSamData) {
          swal(t("noti_choose_await"), "", "warning");
          return;
        }
        dataBody = {
          ...generatingSamData
        }

      }

      if (listGenerating.length === userInfo.maxConcurrent) {
        swal(t('queue_full'), '', 'warning')
        return;
      }
      setIsDisable(true)
      dataBody = {
        ...dataBody,
        width: fashionParams.width,
        height: fashionParams.height,
        batch_size: fashionParams.numberOfImages,
        denoisingStrength: fashionParams.denoisingStrength,
        cfgScale: fashionParams.cfgScale,
        samplingStep: fashionParams.samplingStep,
        maskImage: fashionParams.maskImage,
        maskedImage: fashionParams.maskImage, // Use for canny
        maskedBodyImage: null, // TODO: Openpose not need raw image, use skeleton image
        controlWeight: fashionParams.controlWeight,
        templateName: fashionParams.templateName,
        templateCode: fashionParams.templateCode,
        templateType: clothesName,
        initImage: fashionParams.initImage,
        userPrompt: fashionParams.userPrompt,
        userNegativePrompt: fashionParams.userNegativePrompt,
        typeCode: clothesType,
        poseId: fashionParamsReducer.poseId,
        isClickControlWeight: fashionParamsReducer.isClickControlWeight,
        isClickBGWeight: fashionParamsReducer.isClickBGWeight,
        selectedItems: fashionParams.selectedItems,
        autoMask: autoMask.isAuto,
        language: i18n.language,
        samSpecialId: generatingSamData?.samSpecialId || fashionParamsReducer.samSpecialId,
        autoPose: isAutoPose,
        poseImage: fashionParams.poseImage,
        poseData: fashionParams.poseData,
        backgroundImage: fashionParams.backgroundImage,
        BGWeight: fashionParams.BGWeight,
      }
      const generatingImage = [
        {
          specialId: generateRandomNumberString(10),
          negativePrompt: dataBody.userPrompt,
          prompt: dataBody.userNegativePrompt,
          percent: 0,
          templateName: dataBody.templateName,
          templateType: clothesName,
          text: 'Generating...',
          type: 'img2img_generate',
          numberOfImages: Array.from({ length: dataBody.batch_size }, (_, index) => index)
        },
        ...listGenerating
      ];
      setListGenerating(generatingImage);
      const taskID = await FashionService.getInstance().processImg2Img(dataBody);

      generatingImage[0].specialId = taskID
      if (taskID) {
        const element = document.getElementById('top-list');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
      setListGenerating(generatingImage);
      try {
        const dataUser = await AccountService.getInstance().getUserInfo();
        dispatch(userSaga.setUser(dataUser));
        logEvent(analytics, 'gen_success')
        setCountGen(countGen - 1)
        setIsGenerating(true)
        setTimeout(() => {
          setIsDisable(false)
        }, 3000)
      } catch (error) {
        swal(t('something_wrong'), "", "error");
      }
    } catch (error: any) {
      setIsDisable(false)
      setListGenerating([]);
      swal(t(error.message), '', 'error')
      logEvent(analytics, 'gen_fail')
      if (error.message === 'Insufficient coins') {
        props.setOpenPaymentModal(true)
      } else {
        console.log(error.message);
      }
      logEvent(analytics, `server_error_${customizeKey(error.message)}`)
    }
  }

  const handlePageChange = (page: number) => {
    getImages(page, 10);
  };

  const setDataImagePose = async (image: string, dataPose: number[]) => {
    onSetPose(image, dataPose);
    setImagePrePose(image)
  }
  const updateUserCredits = async () => {
    try {
      const { coins } = await AccountService.getInstance().getUserInfo();
      dispatch(userSaga.setCredits(coins));
    } catch (error) {
      swal(t('something_wrong'), "", "error");
    }
  }
  // Use Effect Hooks
  useEffect(() => {
    (async () => {
      await getTemplates(clothesType);
      if (fashionParamsReducer.templateCode)
        await getCustomItems(fashionParamsReducer.templateCode);
      setImagePrePose(fashionParamsReducer.poseImage)
      setDataOpenPose(fashionParamsReducer.poseData)
      setSizePose({
        width: fashionParamsReducer.width,
        height: fashionParamsReducer.height
      })
      const urlBase64 = await urlToBase64(fashionParamsReducer.poseImage);
      setFashionParams((prevParams: FashionParams) => ({
        ...prevParams,
        controlWeight: fashionParamsReducer.controlWeight,
        BGWeight: fashionParamsReducer.BGWeight,
        denoisingStrength: fashionParamsReducer.denoisingStrength,
        negativePrompt: fashionParamsReducer.negativePrompt,
        prompt: fashionParamsReducer.prompt,
        userPrompt: '',
        userNegativePrompt: '',
        samplingStep: fashionParamsReducer.samplingStep,
        templateName: fashionParamsReducer.templateName,
        templateCode: fashionParamsReducer.templateCode,
        selectedItems: fashionParamsReducer.selectedItems,
        maskImage: fashionParamsReducer.segment && fashionParamsReducer.segment[0],
        maskedImage: fashionParamsReducer.segment && fashionParamsReducer.segment[2],
        maskedBodyImage: fashionParamsReducer.segment && fashionParamsReducer.segment[3],
        poseImage: urlBase64,
        isAutoPose: isAutoPose,
        poseData: fashionParamsReducer.poseData,
        backgroundImage: fashionParamsReducer.backgroundImage,
      }));
    })()
    return () => {
      dispatch(setTypeModel.setOriginImageUrl(''));
    };
  }, [clothesType, i18n.language, fashionParamsReducer.isClickCopySetting])


  useEffect(() => {
    getImages(1, 10);
  }, [i18n.language, listGenerating.length, openModalTool])

  useEffect(() => {
    props.setOpenPaymentModal(false)
    getCustomItems();
    const isNew = localStorage.getItem('FirstUser');
    if (isNew === 'true') {
      setIsNewUser(true)
    } else {
      setIsNewUser(false)
    }
    //f5 get list getGeneratingImages
    FashionService.getInstance().getGeneratingImages().then((data) => {
      if (data.length !== 0) {
        setIsGenerating(true);
      }
    });
  }, [])

  let interval: any;

  useEffect(() => {
    if (isGenerating) {
      //TODO: dũng check job isgen
      //tạm comment lại, chứ k hiểu sao phải chạy cái này
      // if (!interval) {
      //   AccountService.getInstance().getUserInfo().then((dataUser) => dispatch(userSaga.setUser(dataUser)));
      // }
      interval = setInterval(async () => {
        const data = await FashionService.getInstance().getGeneratingImages();
        setListGenerating(data);
        data.forEach((item: any) => {
          if (item.status === 'FAILED') {
            updateUserCredits()
          }
        });
        if (data.length === 0) {
          if (interval) {
            clearInterval(interval);
          }
          setIsGenerating(false);
        }
      }, 2000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [isGenerating, setIsGenerating, dispatch])

  useEffect(() => {
    if (fashionParamsReducer.numberOfImages > 0) {
      setFashionParams((prevParams: FashionParams) => ({
        ...prevParams,
        numberOfImages: fashionParamsReducer.numberOfImages,
      }));
      setInputNumberImages(fashionParamsReducer.numberOfImages)
      setCost(fashionParamsReducer.numberOfImages * dataCost.primaryGen)
    }
  }, [fashionParamsReducer])

  useEffect(() => {
    fashionParams.templateCode && getCustomItems();
  }, [fashionParams.templateCode])

  const boxLeft = document.getElementById('box-left');
  // useEffect(() => {
  //   boxLeft?.addEventListener('scroll', (event: Event) => {
  //     const target = event.target as HTMLElement;

  //   })
  // }, [boxLeft])

  useEffect(() => {
    if (boxLeft) {
      const maxScrollTopLeft = boxLeft.clientHeight;
      const handleBoxScroll = (event: Event) => {
        const target = event.target as HTMLElement;
        if (target.scrollTop >= 250) {
          dispatch(setIsDisplayHeader.setIsDisplayHeader(true))

        } else {
          dispatch(setIsDisplayHeader.setIsDisplayHeader(true))
        }
        if (target.scrollTop <= maxScrollTopLeft) {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      };

      if (boxLeft) {
        boxLeft.addEventListener('scroll', handleBoxScroll);
        // Cleanup function to remove the event listener when component unmounts
        return () => {
          boxLeft.removeEventListener('scroll', handleBoxScroll);
        };
      }
    }
  }, [boxLeft]);
  const isDisplayHeader = useAppSelector(store => store.isDisplayHeader.isDisplayHeader)

  useEffect(() => {
    setIsShowHeader(isDisplayHeader)
  }, [isDisplayHeader])
  return (
    <div className={isShowHeader ? '' : 'mt-4'}>
      <div className='text-left px-10' >
        <div className='flex flex-row gap-4 grid grid-cols-2 sm:grid-cols-1' style={{ overflow: 'hidden' }}>
          <div id={'box-left'} className='px-4 flex flex-col gap-8 box-section' style={{
            overflowY: 'scroll',
            overflowX: 'hidden',
            height: 'calc(90vh - 100px)'
          }}>
            {/* <PopupFeedback open={isShowFB} close={(e: any) => (setIsShowFB(false))} /> */}
            <MenuMain current={clothesType} setCurrent={setFashionParams} customItems={menu} />
            <UploadImage
              openModalTool={openModalTool}
              setOpenModalTool={setOpenModalTool}
              dataOpenPose={dataOpenPose}
              imagePrePose={imagePrePose}
              setDataImagePose={setDataImagePose}
              sizePose={sizePose}
              onChangePose={onChangePose}
              pose={fashionParams.isPose}
              urlImg={fashionParams.initImage}
              onChangeUrlImage={onChangeUrlImage}
              onChangeMaskImage={onChangeMaskImage}
              imgUploadRef={imgUploadRef}
              setFashionParams={setFashionParams}
              fashionParams={fashionParams}
              onSetSegment={onSetSegment}
              onSetPose={onSetPose}
              setGeneratingSamData={setGeneratingSamData}
              setImagePrePose={setImagePrePose}
              setDataOpenPose={setDataOpenPose}
              setSizePose={setSizePose}
            />
            <Customize
              isLoading={isLoading}
              setModel={setFashionParams}
              model={fashionParams.templateCode}
              mockupModel={templates}
              selectTemplateRef={selectTemplateRef}
              setFashionParams={setFashionParams}
              fashionParams={fashionParams}
              listCustomItems={selectedCustomItems}
              initImage={fashionParams.initImage}
            />
            <AdvancedSetting setFashionParams={setFashionParams} fashionParams={fashionParams} user={userInfo} />
            <Generate />
          </div>
          <div className='container-preview' id='container-preview' style={{ height: 'calc(85vh - 100px)' }} >
            <ListRenderedImages
              openModalTool={openModalTool}
              setOpenModalTool={setOpenModalTool}
              listImages={images}
              getImages={getImages}
              setFashionParams={setFashionParams}
              user={userInfo}
              resultRef={resultRef}
              listGenerating={listGenerating}
              setListGenerating={setListGenerating}
              handlePageChange={handlePageChange}
              pagination={pagination}
              openPaymentModal={props.openPaymentModal}
              setOpenPaymentModal={props.setOpenPaymentModal}
              setIsGenerating={setIsGenerating}
            />
          </div>
          {isNewUser && <PopupForNewUser />}
        </div>
      </div>
    </div>
  )
}

export default Home