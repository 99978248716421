import { Collapse, CollapseProps, Tooltip } from "antd";
import { CSSProperties } from "react";
import { CaretRightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import SliderSettings from "./SliderSettings";
import { Input } from 'antd';
import iconClear from '../../assets/icons/icon-x.svg'
import { useTranslation } from 'react-i18next';
import { FashionParams } from "../../utils/interface";
import { useDispatch } from "react-redux";
import { setTypeModel } from "../../redux/app/selectTypeModel";
import { useAppSelector } from "../../redux/hooks/useAppSelector";

const AdvancedSetting = (props: any) => {
    const dispatch = useDispatch();
    const fashionParamsReducer = useAppSelector((store) => store.select);

    const { TextArea } = Input;
    const { t } = useTranslation();
    const onChangeCFG = (value: any) => {
        if (!value) return
        if (isNaN(value)) {
            return;
        }
        props.setFashionParams((prevParams: FashionParams) => ({
            ...prevParams,
            cfgScale: value,
        }));
        dispatch(setTypeModel.setFashionParams({
            ...fashionParamsReducer,
            isClickCFGScale: true,
        }));
    }

    const onChangeStep = (value: any) => {
        if (!value) return
        if (isNaN(value)) {
            return;
        }
        props.setFashionParams((prevParams: FashionParams) => ({
            ...prevParams,
            samplingStep: value,
        }));
        dispatch(setTypeModel.setFashionParams({
            ...fashionParamsReducer,
            isClickSteps: true,
        }));
    }

    const onChangeControlWeight = (value: any) => {
        if (!value) return
        if (isNaN(value)) {
            return;
        }
        props.setFashionParams((prevParams: FashionParams) => ({
            ...prevParams,
            controlWeight: value,
        }));
        dispatch(setTypeModel.setFashionParams({
            ...fashionParamsReducer,
            isClickControlWeight: true,
        }));
    }

    const onChangeDenoisingStrength = (value: any) => {
        if (!value) return
        if (isNaN(value)) {
            return;
        }
        props.setFashionParams((prevParams: FashionParams) => ({
            ...prevParams,
            denoisingStrength: value,
        }));
    }

    const onChangePrompt = (e: any) => {
        props.setFashionParams((prevParams: FashionParams) => ({
            ...prevParams,
            userPrompt: e.target.value,
        }));
    }

    const onChangeNegativePrompt = (e: any) => {
        props.setFashionParams((prevParams: FashionParams) => ({
            ...prevParams,
            userNegativePrompt: e.target.value,
        }));
    }

    const onClearTextArea = (type: string) => {
        type === 'prompt'
            ? props.setFashionParams((prevParams: FashionParams) => ({
                ...prevParams,
                userPrompt: '',
            }))
            : props.setFashionParams((prevParams: FashionParams) => ({
                ...prevParams,
                userNegativePrompt: '',
            }));
    }
    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    };
    const getItems: () => CollapseProps['items'] = () => [
        {
            key: 'prompt',
            label: t('advanced'),
            children:
                <div className='advanced'>
                    <div className="prompt relative hidden">
                        <div className="text-black text-sm leading-5 font-bold">Prompt <Tooltip trigger={isTouchDevice() ? 'click' : 'hover'} title={t('info_prompt')}>
                            <QuestionCircleOutlined className="text-[#0047FF]" />
                        </Tooltip></div>
                        <div className="absolute right-2 top-6 z-50 cursor-pointer hover:opacity-70" onClick={e => onClearTextArea('prompt')}>
                            <img src={iconClear} alt="" />
                        </div>
                        <TextArea
                            style={{ borderRadius: '8px' }}
                            name="prompt"
                            id="prompt"
                            placeholder={t("input_here")}
                            cols={50}
                            rows={8}
                            value={props.fashionParams.userPrompt}
                            onChange={onChangePrompt}
                        />
                    </div>
                    <div className="negative-prompt relative mt-4 hidden">
                        <div className="text-sm font-bold mb-3 text-black">Negative Prompt <Tooltip trigger={isTouchDevice() ? 'click' : 'hover'} title={t('info_negative_prompt')}>
                            <QuestionCircleOutlined className="text-[#0047FF]" />
                        </Tooltip></div>
                        <div className="absolute right-2 top-10 z-50 cursor-pointer hover:opacity-70" onClick={e => onClearTextArea('negative')}>
                            <img src={iconClear} alt="" />
                        </div>
                        <TextArea
                            style={{ borderRadius: '8px' }}
                            name="negative-prompt"
                            id="negative-prompt"
                            placeholder="(worst quality, low quality:2),.."
                            cols={50}
                            rows={8}
                            value={props.fashionParams.userNegativePrompt}
                            onChange={onChangeNegativePrompt} />
                    </div>
                    <div className='slider'>
                        <SliderSettings hidden={true} onChange={onChangeCFG} input={props.fashionParams.cfgScale} title={"CFG Scale"} toolTip={'scale'} min={1} max={30} step={0.1} />
                        <SliderSettings hidden={true} onChange={onChangeStep} input={props.fashionParams.samplingStep} title={"Sampling steps"} toolTip={'step'} min={1} max={40} step={1} />
                        <SliderSettings hidden={false} onChange={onChangeControlWeight} input={props.fashionParams.controlWeight} toolTipMin={t('icw_min')} toolTipMax={t('icw_max')} toolTip={'icw'} title={t('input_control_weight')} min={0} max={1} step={0.01} />
                        {props.user && props.user.roles.some((item: any) => item === 'ADMIN') && (
                            <>
                                <SliderSettings hidden={true} onChange={onChangeDenoisingStrength} input={props.fashionParams.denoisingStrength} title={"Denoising Strength"} toolTip={'denoise'} min={0} max={1} step={0.01} />
                            </>
                        )}
                    </div>
                </div>,
            style: {
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '142.857%'
            } as CSSProperties
        },
    ];
    return (
        <>
            {/* <Collapse
                className={'collapse-advanced'}
                bordered={false}
                ghost
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                style={{ backgroundColor: 'transparent', paddingLeft: '-12px ' }}
                items={getItems()}
            /> */}
        </>
    )
}
export default AdvancedSetting