import { Button, Form, Input, Modal } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../redux/hooks/useAppSelector';
import FormItemLabel from 'antd/es/form/FormItemLabel';
import { IFeatureWhitelist } from '../utils/interface';
import FashionService from '../services/fashion.service';
import { useDispatch } from 'react-redux';
import { userSaga } from '../redux/app/authSaga';
import swal from 'sweetalert';
type PopupToWhiteListProps = {
    isShowFormWhiteList: boolean,
    setIsShowFormWhiteList: (e: boolean) => void,
    setPermission: IFeatureWhitelist
}
function PopupAddToWhiteList({ isShowFormWhiteList, setIsShowFormWhiteList, setPermission }: PopupToWhiteListProps) {
    const { t } = useTranslation();
    const userInfo = useAppSelector((store) => store.user);
    const dispatch = useDispatch()
    const onSubmit = async () => {
        try {
            const setPer = await FashionService.getInstance().addFeatureToWhitelist(setPermission)
            dispatch(userSaga.setUser(userInfo))
        } catch (error: any) {
            console.log('error', error.message);
            if (!error.success) {
                swal(t('whitelist_exist'), '', 'warning').then((res) => {
                    setIsShowFormWhiteList(false)
                })
            }
        }
    }
    return (
        <Modal title={t('add_to_whitelist')} open={isShowFormWhiteList} footer={null} onCancel={e => setIsShowFormWhiteList(false)} >
            <p className='text-gray text-sm italic'>{t('des_whitelist_popup')}</p>
            <Form onFinish={onSubmit} className=''>
                <Form.Item >
                    <Button htmlType="submit" type='primary' className="mt-4 login-form-button w-full">
                        <span>{t('send')}</span>
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default PopupAddToWhiteList