import { useTour } from '@reactour/tour'
import { Button } from 'antd'
import React from 'react'
import { enableEvent, enableScroll } from './utils/function'
import { useNavigate } from 'react-router-dom'
import { ROUTERS } from './utils/constant'
type BoxStepProps = {
    title: string,
    srcVideo: string,
    description: string,
    textButtonMore: string,
    textButtonDone?: string,
    actionMore?: Function,
    actionDone?: Function
}
function BoxStep({ title, srcVideo, description, textButtonMore, textButtonDone, actionMore, actionDone }: BoxStepProps) {
    const { setIsOpen } = useTour();
    const navigate = useNavigate()
    const onClickMore = () => {
        setIsOpen(false);
        enableScroll();
        enableEvent('step-my-work')
        enableEvent('step-tool')
        navigate(ROUTERS.TUTOR)
    }
    const onClickDone = async () => {
        setIsOpen(false);
        localStorage.setItem('FirstUser', 'false')
        enableScroll();
        enableEvent('step-my-work')
        enableEvent('step-tool')
    }
    return (
        <div className='flex flex-col gap-2'>
            <div className='text-[#101828] font-semibold text-[18px]'>
                {title}
            </div>
            <div>
                <video controls autoPlay={true} src={srcVideo} width={'100%'} height={'100%'} />
            </div>
            <div className='text-[#101828] font-normal text-[14px]'>
                {description}
            </div>
            <div className='flex gap-2 justify-end' >
                <Button className='w-full' onClick={onClickMore}>{textButtonMore}</Button>
                {textButtonDone && <Button className='w-full' type='primary' onClick={onClickDone}>{textButtonDone}</Button>}
            </div>
        </div>
    )
}

export default BoxStep