import NotifyController from "./toast";
import swal from 'sweetalert';

export function handleResponseError(error: any, serverErrorMsg?: string) {
  if (error?.response?.status === 400) {
    if (error?.response?.data) {
      NotifyController.error(error?.response?.data?.message);
      return;
    }
  }
  NotifyController.error(serverErrorMsg ?? 'Something went wrong, please try again!');
}


export function handleResponseErrorUsingSwal(error: any, serverErrorMsg?: string) {
  if (error?.response?.status === 400) {
    if (error?.response?.data) {
      swal(error?.response?.data?.message, "", "error");
      return;
    }
  }
  swal(serverErrorMsg ?? 'Something went wrong, please try again!', "", "error");
}
