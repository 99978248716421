import React, { useEffect, useState } from 'react';
type SubscriptionOrderPageProps = {
    setOpenPaymentModal: Function
}
function SubscriptionOrderPage({ setOpenPaymentModal }: SubscriptionOrderPageProps) {
    const [formData, setFormData] = useState<any>(null);

    useEffect(() => {
        setOpenPaymentModal(false)
        const queryString = window.location.search;
        const params = new URLSearchParams(queryString);

        const formDataObject: any = {};

        params.forEach((value, key) => {
            formDataObject[key] = value;
        });

        setFormData(formDataObject);

    }, []);

    useEffect(() => {
        if (formData && formData.dataKey && formData.ispTxnId && formData.tmnCode) {
            // Create a form element
            const form = document.createElement('form');
            form.style.display = 'none';
            form.method = 'POST';
            form.action = 'https://sandbox.vnpayment.vn/isp-svc/recurring-payment/pay';

            // Create and add hidden input fields for the form data
            Object.keys(formData).forEach((key) => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                input.value = formData[key];
                form.appendChild(input);
            });

            // Append the form to the document body
            document.body.appendChild(form);

            // Submit the form
            form.submit();
        }
    }, [formData]);

    return (
        <div>
            Redirecting...
        </div>
    );
}

export default SubscriptionOrderPage;
