import { Button, Modal, Table, Tabs, Tour, TourProps } from 'antd'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import iconSegment from "../../../assets/icons/segment.png";
import iconSegmentActive from "../../../assets/icons/segment-active.png";
import poseImage from "../../../assets/icons/pose.png";
import poseImageActive from "../../../assets/icons/pose-active.png";
import ModalEditRealtimeSegment from '../ModalEditRealtimeSegment';
import CustomizeFabricCanvas from '../OpenPose/CustomizeFabricCanvas';
import { t } from 'i18next';
import VideoTour from '../../VideoTour/VideoTour';
type ModalEditSegmentAndPoseProps = {
    isOpenModal: boolean;
    urlSegment: any;
    setIsOpenModalEdit: Function;
    modelScale: any;
    hasClicked: boolean;
    setHasClicked: Function;
    handleResetState: Function;
    handleEditByRealtimeSegment: Function;
    dataAnnotations: any;
    setSegmentImagesWithBody: Function,
    onSetSegment: Function,
    showRealTime: boolean,
    setIsShowRealTime: Function,
    imageSegment: any,
    valueWeight: number,
    setValueWeight: Function,
    setDataOpenPose: Function,
    widthImage: number,
    heighImage: number,
    image: string,
    pose: any,
    setImageResultPose: Function,
    fashionParams: any,
    setFashionParams: Function
}
export interface ISizeImage {
    width: number,
    height: number,
    scale: number
}
function ModalEditSegmentAndPose({
    isOpenModal,
    setIsOpenModalEdit,
    urlSegment,
    modelScale,
    hasClicked,
    setHasClicked,
    handleResetState,
    handleEditByRealtimeSegment,
    dataAnnotations,
    setSegmentImagesWithBody,
    onSetSegment,
    showRealTime,
    imageSegment,
    valueWeight,
    setValueWeight,
    setDataOpenPose,
    widthImage,
    heighImage,
    image,
    pose,
    setImageResultPose,
    fashionParams,
    setFashionParams,
    setIsShowRealTime }: ModalEditSegmentAndPoseProps) {
    const [openTour, setOpenTour] = useState(false)
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const [keyActive, setKeyActive] = useState<string>('segment')
    const [sizeCanvas, setSizeCanvas] = useState<ISizeImage>({
        width: widthImage,
        height: heighImage,
        scale: Math.round(widthImage / heighImage)
    })
    const itemsTabs = [
        {
            key: 'segment',
            label:
                <div ref={ref1} className='flex justify-center flex-col items-center gap-2'>
                    <img src={keyActive === 'segment' ? iconSegmentActive : iconSegment} />
                    <p>{t('select_outfit')}</p>
                </div>
            ,
            children: <>
                <ModalEditRealtimeSegment
                    showRealTime={showRealTime}
                    setIsShowRealTime={setIsShowRealTime}
                    urlSegmentManual={imageSegment}
                    urlSegment={urlSegment}
                    setIsOpenModalEdit={setIsOpenModalEdit}
                    hasClicked={hasClicked}
                    setHasClicked={setHasClicked}
                    modelScale={modelScale}
                    widthSegmentRealtime={imageSegment.width}
                    heightSegmentRealtime={imageSegment.height}
                    handleResetState={handleResetState}
                    dataAnnotations={dataAnnotations}
                    handleEditByRealtimeSegment={handleEditByRealtimeSegment}
                    onSetSegment={onSetSegment}
                    // setArrayMaskSelected={setArrayMaskSelected}
                    setSegmentImagesWithBody={setSegmentImagesWithBody}
                ></ModalEditRealtimeSegment>
            </>,
        },
        {
            key: 'pose',
            label:
                <div ref={ref2} className='flex justify-center flex-col items-center gap-2'>
                    <img src={keyActive === 'pose' ? poseImageActive : poseImage} />
                    <p>{t('adjust_pose')}</p>
                </div>
            ,
            children: <>
                <CustomizeFabricCanvas setIsOpenModalEdit={setIsOpenModalEdit}
                    valueWeight={valueWeight}
                    setValueWeight={setValueWeight}
                    fashionParams={fashionParams}
                    setFashionParams={setFashionParams}
                    setDataOpenPose={setDataOpenPose}
                    widthImage={widthImage}
                    heighImage={heighImage}
                    image={image}
                    pose={pose}
                    setPoseUrl={setImageResultPose}
                    sizeCanvas={sizeCanvas} />

            </>,
        },
    ]
    const onChangeTabs = (key: string) => {
        setKeyActive(key)
    }

    const calculateWidthHeight = () => {
        const width = document.getElementById('img-segment')?.offsetWidth;
        const height = document.getElementById('img-segment')?.offsetHeight;
        if (width && height) {
            const scale = Math.round(width / height)
            setSizeCanvas({ width, height, scale })
        }
    }

    const steps: TourProps['steps'] = [
        {
            title: 'Chọn trang phục',
            description: <>
                <p>'Save your changes.'</p>
                <Button>Tìm hiểu thêm</Button>
            </>,
            cover: (
                <video controls autoPlay={false} src='https://s3.ap-southeast-1.amazonaws.com/media2.vision2art.ai/20240201-160014.mp4' width={'100%'} height={'100%'} />
            ),
            target: () => ref1.current,
            nextButtonProps: {
                children: <>{t('next')}</>,

            }
        },
        {
            title: 'Điều chỉnh dáng',
            description: <>
                <p>'Save your changes.'</p>
                <Button>Tìm hiểu thêm</Button>
            </>,
            cover: (
                <video autoPlay={false} controls src='https://media2.vision2art.ai/AI+Fashion+Model+-+Google+Chrome+2023-12-18+17-34-29.mp4' width={'100%'} height={'100%'} />
            ),
            target: () => ref2.current,
            prevButtonProps: {
                children: <>{t('previous')}</>,
            },
            nextButtonProps: {
                children: <>{t('got_it')}</>,
            },
            onFinish: () => {
                localStorage.setItem('FirstSegmentAndPose', 'false');
                setOpenTour(false);
            }
        },
    ];
    useLayoutEffect(() => {
        keyActive === 'pose' && calculateWidthHeight()
    }, [imageSegment, keyActive])
    useEffect(() => {
        setOpenTour(true);
        const isFirst = localStorage.getItem('FirstSegmentAndPose');
        if (isFirst === 'true') {
            setOpenTour(true);
        } else {
            setOpenTour(false);
        }
    }, [])
    return (
        <Modal open={isOpenModal} width={'min-content'} centered bodyStyle={{
            minWidth: '50vw',
            minHeight: '85vh',
        }} footer={null} onCancel={() => setIsOpenModalEdit(false)}>
            {/* <Tour open={openTour} onClose={() => { setOpenTour(false) }} zIndex={9999} steps={steps} mask={true} /> */}
            <Tabs items={itemsTabs} onChange={onChangeTabs} />
        </Modal>
    )
}

export default ModalEditSegmentAndPose