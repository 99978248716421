import React, { useEffect, useState } from 'react'
import checkIcon from '../../assets/icons/check.png'
import './styles.css'
import { Button, Select } from 'antd'
import logoVNPay from '../../assets/images/logo-vnpay.png';
import logoStripe from '../../assets/images/logo-stripe.png';
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentService from '../../services/payment.service';
import swal from 'sweetalert';
import { ROUTERS } from '../../utils/constant';
import NotifyController from '../../utils/toast';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import logo from '../../assets/icons/logo-modeli-full.svg';
import { useDispatch } from 'react-redux';
import { ModalSub } from '../../redux/app/appModalSub';
enum EPaymentType {
    STRIPE = 'STRIPE',
    VNPAY = 'VNPAY',
}

enum ECurrencyType {
    VND = 'VND',
    USD = 'USD',
}

function Checkout() {
    const userInfo = useAppSelector(store => store.user)
    const [productSelected, setProductSelected] = useState<any>()
    const [plan, setPlan] = useState<string>('')
    const [typeText, setTypeText] = useState<string>('')
    const [numberOfMonth, setNumberOfMonth] = useState<number>(1)
    const [priceItem, setPriceItem] = useState<any>()
    const { state } = useLocation();
    const [typeMethod, setTypeMethod] = useState<EPaymentType>(state.dataPayment.type)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language; //vi/en

    if (!state) {
        navigate('/')
    }

    const handleChange = (value: string) => {
        if (value === EPaymentType.STRIPE) {
            setTypeMethod(EPaymentType.STRIPE)
        }
        else {
            setTypeMethod(EPaymentType.VNPAY)
        }
    };

    const handlePay = () => {
        handlePayment(typeMethod, state.dataPayment.id)
    }

    const handlePayment = async (type: EPaymentType, productId: string) => {
        switch (type) {
            case EPaymentType.STRIPE:
                if (state.dataPayment.isStripeEnabled) {
                    const response = await PaymentService.getInstance().createPaymentLinkStripe({
                        productId,
                        currency: currentLanguage === 'vi' ? ECurrencyType.VND : ECurrencyType.USD,
                        type: state.dataPayment.radioValue,
                        credits: state.dataPayment.credits
                    })
                    if (response) {
                        window.open(response.url || '', '_self');
                    }
                } else {
                    swal('Stripe is disabled! Please contact support for more information', '', 'warning');
                }
                break;
            case EPaymentType.VNPAY:
                if (state.dataPayment.isVNPayEnabled) {
                    const response = await PaymentService.getInstance().createPaymentLinkVNPay({
                        productId,
                        language: 'vi',
                        type: state.dataPayment.radioValue,
                        credits: state.dataPayment.credits
                    })
                    if (response) {
                        if (response.type === 'postForm') {
                            navigate(`${ROUTERS.SUBSCRIPTION_ORDER}?dataKey=${response.body.dataKey}&ispTxnId=${response.body.ispTxnId}&tmnCode=${response.body.tmnCode}`)
                        } else if (response.type === 'url') {
                            window.open(response.body || '', '_self');
                        } else {
                            NotifyController.error('Not found type')
                        }
                    }
                } else {
                    swal('VNPay is disabled! Please contact support for more information', '', 'warning');
                }
                break;
            default:
                break;
        }
    }

    const getIndexByTypeAndLang = (array: any, type: string) => {
        if (array && array.length > 0)
            for (let i = 0; i < array.length; i++) {
                if (array[i].type === type) {
                    return i;
                }
            }
        return -1;
    }
    useEffect(() => {
        state.dataPayment.products.map((prod: any, index: number) => {
            if (prod.id === state.dataPayment.id) {
                setProductSelected(prod)
                if (prod && prod.price) {
                    const prod_lang = prod?.price[currentLanguage === 'vi' ? 0 : 1];
                    if (prod_lang.list_items) {
                        setPlan('basic_plan')
                        const indexPrice = getIndexByTypeAndLang(prod_lang.list_items, state.dataPayment.radioValue);
                        if (indexPrice >= 0) {
                            const pricePr = {
                                amount: prod_lang.list_items[indexPrice].amount,
                                discountAmount: prod_lang.list_items[indexPrice].discountAmount,
                                discountPercent: prod_lang.list_items[indexPrice].discountPercent,
                                currency: prod_lang.currency,
                            }
                            setPriceItem(pricePr)
                        }
                    } else {
                        setPlan('top_up')
                        const pricePr = {
                            amount: prod_lang.amount,
                            discountAmount: prod_lang.discountAmount,
                            discountPercent: prod_lang.discountPercent,
                            currency: prod_lang.currency,
                        }
                        setPriceItem(pricePr)
                    }
                }
            }

        })
    }, [state, navigate])

    useEffect(() => {
        let typeText = '';
        let monthNum = 1;
        switch (state.dataPayment.radioValue) {
            case 'monthly':
                typeText = t('monthly');
                monthNum = 1;
                break;
            case 'quarterly':
                typeText = t('quarterly');
                monthNum = 3;
                break;
            case 'yearly':
                typeText = t('yearly');
                monthNum = 12;
                break;
            default:
                break;
        }


        setTypeText(typeText)
        setNumberOfMonth(monthNum)
    }, [state])
    useEffect(()=>{
        dispatch(ModalSub.setIsOpenModalSub(false))
    },[])
    return (
        <div className='details-payment flex justify-center items-center h-[90vh]'>
            <div className='box-checkout flex justify-between flex-col '>
                <div className='w-full h-full'>
                    <img className='image_full' src={logo} alt="logo_modeli" />
                </div>
                <div className='flex flex-col items-center'>
                    <div className='heading-top pb-2 uppercase'>{t('checkout')}</div>
                    <div className='title flex gap-2 justify-center items-center pb-6' style={{ alignItems: 'center' }}>
                        <img src={checkIcon} alt="tick" />
                        <p className='text-sm underline'>{t('check_your_order')}</p>
                    </div>
                    <div className='list-items flex flex-col  !pb-6' style={{
                        margin: ' 0 auto'
                    }}>
                        {!state.dataPayment.isTopUp ? <> <div className='flex justify-between  '>
                            <div className='name-prod flex flex-col text-left'>
                                <div className='text-name-prod' >{productSelected?.title ?? productSelected?.name}{' '}
                                    {plan === 'basic_plan' && `(${typeText})`} </div>
                                <div className='flex flex-col gap-2 items-start ml-5 py-3'>
                                    {productSelected?.description?.map((item2: any, index: number) => {
                                        return (
                                            <ul key={index} className='ml-6 text-sm text-blue-600'>
                                                {index === 0 && productSelected.isSub
                                                    ? <li>{parseInt(item2.description) * numberOfMonth} credits</li>
                                                    : <li>{item2.description}</li>
                                                }
                                            </ul>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className='flex gap-2'>
                                {priceItem && <div className='price-prod !text-[12px] line-through'>{(priceItem.amount)?.toLocaleString()}</div>}
                                {priceItem && <div className='price-prod font-semibold flex flex-row gap-1'>
                                    <div>
                                        {(priceItem.discountAmount)?.toLocaleString()}
                                    </div>
                                    <div>
                                        {priceItem.currency}
                                    </div>
                                </div>}
                            </div>
                        </div>
                            <div className='flex justify-between  '>
                                <div className='text-total font-bold'>{t('total')}</div>
                                {priceItem && <div className='text-total font-bold'>{(priceItem.discountAmount)?.toLocaleString()} {priceItem.currency}</div>}
                            </div>
                        </>
                            :
                            <>
                                <div className='name-prod flex flex-col text-left'>
                                    <div className='text-name-prod' >{productSelected?.name}</div>
                                    {currentLanguage === 'vi' && <div className='flex flex-col gap-2 items-start ml-5 py-3'>
                                        {(productSelected?.price[0]?.discountAmountPerCredit * state.dataPayment.credits).toLocaleString()} VND /{state.dataPayment.credits + ' Credits'}
                                    </div>}
                                    {currentLanguage !== 'vi' && <div className='flex flex-col gap-2 items-start ml-5 py-3'>
                                        ${(productSelected?.price[1]?.discountAmountPerCredit * state.dataPayment.credits).toLocaleString()}  /{state.dataPayment.credits + ' Credits'}
                                    </div>}
                                </div>
                            </>
                        }
                    </div>
                    <div className='user-info flex flex-col  mt-6 gap-2'>
                        <div className='detail-info'>
                            <div className='flex justify-between'>
                                <div className='display-name'>
                                    {t('display_name')}:
                                </div>
                                <div className='name-display'> {userInfo.username}</div>
                            </div>
                        </div>
                        <div className='detail-info'>
                            <div className='flex justify-between'>
                                <div className='display-name'>
                                    Email:
                                </div>
                                <div className='name-display'>{userInfo.email}</div>
                            </div>
                        </div>
                        <div className='detail-info'>
                            <div className='flex justify-between'>
                                <div className='display-name'>
                                    Credits:
                                </div>
                                <div className='name-display'>{userInfo.coins}</div>
                            </div>
                        </div>
                        <div className='detail-info'>
                            <div className='flex justify-between'>
                                <div className='display-name'>
                                    {t('Credits_balance_after_payment')}:
                                </div>
                                <div className='name-display'>{!state.dataPayment.isTopUp ? userInfo.coins + productSelected?.amount * numberOfMonth : userInfo.coins + state.dataPayment.credits}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='w-full border border-solid border-gray outline-none my-3' />
                <div className='flex justify-end sm:justify-center md:justify-center lg:justify-center '>
                    <div className='footer flex gap-4 justify-center items-center sm:flex-col md:flex-col'>
                        <div className='flex justify-center items-center gap-4'>
                            <div className='sm:hidden md:hidden'>{t('payment_via')}:</div>
                            <div className='logo-payment'>
                                <Select
                                    defaultValue={state.dataPayment.type}
                                    style={{ width: 200 }}
                                    onChange={handleChange}
                                    options={[
                                        {
                                            value: EPaymentType.STRIPE, label: <img src={logoStripe} alt="stripe" width={50} className={!state.dataPayment.isStripeEnabled ? 'grayscale' : ''} />
                                            ,
                                            disabled: !state.dataPayment.isStripeEnabled
                                        },
                                        {
                                            value: EPaymentType.VNPAY, label: <img src={logoVNPay} alt="stripe" width={'50%'} className={!state.dataPayment.isVNPayEnabled ? 'grayscale' : ''} />
                                            ,
                                            disabled: !state.dataPayment.isVNPayEnabled || currentLanguage === 'en'
                                        }
                                    ]}
                                />
                            </div>
                        </div>
                        <div className='list-button-pay flex gap-4'>
                            <Button className='btn-back-to-home' onClick={() => navigate('/')}>{t('back')}</Button>
                            <Button type="primary" className='primary btn-pay' onClick={handlePay}>{t('place_order')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Checkout