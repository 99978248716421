import { CopyOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Modal, Row, Select, Statistic, Table } from 'antd';
import Search from 'antd/es/input/Search';
import type { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import * as XLSX from 'xlsx';
import InputPassword from '../../components/InputPassword';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import AccountService from '../../services/account.service';
import FashionService from '../../services/fashion.service';
import NotifyController from '../../utils/toast';
import { handleResponseError } from '../../utils/responseError';

const { RangePicker } = DatePicker
interface DataType {
  key: React.Key;
  name: string;
  createAt: string;
  amount: string;
  gateway: string;
  status: string;
  coins: number;
  currency: string;
}
interface DataRefType {
  key: React.Key;
  username: string;
  inviteAt: string;
  rewardCredits: string;
  program_name: string;
  id: string;
}

const Account = (props: any) => {
  const userInfo = useAppSelector(store => store.user)
  const timezone = moment.tz.guess();
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [dataRef, setDataRef] = useState([])
  const [dataAffiliate, setDataAffiliate] = useState([])
  const [totalOrders, setTotalOrders] = useState(1)
  const [rangeTime, setRangeTime] = useState({
    startDate: 0,
    endDate: 0,
  })
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 1
  });
  const [totalRef, setTotalRef] = useState(1)
  const [totalAffiliate, setTotalAffiliate] = useState(1)
  const [paginationRef, setPaginationRef] = useState({
    current: 1,
    pageSize: 5,
    total: 1
  });
  const [paginationAffiliate, setPaginationAffiliate] = useState({
    current: 1,
    pageSize: 5,
    total: 1
  });
  const [textOK, setTextOK] = useState(<>{t('confirm')}</>)
  const [searchText, setSearchText] = useState<string>('')
  const [totalVNDCommissionAmount, setTotalVNDCommissionAmount] = useState(0);
  const [totalUSDCommissionAmount, setTotalUSDCommissionAmount] = useState(0);
  const [sortData, setSortData] = useState<any>({})
  const [partnerProgram, setPartnerProgram] = useState<any>([])
  const [isOpenModalChangePassword, setIsOpenModalChangePassword] = useState(false);
  const { register, handleSubmit, setError, reset, formState: { errors } } = useForm<{
    oldPassword: string,
    newPassword: string,
    verifyNewPassword: string
  }>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      verifyNewPassword: ''
    }
  });
  const [showHidePassword, setShowHidePassword] = useState({
    oldPassword: false,
    newPassword: false,
    verifyNewPassword: false
  })

  const columnsRef: ColumnsType<DataRefType> = [
    {
      title: t('nameUser'),
      dataIndex: 'username'
    },
    {
      title: t('inviteAt'),
      dataIndex: 'inviteAt',
      render: (_record) => {
        return (
          <div>{moment.tz(_record, timezone).format("YYYY-MM-DD HH:mm:ss")}</div>
        )
      }
    },
    {
      title: t('rewardCredits'),
      dataIndex: 'rewardCredits',
    },
    {
      title: t('program_name'),
      dataIndex: 'program_name',
      render: (_record, row) => {
        if (row.program_name) {
          return <div>{row.program_name}</div>;
        } else {
          return (
            <Select
              placeholder={t('select_program')}
              onChange={(value) => handleProgramChange(value, row)}
            >
              {partnerProgram.map((program: any) => (
                <Select.Option key={program.key} value={program.value}>
                  {program.key}
                </Select.Option>
              ))}
            </Select>
          );
        }
      }
    }
  ];

  const columnsAffiliate: ColumnsType<any> = [
    {
      title: t('nameUser'),
      dataIndex: 'username',
      filterSearch: true,
      onFilter: (value, record) => record.username.startsWith(value as string),
    },
    {
      title: t('Email'),
      dataIndex: 'email'
    },
    {
      title: t('namePackage'),
      dataIndex: 'productName',
    },
    {
      title: t('payAt'),
      dataIndex: 'created_ts',
      render: (_record) => {
        return (
          <div>{moment.tz(_record, timezone).format("YYYY-MM-DD HH:mm:ss")}</div>
        )
      },
      sorter: (a, b) => a.created_ts - b.created_ts,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('priceBy'),
      dataIndex: 'amount',
      render: (_record) => {
        return (
          <div>{_record?.toLocaleString()}</div>
        )
      }
    },
    {
      title: t('commissionMoney'),
      dataIndex: 'commissionAmount',
      render: (_record) => {
        return (
          <div>{_record?.toLocaleString()}</div>
        )
      },
      sorter: (a, b) => a.vndCommissionAmount - b.vndCommissionAmount,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('currency'),
      dataIndex: 'currency',
    },
    {
      title: t('is_paid'),
      dataIndex: 'isPaid',
      render: (isPaid) => {
        return <div>{isPaid ? 'Yes' : 'No'}</div>;
      },
      sorter: (a, b) => a.paid_ts - b.paid_ts,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: t('partner_paid_at'),
      dataIndex: 'paid_ts',
      render: (_record, row) => {
        return row.isPaid ? (
          <div>{moment.tz(_record, timezone).format("YYYY-MM-DD HH:mm:ss")}</div>
        ) : null;
      },
      sorter: (a, b) => a.paid_ts - b.paid_ts,
      sortDirections: ['descend', 'ascend'],
    }
  ];

  const onCopyCode = (link: string) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_CLIENT_URL}/login?r_code=${link}`);
    NotifyController.success(t('Copy code successfully'))
  }

  const handleProgramChange = async (value: any, row: DataRefType) => {
    try {
      await FashionService.getInstance().setAffiliate(row.id, value);
      await getRef(paginationRef.pageSize, paginationRef.current);
    } catch (error) {
      handleResponseError(error, t('something_wrong'));
    }
  };

  const getStartOfMonth = () => dayjs().startOf('month');
  const getEndOfMonth = () => dayjs().endOf('month');

  const getPartnerProgram = async () => {
    try {
      const data = await FashionService.getInstance().getPartnerProgram();
      if (data.data) {
        setPartnerProgram(data.data.map((item: any) => {
          return {
            key: item.affiliate_name,
            value: item._id,
          }
        }))
      }
    } catch (error) {
      handleResponseError(error, t('something_wrong'))
    }
  }

  const getRef = async (pageSize: number, current: number) => {
    const data = await FashionService.getInstance().getRef(pageSize, current);
    setDataRef(data.refs)
    setTotalRef(data.totalRefs)
  }

  const getAffiliate = async (pageSize: number, current: number, startDate: number, endDate: number, sorter: any) => {
    const data = await FashionService.getInstance().getAffiliate(pageSize, current, startDate, endDate, sorter, searchText);
    setDataAffiliate(data.data.result)
    setTotalAffiliate(data.data.totalRecord)
    setTotalVNDCommissionAmount(data.data.totalVNDCommissionAmount);
    setTotalUSDCommissionAmount(data.data.totalUSDCommissionAmount);
  }

  const handleTableRefChange = (
    pagination: any) => {
    setPaginationRef(pagination);
    getRef(pagination.pageSize, pagination.current);
  };

  const getDate = (rangeTime: any) => {
    const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime();
    const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getTime();
    let startDate = firstDayOfMonth;
    let endDate = lastDayOfMonth;
    if (rangeTime.startDate > 0 && rangeTime.endDate > 0) {
      startDate = rangeTime.startDate;
      endDate = rangeTime.endDate;
    }
    return { startDate, endDate }
  }

  const callApiAffiliate = (rangeTime: any, paginationAffiliate: any, sorter: any) => {
    const { startDate, endDate } = getDate(rangeTime);
    getAffiliate(paginationAffiliate.pageSize, paginationAffiliate.current, startDate, endDate, sorter);
  }

  const handleTableAffiliateChange = (pagination: any, filters: any, sorter: any) => {
    setPaginationAffiliate(pagination);
    const sort = { sort: sorter.field, order: sorter.order }
    callApiAffiliate(rangeTime, pagination, sort)
    setSortData(sort)
  };

  const onChangeRangeDate = (e: any) => {
    if (e && e.length > 0) {
      const startDate = e[0].$d.getTime();
      const endDate = e[1].$d.getTime();
      setRangeTime({
        startDate, endDate
      })
    } else {
      setRangeTime({
        startDate: 0, endDate: 0
      })
    }
  }

  const onSearch: any = (e: any) => {
    debouncedSearch(e.target.value)
  }

  const onExport = async () => {
    const { startDate, endDate } = getDate(rangeTime);
    const data = await FashionService.getInstance().getExportData(startDate, endDate, sortData, searchText);
    if (data && data.data) {
      data.data.forEach((item: any) => {
        item.created_ts = moment.tz(item.created_ts, timezone).format("YYYY-MM-DD HH:mm")
      })
      const worksheet = XLSX.utils.json_to_sheet(data.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
      XLSX.writeFile(workbook, `data-${new Date()}.xlsx`);
    } else {
      swal(t('No_data_to_export'), '', 'info')
    }
  }

  const debouncedSearch = useCallback(
    debounce((value) => {
      setSearchText(value);
    }, 500),
    []
  );

  const onSubmitChangePassword = (data: { oldPassword: string, newPassword: string, verifyNewPassword: string }) => {
    if (data.newPassword !== data.verifyNewPassword) {
      setError('verifyNewPassword', {
        message: t('new_password_no_match_error_msg')
      })
      return
    }

    if (data.oldPassword === data.newPassword || data.oldPassword === data.verifyNewPassword) {
      setError('verifyNewPassword', {
        message: t('changes_password_no_modification_error_msg')
      })
      return
    }


    AccountService.getInstance().changePassword({
      ...data,
      language: i18n.language
    }).then((data) => {
      if (data?.success) {
        setIsOpenModalChangePassword(false);
        swal(t('change_password_successfully'), '', 'success')
      }
    }).catch((error) => {
      handleResponseError(error);
    })
  }

  useEffect(() => {
    getRef(paginationRef.pageSize, pagination.current);
    getPartnerProgram();
  }, [userInfo])

  useEffect(() => {
    setPagination({ ...pagination, total: totalOrders });
  }, [totalOrders])

  useEffect(() => {
    setPaginationRef({ ...paginationRef, total: totalRef });
  }, [totalRef])

  useEffect(() => {
    setPaginationAffiliate({ ...paginationRef, total: totalAffiliate });
  }, [totalAffiliate])

  useEffect(() => {
    const isKOL = userInfo.roles.includes('KOL')
    if (isKOL) {
      setPaginationAffiliate({ ...paginationAffiliate, total: totalAffiliate });
      if (rangeTime.endDate === 0 || rangeTime.startDate === 0) return
      callApiAffiliate(rangeTime, paginationAffiliate, {})
    }
  }, [userInfo, rangeTime, searchText])


  useEffect(() => {
    const end = getEndOfMonth() as any;
    const tsp_end = end.$d.getTime()
    const start = getStartOfMonth() as any;
    const tsp_start = start.$d.getTime()
    setRangeTime(
      {
        endDate: tsp_end,
        startDate: tsp_start
      }
    )
  }, [])

  return (
    <div>
      <div className='text-left flex flex-col gap-2 py-4'>
        <div className=' text-left font-semibold text-lg'>
          {t('personalDetail')}
        </div>
        <div className='flex w-fit justify-start'>
          <div className='w-[148px]'>{t('username')}:</div>
          <div className='ml-4'><b>{userInfo.username}</b></div>
        </div>
        <div className='flex w-fit justify-start'>
          <div className='w-[148px]' >{t('your_code')}:</div>
          <div className='ml-4'>
            {userInfo.referralCode} <CopyOutlined className='cursor-pointer' onClick={e => onCopyCode(userInfo.referralCode)} />
          </div>
        </div>
        {
          !userInfo.isFromAccSocial && (
            <div className='mr-2 mt-2 mb-4'>
              <Button onClick={() => setIsOpenModalChangePassword(true)}>{t('change_password')}</Button>
            </div>
          )
        }
      </div>
      {
        isOpenModalChangePassword && (
          <Modal open={isOpenModalChangePassword} title={t('change_password')} okButtonProps={{ htmlType: 'submit', style: { width: '80px' }, onClick: handleSubmit(onSubmitChangePassword) }}
            cancelButtonProps={{ style: { width: '80px' } }}
            onCancel={() => {
              setIsOpenModalChangePassword(false);
              reset();
            }}>
            <form onSubmit={handleSubmit(onSubmitChangePassword)} className='block my-4'>
              <InputPassword id='oldPassword'
                label={t('old_password')}
                register={register}
                error={errors?.oldPassword} ignoreCheckPattern={true} />
              <InputPassword id='newPassword'
                label={t('new_password')}
                register={register}
                error={errors?.newPassword} />
              <InputPassword id="verifyNewPassword"
                label={t('verify_new_password')}
                register={register}
                error={errors?.verifyNewPassword} />
            </form>
          </Modal>
        )
      }
      <div className='overflow-x-auto my-4'>
        <div className=' text-left font-semibold text-lg mb-2'>
          {t('your_invitee')}
        </div>
        <Table columns={columnsRef} dataSource={dataRef} pagination={paginationRef} onChange={handleTableRefChange} />
      </div>
      <div className={!userInfo.roles.includes('KOL') ? 'hidden' : 'flex flex-col gap-2'}>
        <div className='flex items-end justify-between gap-2 sm:flex-col sm:!justify-start'>
          <div className='flex flex-col gap-2 justify-center sm:w-full'>
            <div className=' text-left font-semibold text-lg mb-2'>
              {t('your_affiliate')}
            </div>
            <div className='flex gap-2 min-w-full'>
              <RangePicker
                className='w-full'
                defaultValue={[getStartOfMonth(), getEndOfMonth()]}
                onChange={(e: any) => onChangeRangeDate(e)} placeholder={[
                  t('start_date'),
                  t('end_date'),
                ]} />
            </div>
          </div>
          <div className='flex gap-1'>
            <Search allowClear onChange={onSearch} placeholder={t('search_by_username_or_email')} className='min-w-[60%]' />
            <Button onClick={onExport} className='ml-3 min-w-[20%]'>{t('Export')}</Button>
          </div>
        </div>
        <Row className='flex sm:justify-center sm:gap-2 justify-around md:flex-col'>
          <Col xs={32} sm={8} md={8} xl={8} lg={8} >
            <Statistic title={t('Total_users')} value={totalRef} />
          </Col>
          <Col xs={32} sm={8} md={8} xl={8} lg={8} >
            <Statistic title={t('Total_vnd_commission_amount')} value={totalVNDCommissionAmount} />
          </Col>
          <Col xs={32} sm={8} md={8} xl={8} lg={8} >
            <Statistic title={t('Total_usd_commission_amount')} value={totalUSDCommissionAmount} />
          </Col>
        </Row>
        <div className='overflow-x-auto mt-2'>
          <Table columns={columnsAffiliate} dataSource={dataAffiliate} pagination={paginationAffiliate} onChange={handleTableAffiliateChange} />
        </div>
      </div>
    </div>
  )
}
export default Account;