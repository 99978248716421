import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/lazy'
type GuideProps = {
    isPlayVideo: boolean
}
function Guide({ isPlayVideo }: GuideProps) {
    const [isMobile, setIsMobile] = useState<boolean>(false)
    const { t } = useTranslation()
    const widthScreen = window.innerWidth;

    useEffect(() => {
        if (widthScreen < 512) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [widthScreen])

    return (
        <div className=' w-full min-h-[80vh]  flex flex-col justify-center items-center' >
            <div className='player-wrapper flex flex-col justify-center items-center '>
                <div className='font-bold text-2xl mb-10 '>{t('guide_modeli')}</div>
                <ReactPlayer
                    playing={isPlayVideo}
                    className='react-player'
                    url='https://s3.ap-southeast-1.amazonaws.com/media2.vision2art.ai/20240201-160014.mp4'
                    width={isMobile ? '90%' : '55%'}
                    height={isMobile ? '90%' : '55%'}
                    controls
                />
            </div>
        </div>
    )
}

export default Guide