import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { FashionToolType } from '../../../utils/enum';

const TagItem = ({ text }: any) => {
    return (
        <Tag className="bg-[#F5F5F5] w-fit m-auto my-0 rounded-xl border-none px-4 py-0.5 text-center  h-fit text-[12px] font-medium leading-5 ">{text}</Tag>
    )
}

function TagNameMyWork({ val }: any) {
    const { t } = useTranslation()

    switch (val.type) {
        case FashionToolType.UPSCALE:
            return <TagItem text={'Upscale'} />
        case FashionToolType.BORDER_SMOOTH:
            return <TagItem text={t('border_smooth')} />
        case FashionToolType.SWAPFACE:
            return <TagItem text={'Swapface'} />
        case FashionToolType.REMOVE_OBJECT:
            return <TagItem text={t('remove_object')} />
        case FashionToolType.REMOVE_REDUNDANT_DETAIL:
            return <TagItem text={t('remove_redundant_detail')} />
        case FashionToolType.CORRECT_PART:
            return <TagItem text={t('correct_part')} />
        case FashionToolType.TRY_OUTFIT:
            return <TagItem text={t('try_outfit')} />
        default:
            return (
                <div className='flex flex-wrap gap-2'>
                    <TagItem text={val.templateName} />
                    <TagItem text={val.templateType} />
                </div>
            )
    }
}

export default TagNameMyWork