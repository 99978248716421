import { useEffect, useLayoutEffect, useState } from 'react'
import './styles.css';
import { LoginOutlined, CopyOutlined, MenuOutlined, CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Popover, Avatar, Drawer } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ROUTERS } from '../../utils/constant';
import logo from '../../assets/logo/new-logo.svg';
import logo_mobile from '../../assets/logo/logo_mobile.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { authActions } from '../../redux/auth/authSlice';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import ModalRequiredSub from '../ModalRequiredSub/ModalRequiredSub';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import styled from "styled-components";
import NotifyController from '../../utils/toast';
import 'moment-timezone';
import { logEvent } from '@firebase/analytics';
import analytics from '../../firebase';
import { toolEditorImage } from '../../redux/app/toolEditor';
import { FeatureTypeEnum, TypeEditor } from '../../utils/enum';
import NewModalRequiredSub from '../ModalRequiredSub/NewModalRequiredSub';
import { ModalSub } from '../../redux/app/appModalSub';

function NewHeader(props: any) {
    const hasRoleVTO = useAppSelector(store => store.user.roles.includes('VTO') || store.user.roles.includes('ADMIN'))
    const isSub = Object.keys(useAppSelector(store => store.user.subscription)).length !== 0
    const ColorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];
    const [openSubMenu, setOpenSubMenu] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleSubMenuToggle = (index: any) => {
        setOpenSubMenu(openSubMenu === index ? null : index);
    };
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userInfo = useAppSelector((store) => store.user);
    const [open, setOpen] = useState(false);
    const [isToggleOpen, setIsToggleOpen] = useState(false);
    const [color, setColor] = useState(ColorList[0]);

    const handleOpenChange = (newOpen: boolean) => {
        if (!window.location.href.includes(ROUTERS.PROFILE)) {
            setOpen(newOpen);
        } else {
            setOpen(false);
        }

    };

    const onLogout = () => {
        dispatch(authActions.logout())
        logEvent(analytics, 'logout')
    }

    const onCopyCode = (div: string) => {
        navigator.clipboard.writeText(`${process.env.REACT_APP_CLIENT_URL}/login?r_code=${div}`);
        NotifyController.success('Copy code successfully')
    }

    const goToProfile = () => {
        if (!window.location.href.includes(ROUTERS.PROFILE))
            navigate('/profile')
    }
    useEffect(() => {
        if (window.location.href.includes(ROUTERS.PROFILE)) {
            setOpen(false)
        }
        setIsToggleOpen(false)
    }, [window.location.href])
    const content = () => {
        return (
            <div className='flex flex-col h-full w-full justify-center items-start'>
                <div className='flex flex-col gap-2'>
                    <h4>{userInfo.username}</h4>
                    <h5>{t('your_code')}: {userInfo.referralCode}   <CopyOutlined className='cursor-pointer' onClick={e => onCopyCode(userInfo.referralCode)} /></h5>
                    {/* {expired && <h5 className="mb-3">{t('Expires_at')}: {moment.tz(expired, timezone).format("YYYY-MM-DD HH:mm")}</h5>} */}
                </div>
                <div className='flex justify-center items-center py-2 w-full' >
                    <Button type='primary' className='w-full' onClick={() => dispatch(ModalSub.setIsOpenModalSub(true))}>{t('Deposit')}</Button>
                </div>
                <div className='flex flex-row gap-2' style={{
                    borderTop: '1px gray solid',
                    paddingTop: '8px'
                }}>
                    <Button type='text' onClick={goToProfile} >{t('Profile')}</Button>
                    <Button onClick={onLogout}>
                        <div className='flex justify-center items-center gap-2 '>
                            <LoginOutlined />{t('logout')}
                        </div>
                    </Button>
                </div>
            </div>
        )
    }

    const handleToggleOpen = () => {
        setIsToggleOpen(!isToggleOpen);
    };

    const onClickToTool = () => {
        dispatch(toolEditorImage.resetImageEditor())
        dispatch(toolEditorImage.setTypeEdit(TypeEditor.UPLOAD))
        navigate(ROUTERS.UPLOAD)
    }
    const urlCurrent = window.location.pathname;
    const isDisplayHeader = useAppSelector(store => store.isDisplayHeader.isDisplayHeader)
    const StyledHeader = styled.header`
  width: 100%;
  padding: 20px 0px;
  margin: 0 20xp;
  display: flex;
  align-items: center;
  margin-top: ${isDisplayHeader ? '0px' : '-100px'};
  justify-content: space-between;
  .nav_logo {
    padding: 0 12px;
    .nav-logo-link {
      text-decoration: none;
      font-size: 24px;
      color: #fab005;
      font-weight: bold;
    }
  }
  .menuToggleBtn {
    display: none;
    color: white;
    font-size: 24px;
    position: absolute;
    right: 20px;
    top: 15px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    justify-content:center;
    .menuToggleBtn {
      display: block;
    }
    .header-right{
        margin: 0 auto;
    }
  }
`;
    const NavManu = styled.ul`
  list-style: none;
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;

  li {
    position: relative;

    &:hover .sub-menu {
      display: block;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .nav-menu-list {
    text-decoration: none;
    color: white;
    display: block;
    padding: 10px 10px;
    padding-top:0px;
  }

  .sub-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #F5F5F5;
    list-style: none;
    width: 150px;
    border-radius:4px;
    padding:5px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    li {
      padding: 15px 10px;
      border-radius:4px;
      &:hover {
        background-color: #0047FF;
        color: white;
      }
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
    background-color: #F3F6FF;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 5px;
    padding: 10px;
    order: 3;

    li {
      width: 100%;
      text-align: center;

      &:hover .sub-menu {
        display: none;
      }

      .sub-menu {
        position: static;
        display: none;
        width: 100%;
        background-color: #F3F6FF;

        li {
          padding: 10px;
          background-color: #F3F6FF;

          &:hover {
            background-color: #e0e0e0;
          }
        }
      }

      &.open .sub-menu {
        display: block;
      }
    }
  }
`;

    const AvatarUser = ({ isMobile }: any) => {
        return (
            <Avatar className='hover:cursor-pointer' onClick={() => { isMobile && setOpen(!open) }} style={{ backgroundColor: color, verticalAlign: 'middle' }} size="large" shape={'circle'}>
                {userInfo.username.split('')[0].toUpperCase()}
            </Avatar>
        )
    }

    useEffect(() => {
        setColor(ColorList[Math.floor(Math.random() * ColorList.length)])
    }, [])


    useEffect(() => {
        dispatch(ModalSub.setIsOpenModalSub(!isSub))
    }, [isSub])
        ;
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    useLayoutEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setIsToggleOpen(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])
    return (
        <>
            <div className='header-main py-0 mx-0 my-5 flex justify-between items-center w-[98%] md:w-full sm:w-full sm:my-2'>
                <div className="nav_logo ml-2 md:m-0 sm:m-0">
                    <div className="logo flex gap-4 justify-center items-center">
                        <img className='image_full' src={logo} width={'250'} alt="" onClick={e => navigate('/')} style={{ cursor: 'pointer' }} />
                        {/* <img className='image_mobile !w-[60px]' src={logo_mobile} width={'100%'} height={'100%'} alt="" onClick={e => navigate('/')} style={{ cursor: 'pointer' }} /> */}
                    </div>
                </div>
                <NavManu>
                    <li className={openSubMenu === 0 ? 'open' : ''} onClick={() => handleSubMenuToggle(0)}>
                        <div className={`item p-1 flex text-base justify-center step-my-work items-center ${urlCurrent === ROUTERS.HOME ? 'item-active' : ''}`} onClick={() => navigate('/')}>
                            <div className='flex gap-1 justify-center items-center'>
                                <div>
                                    {t('home')}
                                </div>
                                <div className={`arrow ${urlCurrent === ROUTERS.HOME ? 'arrow-active' : ''}`}></div>
                            </div>
                        </div>
                        <ul className="sub-menu !z-[99]">
                            <li onClick={() => navigate(ROUTERS.MY_WORK)} className="text-left"> {t('your_work')} </li>
                            <li onClick={() => navigate(ROUTERS.TUTOR)} className="text-left"> {t('tutor')}</li>
                        </ul>
                    </li>
                    <li>
                        <div className={`item p-1 flex text-base justify-center step-tool items-center ${(urlCurrent.includes(ROUTERS.UPLOAD) || urlCurrent.includes(ROUTERS.TOOL)) ? 'item-active' : ''}`} onClick={onClickToTool}>{t('tool')}</div>
                    </li>
                    <li>
                        <div className={`item p-1 flex text-base justify-center step-tool items-center ${urlCurrent.includes(ROUTERS.VTO) ? 'item-active' : ''}`} onClick={() => navigate(ROUTERS.VTO)}>{t('vto')}</div>
                    </li>
                </NavManu>

                <div className="header-right flex gap-2 md:hidden">
                    <div className="my-account flex items-center justify-center gap-5">
                        <div className=' text-base bg-white border-[#0047FF] border-2 border-solid font-semibold rounded-lg text-[#0047FF] p-2
                        hover:cursor-pointer hover:scale-105 
                        duration-200 ease-in-out
                        '
                            onClick={() => dispatch(ModalSub.setIsOpenModalSub(true))}>{t('upgrade')}  | {userInfo.coins} credits</div>
                        <div className='flex gap-2'>
                            <Popover
                                content={content}
                                placement="bottomRight"
                                trigger="click"
                                open={open}
                                onOpenChange={handleOpenChange}
                            >
                                <AvatarUser isMobile={true} />
                            </Popover>
                            <LanguageSwitch />
                        </div>

                    </div>
                </div>
                {<Drawer open={isToggleOpen} onClose={() => setIsToggleOpen(!isToggleOpen)} width={'65%'} className='drawer-root'
                    title={
                        <img src={logo} width={'180'} height={"auto"} />
                   }
                    closeIcon={<CloseOutlined className='text-black text-[20px]' />}
                    bodyStyle={
                        {
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: 'column'
                        }
                    }
                >
                    <div className='drawer-right mt-2'>
                        <div className='flex gap-2' onClick={() => navigate(ROUTERS.PROFILE)}>
                            <AvatarUser isMobile={false} />
                            <div className='flex flex-col gap-2'>
                                <p className='text-[14px] uppercase font-semibold  max-w-[120px]'>
                                    {userInfo.username}
                                </p>
                                <div className='text-[12px] text-primary'>{t('Profile')} |   {userInfo.coins} credits </div>
                            </div>
                        </div>
                        <ul className='flex flex-col gap-2 mt-1'>
                            <div className='w-full bg-primary rounded-md !text-white flex justify-center text-base text-center mt-2 py-2
                        hover:cursor-pointer
                        hover:bg-white
                        group
                        border border-primary border-solid
                        '
                                onClick={() => dispatch(ModalSub.setIsOpenModalSub(true))}>
                                <div className='text-center text-[18px] text-white group-hover:text-primary uppercase'>
                                    {t('upgrade')}
                                </div>
                            </div>
                            <li className='py-2 cursor-pointer pb-2 mt-6' >
                                <div className={`item flex text-[18px] justify-start step-my-work items-center font-medium ${urlCurrent === ROUTERS.HOME ? 'item-active' : ''}`} onClick={() => navigate('/')}>
                                    <div className='flex gap-1 justify-start items-center'>
                                        <div>
                                            {t('home')}
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <hr />
                            <li className='py-2 cursor-pointer'>
                                <div className={`item flex text-[18px] justify-start step-tool items-center font-medium ${urlCurrent.includes(ROUTERS.VTO) ? 'item-active' : ''}`} onClick={() => navigate(ROUTERS.VTO)}>{t('vto')}</div>
                            </li>
                            <hr />
                            <li onClick={() => navigate(ROUTERS.MY_WORK)} className={`item py-2 cursor-pointer flex text-[18px] justify-start step-tool items-center font-medium ${(urlCurrent.includes(ROUTERS.MY_WORK)) ? 'item-active' : ''}`}> {t('your_work')} </li>
                            <hr />
                            <li className='py-2 cursor-pointer'>
                                <div className={`item flex text-[18px] justify-start step-tool items-center font-medium ${(urlCurrent.includes(ROUTERS.UPLOAD) || urlCurrent.includes(ROUTERS.TOOL)) ? 'item-active' : ''}`} onClick={onClickToTool}>{t('tool')}</div>
                            </li>
                            <hr />
                            <li onClick={() => navigate(ROUTERS.TUTOR)} className={`item flex py-2 cursor-pointer text-[18px] justify-start step-tool items-center font-medium ${(urlCurrent.includes(ROUTERS.TUTOR)) ? 'item-active' : ''}`}> {t('tutor')}</li>
                        </ul>
                    </div>
                    <div className='flex flex-col justify-center items-center gap-2' >
                        <LanguageSwitch />
                        <Button type='primary' onClick={onLogout} className='mb-2 w-full'>
                            <div className='flex justify-center items-center gap-2 '>
                                <LoginOutlined />{t('logout')}
                            </div>
                        </Button>
                        <p className='text-[10px] text-center'>
                            {t('modeli_dev_by')}  <a href="https://heatmob.net" target='_blank'>Heatmob.net</a>
                        </p>
                        <p className='text-[10px]  text-center'>© Modeli. {t('all_rights_reserved')}</p>
                    </div>

                </Drawer>}
                <div className="menuToggleBtn" onClick={handleToggleOpen} > {
                    !isToggleOpen ? <MenuOutlined className='text-black' /> : <CloseOutlined className='text-black' />
                }</div>
            </div >
        </>
    )
}

export default NewHeader