import { useTranslation } from 'react-i18next';
import './styles.css'
import analytics from '../../firebase';
import { logEvent } from '@firebase/analytics';
import { useEffect } from 'react';

export const PrivacyPolicy = () => {
    const { t } = useTranslation();
    useEffect(()=>{
        logEvent(analytics, 'page_privacy_and_policy')
    },[])
    return (
        <section className='privacy-policy'>
            <h1 className="privacy-h1">{t('Privacy_Policy_for_V2A')}</h1>

            <p className="privacy-p">{t('Privacy_Policy_for_V2A_1')}</p>

            <p className="privacy-p">{t('Privacy_Policy_for_V2A_2')}</p>

            <p className="privacy-p">{t('Privacy_Policy_for_V2A_3')}</p>

            <h2 className="privacy-h2">{t('Consent')}</h2>

            <p className="privacy-p">{t('Consent_1')}</p>

            <h2 className="privacy-h2">{t('Information_we_collect')}</h2>

            <p className="privacy-p">{t('Information_we_collect_1')}</p>
            <p className="privacy-p">{t('Information_we_collect_2')}</p>
            <p className="privacy-p">{t('Information_we_collect_3')}</p>

            <h2 className="privacy-h2">{t('How_we_use_your_information')}</h2>

            <p className="privacy-p">{t('How_we_use_your_information_1')}</p>

            <ul className="privacy-ul-il">
                <li>{t('How_we_use_your_information_2')}</li>
                <li>{t('How_we_use_your_information_3')}</li>
                <li>{t('How_we_use_your_information_4')}</li>
                <li>{t('How_we_use_your_information_5')}</li>
                <li>{t('How_we_use_your_information_6')}</li>
                <li>{t('How_we_use_your_information_7')}</li>
                <li>{t('How_we_use_your_information_8')}</li>
            </ul>

            <h2 className="privacy-h2">{t('Log_Files')}</h2>

            <p className="privacy-p">{t('Log_Files_1')}</p>

            <h2 className="privacy-h2">{t('Cookies_and_Web_Beacons')}</h2>

            <p className="privacy-p">{t('Cookies_and_Web_Beacons_1')}</p>

            {/* <p className="privacy-p">For more general information on cookies, please read <a className="privacy-a" href="https://www.generateprivacypolicy.com/#cookies">the Cookies article on Generate Privacy Policy website</a>.</p> */}

            <h2 className="privacy-h2">{t('Advertising_Partners_Privacy_Policies')}</h2>

            <p className="privacy-p">{t('Advertising_Partners_Privacy_Policies_1')}</p>

            <p className="privacy-p">{t('Advertising_Partners_Privacy_Policies_2')}</p>

            <p className="privacy-p">{t('Advertising_Partners_Privacy_Policies_3')}</p>

            <h2 className="privacy-h2">{t('Third_Party_Privacy_Policies')}</h2>

            <p className="privacy-p">{t('Third_Party_Privacy_Policies_1')}</p>

            <p className="privacy-p">{t('Third_Party_Privacy_Policies_2')}</p>

            <h2 className="privacy-h2">{t('CCPA_Privacy_Rights')}</h2>

            <p className="privacy-p">{t('CCPA_Privacy_Rights_1')}</p>
            <p className="privacy-p">{t('CCPA_Privacy_Rights_2')}</p>
            <p className="privacy-p">{t('CCPA_Privacy_Rights_3')}</p>
            <p className="privacy-p">{t('CCPA_Privacy_Rights_4')}</p>
            <p className="privacy-p">{t('CCPA_Privacy_Rights_5')}</p>

            <h2 className="privacy-h2">{t('GDPR_Data_Protection_Rights')}</h2>

            <p className="privacy-p">{t('GDPR_Data_Protection_Rights_1')}</p>
            <p className="privacy-p">{t('GDPR_Data_Protection_Rights_2')}</p>
            <p className="privacy-p">{t('GDPR_Data_Protection_Rights_3')}</p>
            <p className="privacy-p">{t('GDPR_Data_Protection_Rights_4')}</p>
            <p className="privacy-p">{t('GDPR_Data_Protection_Rights_5')}</p>
            <p className="privacy-p">{t('GDPR_Data_Protection_Rights_6')}</p>
            <p className="privacy-p">{t('GDPR_Data_Protection_Rights_7')}</p>
            <p className="privacy-p">{t('GDPR_Data_Protection_Rights_8')}</p>

            <h2 className="privacy-h2">{t('Childrens_Information')}</h2>

            <p className="privacy-p">{t('Childrens_Information_1')}</p>

            <p className="privacy-p last-row">{t('Childrens_Information_2')}</p>

            <h2 className="privacy-h2">{t('Purchase')}</h2>

            <ul className="privacy-ul-il">
                <li>{t('Purchase_1')}</li>
                <li>{t('Purchase_2')}</li>
                <li>{t('Purchase_3')}</li>
                <li>{t('Purchase_4')}</li>
                <li>{t('Purchase_5')}</li>
            </ul>

            <h2 className="privacy-h2">{t('Subscription')}</h2>

            <ul className="privacy-ul-il">
                <li>{t('Subscription_1')}</li>
                <li>{t('Subscription_2')}</li>
                <li>{t('Subscription_3')}</li>
                <li>{t('Subscription_4')}</li>
            </ul>

            <h2 className="privacy-h2">{t('Free_Trial')}</h2>

            <ul className="privacy-ul-il">
                <li>{t('Free_Trial_1')}</li>
                <li>{t('Free_Trial_2')}</li>
                <li>{t('Free_Trial_3')}</li>
                <li>{t('Free_Trial_4')}</li>
                <li>{t('Free_Trial_5')}</li>
            </ul>

            {/* <h2 className="privacy-h2">{t('Terms_of_Use_EULA')}</h2>

            <a href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/">https://www.apple.com/legal/internet-services/itunes/dev/stdeula</a> */}
        </section>
    );
};
