import { PlusCircleOutlined, SettingOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { Button, Drawer, Switch, } from 'antd';
import { createRef, useEffect, useState } from 'react';
import { ReactCropperElement } from 'react-cropper';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import VTOEditOutfit from '../../components/VTO-EditOutfit/VTOEditOutFit';
import ListModelVTO from '../../components/VTO-listmodel';
import SearchInput from '../../components/VTO-SearchInput/SearchInput';
import ModalManagementCategory from '../../components/VTO-UploadModel/VTO-input-setting/ModalManagementCategory/ModalManagementCategory';
import VTOUploadModel, { IInputSetting } from '../../components/VTO-UploadModel/VTOUploadModel';
import i18n from '../../i18n/i18n';
import { setStateVTO } from '../../redux/app/appVTO';
import { setLoadingApp } from '../../redux/app/loadingApp';
import FashionService from '../../services/fashion.service';
import { ROUTERS } from '../../utils/constant';
import { base64ToBlob, isUrl } from '../../utils/function';
import NotifyController from '../../utils/toast';
import { uploadFileToS3 } from '../../utils/uploadS3';
import generation_report_icon from '../../assets/vto/icons/generation-report-icon.svg'
import { handleResponseError } from '../../utils/responseError';
type CropperState = {
    cropBoxData: any,
    canvasData: any,
};

function VTO() {
    const cropperRef = createRef<ReactCropperElement>();
    const [searchParams] = useSearchParams();
    const [slideIndex, setSlideIndex] = useState(0)
    const [cropperStates, setCropperStates] = useState<CropperState[]>([]);
    const [open, setOpen] = useState(false);
    const [dataEdit, setDataEdit] = useState<any>({})
    const [isEdit, setIsEdit] = useState(false)
    const [uploadedImages, setUploadedImages] = useState<any[]>([]);
    const [isOnClickReset, setIsOnClickReset] = useState(false)
    const [inputValue, setInputValue] = useState(searchParams.get('searchValue') ?? "");
    const [debouncedValue, setDebouncedValue] = useState(searchParams.get('searchValue') ?? "");
    const [selectedImage, setSelectedImage] = useState('')
    const [trainLoraShopHistory, setTrainLoraShopHistory] = useState<{ _id: string, status: string, images: string[], params: { prompt: string, weight: number }, outfit_id: string, updated_ts: string, language?: string } | undefined>()

    const { t } = useTranslation()
    const initialState: IInputSetting = {
        IdOutfit: '',
        category: '',
        prompt: '',
        weight: 1,
        productLink: '',
        generationLimitPerDay: null
    }


    const [inputSetting, setInputSetting] = useState<IInputSetting>(initialState)

    const [isOpenModalCategory, setIsOpenModalCategory] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const saveCropperState = (index: number) => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            const newCropperStates = [...cropperStates];
            newCropperStates[index] = {
                cropBoxData: cropper.getCropBoxData(),
                canvasData: cropper.getCanvasData(),
            };
            setCropperStates(newCropperStates);
            const cloneUploadedImages = [...uploadedImages]
            if (cloneUploadedImages.length > 0) {
                cloneUploadedImages[index].data = cropper.getData()
                setUploadedImages(cloneUploadedImages)
            } else {
                const cloneEditedImages = [...dataEdit?.imageAndSetting]
                if (cloneEditedImages.length > 0) {
                    cloneEditedImages[index].data = cropper.getData()
                    setDataEdit({ ...dataEdit, imageAndSetting: cloneEditedImages })
                }
            }
        }
    };

    const removeCropperState = (index: number) => {
        const newCropperStates = [...cropperStates];
        newCropperStates.splice(index, 1);
        setCropperStates(newCropperStates);
    }

    const handleCancel = () => {
        onClose()
        setIsOnClickReset(true)
        setInputSetting(initialState)
        setInputValue('')
        setSelectedImage('')
        setDataEdit({})
        setIsEdit(false)
        setUploadedImages([])
        cropperRef.current?.cropper?.reset()
        setCropperStates([])
    }

    const onFinalEdit = async () => {
        dispatch(setLoadingApp.setLoading(true))
        if (!dataEdit.generationLimitPerDay) {
            NotifyController.warning(t('please_input_limit_gen'))
            dispatch(setLoadingApp.setLoading(false))
            showDrawer()
            return
        }
        if (!dataEdit.category) {
            NotifyController.warning(t('please_input_category'))
            dispatch(setLoadingApp.setLoading(false))
            showDrawer()
            return
        }
        const payload = {
            outfitCode: dataEdit.outfitCode,
            imageAndSetting: dataEdit.imageAndSetting,
            prompt: dataEdit.prompt,
            productLink: dataEdit.productLink,
            category: dataEdit.category,
            weight: dataEdit.weight,
            lang: i18n.language,
            generationLimitPerDay: dataEdit.generationLimitPerDay,
        }
        try {
            const dataReturn = await FashionService.getInstance().updateVTOOutfit(payload);
            if (dataReturn && dataReturn.success) {
                NotifyController.success(t('edit_out_fit_successfully'))
                await handleSaveTrainLoraShopHistory(i18n.language, dataReturn.data.imageAndSetting[slideIndex]._id);
                dispatch(setStateVTO.setReload(true))
                setIsOnClickReset(true)
                dispatch(setLoadingApp.setLoading(false))
                handleCancel()
                onClose()
                setIsOnClickReset(true)
            } else {
                dispatch(setStateVTO.setReload(true))
                dispatch(setLoadingApp.setLoading(false))
            }
        } catch (error) {
            handleResponseError(error);
            dispatch(setLoadingApp.setLoading(false))
        }

    }

    const handleSaveTrainLoraShopHistory = async (language: string, outfit_id?: string) => {
        const clonedTrainLoraShopHistory: any = trainLoraShopHistory ? JSON.parse(JSON.stringify(trainLoraShopHistory)) : {};
        const imageURLs = [];
        const currentTime = Date.now();
        if ((clonedTrainLoraShopHistory.images?.length ?? 0) > 0) {
            for (let i = 0; i < clonedTrainLoraShopHistory.images.length; i++) {
                const image = clonedTrainLoraShopHistory.images[i]
                if (isUrl(image)) {
                    imageURLs.push(image)
                } else {
                    const blobFile = base64ToBlob(image, "image/jpg");
                    const url = await uploadFileToS3(blobFile, currentTime + `_${i}.jpg`, 'jpg')
                    imageURLs.push(url)
                }
            }
        }
        if (imageURLs.length > 0) {
            clonedTrainLoraShopHistory.images = imageURLs;
            clonedTrainLoraShopHistory.language = language;
            if (!clonedTrainLoraShopHistory.outfit_id && !outfit_id) {
                return;
            }
            clonedTrainLoraShopHistory.outfit_id = outfit_id;
            clonedTrainLoraShopHistory.params = '{}';
            try {
                await FashionService.getInstance().createLoraForShopUser(clonedTrainLoraShopHistory)
            } catch (error) {
                handleResponseError(error, t('something_wrong'));
            }
        }
    };

    const onFinalUpload = async () => {

        if (!inputSetting.IdOutfit) {
            NotifyController.warning(t('please_input_id'))
            dispatch(setLoadingApp.setLoading(false))
            showDrawer()
            return
        }
        if (!inputSetting.category) {
            NotifyController.warning(t('please_input_category'))
            dispatch(setLoadingApp.setLoading(false))
            showDrawer()
            return
        }
        if (!inputSetting.generationLimitPerDay) {
            NotifyController.warning(t('please_input_limit_gen'))
            dispatch(setLoadingApp.setLoading(false))
            showDrawer()
            return
        }
        if (uploadedImages?.length === 0) {
            NotifyController.warning(t('please_input_image'))
            dispatch(setLoadingApp.setLoading(false))
            showDrawer()
            return
        }
        const payload = {
            lang: i18n.language,
            outfitCode: inputSetting.IdOutfit,
            category: inputSetting.category,
            productLink: inputSetting.productLink,
            prompt: inputSetting.prompt,
            weight: inputSetting.weight,
            imageAndSetting: uploadedImages,
            generationLimitPerDay: inputSetting.generationLimitPerDay,
        }
        dispatch(setLoadingApp.setLoading(true))
        try {
            const dataReturn = await FashionService.getInstance().createVTOOutfit(payload);
            if (dataReturn && dataReturn.success) {
                NotifyController.success(t('upload_out_fit_successfully'))
                dataReturn.data[slideIndex]?._id && await handleSaveTrainLoraShopHistory(i18n.language, dataReturn.data[slideIndex]._id);
                dispatch(setStateVTO.setReload(true))
                setIsOnClickReset(true)
                handleCancel()
                onClose()
                dispatch(setLoadingApp.setLoading(false))
            } else {
                showDrawer()
                dispatch(setLoadingApp.setLoading(false))
            }
        } catch (error: any) {
            handleResponseError(error);
            dispatch(setLoadingApp.setLoading(false))
        }
    }

    const showDrawer = () => {
        setOpen(true);
    };

    const handleAddProducts = () => {
        showDrawer()
        handleCancel()
        setOpen(true);
    }
    const onCloseShower = () => {
        setOpen(false);
        handleCancel()
    }
    const onClose = () => {
        if (dataEdit) {
            setDataEdit({})
        }
        dispatch(setStateVTO.setReload(true));
        setOpen(false);
        cropperRef.current?.cropper?.reset()
        setCropperStates([])
        setSlideIndex(0) // reset indexActive
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setDebouncedValue(inputValue);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [inputValue]);


    useEffect(() => {
        if (isOnClickReset) {
            setInputSetting(initialState)
            setInputValue('')
            setSelectedImage('')
            setDataEdit({})
            setIsEdit(false)
            cropperRef.current?.cropper?.reset()
            setCropperStates([])
        }
    }, [isOnClickReset])

    useEffect(() => {
        if (Object.keys(dataEdit).length !== 0) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
            cropperRef.current?.cropper?.reset()
            setCropperStates([])
        }
    }, [dataEdit]);

    useEffect(() => {
        setIsOnClickReset(false)
    }, [])

    useEffect(() => {
        //chuyển về upload nếu xóa hết các ảnh
        if (uploadedImages.length === 0 && dataEdit?.imageAndSetting?.length === 0) {
            setIsEdit(false)
            cropperRef.current?.cropper?.reset()
            setCropperStates([])
        }

    }, [uploadedImages])
    return (
        <div className='flex gap-2 my-1 px-2'>
            <div className='left-result flex flex-col  bg-[#101828] rounded-lg w-[100%] p-4 mb-2 sm:p-2 sm:w-[96%]'>
                <div className='header-vto w-full flex gap-2 flex-wrap justify-between  items-center mb-2'>
                    <div className='title-header w-auto'>
                        <p className='title font-bold text-[20px] text-[white]'>{t('vto_title')}</p>
                    </div>
                    <div className='flex gap-2 gap-x-4 sm:w-full sm:justify-start sm:flex-wrap'>
                        <SearchInput setInputValue={setInputValue} />
                        <div>
                            <Button className='flex justify-center items-center' onClick={handleAddProducts} type='primary'>
                                <PlusCircleOutlined size={16} />
                                <span className='md:!hidden !block'>{t('add_product')}</span>
                            </Button>
                        </div>
                        <div>
                            <Button type='default' className='!bg-transparent text-white flex justify-center items-center hover:!text-white' onClick={() => { navigate(ROUTERS.VTO_REPORT) }}>
                                <Icon component={() => (<img src={generation_report_icon} />)} />
                                <span className='md:!hidden !block'>{t('generation_report')}</span>
                            </Button>
                        </div>
                        <div className='w-auto group'>
                            <Button type='default' className='!bg-transparent flex justify-center items-center ' onClick={() => { navigate(ROUTERS.VTO_SETTING) }}>
                                <SettingOutlined className='text-white text-[16px] group-hover:!text-primary' />
                            </Button>
                        </div>
                    </div>
                </div>
                <div className='content-result flex flex-col  bg-white h-full sm:h-full rounded-lg py-2 px-4 pt-3 min-h-fit overflow-hidden'>
                    <ListModelVTO setTrainLoraShopHistory={setTrainLoraShopHistory} dataEdit={dataEdit} setDataEdit={setDataEdit} isClickEdit={() => { setOpen(true); setIsEdit(true); }} valueSearch={debouncedValue} />
                </div>
            </div>
            {(open) && <Drawer title={isEdit ? t('title_edit') : t('title_upload')} onClose={onClose} open={open} size='large'>
                <div className='flex flex-col gap-2 w-full'>
                    <div className='left-result flex flex-col bg-white rounded-lg  '>
                        <div className='cpm-upload '>
                            {!isEdit
                                ? <VTOUploadModel
                                    isOpenCate={isOpenModalCategory}
                                    setIsOpenCate={setIsOpenModalCategory}
                                    setSelectedImage={setSelectedImage}
                                    setUploadedImages={setUploadedImages}
                                    uploadedImages={uploadedImages}
                                    isOnClickReset={isOnClickReset}
                                    setInputSetting={setInputSetting}
                                    inputSetting={inputSetting}
                                    saveCropperState={saveCropperState}
                                    cropperStates={cropperStates}
                                    setCropperStates={setCropperStates}
                                    cropperRef={cropperRef}
                                    slideIndex={slideIndex}
                                    setSlideIndex={setSlideIndex}
                                    removeCropperState={removeCropperState}
                                    onClose={onClose}
                                    setTrainLoraShopHistory={setTrainLoraShopHistory}

                                />
                                : <VTOEditOutfit
                                    isOpenCate={isOpenModalCategory}
                                    setIsOpenCate={setIsOpenModalCategory}
                                    setSelectedImage={setSelectedImage}
                                    setEditInputSetting={setDataEdit}
                                    dataEdit={dataEdit}
                                    setUploadedImages={setUploadedImages}
                                    uploadedImages={uploadedImages}
                                    isOnClickReset={isOnClickReset}
                                    selectedImage={selectedImage}
                                    setInputSetting={setInputSetting}
                                    inputSetting={inputSetting}
                                    saveCropperState={saveCropperState}
                                    cropperStates={cropperStates}
                                    setCropperStates={setCropperStates}
                                    cropperRef={cropperRef}
                                    slideIndex={slideIndex}
                                    setSlideIndex={setSlideIndex}
                                    onClose={onClose}
                                    setTrainLoraShopHistory={setTrainLoraShopHistory}

                                />
                            }
                        </div>
                    </div>
                    <div className='h-12'></div>
                    <div className='button-save flex gap-2 justify-center items-center pr-6  bg-white w-full'>
                        <div className='w-1/2' >
                            <Button className='rounded-md border border-[#667085] w-full' type='default' onClick={handleCancel}>{t('cancel')}</Button>
                        </div>
                        <div className='w-full'>
                            <Button type='primary' onClick={!isEdit ? onFinalUpload : onFinalEdit} className='w-full'>{t('save')}</Button>
                        </div>
                    </div>
                </div>
            </Drawer>}
            {isOpenModalCategory && <ModalManagementCategory isOpen={isOpenModalCategory} setIsOpen={setIsOpenModalCategory} setOpenRight={setOpen} />}
        </div>
    )
}

export default VTO