import { Button, Modal } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ModalGuideUploadExampleProps = {
    iOpen: boolean,
    setIsOpen: Function
}
function ModalGuideUploadExample({ iOpen, setIsOpen }: ModalGuideUploadExampleProps) {
    const { t } = useTranslation()
    const list_images_guide = [
        {
            is_accept: true,
            src_image: 'https://media2.vision2art.ai/modelguide-1.png'
        },
        {
            is_accept: true,
            src_image: 'https://media2.vision2art.ai/image-guide-tick-1.jpg'
        },
        {
            is_accept: false,
            src_image: 'https://media2.vision2art.ai/guide-model/guide-model-false-1.png'
        },
        {
            is_accept: false,
            src_image: 'https://media2.vision2art.ai/guide-model/guide-model-false-2.png'
        },
    ]
    return (
        <Modal open={iOpen} onCancel={() => setIsOpen(false)} centered className="max-w-[375px]" okText={t('got_it')}
            footer={[
                <Button type='primary' onClick={() => setIsOpen(false)}>{t('got_it')} </Button>
            ]
            }
        >
            <div className='grid grid-cols-2 items-center justify-items-center gap-6'>
                {list_images_guide.map((img: any, idx: number) => (
                    <div className={`select-bg w-[140px] min-h-[190px] h-auto rounded-sm bg-img ${img.is_accept ? 'active_acpt' : 'unactive_acpt'}`} style={{
                        backgroundImage: `url(${img.src_image})`,
                        backgroundPosition: 'center center',
                        backgroundSize: '100% 100%',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: '4px',
                        objectFit: 'fill',
                        position: 'relative',
                        textAlign: 'center'
                    }}></div>
                ))}
            </div>
            <ul>
                <div className='text-[12px] text-[#101828] rounded-lg p-2 text-left'>
                    <div>&#10003; {t('guide_image_vto_1')}</div>
                    <div>&#10003; {t('guide_image_vto_2')}</div>
                    <div>&#10003; {t('guide_image_vto_3')}</div>
                </div>
            </ul>
        </Modal>
    )
}

export default ModalGuideUploadExample