import { useAppSelector } from "../../redux/hooks/useAppSelector";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTERS } from "../../utils/constant";

export const TryNow = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const hasRoleVTO = useAppSelector(store => store.user.roles.includes('VTO') || store.user.roles.includes('ADMIN'))

    if (!hasRoleVTO) {
        navigate(ROUTERS.VTO)
    }

    const queryParams = new URLSearchParams(location.search);
    const shopId = queryParams.get('shopId');
    const id = queryParams.get('outfitCode');
    const linkBaseVto = process.env.REACT_APP_VTO_URL
    const url = linkBaseVto + `?shopId=${shopId}&outfitCode=${id}`

    const iframeStyle: React.CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: 'none',
    };

    const containerStyle: React.CSSProperties = {
        position: 'relative',
        width: '100%',
        height: '100vh',
        overflow: 'hidden',
    };

    return (
        <div style={containerStyle}>
            <iframe
                src={url}
                style={iframeStyle}
                title="Try Now"
            ></iframe>
        </div>

    );
};
