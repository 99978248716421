// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy {
    text-align: left !important;
}

.privacy-policy {
    padding: 50px;
}

.privacy-policy h1,
.privacy-policy h2 {
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PrivacyAndPolicy/styles.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAE/B;;AAHA;IAEI,aAAa;AACjB;;AAEA;;IAEI,aAAa;AACjB","sourcesContent":[".privacy-policy {\n    text-align: left !important;\n    padding: 50px;\n}\n\n.privacy-policy h1,\n.privacy-policy h2 {\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
