import { logEvent } from "@firebase/analytics";
import NotifyController from "../utils/toast";
import { httpGet, httpPost } from "./http.service";
import analytics from "../firebase";
import { customizeKey } from "../utils/function";

export default class PaymentService {
    private static instance: PaymentService;
    private constructor() { }
    public static getInstance() {
        if (!this.instance) {
            this.instance = new PaymentService();
        }
        return this.instance;
    }
    async getListPackage(
        featureType: string,
        lang: string
    ) {
        const { data } = await httpGet(`/product?featureType=${featureType}&lang=${lang}`);
        return data.data;
    }

    async createPaymentLinkVNPay(params: { productId: string, language: string, type: string, credits: number }) {
        const { data } = await httpPost('/payment/create-payment-vnpay', params);
        return data.data;
    }

    async createPaymentLinkStripe(params: { productId: string, currency: string, type: string, credits: number }) {
        const { data } = await httpPost('/payment/create-checkout-session', params);
        return data.data;
    }

    async checkPaymentStatus(checkoutSessionId: string) {
        const { data } = await httpPost('/payment/check-status', { checkoutSessionId });
        return data.data;
    }

    async cancelSubscription() {
        try {
            const { data } = await httpPost('/payment/cancel-subscription', {});
            return data;
        } catch (error: any) {
            NotifyController.error(error.message)
            logEvent(analytics, `server_error_${customizeKey(error.message)}`)
        }
    }

    async updateCardInfo() {
        const { data } = await httpGet('/payment/vnpay-update-card-info');
        return data.data;
    }
}
