import {
  FullscreenExitOutlined,
  RedoOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { Button, InputNumber, Modal, Slider, Tooltip } from "antd";
import { fabric } from "fabric";
import lodash from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "../../../hooks/useDebouncedCallback";
import { setIsAutoPose } from "../../../redux/app/appAutoPose";
import {
  IOpenposeJson,
  KEYPOINT_CONSTANT_RADIUS,
  OpenposeBody,
  OpenposeKeypoint2D,
  OpenposePerson
} from "./Openpose";
import SliderSettings from "../../AdvancedSetting/SliderSettings";
import { FashionParams } from "../../../utils/interface";
import { setTypeModel } from "../../../redux/app/selectTypeModel";
import { useAppSelector } from "../../../redux/hooks/useAppSelector";
import { ISizeImage } from "../ModalEditSegmentAndPose/ModalEditSegmentAndPose";
interface IPeople {
  peopleId: number | any;
  openposePersonRef: OpenposePerson | null;
}

type CustomizeFabricCanvasProp = {
  widthImage: number;
  heighImage: number;
  image: string;
  pose: any;
  setPoseUrl: Function;
  setDataOpenPose: Function;
  fashionParams: any;
  setFashionParams: Function;
  valueWeight: number;
  setValueWeight: Function;
  setIsOpenModalEdit: Function;
  sizeCanvas: ISizeImage
};

const CustomizeFabricCanvas = ({
  widthImage,
  heighImage,
  image,
  pose,
  setDataOpenPose,
  setPoseUrl,
  fashionParams,
  setFashionParams,
  valueWeight,
  setValueWeight,
  setIsOpenModalEdit,
  sizeCanvas
}: CustomizeFabricCanvasProp) => {
  const isAdmin = useAppSelector((store) => store.user.roles).includes("ADMIN");
  const fashionParamsReducer = useAppSelector((store) => store.select);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvas = useRef<fabric.Canvas>();
  const [hasChanged, setHasChanged] = useState(false);
  // const SCALE = sizeCanvas.width / sizeCanvas.height;
  const SCALE = sizeCanvas.width / widthImage;
  const [people, setPeople] = useState<IPeople>({
    peopleId: 0,
    openposePersonRef: null,
  });
  const [backgroundImage, setBackgroundImage] = useState<any>();

  const [openposeCanvas, _] = useState<fabric.Rect>(
    new fabric.Rect({
      selectable: false,
      evented: false,
      fill: "#000",
      // hasControls: false
    })
  );

  const keyPointMapTemp = useMemo(
    () => new Map<number, OpenposeKeypoint2D>(),
    []
  );
  const dispatch = useDispatch();

  // const SCALE = sizeCanvas.scale;
  const { t } = useTranslation();

  const undoHistory = useMemo(() => new Array(), []);
  const redoHistory = useMemo(() => new Array(), []);
  let lockMode = false;

  const resetZoom = () => {
    canvas.current?.setViewportTransform(IDENTITY_MATRIX);
    // initZoom();
  };

  const reset = async (
    hasResetZoom: boolean,
    resetChanged?: boolean,
    customizePose?: [number, number, number][]
  ) => {

    // If the person is active right now, deactivate it.
    if (!people.openposePersonRef) return;

    people.openposePersonRef.removeFromCanvas();
    people.openposePersonRef.allKeypoints().forEach((keypoint) => {
      keyPointMapTemp.delete(keypoint.id);
    });
    canvas.current && canvas.current.discardActiveObject().renderAll();
    // await sleep(5);

    const body = OpenposeBody.create(
      customizePose ?? preprocessPoints(pose, widthImage, heighImage)
    );
    if (body === undefined) {
      // If body is malformatted, no need to render face/hand.
      return undefined;
    }
    const newPerson = new OpenposePerson(null, body);
    setPeople({ peopleId: newPerson.id, openposePersonRef: newPerson });
    newPerson.addToCanvas(openposeCanvas);
    if (keyPointMapTemp.size === 0) {
      newPerson.allKeypoints().forEach((keypoint) => {
        keyPointMapTemp.set(keypoint.id, keypoint);
      });
    }
    canvas.current?.renderAll();
    hasResetZoom && resetZoom();
    resetChanged && setHasChanged(false);
  };

  const getKeypointProxy = (
    keypoint: OpenposeKeypoint2D
  ): OpenposeKeypoint2D | undefined => {
    return keyPointMapTemp.get(keypoint.id);
  };

  const updateKeypointProxy = (keypoint: OpenposeKeypoint2D) => {
    const proxy = getKeypointProxy(keypoint);
    if (!proxy) return;

    proxy.x = keypoint.x;
    proxy.y = keypoint.y;
  };

  const IDENTITY_MATRIX = [1, 0, 0, 1, 0, 0];
  const keypointMoveHandler = (event: fabric.IEvent<MouseEvent>) => {
    const target = event.target;
    if (target === undefined) return;

    if (target instanceof fabric.ActiveSelection) {
      // Group of points movement.
      const t = target.calcTransformMatrix();
      target.forEachObject((obj) => {
        if (obj instanceof OpenposeKeypoint2D) {
          obj.updateConnections(t);
        }
      });
    } else if (target instanceof OpenposeKeypoint2D) {
      target.updateConnections(IDENTITY_MATRIX);
      updateKeypointProxy(target);
    }
    setHasChanged(true);
    canvas.current?.renderAll();
  };

  const keypointModifiedHandler = useDebouncedCallback((event: fabric.IEvent<MouseEvent>) => {
    if (lockMode) return;
    const target = event.target;
    if (target instanceof fabric.ActiveSelection) {
      // Group of points movement.
      const tempMap = new Map();
      target.forEachObject((obj) => {
        if (obj instanceof OpenposeKeypoint2D) {
          const matrix = obj.calcTransformMatrix();
          const rectLeft = matrix[4];
          const rectTop = matrix[5];
          tempMap.set(obj.id, { x: rectLeft, y: rectTop });
        }
      });
      const clonedArray = [...Array.from(keyPointMapTemp.values())].map(
        (kp) => {
          if (!kp.selected_in_group) {
            return [
              kp.x + KEYPOINT_CONSTANT_RADIUS,
              kp.y + KEYPOINT_CONSTANT_RADIUS,
              kp.visible ? 1 : 0,
            ];
          } else {
            const clonedKp = {
              id: kp.id,
              left: kp.x,
              top: kp.y,
              visible: kp.visible,
            }
            const selectedObj = tempMap.get(clonedKp.id);
            if (selectedObj) {
              clonedKp.left = selectedObj.x;
              clonedKp.top = selectedObj.y;

              return [
                clonedKp.left!,
                clonedKp.top!,
                clonedKp.visible ? 1 : 0,
              ];
            }
          }
        }
      );
      undoHistory.push(clonedArray);
    } else {
      undoHistory.push(
        Array.from(keyPointMapTemp.values()).map((kp) => [
          kp.x + KEYPOINT_CONSTANT_RADIUS,
          kp.y + KEYPOINT_CONSTANT_RADIUS,
          kp.visible ? 1 : 0,
        ])
      );
    }
    redoHistory.length = 0;
  }, 200);

  const undo = () => {
    if (!canvas?.current) return;
    if (undoHistory.length > 0) {
      lockMode = true;
      if (undoHistory.length > 1) redoHistory.push(undoHistory.pop());
      const keypoints = undoHistory[undoHistory.length - 1];
      if (!keypoints) return;
      reset(false, undoHistory.length === 0, keypoints);
      lockMode = false;
    }
  };

  const redo = () => {
    if (!canvas?.current) return;
    if (redoHistory.length > 0) {
      lockMode = true;
      const keypoints = redoHistory.pop();
      undoHistory.push(keypoints);
      if (!keypoints) return;
      reset(
        false,
        undoHistory.length === 0 && redoHistory.length === 0,
        keypoints
      );
      lockMode = false;
    }
  };

  const initializeCanvas = () => {
    if (canvas.current) return;
    if (!canvasRef) return;
    canvas.current = new fabric.Canvas(canvasRef.current!, {
      // selection: true,
      preserveObjectStacking: true,
      fireRightClick: true,
      stopContextMenu: true,
    });
    resizeHTMLCanvas(widthImage * SCALE, heighImage * SCALE);
    canvas.current && canvas.current.add(openposeCanvas);
    // The openpose canvas should be at last layer.
    canvas.current && canvas.current.moveTo(openposeCanvas, 0);
    addPerson();



    const selectionHandler = (event: fabric.IEvent<MouseEvent>) => {
      if (event.selected) {
        event.selected
          .filter((o) => o instanceof OpenposeKeypoint2D)
          .forEach((p) => {
            const proxy = getKeypointProxy(p as OpenposeKeypoint2D);
            if (proxy) {
              if (event.selected!.length > 1) {
                proxy.selected_in_group = true;
              }
              proxy.selected = true;
            }
          });
      }

      if (event.deselected) {
        event.deselected
          .filter((o) => o instanceof OpenposeKeypoint2D)
          .forEach((p) => {
            const proxy = getKeypointProxy(p as OpenposeKeypoint2D);
            if (proxy) {
              if (event.selected!.length > 1) {
                proxy.selected_in_group = false;
              }
              proxy.selected = false;
            }
          });
      }
    };

    canvas.current && canvas.current.on("object:moving", keypointMoveHandler);
    canvas.current && canvas.current.on("object:scaling", keypointMoveHandler);
    canvas.current && canvas.current.on("object:rotating", keypointMoveHandler);
    canvas.current &&
      canvas.current.on("object:modified", keypointModifiedHandler);

    canvas.current && canvas.current.on("selection:created", selectionHandler);
    canvas.current && canvas.current.on("selection:updated", selectionHandler);
    let isHoverCtrl = false
    document.addEventListener("keydown", (e) => {
      if (e.code === "ControlLeft" || e.code === "ControlRight") {
        isHoverCtrl = true
      }
    });
    document.addEventListener("keyup", (e) => {
      if (e.code === "ControlLeft" || e.code === "ControlRight") {
        isHoverCtrl = false
      }
    });
    canvas.current &&
      canvas.current.on("mouse:wheel", (opt: fabric.IEvent<WheelEvent>) => {
        const delta = opt.e.deltaY;
        let zoom = canvas.current!.getZoom();
        zoom *= 0.999 ** delta;

        if (zoom > 20) zoom = 20;
        if (zoom < 0.01) zoom = 0.01;

        canvas.current!.zoomToPoint(
          { x: opt.e.offsetX, y: opt.e.offsetY } as fabric.Point,
          zoom
        );
        opt.e.preventDefault();
        opt.e.stopPropagation();
      });

    // Panning handler.
    let panning = false;
    let panningEnabled = false;

    canvas.current?.on("mouse:down", (opt: fabric.IEvent) => {
      if (opt.button !== 3) return;
      if (isHoverCtrl) {
        if (opt.button !== 3) return;
        if (!(opt.target instanceof OpenposeKeypoint2D)) return;
        opt.target._visible = false;
        canvas.current?.renderAll();
        return
      }
      panningEnabled = true;
      canvas.current!.selection = false;
      // Prevent default behaviour of Space which is scroll the page down.
      opt.e.preventDefault();
    });

    canvas.current?.on("mouse:up", (opt: fabric.IEvent) => {
      if (opt.button !== 3) return;
      panningEnabled = false;
      canvas.current!.selection = true;
    });
    // Attach the mouse down event to start panning
    canvas.current?.on("mouse:down", (opt: fabric.IEvent) => {
      if (panningEnabled) {
        panning = true;
      }
    });

    // Attach the mouse move event for panning
    canvas.current?.on("mouse:move", (opt: fabric.IEvent<MouseEvent>) => {
      if (panning && opt && opt.e) {
        const delta = new fabric.Point(opt.e.movementX, opt.e.movementY);
        canvas.current?.relativePan(delta);
      }
    });

    // Attach the mouse up event to stop panning
    canvas.current?.on("mouse:up", () => {
      panning = false;
    });
  };

  const resizeHTMLCanvas = (newWidth: number, newHeight: number) => {
    if (!canvas.current) return;
    canvas.current.setWidth(newWidth);
    canvas.current.setHeight(newHeight);
    canvas.current.calcOffset();
    canvas.current.requestRenderAll();
  };

  // const resizeOpenposeCanvas = (newWidth: number, newHeight: number) => {
  //   if (!canvas || !canvas.current) return;
  //   openposeCanvas.set({
  //     width: newWidth,
  //     height: newHeight,
  //   });
  //   canvas.current.centerObject(openposeCanvas);
  //   openposeCanvas.setCoords();
  //   canvas.current.requestRenderAll();
  // };

  const addPerson = () => {
    if (!canvas.current) return;

    const points = preprocessPoints(pose, sizeCanvas.width, sizeCanvas.height);
    const body = OpenposeBody.create(points);
    if (body === undefined) {
      // If body is malformatted, no need to render face/hand.
      return undefined;
    }
    const newPerson = new OpenposePerson(null, body);
    setPeople({ peopleId: newPerson.id, openposePersonRef: newPerson });
    newPerson.addToCanvas(openposeCanvas);
    if (keyPointMapTemp.size === 0) {
      newPerson.allKeypoints().forEach((keypoint) => {
        keyPointMapTemp.set(keypoint.id, keypoint);
      });
    }
    undoHistory.push(points);
    canvas.current?.renderAll();
    loadBackgroundImage();
  };

  function preprocessPoints(
    nums: number[],
    canvasWidth: number,
    canvasHeight: number
  ): [number, number, number][] {
    const normalized = lodash.every(nums, (num) => Math.abs(num) <= 1.0);
    const xFactor = normalized ? canvasWidth : 1.0;
    const yFactor = normalized ? canvasHeight : 1.0;
    const points = lodash.chunk(nums, 3) as [number, number, number][];
    return points.map((p) => [
      p[0] * xFactor * SCALE,
      p[1] * yFactor * SCALE,
      p[2],
    ]);
  }

  const downloadCanvasAsImage = () => {
    if (!hasChanged) {
      setIsOpenModalEdit(false);
      return
    }
    // resetZoom()
    if (!canvas.current) return;
    //de-active all object
    canvas.current.discardActiveObject();
    // Remove background
    if (backgroundImage) {
      canvas.current?.remove(backgroundImage);
      canvas.current?.renderAll();
    }

    function parseOpenposeJson(poseJson: IOpenposeJson): OpenposePerson[] {
      const canvasHeight = sizeCanvas.height;
      const canvasWidth = sizeCanvas.width;

      function restorePoints(
        nums: number[],
        canvasWidth: number,
        canvasHeight: number
      ): [number, number, number][] {
        const normalized = lodash.every(nums, (num) => Math.abs(num) <= 1.0);
        const xFactor = normalized ? canvasWidth : 1.0;
        const yFactor = normalized ? canvasHeight : 1.0;
        const points = lodash.chunk(nums, 3) as [number, number, number][];
        return points.map((p) => [
          p[0] * xFactor * (1 / SCALE),
          p[1] * yFactor * (1 / SCALE),
          p[2],
        ]);
      }

      return (poseJson.people || [])
        .map((personJson): OpenposePerson | undefined => {
          const body = OpenposeBody.create(
            restorePoints(
              personJson.pose_keypoints_2d,
              canvasWidth,
              canvasHeight
            )
          );
          if (body === undefined) {
            // If body is malformatted, no need to render face/hand.
            return undefined;
          }
          return new OpenposePerson(null, body);
        })
        .filter((person) => person !== undefined) as OpenposePerson[];
    }

    function addPerson(newPerson: OpenposePerson) {
      newPerson.addToCanvas(openposeCanvas);
      // Add the reactive keypoints to the keypointMap
      canvas.current?.renderAll();
    }

    if (people && people.openposePersonRef) {
      resetZoom()
      const poseJson = getCanvasAsOpenposeJson(people.openposePersonRef);
      if (!poseJson) return;
      people.openposePersonRef.removeFromCanvas();
      canvas.current?.renderAll();

      const newCanvasHeight = poseJson?.canvas_height;
      const newCanvasWidth = poseJson?.canvas_width;
      const canvasHeight = lodash.max([newCanvasHeight, heighImage])!;
      const canvasWidth = lodash.max([newCanvasWidth, widthImage])!;

      if (!canvas.current) return;
      canvas.current.setWidth(canvasWidth);
      canvas.current.setHeight(canvasHeight);
      canvas.current.calcOffset();
      canvas.current.renderAll();

      openposeCanvas.set({
        width: canvasWidth,
        height: canvasHeight,
      });
      canvas.current.centerObject(openposeCanvas);
      openposeCanvas.setCoords();
      canvas.current.renderAll();
      const finalPose = poseJson.people[0]?.pose_keypoints_2d.map((i) => {
        let newValue = i;
        if (i > 1) {
          newValue = i * (1 / SCALE);
        }
        return newValue;
      });
      parseOpenposeJson(poseJson).forEach((person) => addPerson(person));
      // Get the data URL of the canvas as a PNG image
      const dataUrl = canvas.current?.toDataURL({ format: "image/png" });
      setIsOpenModalEdit(false);
      dispatch(setIsAutoPose.setIsAutoPose(false));
      setDataOpenPose(finalPose)
      setPoseUrl(dataUrl, finalPose);
    }
  };

  const getCanvasAsOpenposeJson = (people: OpenposePerson) => {
    if (people == null) return;
    people.body.keypoints = Array.from(keyPointMapTemp.values());
    return {
      people: [people]
        .filter((person) => !person.allKeypointsInvisible())
        .map((person) => person.toJson()),
      animals: [],
      canvas_width: widthImage,
      canvas_height: heighImage,
    } as IOpenposeJson;
  };

  const loadBackgroundImage = () => {

    fabric.Image.fromURL(image, (img) => {
      img.set({
        left: openposeCanvas.left,
        top: openposeCanvas.top,
        scaleX: sizeCanvas.width / widthImage,
        scaleY: sizeCanvas.height / heighImage,
        opacity: 0.7,
        hasControls: false,
        hasBorders: false,
        lockScalingX: false,
        lockScalingY: false,
        hoverCursor: "default",
      });
      img.selectable = false;
      canvas.current?.setWidth(sizeCanvas.width)
      canvas.current?.setHeight(sizeCanvas.height)
      // Image should not block skeleton.
      canvas.current?.moveTo(img, 1);
      canvas.current?.renderAll();
      setBackgroundImage(img);
    });
  };
  const onChangePoseWeight = (value: any) => {
    if (!value) return
    if (isNaN(value)) {
      return;
    }
    setValueWeight(value)
    setFashionParams((prevParams: FashionParams) => ({
      ...prevParams,
      controlWeight: value,
    }));
    dispatch(setTypeModel.setFashionParams({
      ...fashionParamsReducer,
      isClickControlWeight: true,
    }));
  }

  useEffect(() => {
    initializeCanvas();
  }, [pose, widthImage, heighImage]);

  return (
    <>
      <div className="flex gap-2 justify-between sm:flex-col-reverse md:flex-col-reverse lg:flex-col-reverse">
        {/* text and button  */}
        <div className="flex flex-col justify-between w-1/2 md:!w-full lg:w-full sm:!w-full" >
          <div className="flex flex-col gap-2">
            <hr className="sm:hidden" style={{
              margin: '40px 0 ',
              opacity: '0.7'
            }} />
            <div className="flex flex-col gap-2">
              <div className="flex justify-between py-4">
                <div className="undo flex justify-between gap-4">
                  <div onClick={undo}><Tooltip title={t('undo')}><RollbackOutlined className="text-gray text-2xl hover:cursor-pointer hover:text-black " /></Tooltip></div>
                  <div onClick={redo}><Tooltip title={t('redo')}><RollbackOutlined className="text-gray text-2xl hover:cursor-pointer -scale-y-100 rotate-180 hover:text-black " /></Tooltip></div>
                </div>
                <div onClick={() => reset(true, true)}>
                  <Tooltip title={t('Reset')}>
                    <RedoOutlined className="text-gray text-2xl hover:cursor-pointer -scale-y-100 rotate-180 hover:text-black " />
                  </Tooltip>
                </div>
              </div>
              {/* <Button
                type="default"
                onClick={() => resetZoom()}
                className=" text-black w-[100px]"
              >
                <FullscreenExitOutlined className="text-black " />{" "}
                {t("reset_zoom")}
              </Button> */}
            </div>
            <div>
              <div className="flex justify-between">
                <div>
                  <p className="text-[#667085]">{t('icw_min')}</p>
                </div>
                <div>
                  <p className="text-[#667085]">{t('icw_max')}</p>
                </div>
              </div>
              <div className="flex ">
                <Slider
                  style={{
                    width: '100%'
                  }}
                  min={isAdmin ? 0 : 0.8} max={isAdmin ? 2 : 1.2}
                  step={0.01}
                  onChange={(e) => onChangePoseWeight(e)}
                  value={valueWeight}
                />
                <InputNumber
                  min={isAdmin ? 0 : 0.8}
                  max={isAdmin ? 2 : 1.2}
                  pattern="[0-9]*"
                  inputMode='decimal'
                  style={{ margin: '0 16px', width: '80px' }}
                  step={0.01}
                  value={valueWeight}
                  onChange={(e) => onChangePoseWeight(e)}
                />
              </div>
            </div>
            {/* <SliderSettings hidden={false} onChange={onChangePoseWeight} input={valueWeight} toolTipMin={t('icw_min')} toolTipMax={t('icw_max')} toolTip={'icw'} title={t('input_pose_weight')} min={isAdmin ? 0 : 0.8} max={isAdmin ? 2 : 1.2} step={0.01} /> */}
            <div className=" guide bg-[#F3F6FF] !p-7  min-h-[50px] flex rounded my-4 sm:hidden md:hidden lg:hidden">
              <div className="bg-[#F3F6FF]">
                <ul>
                  <span className="text-[#101828] text-[16px] font-semibold ">
                    {" "}
                    {t("guide")}:
                  </span>
                  <div className="text-[16px] text-[#667085] text-left">
                    &#8226; <span className="ml-2">{t("value_guide")}</span>
                  </div>
                  <div className="text-[16px] text-[#667085] text-left">
                    &#8226; <span className="ml-2">{t("point_guide")}</span>
                  </div>
                  <div className="text-[16px] text-[#667085] text-left">
                    &#8226; <span className="ml-2">{t("moving_guide")}</span>
                  </div>
                  <div className="text-[16px] text-[#667085] text-left">
                    &#8226; <span className="ml-2">{t("zoom_guide")}</span>
                  </div>
                  {/* <div className="text-[16px] text-[#667085] text-left">
                    &#8226; <span className="ml-2">{t("delete_node")}</span>
                  </div> */}
                </ul>
              </div>
            </div>
          </div>
          <div className="flex gap-2 h-full items-end">
            <Button
              className="w-full"
              danger
              onClick={() => setIsOpenModalEdit(false)}
            >
              {t("cancel")}
            </Button>
            <Button
              className="w-full"
              type="primary"
              onClick={() => downloadCanvasAsImage()}
            >
              {t('apply_pose')}
            </Button>
          </div>
        </div>
        {/* canvas */}
        <div
          className="flex justify-center items-center"
        >
          <canvas
            className=" relative "
            style={{
              position: "absolute",
              left: "0px",
              top: "0px",
              touchAction: "none",
              userSelect: "none",
              cursor: "default",
            }}
            ref={canvasRef}
          />
        </div>
        <div className=" guide bg-[#F3F6FF] !p-7  min-h-[50px]  rounded my-4 hidden sm:flex md:flex lg:flex ">
          <div className="bg-[#F3F6FF]">
            <ul>
              <span className="text-[#101828] text-[16px] font-semibold ">
                {" "}
                {t("guide")}:
              </span>
              <div className="text-[16px] text-[#667085] text-left">
                &#8226; <span className="ml-2">{t("value_guide")}</span>
              </div>
              <div className="text-[16px] text-[#667085] text-left">
                &#8226; <span className="ml-2">{t("point_guide")}</span>
              </div>
              <div className="text-[16px] text-[#667085] text-left">
                &#8226; <span className="ml-2">{t("moving_guide")}</span>
              </div>
              <div className="text-[16px] text-[#667085] text-left">
                &#8226; <span className="ml-2">{t("zoom_guide")}</span>
              </div>
            </ul>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default CustomizeFabricCanvas;
