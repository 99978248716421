import { Button, Collapse } from 'antd';
import { CollapseProps } from 'rc-collapse';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type CollapseProp = {
  promptInput: string,
  negativeInput: string,
  user?: any,
  response?: any,
  genTime?: string,
  id: string,
  genTimePerJob?: string[]
  queueTimePerJob?: string[]
}

function CollapseCustomize({ promptInput, id, negativeInput, user, response, genTime, genTimePerJob, queueTimePerJob }: CollapseProp) {
  const { t } = useTranslation();
  const panelStyle: React.CSSProperties = {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '20px',
    marginLeft: '-15px'
  };
  const goToDebug = (id: string) => {
    const link = process.env.REACT_APP_DEBUG_URL + '/' + id;
    window.open(link, "_blank");
  }
  const copyLink = (id: string) => {
    const link = process.env.REACT_APP_DEBUG_URL + '/' + id;
    navigator.clipboard.writeText(link);
  }
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: t('advanced'),
      children:
        <ul className='mt-[-25px] ml-5 text-sm'>
          <li style={{ color: '#667085' }}>
            <span className='text-black'>Prompt:</span> {promptInput}
          </li>
          <li style={{ color: '#667085' }}>
            <span className='text-black'>Negative prompt:</span> {negativeInput}
          </li>
          {user && user.roles.includes('ADMIN') && (
            <>
              <li>
                <span className='text-black'>Processing Time: </span> {genTime}
              </li>
              <li>
                <span className='text-black'>Queue Time per Job: </span> {queueTimePerJob?.join("  ")}
              </li>
              <li>
                <span className='text-black'>Gen Time per Job: </span> {genTimePerJob?.join("  ")}
              </li>
              <li style={{ color: '#667085' }}>
                <span className='text-black'>More Info:</span> {response}
              </li>
              <li className='flex gap-4 mt-2' style={{ color: '#667085' }}>

                <Button onClick={e => goToDebug(id)}>Go to Link debug </Button>
                <Button onClick={e => copyLink(id)}>Copy Link debug </Button>

              </li>
            </>
          )}
        </ul>,
      style: panelStyle
    }
  ];

  return (
    <div>
      <Collapse ghost items={items} />
    </div>
  )
}

export default CollapseCustomize