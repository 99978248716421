import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useState } from "react";
import { useTranslation } from 'react-i18next';

interface InputPasswordProps {
  id: string
  label: string
  error: any
  register: any,
  ignoreCheckPattern?: boolean
}

export default function InputPassword({ id, label, error, register, ignoreCheckPattern }: InputPasswordProps) {
  const [showPassword, setShowHidePassword] = useState(false);
  const { t } = useTranslation()

  return (
    <div className='my-2'>
      <label className='font-semibold' htmlFor={id}>{label}<span className='text-red-500 ml-1'>*</span></label>
      <div className="flex">
        <input id={id} type={showPassword ? 'text' : 'password'}
          className='ant-input css-dev-only-do-not-override-yi65l5 custom-input my-2 w-full pl-3'
          {...register(id, {
            required: `${t('form_required_error_msg').replace('{{fieldName}}', label.toLowerCase())}`,
            pattern: ignoreCheckPattern ? undefined : {
              value: new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#\$%\^&\*])(.{8,})"),
              message: t('pw_wrong_format')
            }
          })} />
        <span className="flex justify-around items-center cursor-pointer" onClick={() => setShowHidePassword((preS) => !preS)}>
          {showPassword ? <EyeInvisibleOutlined width={14} className='absolute right-10' /> : <EyeOutlined width={14} className='absolute right-10' />}
        </span>
      </div>
      {error && <span className='text-red-500'>{error.message}</span>}
    </div>
  )
}