import { createSlice } from '@reduxjs/toolkit';

interface LoadingState {
  isLoading: boolean;
}

const initialState: LoadingState = {
    isLoading: false,
};


const eventLoading = createSlice({
  name: 'setLoadingApp',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
  },
});


export const setLoadingApp = eventLoading.actions;
export default eventLoading.reducer;