import { Button, Modal } from 'antd'
import { ScissorOutlined, HighlightOutlined, FullscreenOutlined, GatewayOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { CloseOutlined } from '@ant-design/icons';
import 'react-image-crop/dist/ReactCrop.css'
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../redux/hooks/useAppSelector';
import { useDispatch } from 'react-redux';
import { editorImage } from '../../redux/app/appImagePreprocessing';
import more from '../../assets/icons/more.svg';
import hidden from '../../assets/icons/hidden.svg';
import watermark from '../../assets/icons/watermark-black-2.svg';
import watermarkActive from '../../assets/icons/watermark-black-2-active.svg';
import adjust from '../../assets/icons/colorBlack.svg';
import adjustActive from '../../assets/icons/colorBlue.svg';
import swapface from '../../assets/icons/swapfaceBlack.svg';
import swapfaceActive from '../../assets/icons/swapfaceBlue.svg';
import upscale from '../../assets/icons/upscaleBlack.svg';
import upscaleActive from '../../assets/icons/upscaleBlue.svg';
import createWatermark from '../../assets/icons/createWatemarkBlack.svg';
import createWatermarkActive from '../../assets/icons/createWatemarkBlue.svg';
import reGen from '../../assets/icons/regenBlack.svg';
import reGenActive from '../../assets/icons/regenBlue.svg';
import borderSmooth from '../../assets/icons/borderSmoothBlack.svg';
import borderSmoothActive from '../../assets/icons/borderSmoothBlue.svg';
import { logEvent } from '@firebase/analytics';
import { setLoadingApp } from '../../redux/app/loadingApp';
import FashionService from '../../services/fashion.service';
import { userSaga } from '../../redux/app/authSaga';
import { FashionToolType, TypeEditor } from '../../utils/enum';
import NotifyController from '../../utils/toast';
import analytics from '../../firebase';
import { convertBase64Image, convertBlobToBase64, convertHeicToJpg, getImageSize, resizeImageByWidthAndHeight, resizeImageMax2k, urlToBase64 } from '../../utils/function';
import ModalResize from '../../components/UploadImage/ModalResize';
import Advanced from '../../components/UploadImage/advanced';
import ModalResizeCanvas from '../../components/UploadImage/ModalResizeCanvas';
import RemoveWatermark from '../../components/UploadImage/RemoveWatermark';
import ModalUpscale from '../../components/UploadImage/ModalUpscale';
import RotateScreen from '../../components/UploadImage/RotateScreen';
import { useLocation, useNavigate } from 'react-router-dom';
import ModalAddWaterMark from '../../components/ListRenderedImages/ModalAddWaterMark';
import ModalRemoveObject from '../../components/ListRenderedImages/ModalRemoveObject';
import ModalBorderSmooth from '../../components/ListRenderedImages/ModalBorderSmooth';
import { toolEditorImage } from '../../redux/app/toolEditor';
import './styles.css'
import SwapFace from './Tools/swapface';
import swal from 'sweetalert';
import { setLess512 } from '../../redux/app/uploadImgeSaga';
import { useDropzone } from 'react-dropzone';
import Upscale from './Tools/Upscale';
import BasicTool from './Tools/BasicTool';
import Color from './Colors/Color';
import Upload from './PageUpload/Upload';
import { ROUTERS } from '../../utils/constant';
import { isGenStore } from '../../redux/app/appIsGenerating';
import { useOrientation } from 'react-use';
type ImageCropDataFromResizeTabProps = {
    width: number,
    height: number,
    imageUrl: string
}
type ToolEditor = {
    isToolModal: boolean;
    setIsOpenModal: Function;
    setIsGenerating: Function;
}
function ToolEditor({ isToolModal, setIsOpenModal, setIsGenerating }: ToolEditor) {
    const [isModal, setIsModal] = useState<boolean>(isToolModal)
    const [showAll, setShowAll] = useState(false);
    const storeEditor = useAppSelector(store => store.toolEditor)
    const dataCost = useAppSelector(store => store.costFeature)
    const typeInput = storeEditor.typeEdit
    const typeOutPutImagePost = storeEditor.typeImageOutput;
    const [image, setImage] = useState('')
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const imageCropData = useAppSelector((store) => store.EditorImage)
    const currentCoins = useAppSelector((store) => store.user.coins);
    const hasCrop = useAppSelector(store => store.EditorImage.hasCropImage)
    const resizeCanvasRef = useRef<HTMLCanvasElement>(null)
    const [openModalAdvanced, setOpenModalAdvanced] = useState(false)
    const [imageUrl, setImageUrl] = useState<string>(storeEditor.inputImage || '')
    const [samID, setSamID] = useState<string>('')
    const [imageId, setImagId] = useState<string>('')
    const [isOpenModalWarning, setIsOpenModalWarning] = useState(false);
    const [firstLoadImage, setFirstLoadImage] = useState(false);
    const [imageBeforeUpscale, setImageBeforeUpscale] = useState('');
    let defaultTabActive = (typeInput === TypeEditor.PRE || typeInput === TypeEditor.UPLOAD) ? 'cut' : (typeInput === TypeEditor.POST && storeEditor.samId) ? 'border_smooth' : 'swap_face';
    const [tabActive, setTabActive] = useState<string>(defaultTabActive)
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [isRotate, setRotate] = useState(false)
    useEffect(() => {
        storeEditor.samId && setSamID(storeEditor.samId)
        storeEditor.imageId && setImagId(storeEditor.imageId)
        setImageUrl(storeEditor.inputImage)
        dispatch(editorImage.setURLImageEditor({ imageUrl: storeEditor.inputImage }))
        setImage(storeEditor.inputImage)
    }, [storeEditor, tabActive])
    const widthScreen = window.innerWidth;

    const onConfirmEditor = async (imageCropDataFromResizeTab?: ImageCropDataFromResizeTabProps) => {
        if (!hasCrop && tabActive !== 'cut') {
            NotifyController.warning(t('please_crop_correct_ratio_image'));
            setLoading(false)
            setTabActive('cut')
            return
        }
        let imageDataCrop = {
            width: imageCropData.width,
            height: imageCropData.height,
            imageUrl: imageCropData.imageUrl
        }

        if (imageCropDataFromResizeTab && imageCropDataFromResizeTab.width && imageCropDataFromResizeTab.height && imageCropDataFromResizeTab.imageUrl) {
            let imgWidth: number = imageCropDataFromResizeTab.width;
            let imgHeight: number = imageCropDataFromResizeTab.height;
            if (imageCropDataFromResizeTab.width > 768) {
                imgWidth = 768;
                imgHeight = Math.round((768 * imageCropDataFromResizeTab.height) / imageCropDataFromResizeTab.width);
            }
            else {
                imgWidth = imageCropDataFromResizeTab.width - (imageCropDataFromResizeTab.width % 8);
                imgHeight = imageCropDataFromResizeTab.height - (imageCropDataFromResizeTab.height % 8);
            }
            const newBase64Image = await resizeImageByWidthAndHeight(imageCropDataFromResizeTab.imageUrl, imgWidth, imgHeight)
            imageDataCrop = {
                width: imgWidth,
                height: imgHeight,
                imageUrl: newBase64Image as string
            }
        }
        dispatch(editorImage.setImageEditor(imageDataCrop))
        const ratioImage = Math.round((imageDataCrop.height / imageDataCrop.width) * 10) / 10;
        if (ratioImage === Math.round((3 / 2) * 10) / 10 || ratioImage === Math.round((4 / 3) * 10) / 10) {
            setFirstLoadImage(true)
            checkLess512(imageDataCrop.imageUrl, imageDataCrop.width, imageDataCrop.height)
        } else {
            NotifyController.warning(t('please_crop_correct_ratio_image'))
            setTabActive('cut')
            // setFirstLoadImage(false)
        }
        // }
    }

    const checkLess512 = (url: string, inputWidth: number, inputHeight: number) => {
        if (inputWidth < 700 || inputHeight < 1000) {
            setImageBeforeUpscale(url)
            setIsOpenModalWarning(true)
        } else {
            closeModal();
            dispatch(toolEditorImage.setImageOutput(url))
            dispatch(toolEditorImage.setSizeImage({
                width: inputWidth,
                height: inputHeight
            }))
            dispatch(toolEditorImage.setIsLoadingSegment(true))
            checkIsModalAndNavigate()
        }
    }

    const upscaleImage = async () => {
        dispatch(setLoadingApp.setLoading(true));
        try {
            const imageAfterUpscale = await FashionService.getInstance().processUpscaleV2(imageBeforeUpscale);
            dispatch(toolEditorImage.setImageOutput(`data:image/jpeg;base64,${imageAfterUpscale.imagePaths}`))
            dispatch(toolEditorImage.setSizeImage({
                width: Math.round(imageAfterUpscale.width),
                height: Math.round(imageAfterUpscale.height)
            }))
            dispatch(toolEditorImage.setIsLoadingSegment(true))
            checkIsModalAndNavigate()
            // getCropData(`data:image/jpeg;base64,${imageAfterUpscale.imagePaths}`, Math.round(imageAfterUpscale.width), Math.round(imageAfterUpscale.height));
            setIsOpenModalWarning(false);
            dispatch(userSaga.setCredits(currentCoins - dataCost.upscale));
            closeModal();
        } catch (error) {
            NotifyController.error(t('An error occurred, please proceed with a low-quality image.'));
            setIsOpenModalWarning(false);
            continuesUploadFunction();
            closeModal();
        }
        dispatch(setLoadingApp.setLoading(false));
    }

    const continuesUploadFunction = () => {
        logEvent(analytics, 'upload_image')
        dispatch(toolEditorImage.setImageOutput(imageBeforeUpscale))
        dispatch(toolEditorImage.setSizeImage({
            width: imageCropData.width,
            height: imageCropData.height
        }))
        checkIsModalAndNavigate()
        dispatch(toolEditorImage.setIsLoadingSegment(true))
        // getCropData(imageBeforeUpscale, imageCropData.width, imageCropData.height);
    }

    const onChangeToCut = () => {
        if (firstLoadImage)
            setTabActive('cut')
        else
            NotifyController.warning(t('please_crop_image'))
    }

    const onChangeToRemove = () => {
        if (firstLoadImage) {

            setTabActive('remove')
            setOpenModalAdvanced(true)

        }
        else
            NotifyController.warning(t('please_crop_image'))
    }

    const onChangeToResize = () => {
        if (firstLoadImage) {
            setImageUrl(storeEditor.inputImage)
            dispatch(editorImage.setURLImageEditor({ imageUrl: storeEditor.inputImage }))
            setTabActive('resize')
        } else
            NotifyController.warning(t('please_crop_image'))
    }

    const onChangeToRemoveWatermark = () => {
        if (firstLoadImage) {
            setImageUrl(storeEditor.inputImage)
            setTabActive('remove_watermark')
        }
        else
            NotifyController.warning(t('please_crop_image'))
    }
    const onChangeToSwapFace = () => {
        setImageUrl(storeEditor.inputImage)
        setTabActive('swap_face')

    }
    const onChangeToColor = () => {
        setImageUrl(storeEditor.inputImage)
        setTabActive('color')

    }
    const onChangeToRegen = () => {
        setImageUrl(storeEditor.inputImage)
        setTabActive('regen')

    }
    const onChangeToCreateWaterMark = () => {
        setImageUrl(storeEditor.inputImage)
        setTabActive('create_wtm')

    }
    const onChangeToBorderSmooth = () => {
        setImageUrl(storeEditor.inputImage)
        setTabActive('border_smooth')

    }
    const onChangeToUpscale = () => {
        setImageUrl(storeEditor.inputImage)
        setTabActive('upscale')

    }
    const onChangeToBasic = () => {
        setImageUrl(storeEditor.inputImage)
        setTabActive('basic')

    }
    const onClickMore = () => {
        dispatch(editorImage.setURLImageEditor({ imageUrl: storeEditor.inputImage }))
        setShowAll(!showAll);
    }
    const closeModal = () => {
        dispatch(editorImage.resetImageEditor());
        setTimeout(() => {
            // closeModel && closeModel();
        }, 100)
    }

    const onClickSave = () => {
        if (storeEditor.inputImage) {
            const link = document.createElement('a');
            link.href = storeEditor.inputImage;
            link.download = 'modeli-image.png';
            link.click();
        } else {
            NotifyController.info(t('image_not_found'))
        }
    }

    const checkIsModalAndNavigate = () => {
        if (isModal) {
            setIsOpenModal(false)
        } else {
            navigate('/')
        }
    }

    const onClickBackToHome = () => {
        // dispatch(toolEditorImage.resetImageEditor());
        if (isModal) {
            setIsOpenModal(false)
            dispatch(isGenStore.setBackToHome(true))
        } else {
            navigate('/')
        }
    }

    useEffect(() => {
        return () => {
            if (!urlCurrent.includes(ROUTERS.UPLOAD)) resizeCanvasRef.current?.remove()
        }
    }, [imageCropData.imageUrl])

    useEffect(() => {
        setFirstLoadImage(true)
    }, [image])

    useEffect(() => {
        if (!urlCurrent.includes(ROUTERS.UPLOAD))
            if (image) {
                setImageUrl(image)
            }
    }, [image])


    useEffect(() => {
        (async () => {
            if (!urlCurrent.includes(ROUTERS.UPLOAD)) {
                const { width, height } = await getImageSize(storeEditor.inputImage)

                dispatch(toolEditorImage.setSizeImage({ width, height }))
            }
        })()
        return () => {
            dispatch(editorImage.resetImageEditor());
            // isModal && checkIsModalAndNavigate()
            // dispatch(toolEditorImage.resetImageEditor())
        }
    }, [])

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            // setWidthScreen(newWidth);
            if (newWidth < 512) {
                setRotate(true);
            } else {
                setRotate(false);
            }
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Initial log and check
        handleResize();

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const urlCurrent = window.location.pathname;
    return (
        <div id="modal-resize" className='px-3 max-h-[calc(100vh_-_120px)]'>
            {isRotate ? <RotateScreen /> :
                <div className='flex gap-2 '>
                    <div className='flex flex-col min-w-[110px] max-h-[calc(100vh_-_120px)] overflow-y-auto overflow-x-hidden fixed left-4'>
                        {typeInput === TypeEditor.UPLOAD && <>
                            <div className={`tab-pre  ${tabActive === 'cut' ? 'tab_selected hover:cursor-pointer' : ''}`} onClick={onChangeToCut} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2' >
                                    <ScissorOutlined className={tabActive === 'cut' ? 'text-[#4E89FF] text-2xl font-semibold' : 'text-2xl font-semibold text-[#667085]'} />
                                    <p className='font-normal text-[12px] text-center'>{t('cut_image')}</p>
                                </div>
                            </div>
                            <div className={`tab-pre ${tabActive === 'resize' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToResize} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <FullscreenOutlined className={tabActive === 'resize' ? 'text-[#4E89FF] text-2xl font-semibold' : 'text-2xl font-semibold text-[#667085]'} />
                                    <p className='font-normal text-[12px] text-center'>{t('resize_image')}</p>
                                </div>
                            </div>
                            <div className={`tab-pre ${tabActive === 'color' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToColor} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <Icon className={tabActive === 'color' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'color' ? adjustActive : adjust} className={'w-[30px] h-[30px]'} />)} />
                                    <p className='font-normal text-[12px] text-center'>{t('color')}</p>
                                </div>
                            </div>
                            <div className={`tab-pre  ${tabActive === 'remove' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToRemove} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <HighlightOutlined className={tabActive === 'remove' ? 'text-[#4E89FF] text-2xl font-normal' : 'text-2xl font-semibold text-[#667085]'} />
                                    <p className='font-normal text-[12px] text-center'>{t('remove_image')}</p>
                                </div>
                            </div>

                            <div className={`tab-pre  ${tabActive === 'remove_watermark' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToRemoveWatermark} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <Icon className={tabActive === 'remove_watermark' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'remove_watermark' ? watermarkActive : watermark} className={'w-[20px] h-[20px]'} />)} />
                                    <p className='font-normal text-[12px] text-center'>{t('remove_watermark_image')}</p>
                                </div>
                            </div>
                            <div className={`tab-pre ${tabActive === 'upscale' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToUpscale} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <Icon className={tabActive === 'upscale' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'upscale' ? upscaleActive : upscale} className={'w-[20px] h-[20px]'} />)} />
                                    <p className='font-normal text-[12px] text-center'>{t('upscale')}</p>
                                </div>
                            </div>
                            <div className={`tab-pre ${tabActive === 'swap_face' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToSwapFace} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <Icon className={tabActive === 'swap_face' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'swap_face' ? swapfaceActive : swapface} className={'w-[25px] h-[25px]'} />)} />
                                    <p className='font-normal text-[12px] text-center'>{t('swap_face')}</p>
                                </div>
                            </div>
                            <div className={`tab-pre  ${tabActive === 'create_wtm' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToCreateWaterMark} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <Icon className={tabActive === 'create_wtm' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'create_wtm' ? createWatermarkActive : createWatermark} className={'w-[20px] h-[20px]'} />)} />
                                    <p className='font-normal text-[12px] text-center'>{t('create_wtm')}</p>
                                </div>
                            </div>

                            <div className={`tab-pre ${tabActive === 'regen' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToRegen} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <Icon className={tabActive === 'regen' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'regen' ? reGenActive : reGen} className={'w-[25px] h-[25px]'} />)} />
                                    <p className='text-center font-normal text-[12px] text-center'>{t('regen')}</p>
                                </div>
                            </div>
                        </>}
                        {typeInput === TypeEditor.PRE && <>
                            <div className={`tab-pre  ${tabActive === 'cut' ? 'tab_selected hover:cursor-pointer' : ''}`} onClick={onChangeToCut} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2' >
                                    <ScissorOutlined className={tabActive === 'cut' ? 'text-[#4E89FF] text-2xl font-semibold' : 'text-2xl font-semibold text-[#667085]'} />
                                    <p className='font-normal text-[12px] text-center'>{t('cut_image')}</p>
                                </div>
                            </div>
                            <div className={`tab-pre ${tabActive === 'resize' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToResize} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <FullscreenOutlined className={tabActive === 'resize' ? 'text-[#4E89FF] text-2xl font-semibold' : 'text-2xl font-semibold text-[#667085]'} />
                                    <p className='font-normal text-[12px] text-center'>{t('resize_image')}</p>
                                </div>
                            </div>
                            <div className={`tab-pre ${tabActive === 'color' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToColor} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <Icon className={tabActive === 'color' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'color' ? adjustActive : adjust} className={'w-[30px] h-[30px]'} />)} />
                                    <p className='font-normal text-[12px] text-center'>{t('color')}</p>
                                </div>
                            </div>
                            <div className={`tab-pre  ${tabActive === 'remove' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToRemove} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <HighlightOutlined className={tabActive === 'remove' ? 'text-[#4E89FF] text-2xl font-normal' : 'text-2xl font-normal text-[#667085]'} />
                                    <p className='font-normal text-[12px] text-center'>{t('remove_image')}</p>
                                </div>
                            </div>

                            <div className={`tab-pre  ${tabActive === 'remove_watermark' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToRemoveWatermark} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <Icon className={tabActive === 'remove_watermark' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'remove_watermark' ? watermarkActive : watermark} className={'w-[20px] h-[20px]'} />)} />
                                    <p className='font-normal text-[12px] text-center'>{t('remove_watermark_image')}</p>
                                </div>
                            </div>
                        </>}
                        {typeInput === TypeEditor.POST && <>
                            {
                                samID && <div className={`tab-pre ${typeInput === TypeEditor.POST ? '' : '!hidden'} ${tabActive === 'border_smooth' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToBorderSmooth} style={{
                                    pointerEvents: loading ? 'none' : 'auto',
                                    color: loading ? 'gray' : 'black'
                                }}>
                                    <div className='flex justify-center items-center flex-col gap-2'>
                                        <Icon className={tabActive === 'border_smooth' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'border_smooth' ? borderSmoothActive : borderSmooth} className={'w-[20px] h-[20px]'} />)} />
                                        <p className='font-normal text-[12px] text-center'>{t('border_smooth')}</p>
                                    </div>
                                </div>
                            }
                            <div className={`tab-pre ${tabActive === 'regen' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToRegen} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <Icon className={tabActive === 'regen' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'regen' ? reGenActive : reGen} className={'w-[25px] h-[25px]'} />)} />
                                    <p className='text-center font-normal text-[12px]'>{t('regen')}</p>
                                </div>
                            </div>
                            <div className={`tab-pre ${tabActive === 'color' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToColor} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <Icon className={tabActive === 'color' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'color' ? adjustActive : adjust} className={'w-[30px] h-[30px]'} />)} />
                                    <p className='font-normal text-[12px] text-center'>{t('color')}</p>
                                </div>
                            </div>
                            <div className={`tab-pre  ${typeInput === TypeEditor.POST ? '!hidden' : ''}  ${tabActive === 'cut' ? 'tab_selected hover:cursor-pointer' : ''}`} onClick={onChangeToCut} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2' >
                                    <ScissorOutlined className={tabActive === 'cut' ? 'text-[#4E89FF] text-2xl font-semibold' : 'text-2xl font-semibold text-[#667085]'} />
                                    <p className='font-normal text-[12px] text-center'>{t('cut_image')}</p>
                                </div>
                            </div>
                            <div className={`tab-pre ${tabActive === 'swap_face' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToSwapFace} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <Icon className={tabActive === 'swap_face' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'swap_face' ? swapfaceActive : swapface} className={'w-[25px] h-[25px]'} />)} />
                                    <p className='font-normal text-[12px] text-center'>{t('swap_face')}</p>
                                </div>
                            </div>
                            {typeOutPutImagePost !== FashionToolType.UPSCALE && <div className={`tab-pre ${tabActive === 'upscale' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToUpscale} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <Icon className={tabActive === 'upscale' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'upscale' ? upscaleActive : upscale} className={'w-[20px] h-[20px]'} />)} />
                                    <p className='font-normal text-[12px] text-center'>{t('upscale')}</p>
                                </div>
                            </div>}

                            <div className={`tab-pre  ${tabActive === 'create_wtm' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToCreateWaterMark} style={{
                                pointerEvents: loading ? 'none' : 'auto',
                                color: loading ? 'gray' : 'black'
                            }}>
                                <div className='flex justify-center items-center flex-col gap-2'>
                                    <Icon className={tabActive === 'create_wtm' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'create_wtm' ? createWatermarkActive : createWatermark} className={'w-[20px] h-[20px]'} />)} />
                                    <p className='font-normal text-[12px] text-center'>{t('create_wtm')}</p>
                                </div>
                            </div>
                        </>}
                        {/* <div className={`tab-pre  ${tabActive === 'basic' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToBasic} style={{
                            pointerEvents: loading ? 'none' : 'auto',
                            color: loading ? 'gray' : 'black'
                        }}>
                            <div className='flex justify-center items-center flex-col gap-2'>
                                <GatewayOutlined className={`text-[20px]  ${tabActive === 'basic' ? 'text-[#0047FF]' : 'text-black'}`} />
                                <p className='font-semibold'>{t('basic')}</p>
                            </div>
                        </div> */}
                        {/* <div className='flex justify-center items-center flex-col gap-2'>
                                <Icon className={tabActive === 'more' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={more} className={'w-[20px] h-[20px]'} />)} />
                                <p className='font-semibold'>{t('more')}</p>
                            </div> */}

                        {showAll && typeInput === TypeEditor.PRE && (
                            <div className=" container-1  max-w-fit min-w-fit " >
                                <ul className="list !flex !flex-col !gap-2 sm:!flex-col md:!flex-col  ">
                                    <div className={`tab-pre sm:w-full md:w-full ${tabActive === 'swap_face' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToSwapFace} style={{
                                        pointerEvents: loading ? 'none' : 'auto',
                                        color: loading ? 'gray' : 'black'
                                    }}>
                                        <div className='flex justify-center items-center flex-col gap-2'>
                                            <Icon className={tabActive === 'swap_face' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'swap_face' ? swapfaceActive : swapface} className={'w-[25px] h-[25px]'} />)} />
                                            <p className='font-normal text-[12px] text-center'>{t('swap_face')}</p>
                                        </div>
                                    </div>
                                    <div className={`tab-pre sm:w-full md:w-full ${tabActive === 'upscale' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToUpscale} style={{
                                        pointerEvents: loading ? 'none' : 'auto',
                                        color: loading ? 'gray' : 'black'
                                    }}>
                                        <div className='flex justify-center items-center flex-col gap-2'>
                                            <Icon className={tabActive === 'upscale' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'upscale' ? upscaleActive : upscale} className={'w-[20px] h-[20px]'} />)} />
                                            <p className='font-normal text-[12px] text-center'>{t('upscale')}</p>
                                        </div>
                                    </div>
                                    <div className={`tab-pre  sm:w-full md:w-full ${tabActive === 'create_wtm' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToCreateWaterMark} style={{
                                        pointerEvents: loading ? 'none' : 'auto',
                                        color: loading ? 'gray' : 'black'
                                    }}>
                                        <div className='flex justify-center items-center flex-col gap-2'>
                                            <Icon className={tabActive === 'create_wtm' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'create_wtm' ? createWatermarkActive : createWatermark} className={'w-[20px] h-[20px]'} />)} />
                                            <p className='font-normal text-[12px] text-center'>{t('create_wtm')}</p>
                                        </div>
                                    </div>
                                    <div className={`tab-pre sm:w-full md:w-full ${tabActive === 'regen' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToRegen} style={{
                                        pointerEvents: loading ? 'none' : 'auto',
                                        color: loading ? 'gray' : 'black'
                                    }}>
                                        <div className='flex justify-center items-center flex-col gap-2'>
                                            <Icon className={tabActive === 'regen' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'regen' ? reGenActive : reGen} className={'w-[25px] h-[25px]'} />)} />
                                            <p className='text-center font-normal text-[12px] '>{t('regen')}</p>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        )}
                        {showAll && typeInput === TypeEditor.POST && (
                            <div className="container-1 " >
                                <ul className="list flex flex-col">
                                    <>
                                        <div className={`tab-pre  ${tabActive === 'remove' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToRemove} style={{
                                            pointerEvents: loading ? 'none' : 'auto',
                                            color: loading ? 'gray' : 'black'
                                        }}>
                                            <div className='flex justify-center items-center flex-col gap-2'>
                                                <HighlightOutlined className={tabActive === 'remove' ? 'text-[#4E89FF] text-2xl font-normal' : 'text-2xl font-normal text-[#667085]'} />
                                                <p className='font-normal text-[12px] text-center'>{t('remove_image')}</p>
                                            </div>
                                        </div>
                                        <div className={`tab-pre  ${tabActive === 'remove_watermark' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onChangeToRemoveWatermark} style={{
                                            pointerEvents: loading ? 'none' : 'auto',
                                            color: loading ? 'gray' : 'black'
                                        }}>
                                            <div className='flex justify-center items-center flex-col gap-2'>
                                                <Icon className={tabActive === 'remove_watermark' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={tabActive === 'remove_watermark' ? watermarkActive : watermark} className={'w-[20px] h-[20px]'} />)} />
                                                <p className='font-normal text-[12px] text-center'>{t('remove_watermark_image')}</p>
                                            </div>
                                        </div>
                                    </>
                                </ul>
                            </div>
                        )}

                        {typeInput !== TypeEditor.UPLOAD && <div className={`tab-pre  ${tabActive === 'more' ? 'tab_selected hover:cursor-pointer' : 'hover:cursor-pointer'}`} onClick={onClickMore} style={{
                            pointerEvents: loading ? 'none' : 'auto',
                            color: loading ? 'gray' : 'black'
                        }}>
                            <div className='flex justify-center items-center flex-col gap-2'>
                                <Icon className={tabActive === 'more' ? 'text-[#4E89FF] text-2xl font-semibold' : 'font-semibold'} component={() => (<img src={!showAll ? more : hidden} className={'w-[20px] h-[20px]'} />)} />
                                <p className='font-normal text-[12px] text-center'>{!showAll ? t('more') : t('hidden')}</p>
                            </div>
                        </div>}
                    </div>
                    <div className='pt-4  ml-[100px] max-w-[calc(100vw_-_100px)] w-[calc(100vw_-_180px)]'>
                        {urlCurrent.includes(ROUTERS.UPLOAD) ? <Upload /> :
                            <>
                                {(tabActive === 'cut' && imageUrl !== '') && <ModalResize onClickBackToHome={() => onClickBackToHome()} onClickSave={() => onClickSave()} image={imageUrl} onConfirmEditor={onConfirmEditor} isLoading={loading} setLoading={setLoading} typeInput={storeEditor.typeEdit} />}
                                {(tabActive === 'remove' && imageUrl !== '') && openModalAdvanced && <Advanced onClickBackToHome={() => onClickBackToHome()} onClickSave={() => onClickSave()} openModalAdvanced={openModalAdvanced} tabActive={tabActive} setTabActive={setTabActive} onConfirmEditor={onConfirmEditor} isLoading={loading} typeInput={storeEditor.typeEdit} />}
                                {tabActive === 'resize' && <ModalResizeCanvas onClickBackToHome={() => onClickBackToHome()} onClickSave={() => onClickSave()} tabActive={tabActive} setTabActive={setTabActive} onConfirmEditor={onConfirmEditor} isLoading={loading} typeInput={storeEditor.typeEdit} />}
                                {tabActive === 'create_wtm' && <ModalAddWaterMark onClickBackToHome={() => onClickBackToHome()} onClickSave={() => onClickSave()} url={imageUrl} />}
                                {tabActive === 'border_smooth' && <ModalBorderSmooth setIsGenerating={(e: boolean) => setIsGenerating(e)} isModal={isModal} setIsOpenModal={setIsOpenModal} onClickBackToHome={() => onClickBackToHome()} onClickSave={() => onClickSave()} url={imageUrl} samID={samID} imageId={imageId} />}
                                {(tabActive === 'swap_face' && imageUrl !== '') && <SwapFace setIsGenerating={(e: boolean) => setIsGenerating(e)} isModal={isModal} setIsOpenModal={setIsOpenModal} onClickBackToHome={() => onClickBackToHome()} onClickSave={() => onClickSave()} originImageUrl={imageUrl} imageId={imageId} />}
                                {tabActive === 'upscale' && <Upscale setIsGenerating={(e: boolean) => setIsGenerating(e)} isModal={isModal} setIsOpenModal={setIsOpenModal} onClickBackToHome={() => onClickBackToHome()} onClickSave={() => onClickSave()} url={imageUrl} imageId={imageId} />}
                                {tabActive === 'regen' && imageUrl !== '' && <ModalRemoveObject setIsGenerating={(e: boolean) => setIsGenerating(e)} isModal={isModal} setIsOpenModal={setIsOpenModal} onClickBackToHome={() => onClickBackToHome()} onClickSave={() => onClickSave()}
                                    url={imageUrl}
                                    imageId={imageId}
                                />}
                                {tabActive === 'color' && imageUrl !== '' && <Color onClickBackToHome={() => onClickBackToHome()} onClickSave={() => onClickSave()}
                                    url={imageUrl}
                                    typeInput={storeEditor.typeEdit}
                                    onConfirmEditor={onConfirmEditor}
                                />}
                                {tabActive === 'remove_watermark' && <RemoveWatermark onClickBackToHome={() => onClickBackToHome()} onClickSave={() => onClickSave()} typeInput={storeEditor.typeEdit} imageId={imageId}
                                    setLoading={setLoading} isLoading={loading} onConfirmEditor={onConfirmEditor} tabActive={tabActive} />}
                                {isOpenModalWarning && <ModalUpscale isOpenModalWarning={isOpenModalWarning} setIsOpenModalWarning={setIsOpenModalWarning} upscaleImage={upscaleImage} onClickContinuesUpload={continuesUploadFunction} setLoading={setLoading} />}
                            </>}
                    </div>

                </div>
            }
        </div >
    )
}

export default ToolEditor