// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch-container-v2 {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 400px;
  border-radius: 8px;
  background-color: #F5F5F5;
}

.switch-container-v2 input[type="radio"] {
  display: none;
}

.switch-container-v2 label {
  position: relative;
  z-index: 1;
  flex: 1;
  text-align: center;
  padding: 8px 0px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.switch-container-v2 .slider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 33.33%;
  background-color: #DCE2FF;
  background-color: var(--Primary-100, #DCE2FF);
  border: 1px solid #0047FF;
  border: 1px solid var(--Primary-900, #0047FF);
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
  z-index: 0;
}

.switch-container-v2 input[type="radio"]:checked+label p {
  color: #0047FF;
}

.switch-container-v2 input[type="radio"]:nth-child(1):checked~.slider {
  transform: translateX(0%);
}

.switch-container-v2 input[type="radio"]:nth-child(3):checked~.slider {
  transform: translateX(100%);
}

.switch-container-v2 input[type="radio"]:nth-child(5):checked~.slider {
  transform: translateX(200%);
}`, "",{"version":3,"sources":["webpack://./src/components/ModalRequiredSub/Toggle/styles.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,OAAO;EACP,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,kCAAkC;AACpC;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,aAAa;EACb,yBAA6C;EAA7C,6CAA6C;EAC7C,yBAA6C;EAA7C,6CAA6C;EAC7C,kBAAkB;EAClB,sCAAsC;EACtC,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".switch-container-v2 {\n  position: relative;\n  display: flex;\n  justify-content: space-between;\n  width: 400px;\n  border-radius: 8px;\n  background-color: #F5F5F5;\n}\n\n.switch-container-v2 input[type=\"radio\"] {\n  display: none;\n}\n\n.switch-container-v2 label {\n  position: relative;\n  z-index: 1;\n  flex: 1;\n  text-align: center;\n  padding: 8px 0px;\n  cursor: pointer;\n  transition: color 0.3s ease-in-out;\n}\n\n.switch-container-v2 .slider {\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  width: 33.33%;\n  background-color: var(--Primary-100, #DCE2FF);\n  border: 1px solid var(--Primary-900, #0047FF);\n  border-radius: 8px;\n  transition: transform 0.3s ease-in-out;\n  z-index: 0;\n}\n\n.switch-container-v2 input[type=\"radio\"]:checked+label p {\n  color: #0047FF;\n}\n\n.switch-container-v2 input[type=\"radio\"]:nth-child(1):checked~.slider {\n  transform: translateX(0%);\n}\n\n.switch-container-v2 input[type=\"radio\"]:nth-child(3):checked~.slider {\n  transform: translateX(100%);\n}\n\n.switch-container-v2 input[type=\"radio\"]:nth-child(5):checked~.slider {\n  transform: translateX(200%);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
