import React from 'react'
function Loading() {
    return (
        <div className='loading-center flex flex-col'>
            <div className="container-loading">
                <div className="dot-loading"></div>
                <div className="dot-loading"></div>
                <div className="dot-loading"></div>
                <div className="dot-loading"></div>
            </div>

        </div>
    )
}

export default Loading