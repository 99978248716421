import { Button, Radio, Slider, Spin, Switch, Tooltip } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import { convertBase64ImageBlueToWhite, getDrawCursor, getImageSize, scaleImgForSegment } from '../../utils/function';

import {
    DragOutlined, HighlightOutlined, MinusOutlined, PlusOutlined
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setIsAuto } from '../../redux/app/appAutoSegment';
import redo from '../../assets/icons/redo.svg';
import reset from '../../assets/icons/reset.svg';
import undo from '../../assets/icons/undo.svg';
import { removeBlackImage } from './function';
type CanvasEditSegmentProps = {
    url: string,
    segment: string,
    isShowCanvas: boolean,
    setIsShowCanvas: Function,
    onSetSegment: Function,
    setIsOpenModalEdit: Function,
    setSegmentImagesWithBody: Function
}
function CanvasEditSegment({ url, segment, setIsShowCanvas, onSetSegment, setIsOpenModalEdit, setSegmentImagesWithBody }: CanvasEditSegmentProps) {
    const [isSetBg, setIsSetBg] = useState(false)
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const prevImageRef = useRef<fabric.Canvas | null>(null);
    const fabricRef = useRef<fabric.Canvas | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [isDraw, setIsDraw] = useState<boolean>(true);
    const [valueZoom, setValueZoom] = useState<any>(100);
    const [widthPoint, setWidthPoint] = useState<any>(10);
    const [widthPointByZoom, setWidthPointByZoom] = useState<any>(10);
    const [isDragging, setDragging] = useState(false);
    const [modeMove, setModeMove] = useState("brush");
    const [firstLoading, setFirstLoading] = useState(false)
    const [imageAutoLayer, setImageAutoLayer] = useState<
        | {
            imgMaskAuto: string;
            imgMaskedAuto: string;
        }
        | undefined
    >();
    const [ratio, setRatio] = useState<number>(1);
    const [isRightMouseDown, setRightMouseDown] = useState(false);
    const [multiplier, setMultiplier] = useState(1);
    const [canvasSizeData, setCanvasSizeData] = useState<
        | {
            scaleX: number;
            scaleY: number;
            widthCanvas: number;
            heightCanvas: number;
        }
        | undefined
    >();
    const [ready, setReady] = useState(false);
    const [thickness, setThickness] = useState<number>(10);
    const OPACITY = 0.5;

    let isRedoing = false;

    const handleReset = () => {
        isRedoing = true
        if (fabricRef.current) {
            fabricRef.current._objects = []
            fabricRef.current && fabricRef.current.renderAll();
            setValueZoom(100)
            setWidthPoint(10)
            fabricRef.current.setViewportTransform([1, 0, 0, 1, 0, 0]);
        }
        setSelectedImageToCanvas()
    }

    function disablePanning() {
        if (fabricRef.current) {
            fabricRef.current.isDrawingMode = true;
            fabricRef.current.off("mouse:move");
        }
    }

    const canvas_ZoomPlus = (scaleZoom: number) => {
        if (fabricRef.current) {
            const zoomCenter = new fabric.Point(200.0, 200.0);
            fabricRef.current.forEachObject(function (object) {
                object.selectable = true;
            });
            fabricRef.current.on("mouse:up", function (e) {
                panning = false;
            });

            fabricRef.current.on("mouse:down", function (e) {
                panning = false;
            });
            fabricRef.current.zoomToPoint(zoomCenter, scaleZoom);
        }
    };

    const onChangeZoomPlus = (e: number) => {
        canvas_ZoomPlus(e / 100);
        setValueZoom(e);
    };

    const inputOpacityPoint = (e: number) => {
        setWidthPoint(e);
    };

    const handleMinus = (e: any) => {
        const valueInput = widthPoint - 1;
        setWidthPoint(valueInput);
    };

    const handlePlus = (e: any) => {
        const valueInput = widthPoint + 1;
        setWidthPoint(valueInput);
    };

    let panning = false;


    const fillAll = async (arrayPath: any) => {
        try {
            // @ts-ignore
            fabricRef.current?.setBackgroundImage(null);
            fabricRef.current?.setBackgroundColor("black", () => { });
            fabricRef.current?.renderAll();
            const maskBase64 =
                fabricRef.current &&
                (fabricRef.current.toDataURL({
                    format: "png",
                    multiplier: multiplier
                }) as any);
            const finalMaskBase64 = await convertBase64ImageBlueToWhite(maskBase64, OPACITY);
            if (finalMaskBase64) {
                const blueImage = await removeBlackImage(maskBase64)
                setSegmentImagesWithBody(blueImage)
                onSetSegment(finalMaskBase64)
                await initialSetStateBG()
                setIsOpenModalEdit(false)
                dispatch(setIsAuto.setIsAuto(false))
            }
        } catch (error: any) {
            console.log(error)
        }
    };

    const onOke = async () => {
        if (fabricRef.current) {
            fabricRef.current.setViewportTransform([1, 0, 0, 1, 0, 0]);
            if (ready) await fillAll(fabricRef.current._objects);

        }
    };

    const handleContextMenu = (event: any) => {
        if (event.button === 2) {
            disablePanning();
            setRightMouseDown(true);
            event.preventDefault();
        }
    };

    const handleMouseDown = (event: any) => {
        if (+event.button !== 2) return;
        if (isRightMouseDown && fabricRef.current) {
            fabricRef.current.freeDrawingCursor = "grab";
            setDragging(true);
        }
    };

    const handleMouseMove = (event: any) => {
        if (isDragging && isRightMouseDown && fabricRef.current) {
            fabricRef.current.freeDrawingCursor = `grab`;
            const delta = new fabric.Point(event.movementX, event.movementY);
            fabricRef.current.relativePan(delta);
        }
    };

    const handleMouseUp = () => {
        if (isDragging && fabricRef.current) {
            setDragging(false);
            modeMove === "move" && setModeMove("brush");
            fabricRef.current.isDrawingMode = true;
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(
                widthPoint
            )}) ${widthPoint / 2} ${widthPoint / 2}, crosshair`;
        }
        setRightMouseDown(false);
    };

    const onChangeType = (value: string) => {
        const checked = value === "move";
        setModeMove(value);
        if (fabricRef.current && checked) {
            fabricRef.current.selection = false;
            fabricRef.current.isDrawingMode = false;
            fabricRef.current.renderAll();
            fabricRef.current.forEachObject(function (object) {
                object.selectable = false;
            });
            fabricRef.current.on("mouse:up", function (e) {
                panning = false;
            });

            fabricRef.current.on("mouse:down", function (e) {
                panning = true;
            });

            let previousTouch: any;
            checked &&
                fabricRef.current.on("mouse:move", function (e: any) {
                    if (checked && panning && e && e.e && fabricRef.current) {
                        if (e.e.type === "mousemove") {
                            const delta = new fabric.Point(e.e.movementX, e.e.movementY);
                            fabricRef.current.relativePan(delta);
                        }
                        if (e.e.type === "touchmove" && panning) {
                            const touch = e.e?.touches[0];
                            if (previousTouch) {
                                const mX = touch.pageX - previousTouch?.pageX;
                                const mY = touch.pageY - previousTouch?.pageY;
                                const delta = new fabric.Point(mX, mY);
                                fabricRef.current.relativePan(delta);
                            }
                            previousTouch = touch;
                        }
                    }
                });
        }
        if (!checked && fabricRef.current) {
            disablePanning();
        }
    };

    const initialSetStateBG = useCallback(async () => {
        const { width, height } = await getImageSize(url);
        width && height && setRatio(width / height);
        const imgScale = scaleImgForSegment(width && width, height && height);

        if (imgScale) {
            setCanvasSizeData({
                widthCanvas: imgScale.w,
                heightCanvas: imgScale.h,
                scaleX: imgScale.sX,
                scaleY: imgScale.sY,
            });
            setMultiplier(imgScale && imgScale.multiplier);
        }
    }, [url]);

    const setBg = (canvasSizeData: any) => {
        return new Promise<void>((resolve) => {
            fabric.Image.fromURL(
                url,
                function (img: any) {
                    img.set({ erasable: false });
                    fabricRef.current &&
                        fabricRef.current.setBackgroundImage(
                            img,
                            fabricRef.current.renderAll.bind(fabricRef.current),
                            {
                                scaleX: canvasSizeData.scaleX,
                                scaleY: canvasSizeData.scaleY,
                            }
                        );
                    setSelectedImageToCanvas()
                    ratio > 0 &&
                        fabricRef &&
                        fabricRef.current?.setHeight(
                            canvasSizeData.widthCanvas / ratio || 300
                        );
                    fabricRef &&
                        fabricRef.current?.setWidth(canvasSizeData.widthCanvas || 400);
                    resolve();
                },
                { crossOrigin: "*" }
            );
            setIsSetBg(true)
        })
    };



    const setSelectedImageToCanvas = () => {
        canvasSizeData && fabric.Image.fromURL(
            segment,
            function (img: any) {
                img.set({
                    scaleX: canvasSizeData.scaleX,
                    scaleY: canvasSizeData.scaleY,
                    selectable: false,
                    opacity: 0.5
                });

                // Save a reference to the current image
                prevImageRef.current = img;

                fabricRef.current && fabricRef.current.add(img);
                fabricRef.current && fabricRef.current.renderAll();
            },
            { crossOrigin: "*" }
        );
    }
    const initFabric = useCallback(() => {
        // Check if the canvas element is available
        if (canvasRef.current) {
            // Initialize a new Fabric.js canvas with drawing mode enabled
            fabricRef.current = new fabric.Canvas(canvasRef.current, {
                isDrawingMode: true,
            });
            // Set up drawing cursor with a custom cursor image and crosshair fallback
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(widthPoint)}) 5 5, crosshair`;

            // Configure brush settings for free drawing
            fabricRef.current.freeDrawingBrush.width = 10;
            fabricRef.current.freeDrawingBrush.color = "rgba(0, 0, 255, 0.5)";

            // Add event listener for the "object:added" event
            fabricRef.current.on("object:added", function () {
                // Clear history if not redoing
                // if (!isRedoing) {
                //     h = [];
                // }
                isRedoing = false;
            });

            // Add event listener for the "mouse:wheel" event (zooming)
            fabricRef.current.on("mouse:wheel", function (opt: any) {
                // Handle zooming based on mouse wheel movement
                if (fabricRef.current) {
                    const delta = opt.e.deltaY;
                    let zoom = fabricRef.current.getZoom();
                    zoom *= 0.999 ** delta;

                    // Set zoom constraints
                    if (zoom > 20) zoom = 20;
                    if (zoom < 0.01) zoom = 0.01;

                    // Get zoom center point and zoom to that point
                    const zoomCenter = new fabric.Point(opt.pointer.x, opt.pointer.y);
                    fabricRef.current.zoomToPoint(zoomCenter, zoom);
                }
            });
        }
    }, [canvasRef, fabricRef, canvasSizeData, imageAutoLayer]);


    useEffect(() => {
        if (!ready) {
            (async () => {
                await initialSetStateBG();
                setReady(true);
            })();
        }
    }, [ready, initialSetStateBG]);

    useEffect(() => {
        let width = Math.round(widthPoint / (valueZoom / 100));
        if (width === 0) width = 1
        setWidthPointByZoom(width)
    }, [valueZoom, widthPoint])

    useEffect(() => {
        const handleWheel = (opt: any) => {
            if (fabricRef.current) {
                const delta = opt.e.deltaY;
                let zoom = fabricRef.current.getZoom();
                zoom *= 0.999 ** delta;
                if (zoom > 3) zoom = 3;
                if (zoom < 1) zoom = 1;
                if (zoom === 1) {
                    setWidthPoint(10);
                }
                setValueZoom(zoom * 100);
                const zoomCenter = new fabric.Point(opt.pointer.x, opt.pointer.y);
                fabricRef.current.zoomToPoint(zoomCenter, zoom);
            }
        };

        const handleModeOther = () => {
            // handleReset();
            if (fabricRef.current) {
                fabricRef.current.isDrawingMode = true;
                fabricRef.current.on('mouse:wheel', handleWheel);
            }
        };

        const initializeFabric = async () => {
            if (!fabricRef.current) {
                initFabric();
            }
        };

        const setBackground = async (canvasSizeData: any) => {
            if (canvasSizeData) {
                await setBg(canvasSizeData);
            }
        };

        if (ready) {
            (async () => {
                await initializeFabric();
                await setBackground(canvasSizeData);
                handleModeOther();
            })();
        }
    }, [ready, initFabric, canvasSizeData, imageAutoLayer]);

    useEffect(() => {
        if (fabricRef.current) {
            fabricRef.current.freeDrawingBrush.width = widthPointByZoom;
            fabricRef.current.freeDrawingCursor = `url(${getDrawCursor(widthPoint)}) ${widthPoint / 2} ${widthPoint / 2}, crosshair`
        }
    }, [widthPointByZoom])

    useEffect(() => {
        if (fabricRef.current) {
            if (isDraw) {
                fabricRef.current.freeDrawingBrush = new fabric.PencilBrush(fabricRef.current);
                fabricRef.current.freeDrawingBrush.width = widthPoint;
                fabricRef.current.freeDrawingBrush.color = "rgba(0, 0, 255, 0.5)";
            } else {
                // @ts-ignore
                fabricRef.current.freeDrawingBrush = new fabric.EraserBrush(fabricRef.current);
                fabricRef.current.freeDrawingBrush.width = widthPoint;
            }
        }
    }, [isDraw])
    let h: any = [];

    const handleUndo = () => {
        if (fabricRef.current && fabricRef.current._objects.length == 1) {
            //still bg image selected
            return
        }
        if (fabricRef.current && fabricRef.current._objects.length > 0) {
            h.push(fabricRef.current && fabricRef.current._objects.pop());
            fabricRef.current && fabricRef.current.renderAll();
        }
    }

    const handleRedo = () => {
        if (h.length > 0) {
            isRedoing = true;
            fabricRef.current && fabricRef.current.add(h.pop());
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setFirstLoading(true)
        }, 200)
    }, [])
    return (
        <Spin spinning={!firstLoading}>
            <div className='flex flex-col gap-2'>
                <div className="flex gap-2 flex-row sm:flex-col  md:flex-col lg:flex-col w-full justify-between  md:items-center sm:items-center lg:items-center ">
                    <div className="flex flex-col justify-between gap-4 w-full  max-w-[50%] sm:max-w-full  md:max-w-full lg:max-w-full">
                        <div>
                            <div className='flex flex-row justify-between p-x-2'>
                                <div className='sub-title text-base font-semibold text-left'>{t('action')}</div>
                                <div className='btn-back-to-segment'>
                                    <Button onClick={() => { setIsShowCanvas(false) }}>{t('back_to_segment_manual')}</Button>
                                </div>
                            </div>
                            <div className="flex justify-center items-center flex-col">
                                <div className='container-button flex justify-between w-full'>
                                    <div className='box-button flex gap-2'>
                                        <div className='undo hover:cursor-pointer' onClick={handleUndo}>
                                            <img src={undo} alt="" />
                                        </div>
                                        <div className='redo hover:cursor-pointer' onClick={handleRedo}>
                                            <img src={redo} alt="" />
                                        </div>
                                    </div>
                                    <div className='reset flex gap-2'>
                                        <div className='hover:cursor-pointer' onClick={handleReset}>
                                            <img src={reset} alt="" />
                                        </div>
                                        <div className='text-[#667085]'>{t('Reset')}</div>
                                    </div>
                                </div>
                                <div className="flex flex-col gap-2 w-full">
                                    <div className="flex gap-2 flex-col">
                                        <div className='flex flex-row justify-between min-w-1/3  gap-2'>
                                            <div className='text-left'>
                                                {t('size_brush')}:
                                            </div>
                                            <div className="flex gap-2 justify-center items-center">
                                                <Button
                                                    shape="circle"
                                                    type="default"
                                                    icon={<MinusOutlined />}
                                                    onClick={(e) => handleMinus(e)}
                                                />
                                                <Slider
                                                    min={1}
                                                    max={100}
                                                    value={widthPoint}
                                                    onChange={(e) => inputOpacityPoint(e)}
                                                    className="min-w-[210px]"
                                                />
                                                <Button
                                                    shape="circle"
                                                    type="default"
                                                    icon={<PlusOutlined />}
                                                    onClick={(e) => handlePlus(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='flex flex-row justify-between gap-2 min-w-1/3'>
                                            <div className='text-left'>{t('zoom_image')}:</div>
                                            <div className="flex gap-2 justify-center items-center">
                                                <div>100%</div>
                                                <Slider
                                                    min={100}
                                                    max={300}
                                                    defaultValue={100}
                                                    value={valueZoom}
                                                    onChange={onChangeZoomPlus}
                                                    className="max-w-[210px] min-w-[170px]"
                                                />
                                                <div>300%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className='w-full flex justify-center gap-4 mt-2'>
                            <Button danger className='w-full' onClick={() => setIsOpenModalEdit(false)}>{t('cancel')}</Button>
                            <Button type='primary' className='w-full' onClick={onOke}>{t('confirm')}</Button>
                        </div>
                    </div >
                    <Spin spinning={!isSetBg}>
                        <div
                            className="border-smooth-left relative bg-[#CCD9FF] max-w-full  h-full  flex justify-center items-center"
                            onContextMenu={(e) => handleContextMenu(e)}
                            onMouseDown={(e) => handleMouseDown(e)}
                            onMouseMove={(e) => handleMouseMove(e)}
                            onMouseUp={handleMouseUp}
                        >
                            <canvas
                                id="mycanvas"
                                ref={canvasRef}
                            ></canvas>
                            <div className={"absolute bottom-0 right-0 opacity-50"}>
                                <Radio.Group
                                    style={{ width: "100%" }}
                                    buttonStyle="solid"
                                    value={modeMove}
                                    onChange={(e) => onChangeType(e.target.value)}
                                >
                                    <Radio.Button value="brush">
                                        <HighlightOutlined />{" "}
                                    </Radio.Button>
                                    <Radio.Button value="move">
                                        <DragOutlined />
                                    </Radio.Button>
                                </Radio.Group>
                            </div>

                            <div className={"absolute mx-1 bottom-0 left-0 opacity-50"}>
                                <Switch onChange={(value: any) => setIsDraw(value)} checkedChildren="Draw" unCheckedChildren="Erase" defaultChecked />
                            </div>
                        </div>
                    </Spin>

                </div >
                {/* </Modal> */}
            </div >
        </Spin>
    )
}

export default CanvasEditSegment