import {
    CaretDownOutlined,
    SearchOutlined,
    LeftOutlined
} from '@ant-design/icons';
import { Button, PaginationProps, TableColumnsType, Tooltip } from 'antd';
import { ConfigProvider, DatePicker, Image, Input, Pagination, Select, Table } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FashionService from '../../../services/fashion.service';
import NotifyController from '../../../utils/toast';
import { ArrowUpOutlined, ArrowDownOutlined, SortAscendingOutlined, SortDescendingOutlined, VerticalAlignBottomOutlined, DeliveredProcedureOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { ROUTERS } from '../../../utils/constant';
import { handleResponseError } from '../../../utils/responseError';

interface DataType {
    outfitCode: string;
    category: string;
    rate: number;
    totalGen: number;
    userCount: number,
    genPerUser: number,
    outfits?: DataType[];
}

interface OutfitType extends Omit<DataType, 'outfits'> {
    imageUrl: string;
}
let timeoutId: any

function ReportShop() {
    const [isLoading, setIsLoading] = useState(true)
    const [totalRecord, setTotalRecord] = useState<number>(0)
    const [dataReport, setDataReport] = useState<DataType | any>()
    const [categories, setCategories] = useState<any[]>([])
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { RangePicker } = DatePicker;
    const [sortingObj, setSortingObj] = useState({
        sortCol: '',
        sortType: 1
    })
    const [filters, setFilters] = useState({
        inputValue: '',
        categorySelected: '',
        startTime: 0,
        endTime: 0,
        pageSize: 20,
        currentPage: 1,
        interval: '7d',
        shopName: ''
    })

    const SHOP_NAMES = [
        {
            key: '',
            value: t('all')
        },
        {
            key: 'admin',
            value: t('place_select_shop_name_admin')
        },
        {
            key: 'user',
            value: t('place_select_shop_name_user')
        }
    ]

    const getDataCategory = async () => {
        try {
            const { data } = await FashionService.getInstance().getVTOCategories();
            if (data) {
                setCategories([
                    {
                        key: '',
                        value: t('all'),
                    },
                    ...data.map((item: any) => {
                        return {
                            key: item._id,
                            value: item.category_name,
                        };
                    })
                ]);
            }
        } catch (error) {
            handleResponseError(error);
        }
    };


    const handleChangeCategory = (key: any, option: any) => {
        setFilters((preFilter) => ({
            ...preFilter,
            categorySelected: option.key
        }))
    }
    const handleShopNameChanged = (key: any, option: any) => {
        setFilters((preFilter) => ({
            ...preFilter,
            shopName: option.key
        }))
    }
    const handleChangeTypeTime = (value: any) => {
        let changed: any = { interval: value };


        if (value !== 'customize') {
            let valueNumber = 0;
            if (value === '7d') {
                valueNumber = 7;
            }
            if (value === '30d') {
                valueNumber = 30;
            }
            if (value === '90d') {
                valueNumber = 90;
            }

            if (valueNumber > 0) {
                const start = moment().subtract(valueNumber, 'days');
                const end = moment();

                changed = {
                    ...changed,
                    startTime: start.valueOf(),
                    endTime: end.valueOf(),
                }
            } else {
                changed = {
                    ...changed,
                    startTime: 0,
                    endTime: 0,
                }
            }
        }
        setFilters((preFilter) => ({
            ...preFilter,
            ...changed
        }))
    }
    const onChangePage: PaginationProps['onChange'] = (pageNumber) => {
        setFilters((preFilter) => ({
            ...preFilter,
            currentPage: pageNumber
        }))
    };
    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (_, pageSize) => {
        setFilters((preFilter) => ({
            ...preFilter,
            pageSize
        }))
    };
    const columns: TableColumnsType<DataType> = [
        {
            title: t('outfitCode'), dataIndex: 'outfitCode', key: 'outfitCode', width: '15%',
            sorter: {},
            sortIcon: ({ sortOrder }) => {
                if (!sortOrder) {
                    return <></>
                } else
                    return (

                        <>
                            {sortOrder === 'ascend' ? <ArrowUpOutlined className={'text-primary text-lg'} /> : <ArrowDownOutlined className={'text-primary text-lg'} />}
                        </>
                    )
            }

        },
        {
            title: t('category'), dataIndex: 'category', key: 'category', width: '15%',
            sorter: {},
            sortIcon: ({ sortOrder }) => {
                if (!sortOrder) {
                    return <></>
                } else
                    return (

                        <>
                            {sortOrder === 'ascend' ? <ArrowUpOutlined className={'text-primary text-lg'} /> : <ArrowDownOutlined className={'text-primary text-lg'} />}
                        </>
                    )
            }
        },
        {
            title: t('number_of_user'), dataIndex: 'userCount', key: 'userCount', width: '15%',
            sorter: {},
            sortIcon: ({ sortOrder }) => {
                if (!sortOrder) {
                    return <></>
                } else
                    return (

                        <>
                            {sortOrder === 'ascend' ? <ArrowUpOutlined className={'text-primary text-lg'} /> : <ArrowDownOutlined className={'text-primary text-lg'} />}
                        </>
                    )
            }
        },
        {
            title: t('total_gen'), dataIndex: 'totalGen', key: 'totalGen', width: '15%',
            sorter: {},
            sortIcon: ({ sortOrder }) => {
                if (!sortOrder) {
                    return <></>
                } else
                    return (

                        <>
                            {sortOrder === 'ascend' ? <ArrowUpOutlined className={'text-primary text-lg'} /> : <ArrowDownOutlined className={'text-primary text-lg'} />}
                        </>
                    )
            }
        },
        {
            title: t('number_of_gen_per_user'), dataIndex: 'genPerUser', key: 'genPerUser', width: '20%',
            sorter: {},
            sortIcon: ({ sortOrder }) => {
                if (!sortOrder) {
                    return <></>
                } else
                    return (
                        <>
                            {sortOrder === 'ascend' ? <ArrowUpOutlined className={'text-primary text-lg'} /> : <ArrowDownOutlined className={'text-primary text-lg'} />}
                        </>
                    )
            },
            render: (e: number) => <p>{formatRate(e)}</p>
        },

        {
            title: t('rate_by'), dataIndex: 'rate', key: 'rate', width: '20%',
            sorter: {},
            sortIcon: ({ sortOrder }) => {
                if (!sortOrder) {
                    return <></>
                } else
                    return (

                        <>
                            {sortOrder === 'ascend' ? <ArrowUpOutlined className={'text-primary text-lg'} /> : <ArrowDownOutlined className={'text-primary text-lg'} />}
                        </>
                    )
            },
            render: (e: number) => {
                if (e < 0) {
                    return <div className='flex gap-1 justify-center items-center'>
                        <CaretDownOutlined className='text-[#FF3A44]' />
                        <div className='text-[#FF3A44]' >
                            {formatPercentRate(e)}%
                        </div>
                    </div>
                } else {
                    return <div className='flex gap-1 justify-center items-center'>
                        <CaretDownOutlined className='text-[#15CF74] rotate-180' />
                        <div className='text-[#15CF74]'>
                            {e !== 0 ? formatPercentRate(e) : e}%
                        </div>
                    </div>
                }
            }
        }
    ];
    const customizeRenderEmpty = () => {
        return (
            <div className="text-center empty">
                <h2>Data Empty</h2>
                <p>You don't have any data yet.</p>
            </div>
        );
    };

    const formatPercentRate = (rate: number) => (rate * 100).toFixed(2);
    const formatRate = (rate: number) => rate.toFixed(2);
    const columnsExpanded: TableColumnsType<OutfitType> = [
        {
            title: t('image'), dataIndex: 'imageUrl', key: 'imageUrl', width: '15%',
            render: (e) => <Image src={e} alt="" width={100} />
        },
        {
            title: t('category'), dataIndex: 'category', key: 'category', width: '15%',
            sorter: {}
        },
        {
            title: t('number_of_user'), dataIndex: 'userCount', key: 'userCount', width: '15%',
            sorter: {}
        },
        {
            title: t('total_gen'), dataIndex: 'totalGen', key: 'totalGen', width: '15%',
            sorter: {}
        },
        {
            title: t('number_of_gen-per_user'), dataIndex: 'genPerUser', key: 'genPerUser', width: '20%',
            sorter: {},
            render: (e) => <>{formatRate(e)}</>
        },

        {
            title: t('rate_by'), dataIndex: 'rate', key: 'rate', width: '20%',
            sorter: {},
            render: (e: number) => {
                if (e < 0) {
                    return <div className='flex gap-1 justify-center items-center'>
                        <CaretDownOutlined className='text-[#FF3A44]' />
                        <div className='text-[#FF3A44]' >
                            {formatPercentRate(e)}%
                        </div>
                    </div>
                } else {
                    return <div className='flex gap-1 justify-center items-center'>
                        <CaretDownOutlined className='text-[#15CF74] rotate-180' />
                        <div className='text-[#15CF74]'>
                            {e !== 0 ? formatPercentRate(e) : e}%
                        </div>
                    </div>
                }
            }
        }
    ];
    const expandedRowRender = (parentOrder: OutfitType[]) => {
        return (<ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Table
                className="suborders__table"
                rowKey="imageUrl"
                showHeader={false}
                columns={columnsExpanded}
                dataSource={parentOrder}
                pagination={false}
            />
        </ConfigProvider>)
    };

    const onChangeTime = (dates: any, dateString: any) => {
        if (dates) {
            const [start, end] = dates;
            const startTimestamp = start.valueOf();
            const endTimestamp = end.valueOf();
            setFilters((preFilter) => ({
                ...preFilter,
                startTime: startTimestamp,
                endTime: endTimestamp
            }))
        } else {
            console.log('No dates selected');
        }
    };
    const getAllDataReport = () => {
        FashionService
            .getInstance()
            .getDataReportVTO(1, 100000,
                filters.inputValue,
                filters.categorySelected,
                filters.startTime,
                filters.endTime,
                filters.interval,
                filters.shopName,
                sortingObj)
            .then(({ data }) => {
                downloadxls(data.reports)
            }).catch((error) => {
                handleResponseError(error, t('something_wrong'));
            })
    }
    const downloadxls = (dataReport: any) => {
        const structuredData = dataReport.flatMap((item: any, idx: number) => {
            // Parent item row
            const parentRow = {
                No: idx + 1,
                outfitCode: item.outfitCode,
                category: item.category,
                totalGen: item.totalGen,
                userCount: item.userCount,
                genPerUser: item.genPerUser,
                rate: `${formatPercentRate(item.rate)}%`,
                outfitImageUrl: '', // Leave empty to differentiate parent row
                outfitTotalGen: '',
                outfitUserCount: '',
                outfitGenPerUser: '',
                outfitRate: ''
            };

            // Child outfit rows with indentation
            const outfitRows = item.outfits.map((outfit: any) => ({
                outfitCode: '', // Leave empty for indentation
                category: '',
                totalGen: '',
                userCount: '',
                genPerUser: '',
                rate: '',
                outfitImageUrl: outfit.imageUrl,
                outfitTotalGen: outfit.totalGen,
                outfitUserCount: outfit.userCount,
                outfitGenPerUser: outfit.genPerUser,
                outfitRate: `${formatPercentRate(outfit.rate)}%`
            }));

            // Combine parent and child rows
            return [parentRow, ...outfitRows];
        });

        // Generate the worksheet
        let ws = XLSX.utils.json_to_sheet(structuredData);

        // Calculate column widths based on the maximum length of the content in each column
        const colWidths = Object.keys(structuredData[0]).map(key => {
            return {
                wch: Math.max(
                    key.length, // Header width
                    ...structuredData.map((row: any) => (row[key] ? row[key].toString().length : 0)) // Data width
                )
            };
        });

        // Apply the calculated column widths to the worksheet
        ws['!cols'] = colWidths;

        // Create a new workbook and append the sheet
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "sheet");

        // Write the workbook to a file
        XLSX.writeFile(wb, `modeli-report.xlsx`);
    };

    useEffect(() => {
        getDataCategory();

        return () => {
            timeoutId && clearTimeout(timeoutId);
        };
    }, [])

    useEffect(() => {
        setIsLoading(true)
        FashionService
            .getInstance()
            .getDataReportVTO(filters.currentPage, filters.pageSize,
                filters.inputValue,
                filters.categorySelected,
                filters.startTime,
                filters.endTime,
                filters.interval,
                filters.shopName,
                sortingObj)
            .then(({ data }) => {
                setIsLoading(false)
                setTotalRecord(data.totalRecord)
                setDataReport(data.reports)
            }).catch((error) => {
                setIsLoading(true)
                handleResponseError(error, t('something_wrong'));
            })
    }, [filters, sortingObj])
    const onChangeSearch = (inputValue: string) => {
        timeoutId && clearTimeout(timeoutId)
        timeoutId = setTimeout(() => {
            setFilters((prevFilters) => ({
                ...prevFilters,
                inputValue
            }));
        }, 500);
    }
    const today = dayjs();
    const sevenDaysAgo = today.subtract(7, 'day');
    return (
        <div className='flex flex-col gap-2 ml-10 pb-8'>
            <div className='flex gap-2  text-[20px] pb-2' >
                <div className='hover:cursor-pointer' onClick={() => { navigate(-1) }}>
                    <LeftOutlined className='!text-[20px]' />
                    {t('back')}
                </div>
            </div>
            <div className='flex gap-4'>
                <div className='search-button w-[200px]'>
                    <Input className='h-10 w-[200px] rounded-lg' allowClear prefix={<SearchOutlined />}
                        placeholder={t('search_by_outfit_code')} onChange={(e: any) => onChangeSearch(e.target.value)} />
                </div>
                <div>
                    <Select
                        placeholder={t('place_select_category')}
                        size='large'
                        className='vto-select-category'
                        style={{ width: '160px', height: '40px !important', borderRadius: '8px' }}
                        onChange={(key, value) => handleChangeCategory(key, value)}
                        options={categories}
                        defaultValue={t('all')}
                    />
                </div>
                <div>
                    <Select
                        placeholder={t('place_select_shop_name')}
                        size='large'
                        className='vto-select-shop-name'
                        style={{ width: '160px', height: '40px !important', borderRadius: '8px' }}
                        onChange={(key, value) => handleShopNameChanged(key, value)}
                        options={SHOP_NAMES}
                        defaultValue={t('all')}
                    />

                </div>
                <div className='flex gap-1'>
                    <Select
                        placeholder={t('place_select_time')}
                        size='large'
                        className='vto-select-category'
                        style={{ width: '200px', height: '40px !important', borderRadius: '8px' }}
                        options={[
                            {
                                value: '7d',
                                label: t('7d'),
                            },
                            {
                                value: '30d',
                                label: t('30d'),
                            },
                            {
                                value: '90d',
                                label: t('90d'),
                            },
                            {
                                value: 'customize',
                                label: t('customize'),
                            },
                        ]}
                        value={filters.interval}
                        onChange={handleChangeTypeTime}
                    />
                    <div>
                        {filters.interval === 'customize' && <RangePicker
                            showNow
                            defaultValue={[sevenDaysAgo, today]}
                            onChange={onChangeTime}
                            style={{
                                height: '40px',
                                width: '250px'
                            }} />}
                    </div>
                    <div>
                        <Tooltip trigger={'click'} color={'white'} placement="bottom" title={
                            <div className='flex flex-col gap-2  bg-white p-3'>
                                <div>
                                    <Button onClick={() => { downloadxls(dataReport) }} type='primary' className='min-w-[200px]' icon={<VerticalAlignBottomOutlined />}>{t('export_current_page')}</Button>
                                </div>
                                <div>
                                    <Button onClick={getAllDataReport} type='primary' className='min-w-[200px]' icon={<VerticalAlignBottomOutlined />}>{t('export_all')}</Button>
                                </div>
                            </div>
                        }>
                            <Button icon={<DeliveredProcedureOutlined />} type='primary' className='h-10'>{t('export')}</Button>
                        </Tooltip>
                    </div>
                </div>
            </div >
            <Table

                locale={{
                    triggerDesc: t('sort_desc'),
                    triggerAsc: t('sort_asc'),
                    cancelSort: t('cancel_sort')
                }}
                className='table-report'
                loading={isLoading}
                pagination={false}
                columns={columns}
                rowKey="outfitCode"
                expandable={{
                    expandedRowRender: record => record.outfits ? expandedRowRender(record?.outfits as any) : null,
                }}
                dataSource={dataReport}
                onChange={(_, filters, sorter) => {
                    setSortingObj({
                        // @ts-ignore
                        sortCol: String(sorter.field),
                        // @ts-ignore
                        sortType: String(sorter.order) ? (String(sorter.order) === 'ascend' ? 1 : -1) : 1
                    })
                }}
            />
            <Pagination
                className='flex justify-center items-center'
                responsive
                pageSize={filters.pageSize}
                onChange={onChangePage}
                onShowSizeChange={onShowSizeChange}
                showSizeChanger
                total={totalRecord}
                current={filters.currentPage}
            />
        </div >
    )
}

export default ReportShop