import { Button, InputNumber } from "antd";
import { useEffect, useRef, useState } from "react";
import "./modal-resize.css";
import { fabric } from "fabric";
import { resizeImageBase64 } from "../../utils/function";
import { Editor, EditorMode } from "mini-canvas-editor";
import "../../mini-canvas.css";
import 'mini-canvas-editor/css/editor.css';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../redux/hooks/useAppSelector";
import { useDispatch } from "react-redux";
import { editorImage } from "../../redux/app/appImagePreprocessing";
import NotifyController from "../../utils/toast";
import { toolEditorImage } from "../../redux/app/toolEditor";
import { TypeEditor } from "../../utils/enum";
import Heading from "../../pages/Editor/DetailsTools/Heading";

type ModalResizeCanvasProps = {
  tabActive: string;
  setTabActive: Function;
  isLoading: boolean;
  onConfirmEditor: any;
  typeInput: TypeEditor;
  onClickSave: () => void;
  onClickBackToHome: () => void
};

function ModalResizeCanvas({
  tabActive,
  setTabActive,
  isLoading,
  onConfirmEditor,
  typeInput,
  onClickSave,
  onClickBackToHome
}: ModalResizeCanvasProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let imageData = useAppSelector((store) => store.EditorImage);
  const imageTool = useAppSelector((store) => store.toolEditor);
  const editorRef = useRef<HTMLElement | any>(null);
  const [hasRender, setHasRender] = useState(false);
  const destroyEffect = async () => {
    await editorRef?.current.destroy();
  };
  let imageRealtime: string = imageData.imageUrl
  const getImage = async () => {
    if (!editorRef || !editorRef.current) throw new Error('Not found edtior ref');
    const urlImage = editorRef.current.render().toDataURL();
    const urlReplaced = await resizeImageBase64(urlImage); //replace pixel transparent to white
    const payload = {
      width: imageData.width,
      height: imageData.height,
      imageUrl: urlReplaced,
    };
    NotifyController.success(t("apply_success"));
    dispatch(editorImage.setImageEditor(payload));
    setHasRender(false);
  };

  const setBg = async (url: string) => {
    const editorHTMLElement = document.getElementById(
      "editor-placeholder"
    ) as HTMLElement;

    if (editorHTMLElement) {
      const w = imageData.width;
      const h = imageData.height;
      // fabric.Image.fromURL(
      // imageData.imageUrl,
      // (img: any) => {
      const image = new Image(imageData.width, imageData.height);
      image.src = url;
      editorHTMLElement.style.height = `${getCanvasHeight(imageData.height)}px`;

      const editor = Editor.createFromImage(
        editorHTMLElement,
        image,
        {
          workspaceWidth: +w,
          workspaceHeight: +h,
          fitToWorkspace: true,
          select: true,
        },
        {
          initialMode: EditorMode.select,
          brush: false,
          rect: false,
          image: false,
          textbox: false,
          sidebar: false,
        }
      );
      editor.onChanged.subscribe(() => {
        const png = editor.render().toDataURL('img/png');
        imageRealtime = png
      })
      // editorRef.current.addEventListener('mouseup', () => {
      //   const png = editor.render().toDataURL('img/png');
      //   imageRealtime = png

      // })
      editorRef.current = editor;
      editor.getWorkspaceObjects()[0].set({
        cornerColor: "#0047FF",
        corderSize: 15,
        cornerStyle: 'circle',
        transparentCorners: false,
        borderColor: 'black',
        borderDashArray: [5, 5],
      })
    }
  };
  const onConfirmEditorComponent = async () => {
    if (hasRender && imageRealtime) {
      setTabActive('cut')
      const urlReplaced = await resizeImageBase64(imageRealtime); //replace pixel transparent to white 
      const payload = {
        width: imageData.width,
        height: imageData.height,
        imageUrl: urlReplaced ? urlReplaced : imageData.imageUrl,
      };
      dispatch(editorImage.setImageEditor(payload))
      onConfirmEditor(payload)
    }
  }
  const onSkip = () => {
    const payload = {
      width: imageData.width,
      height: imageData.height,
      imageUrl: imageData.imageUrl,
    };
    dispatch(editorImage.setImageEditor(payload));
    setTabActive("remove_watermark");
  };

  const getCanvasHeight = (defaultHeight?: number) => {
    return imageData.renderedImageSize ? imageData.renderedImageSize.height : (defaultHeight ?? 600);
  };
  useEffect(() => {
    return () => {
      (async () => {
        if (hasRender && imageRealtime) {
          const urlReplaced = await resizeImageBase64(imageRealtime); //replace pixel transparent to white 
          const payload = {
            width: imageData.width,
            height: imageData.height,
            imageUrl: urlReplaced ? urlReplaced : imageData.imageUrl,
          };
          dispatch(toolEditorImage.setSizeImage({
            width: imageData.width,
            height: imageData.height,
          }))
          dispatch(toolEditorImage.setImageInput(urlReplaced ? urlReplaced : imageData.imageUrl))
          dispatch(editorImage.setImageEditor(payload));
        }
      })()
    }
  }, [imageData, hasRender])
  useEffect(() => {
    if (typeInput === TypeEditor.POST || typeInput === TypeEditor.UPLOAD) {
      const imageCropData2 = {
        ...imageData,
        imageUrl: imageTool.inputImage,
        height: imageTool.heightImage,
        width: imageTool.widthImage,
      }
      imageData = imageCropData2
    }
    (async () => {
      if (hasRender || typeInput === TypeEditor.POST || typeInput === TypeEditor.UPLOAD) {
        const editorDiv = document.getElementById(
          "editor-placeholder"
        ) as HTMLElement;
        if (!editorDiv) return;
        await setBg(imageData.imageUrl);
      }
      return () => {
        if (editorRef?.current) {
          destroyEffect();
        }
      };
    })();
  }, [tabActive, hasRender, imageData]);

  useEffect(() => {
    if (!hasRender) {
      setHasRender(true);
    }
  }, [hasRender, imageData.renderedImageSize]);

  return (
    <div>
      <div
        className={
          tabActive === "resize"
            ? " flex flex-col gap-4 justify-around items-start sm:items-center md:items-center lg:items-center  w-full sm:flex-col md:flex-col lg:flex-col"
            : "hidden"
        }
      >
        <div className="w-full">
          <Heading title={t('resize_image')} onSave={onClickSave} onClickBackToHome={onClickBackToHome} />
        </div>
        <div className="flex flex-row gap-4 justify-between items-start sm:items-center md:items-center lg:items-center  w-full sm:flex-col md:flex-col lg:flex-col">
          <div className="flex flex-col min-h-[75vh] items-start justify-between  gap-4 w-full min-w-[400px] ml-10 sm:justify-center md:justify-center lg:justify-center xl:justify-start  box-button-pre ">
            <div>
              <hr className="bg-[#F5F5F5] h-[1px] border-none mt-2 " />
              <div className='sub-title text-base font-semibold text-left'>{t('guide')}</div>
              <div className="mt-3 w-auto  text-left text-gray text-guide box-guide-tool">
                {t('text_guide_resize_canvas')}
              </div>
              {/* <div className='sub-title text-base font-semibold text-left'>{t('info')}</div> */}
              {/* <div className="flex gap-4 text-left mt-2 ">
                <div className="flex flex-col w-full gap-1 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                  <div>{t("width")}</div>
                  <div>
                    {" "}
                    <InputNumber
                      disabled
                      readOnly={true}
                      value={imageData.width}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full gap-1 sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2">
                  <div>{t("height")}</div>
                  <div>
                    {" "}
                    <InputNumber
                      disabled
                      readOnly={true}
                      value={imageData.height}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="w-full">
              <hr className="bg-[#F5F5F5] h-[1px] border-none mt-3" />
              <div className={`btn-confirm mt-4 flex justify-center items-center w-full ${typeInput === TypeEditor.PRE ? '' : ' !hidden'}`}>
                <Button onClick={onConfirmEditorComponent} type='primary' disabled={isLoading} className='w-full ' >{t('done')}</Button>
              </div>
            </div>
          </div>

          <div className="pre-processing-left  relative box-canvas-pre  bg-[#CCD9FF] !w-[65vw] w-full min-h-3/4 flex justify-center items-center min-h-[75vh]  ">
            {hasRender && (
              <div
                id="editor-placeholder"
                ref={editorRef}
                className={`flex justify-center items-center box-canvas-pre h-[${getCanvasHeight()}px] w-3/4`}
              ></div>
            )}
          </div>
        </div>
      </div>

    </div>
  );
}

export default ModalResizeCanvas;
