// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
}

.current-language {
    font-weight: 400;
    margin-left: 8px;
}

.dropdown-custom {
    background: #101828; /* Set the background color to a dark shade */
    box-shadow: 0px 8px 28px -6px rgba(255, 255, 255, 0.12), 0px 18px 88px -4px rgba(255, 255, 255, 0.14);
    border-radius: 12px;
    position: absolute;
    top: 36px;
    right: 0;
    overflow: hidden;
    width: 120px;
}

.dropdown-item {
    padding: 8px 16px;
    font-size: 14px;
    color: #ffffff;
}

/* Set the text color to white */

.dropdown-item:hover {
        background-color: #374151 !important;
    }

.dropdown-item:hover {
        cursor: pointer; /* Set the hover background color to a darker shade */
    }`, "",{"version":3,"sources":["webpack://./src/components/LanguageSwitch/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB,EAAE,6CAA6C;IAClE,qGAAqG;IACrG,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;IACT,QAAQ;IACR,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,cAAc;AAKlB;;AALoB,gCAAgC;;AAChD;QAEI,oCAAoC;IACxC;;AAHA;QACI,eAAe,EACuB,qDAAqD;IAC/F","sourcesContent":[".language-container {\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    position: relative;\n}\n\n.current-language {\n    font-weight: 400;\n    margin-left: 8px;\n}\n\n.dropdown-custom {\n    background: #101828; /* Set the background color to a dark shade */\n    box-shadow: 0px 8px 28px -6px rgba(255, 255, 255, 0.12), 0px 18px 88px -4px rgba(255, 255, 255, 0.14);\n    border-radius: 12px;\n    position: absolute;\n    top: 36px;\n    right: 0;\n    overflow: hidden;\n    width: 120px;\n}\n\n.dropdown-item {\n    padding: 8px 16px;\n    font-size: 14px;\n    color: #ffffff; /* Set the text color to white */\n    &:hover {\n        cursor: pointer;\n        background-color: #374151 !important; /* Set the hover background color to a darker shade */\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
