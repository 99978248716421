import * as React from 'react';
import { useNavigatorOnLine } from '../hooks/useNavigatorOnLine';
import { ROUTERS } from '../utils/constant';
import { useNavigate } from 'react-router-dom';

const NetworkStatusIndicator = () => {
    const isOnline = useNavigatorOnLine();
    const firstUpdate = React.useRef(true);
    const navigator = useNavigate()
    React.useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        isOnline ? navigator(-1) : navigator(ROUTERS.OFFLINE);
    }, [isOnline]);

    return null;
};
export default NetworkStatusIndicator